import { UserOutlined } from "@ant-design/icons";
import { CardTitle, KButton } from "@components";
import { assignOrClaimToDoService } from "@services";
import { useDetailCard, useProfile } from "@store";
import {
  ETodoType,
  IAvatar,
  ICardTodoDetail,
  IParams,
  IUserAccessProfile
} from "@types";
import { Flex, message } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";
import { UserAccessChecklist } from "./UserAccessChecklist";
import { useLoggingService } from "@hooks";

export const AssignedToSection = ({ assigned }: { assigned: string[] }) => {
  const { cardId } = useParams<IParams>();
  const [assignedList, setAssignedList] = useState(assigned);
  const [loading, setLoading] = useState(false);
  const { usersAccessProfile: users } = useProfile();
  const {
    getValues,
    setValue,
  } = useFormContext<ICardTodoDetail>();
  const { editableDetail } = useDetailCard();
  const { pushInfoTypeLog } = useLoggingService();

  useEffect(() => {
    setAssignedList(assigned);
  }, [assigned]);

  useEffect(() => {
    const isDirty = assigned?.length !== assignedList?.length;
    if (!isDirty || !getValues() || !editableDetail || !assignedList || loading) return;
    (async () => {
      try {
        setLoading(true);
        const { _id, profile } = getValues();
        const { assigned } = await assignOrClaimToDoService(
          profile,
          _id,
          ETodoType.ASSIGN,
          assignedList
        );
        const resetAssigned: IAvatar[] = assigned?.map((_id) => {
          return { _id, firstName: "", lastName: "" };
        });
        setValue("assigned", resetAssigned, { shouldDirty: false });
        pushInfoTypeLog("MODIFIED", getValues());
        message.success("Assign to user successfully!");
      } catch (err: any) {
        message.error(err?.response?.data?.message);
      } finally {
        setLoading(false);
      }
    })();
  }, [cardId, assignedList, editableDetail, getValues]);

  const handleCheckAssigned = (
    e: CheckboxChangeEvent,
    user: IUserAccessProfile
  ) => {
    if (loading) return;
    const { checked } = e.target;
    const { user: _id } = user;
    if (checked) {
      setAssignedList((prev) => [...prev, _id]);
    } else {
      const newCheckList = assignedList.filter((u) => u !== _id);
      setAssignedList(newCheckList);
    }
  };

  const handleCheckAll = () => {
    if ((users?.length === assignedList?.length) || loading) return;
    const allUsers = users?.map(({ user }) => user) || [];
    setAssignedList(allUsers);
  };

  return (
    <Flex vertical justify="between" gap={8}>
      <CardTitle
        icon={<UserOutlined style={{ fontSize: 16 }}/>}
        suffix={
          <KButton
            title="Select All"
            type="link"
            disabled={loading || !editableDetail}
            onClick={handleCheckAll}
          />
        }
      >
        Assigned To
      </CardTitle>
      <UserAccessChecklist
        ids={assignedList}
        onCheck={handleCheckAssigned}
        disabled={loading}
        assignMode
      />
    </Flex>
  );
};
