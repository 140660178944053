import { Flex, Select } from "antd";
import { useEffect, useMemo, useState } from "react";

import { KMdText, KSemiText700, Loading } from "@components";
import { useFetchInfoTypesService } from "@hooks";
import { useListInfoType } from "@store";
import { EFileSort, EFileType, ICardFileRes } from "@types";
import { getQuery } from "@utils/card";
import { FilesItem, SortItem } from "../item";
import { CustomSpaceList, WrapperCardList } from "../share-component";
import { FilterFile, IconFilter } from "./styled-component";

export const ListFiles = () => {
  const { cardFileList = [], isLoadingList } = useListInfoType();
  const [filter, setFilter] = useState<EFileType>(EFileType.ALL);
  const [sort, setSort] = useState<EFileSort>(EFileSort.New);
  const [totalCount, setTotalCount] = useState(0);
  const [openFilterFile, setOpenFilterFile] = useState(false);

  const query = useMemo(() => getQuery(sort, filter), [filter, sort]);

  useEffect(() => {
    if (filter === EFileType.ALL) setTotalCount(cardFileList.length);
  }, [filter, cardFileList]);

  useFetchInfoTypesService("Files", query);

  return (
    <WrapperCardList gap={8} flex={1}>
      <CustomSpaceList>
        <KSemiText700>Files</KSemiText700>
      </CustomSpaceList>
      <Flex gap={16} align="center" wrap="wrap" justify="space-between">
        <Flex gap={16} align="center" wrap="wrap">
          <SortItem sort={sort} setSort={setSort} />
          <FilterFile
            onClick={() => setOpenFilterFile(!openFilterFile)}
            onBlur={() => setOpenFilterFile(false)}
            onChange={(value) => setFilter(value as EFileType)}
            suffixIcon={<IconFilter />}
            defaultValue={EFileType.ALL}
            value={filter}
            open={openFilterFile}
          >
            <Select.Option value={EFileType.ALL}>Types (All)</Select.Option>
            <Select.Option value={EFileType.IMAGES}>
              {EFileType.IMAGES}
            </Select.Option>
            <Select.Option value={EFileType.DOCUMENTS}>
              {EFileType.DOCUMENTS}
            </Select.Option>
            <Select.Option value={EFileType.OTHER}>
              {EFileType.OTHER}
            </Select.Option>
          </FilterFile>
        </Flex>
        {!isLoadingList && (
          <KMdText>
            {cardFileList?.length} of {totalCount}
          </KMdText>
        )}
      </Flex>
      {isLoadingList ? (
        <Loading />
      ) : (
        !!cardFileList?.length &&
        cardFileList.map((card: ICardFileRes) => (
          <FilesItem key={card._id} card={card} />
        ))
      )}
    </WrapperCardList>
  );
};
