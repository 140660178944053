import { KSmText } from "@components";
import { sizeAttachment } from "@constants/index";
import { Upload } from "antd";
import styled from "styled-components";
import { Color, ESpacing } from "../../types";
import { ImgUploadProps, StyledAttachmentProps } from "./types";

const ImageWrapper = styled.div`
  width: ${sizeAttachment}px;
  height: ${sizeAttachment}px;
`;

const StyledAttachment = styled(Upload)<StyledAttachmentProps>`
  .ant-upload-list {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    @media (max-width: 427px) {
      justify-content: space-between;
      padding: 0 24px;
    }
  }
  &.ant-upload-wrapper.ant-upload-picture-card-wrapper {
    width: 100%;
  }
  &.ant-upload-wrapper.ant-upload-picture-card-wrapper
    .ant-upload.ant-upload-select {
    ${({ isUploaded }) =>
      isUploaded &&
      `border: 1px solid ${Color.Secondary10}; background-color: ${Color.White};`}
  }
  &.ant-upload-wrapper.ant-upload-picture-card-wrapper
    .ant-upload.ant-upload-select:hover {
    border-color: ${Color.PrimaryBase};
    border-radius: 6px;
  }
  &.ant-upload-wrapper.ant-upload-picture-card-wrapper
    .ant-upload-list.ant-upload-list-picture-card
    .ant-upload-list-item-container {
    width: max-content;
    height: max-content;
    margin: 0;
  }
  .ant-upload-list::before {
    position: absolute;
  }
  &.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {
    width: ${sizeAttachment}px;
    height: ${sizeAttachment}px;
  }
`;

const StyledUploadBtn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  gap: ${ESpacing.md};
`;

const WrapperItemUpload = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px;
  overflow: hidden;
  cursor: pointer;
  border-radius: 6px;
  border: 1px solid ${Color.Secondary10};
`;

const ImgUpload = styled.img<ImgUploadProps>`
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: 24px;
  color: ${Color.White};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transition: filter 0.3s ease;
`;

const StyledLabel = styled(KSmText)`
  max-width: ${sizeAttachment}px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
`;

export {
  ImageWrapper,
  ImgUpload,
  StyledAttachment,
  StyledLabel,
  StyledUploadBtn,
  WrapperItemUpload,
};
