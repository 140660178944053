import { Color } from "../../types";
import { IconProps } from "./types";

export const HelpIcon: React.FC<IconProps> = ({
  style,
  width = 16,
  height = 16,
  color = Color.SecondaryBase,
}) => {
  return (
    <svg
      style={style}
      width={width}
      height={height}
      fill={color}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM20 12H17C16.9955 11.1248 16.7613 10.266 16.3208 9.50969C15.8803 8.75334 15.249 8.12587 14.49 7.69L16 5.08C17.215 5.78145 18.2241 6.79006 18.9262 8.00465C19.6282 9.21924 19.9986 10.5971 20 12ZM9 12C9 11.4067 9.17595 10.8266 9.5056 10.3333C9.83524 9.83994 10.3038 9.45542 10.852 9.22836C11.4001 9.0013 12.0033 8.94189 12.5853 9.05764C13.1672 9.1734 13.7018 9.45912 14.1213 9.87868C14.5409 10.2982 14.8266 10.8328 14.9424 11.4147C15.0581 11.9967 14.9987 12.5999 14.7716 13.1481C14.5446 13.6962 14.1601 14.1648 13.6667 14.4944C13.1734 14.8241 12.5933 15 12 15C11.2044 15 10.4413 14.6839 9.87868 14.1213C9.31607 13.5587 9 12.7956 9 12ZM8 5.08L9.5 7.67C8.73993 8.10883 8.10875 8.74 7.66991 9.50007C7.23107 10.2601 7.00003 11.1223 7 12H4C4.00144 10.5971 4.37178 9.21924 5.07385 8.00465C5.77593 6.79006 6.78505 5.78145 8 5.08ZM8 18.92L9.5 16.33C10.2545 16.7851 11.1189 17.0257 12 17.0257C12.8811 17.0257 13.7455 16.7851 14.5 16.33L16 18.92C14.7855 19.6271 13.4053 19.9996 12 19.9996C10.5947 19.9996 9.21448 19.6271 8 18.92Z"
        fill="#161925"
      />
    </svg>
  );
};