import { Color, EFontSize, ELineHeight } from "@types";
import TextArea from "antd/es/input/TextArea";
import styled from "styled-components";

export const KInputBorderless = styled(TextArea)`
  border: none;
  width: 100%;
  padding: 0;
  border-radius: 0;
  &.ant-input:focus {
    box-shadow: none;
  }
  &.ant-input[disabled] {
    color: ${Color.Secondary88};
    background-color: ${Color.White};
  }
  &.ant-input-lg {
    font-size: ${EFontSize.semi}px;
    line-height: ${ELineHeight.semi}px;
    min-height: ${ELineHeight.semi}px;
  }
`;