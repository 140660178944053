import {
  KButton,
  KFormUser,
  KLgText700,
  KMdText,
  KSmTextSecondary50,
  StyledModal,
} from "@components";
import { MarkCompleteToDo } from "@pages/components";
import { requestHelpToDoService } from "@services";
import { useUser } from "@store";
import { IAvatar, ICardTodoDetail, IParams, triggerOptions } from "@types";
import { getPropsCompleteToDo } from "@utils/index";
import { Divider, Flex, message } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useDetailCard, useToDo } from "store/cards";
import { AttachmentsDetail } from "..";
import { SetReminderSection } from "../SetReminderSection";
import { ActivitySection } from "./ActivitySection";
import { AssignedToSection } from "./AssignedToSection";
import { IncludedInfoSection } from "./IncludedInfoSection";
import { SetRepeat } from "./SetRepeat";
import { modalTodoProps } from "config";

export const ToDoDetail = () => {
  const { cardId, profileId } = useParams<IParams>();
  const [loading, setLoading] = useState(false);
  const [confirmRequestHelp, setConfirmRequestHelp] = useState(false);
  const { getValues, watch } = useFormContext<ICardTodoDetail>();
  const { editableDetail } = useDetailCard();
  const { userInfo } = useUser();
  const { setUserAssigned } = useToDo();

  const assigned = useMemo(
    () => getValues("assigned")?.map((u: IAvatar) => u._id),
    [watch("assigned")]
  );

  const completeCheckProps = useMemo(
    () => getPropsCompleteToDo(getValues("isCompleted"), getValues("isMissed")),
    [watch("isCompleted"), watch("isMissed")]
  );

  useEffect(() => {
    const isAssigned = !assigned?.includes(userInfo?._id || "");
    setUserAssigned(isAssigned);
  }, [userInfo, assigned, setUserAssigned]);

  const handleRequestHelp = async () => {
    if (!profileId || !cardId) return;
    try {
      setLoading(true);
      await requestHelpToDoService(profileId, cardId);
      setConfirmRequestHelp(false);
      message.success("Request help success!");
    } catch (err: any) {
      message.error(err?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <MarkCompleteToDo {...completeCheckProps} card={watch()} isDetail />
      <ActivitySection />
      <KFormUser.Select
        label="Finish To-Do by adding..."
        name="trigger"
        options={triggerOptions}
      />
      <Divider />
      <SetRepeat time="startTime" repeat="repeat" />
      <KFormUser.TextArea label="Details" name="description" />
      <Divider />
      <AssignedToSection assigned={assigned} />
      {editableDetail && (
        <KButton
          ghost
          disabled={assigned?.length > 0}
          title="Request help on this To-Do"
          size="large"
          loading={loading}
          onClick={() => setConfirmRequestHelp(true)}
        />
      )}
      <Divider />
      <IncludedInfoSection />
      <Divider />
      <AttachmentsDetail />
      <Divider />
      <SetReminderSection />
      <StyledModal
        {...modalTodoProps}
        width={414}
        open={confirmRequestHelp}
        onOk={handleRequestHelp}
        onCancel={() => setConfirmRequestHelp(false)}
        confirmLoading={loading}
        title={
          <Flex vertical gap={8}>
            <KLgText700>Want to ask everyone for help?</KLgText700>
            <KMdText>
              We’ll send a notification to everyone with full profile access to
              see if they can claim this task.
            </KMdText>
          </Flex>
        }
        okButtonProps={{ style: { flex: 1 } }}
        okText="Yes, send request"
      />
    </>
  );
};
