import {
  EAllergiesType,
  EConditionSeverity,
  EConditionType,
  EHospitalizationType,
  EIdCardType,
  EMeasurement,
  EMedicationStatus,
  EMedicationType,
  EProcedureType,
  EQuestionStatus,
  ESeverity,
  ESpecialtyType,
  IKSelectOption,
} from "@types";
import { handleMapOptions } from "@utils/function-helper";

const AllergiesOptions: IKSelectOption[] = handleMapOptions(EAllergiesType);
const AllergiesSeverityOptions: IKSelectOption[] = handleMapOptions(ESeverity);

const IDCardTypeOptions: IKSelectOption[] = handleMapOptions(EIdCardType);

const ProcedureTypeOptions: IKSelectOption[] = handleMapOptions(EProcedureType);

const HospitalizationTypeOptions: IKSelectOption[] =
  handleMapOptions(EHospitalizationType);

const QuestionStatusOptions: IKSelectOption[] =
  handleMapOptions(EQuestionStatus);

const MedicationTypeOptions: IKSelectOption[] =
  handleMapOptions(EMedicationType);

const MedicationStatusOptions: IKSelectOption[] =
  handleMapOptions(EMedicationStatus);

const MedicationMeasureOptions: IKSelectOption[] =
  handleMapOptions(EMeasurement);

const ConditionTypeOptions: IKSelectOption[] = handleMapOptions(EConditionType);

const ConditionSeverityOptions: IKSelectOption[] = handleMapOptions(EConditionSeverity);

const specialtyTypeOptions: IKSelectOption[] = handleMapOptions(ESpecialtyType);

export {
  AllergiesOptions,
  AllergiesSeverityOptions,
  HospitalizationTypeOptions,
  ConditionTypeOptions,
  ConditionSeverityOptions,
  IDCardTypeOptions,
  MedicationMeasureOptions,
  MedicationStatusOptions,
  MedicationTypeOptions,
  ProcedureTypeOptions,
  QuestionStatusOptions,
  specialtyTypeOptions
};

