import { ECardType } from "@types";
import { create } from "zustand";

interface IKModalAddCardProps {
  open: boolean;
  cardType?: ECardType;
}

type IDetailCardProps = {
  editableDetail: boolean;
  setEditableDetail: (value: boolean) => void;
  forceUpdateDetailIndex: number;
  setForceUpdateDetail: () => void;
  openAddCard: IKModalAddCardProps;
  setOpenAddCard: (props: IKModalAddCardProps) => void;
};

export const useDetailCard = create<IDetailCardProps>((set, get) => ({
  editableDetail: false,
  setEditableDetail: (value) => set({ editableDetail: value }),
  forceUpdateDetailIndex: 0,
  setForceUpdateDetail: () =>
    set({ forceUpdateDetailIndex: get().forceUpdateDetailIndex + 1 }),
  openAddCard: { open: false },
  setOpenAddCard: (props) => set({ openAddCard: props }),
}));
