import { ShareEllipseIcon } from "@assets/index";
import { KSmTextPrimaryBase, KSmTextSecondary65 } from "@components";
import { useCustomNavigate } from "@hooks";
import { EFontSize, ERelationshipType, EUrlPath } from "@types";
import { calculateAge, findRelationshipLabel } from "@utils/profile";
import { Flex, Space } from "antd";
import { FC, useMemo } from "react";
import { EAvatarSize } from "../enums";
import { KNameDisplay } from "./NameDisplay";
import { StyledAvatarProfile } from "./styled-components";

interface IKAvatarProfileProps {
  avatar?: string | null;
  firstName: string;
  lastName: string;
  relationship?: ERelationshipType | string;
  birthDate: string;
  ownerName?: string;
  size: keyof typeof EAvatarSize;
  isMainProfile?: boolean;
}

type AvatarProfileSizeValue = {
  fontSize: keyof typeof EFontSize;
  space: number;
  gap: number;
};

const avatarProfileSize: Record<
  keyof typeof EAvatarSize,
  AvatarProfileSizeValue
> = {
  ["small"]: { fontSize: "sm", space: 0, gap: 0 },
  ["medium"]: { fontSize: "md", space: 6, gap: 0 },
  ["semi"]: { fontSize: "semi", space: 8, gap: 0 },
  ["large"]: { fontSize: "xl", space: 12, gap: 16 },
  ["extra_large"]: { fontSize: "xxxxl", space: 14, gap: 18 },
};

export const KAvatarProfile: FC<IKAvatarProfileProps> = ({
  avatar,
  firstName,
  lastName,
  relationship = "",
  birthDate,
  ownerName,
  size,
  isMainProfile
}) => {
  const { navigate } = useCustomNavigate();
  const { fontSize, space, gap } = avatarProfileSize[size];
  const labelRelationship = useMemo(() => findRelationshipLabel(relationship), [relationship]);
  const onClickAvatar = () => {
    if (!isMainProfile) return;
    navigate(EUrlPath.PROFILE_BASICS);
  };

  return (
    <Space align="center" size={space}>
      <StyledAvatarProfile
        onClick={onClickAvatar}
        firstName={firstName}
        lastName={lastName}
        imgUrl={avatar}
        size={EAvatarSize[size]}
        allowHover={isMainProfile}
      />
      <Flex vertical gap={gap}>
        <KNameDisplay size={fontSize} firstName={firstName} lastName={lastName}/>
        <Flex gap={4} align="center">
          <KSmTextSecondary65>
            {labelRelationship} • {calculateAge(birthDate)}{" "}
          </KSmTextSecondary65>
          {ownerName && (
            <Flex gap={4} align="center">
              •<ShareEllipseIcon/>
              <KSmTextPrimaryBase>{ownerName}</KSmTextPrimaryBase>
            </Flex>
          )}
      </Flex>
      </Flex>
    </Space>
  );
};

export * from "./NameDisplay";
