import { useMemo } from "react";

import { useProfile, useRoleUser, useUser } from "@store";

export const usePremiumPlan = () => {
  const { isProfilePremium } = useProfile();
  const { isFreemium } = useUser();
  const { roleOwner } = useRoleUser();

  return useMemo(
    () => (roleOwner && !isFreemium) || (!roleOwner && isProfilePremium),
    [isProfilePremium, isFreemium, roleOwner]
  );
};
