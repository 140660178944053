import { EErrorMessage, phoneNumberUSAuthRegex } from "@types";
import { z } from "zod";

const phoneNumberRegexView = /(\d{3})(\d{3})(\d{4})/;

export const userInfoRules = {
  firstName: { firstName: z.string().min(1, EErrorMessage.REQUIRED) },
  lastName: { lastName: z.string().min(1, EErrorMessage.REQUIRED) },
  phoneNumber: {
    phoneNumber: z
      .string()
      .min(1, "Phone number is required")
      .regex(phoneNumberUSAuthRegex, EErrorMessage.PHONE)
      .transform((val) => {
        return val.replace(phoneNumberRegexView, "$1-$2-$3");
      }),
  },
  email: { email: z.string().email() },
};

const { firstName, lastName, phoneNumber, email } = userInfoRules;

export const accountSettingsSchema = z.object({
  ...firstName,
  ...lastName,
  ...phoneNumber,
  ...email,
});

export const authSchema = z.object({
  ...email,
});

export const signUpSchema = z.object({
  ...firstName,
  ...lastName,
  ...phoneNumber,
  ageAccepted: z.literal<boolean>(true),
  termsAccepted: z.literal<boolean>(true),
  policyAccepted: z.literal<boolean>(true),
});

export type AuthSchemaType = z.infer<typeof authSchema>;
export type SignUpSchemaType = z.infer<typeof signUpSchema>;