import { RefillEmptyIcon } from "@assets/icons/RefillEmptyIcon";
import {
  KButton,
  KCollapse,
  KEmptyView,
  KFormUser,
  KLgText700,
} from "@components";
import { Color, ECardType } from "@types";
import { Divider, Flex, Space } from "antd";
import { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";

export const Prescription = () => {
  const { getValues, watch } = useFormContext();
  const isActiveButton = useMemo(() => {
    const canActivate =
      getValues("daySupply") &&
      getValues("ofFills") &&
      getValues("prescriptionDate");
    return canActivate;
  }, [watch("daySupply"), watch("ofFills"), watch("prescriptionDate")]);

  return (
    <Flex
      vertical
      gap={16}
      style={{ maxHeight: "calc(100vh - 330px)", overflowY: "auto" }}
    >
      <>
        <KCollapse title="Current Prescription">
          <Divider style={{ margin: 0, marginTop: -8 }} />
          <Space
            direction="vertical"
            size={16}
            style={{ width: "100%", marginTop: 16 }}
          >
            <KFormUser.Switch
              name="prescription.prescription"
              label="Prescription"
            />
            <KFormUser.OptionsSelector
              label="Prescriber"
              name="prescription.prescriber"
              displayName="prescription.prescriberName"
              cardType={ECardType.Practitioners}
            />
            {/* <KFormUser.DatePicker
              label="Prescription Date"
              name="prescription.prescriptionDate"
            /> */}
            <Flex justify="space-between" gap={16}>
              <KFormUser.InputNumber
                label="Day Supply"
                name="prescription.daySupply"
                min={0}
              />
              <KFormUser.InputNumber
                label="# of Fills"
                name="prescription.ofFills"
                min={0}
              />
            </Flex>
            <KFormUser.InputNumber
              label="Cost"
              name="prescription.cost"
              min={0}
              addonAfter="$"
            />
            <KFormUser.Input
              label="Location"
              name="prescription.location"
              placeholder="Enter location info"
            />
            <KButton
              style={{ width: "100%" }}
              disabled={!isActiveButton}
              ghost
              type="primary"
              title="Create New Fill Plan"
            />
          </Space>
        </KCollapse>
        <Divider style={{ margin: "9px 0px", color: Color.Secondary06 }} />
        <KLgText700>Fill Management</KLgText700>
        <KEmptyView
          title="Create New Fill Plan to manage refills"
          subTitle="View past and upcoming refills for this prescription."
          icon={<RefillEmptyIcon />}
        />
        {/* {pres.fillHistories &&
            pres.fillHistories.map((his, idx) => (
              <CardItemStyled key={idx} $isActive={false}>
                <Flex vertical gap={4}>
                  <KLgText700>
                    {moment(his.fillDate).format(
                      EDateTimeFormat.FULL_MONTH_YEAR
                    )}
                  </KLgText700>
                  <KMdTextSecondary65>
                    Day Supply: {his.daySupply}
                  </KMdTextSecondary65>
                  <KMdTextSecondary65>Cost: ${his.cost}</KMdTextSecondary65>
                </Flex>
              </CardItemStyled>
            ))} */}
      </>
    </Flex>
  );
};
