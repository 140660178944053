import { useContext, useState } from "react";
import { Flex } from "antd";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useIntercom } from "react-use-intercom";

import {
  KButton,
  KErrorText,
  KMdText700,
  KMdText700Secondary88,
  KPin,
  KXlText700Secondary88
} from "@components";
import { createSharingLog, requestOTP, verifyOTP } from "@services";
import {
  CODE_LENGTH,
  EInvitationStatus,
  EPermissionTypeShared,
  EStatusCode,
  IParams,
} from "@types";
import { setToken } from "@utils/storage";
import { getBootIntercom } from "@utils/third-party";
import { KContext } from "@context";
import { useAuthStore } from "@store";
import { useCustomNavigate } from "@hooks";

export const VerifyOtpGuest = () => {
  const [code, setCode] = useState("");
  const { boot, show } = useIntercom();
  const { navigate } = useCustomNavigate();

  const { infoPrincipal: logInfo } = useContext(KContext);
  const { tempShareId } = useParams<IParams>();

  const [error, setError] = useState("");

  const { setIsLoadingCSL, setIsAuthenticated } = useAuthStore();

  const handleSendCode = async () => {
    if (!tempShareId) return;
    try {
      setIsLoadingCSL(true);
      await requestOTP(tempShareId, true, true, true);
      // Remove error message of the BadRequest on RequestOtp & VerifyOtp screens
      setError("");
    } catch (error: any) {
      if (error?.response?.data?.code === EStatusCode.BAD_REQUEST) {
        // Set error message of the BadRequest for RequestOtp & VerifyOtp screens.
        setError(error?.response?.data?.message);
      }
      console.log('error-RequestOTP', error);
    } finally {
      setIsLoadingCSL(false);
    }
  };

  const handleVerifyCode = async () => {
    if (!tempShareId) return;
    try {
      setIsLoadingCSL(true);
      const { data: { accessToken } } = await verifyOTP(tempShareId, code);
      setToken('TempShare', accessToken);
      setIsAuthenticated(true);
      handleCreateSharingLog();
      const bootIntercom = getBootIntercom(logInfo?.principal);
      boot(bootIntercom);
      show();
      navigate(`/${tempShareId}`, { replace: true });
    } catch (err: any) {
      if (err?.response?.data?.code === EStatusCode.BAD_REQUEST) {
        setError(err?.response?.data?.message);
      }
      if (!!error) {
        handleSendCode();
      }
    } finally {
      setIsLoadingCSL(false);
    }
  };

  const handleCreateSharingLog = async () => {
    if (!logInfo?.principal || !tempShareId) return;
    try {
      const { userId, method, principal } = logInfo?.principal;
      await createSharingLog(tempShareId, {
        userId,
        method,
        recipientInfo: principal,
        permissionTypeShared: EPermissionTypeShared.SHARE_LINK,
        status: EInvitationStatus.COMPLETED,
        createdTime: moment().format(),
      });
    } catch (err) {
      console.log("err-createSharingLog --- ", err);
    }
  };

  const handleChangeCode = (val: string) => {
    if (!!error) {
      setError("");
    }
    setCode(val);
  };

  return (
    <Flex vertical gap={32}>
      <KXlText700Secondary88>
        Enter the code we sent to verify your device:
      </KXlText700Secondary88>
      <Flex style={{ alignSelf: 'center' }}>
        <KPin code={code} setCode={handleChangeCode} isError={!!error} />
      </Flex>
      {!!error && (
        <KErrorText>{error}</KErrorText>
      )}
      <KButton
        size="large"
        title="View what was shared with me"
        type="primary"
        disabled={code.length !== CODE_LENGTH || !!error}
        onClick={handleVerifyCode}
      />
      <Flex justify="center">
        <KMdText700Secondary88>
          {`Didn’t receive your code? `}
          <KMdText700
            color="Gray"
            {...(!error && {
              color: "PrimaryBase",
              style: { cursor: "pointer" },
              onClick: () => {
                handleSendCode();
                setError("");
                setCode("");
              }
            })}
          >
            Try again.
          </KMdText700>
        </KMdText700Secondary88>
      </Flex>
    </Flex>
  );
};
