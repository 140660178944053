import { IconProps } from "./types";

export const ContactOutlinedIcon: React.FC<IconProps> = ({
  style,
  width = 24,
  height = 24,
}) => {
  return (
    <svg 
      style={style}
      width={width}
      height={height}
    >
      <path
        d="M13.9289 14.0977C14.3692 13.6137 14.6125 12.9824 14.6109 12.3281C14.6109 10.8773 13.4414 9.70312 12 9.70312C10.5586 9.70312 9.38906 10.8773 9.38906 12.3281C9.38906 13.0102 9.64688 13.6289 10.0711 14.0977C9.5628 14.4126 9.1383 14.846 8.83383 15.3606C8.52936 15.8753 8.35398 16.456 8.32266 17.0531C8.32138 17.0785 8.32529 17.1039 8.33415 17.1278C8.343 17.1516 8.35661 17.1734 8.37415 17.1918C8.39169 17.2102 8.41279 17.2249 8.43617 17.2349C8.45955 17.2449 8.48472 17.25 8.51016 17.25H9.53906C9.6375 17.25 9.71719 17.1727 9.72422 17.0742C9.81328 15.8883 10.8023 14.9531 12.0023 14.9531C13.2023 14.9531 14.1914 15.8906 14.2805 17.0742C14.2875 17.1727 14.3672 17.25 14.4656 17.25H15.4922C15.5176 17.25 15.5428 17.2449 15.5662 17.2349C15.5896 17.2249 15.6107 17.2102 15.6282 17.1918C15.6457 17.1734 15.6593 17.1516 15.6682 17.1278C15.6771 17.1039 15.681 17.0785 15.6797 17.0531C15.6141 15.8039 14.9297 14.7164 13.9289 14.0977ZM12 13.5469C11.332 13.5469 10.7883 13.0008 10.7883 12.3281C10.7883 11.6555 11.332 11.1094 12 11.1094C12.668 11.1094 13.2117 11.6555 13.2117 12.3281C13.2117 13.0008 12.668 13.5469 12 13.5469ZM21.75 5.25H18V3.9375C18 3.83437 17.9156 3.75 17.8125 3.75H16.5C16.3969 3.75 16.3125 3.83437 16.3125 3.9375V5.25H12.8438V3.9375C12.8438 3.83437 12.7594 3.75 12.6562 3.75H11.3438C11.2406 3.75 11.1562 3.83437 11.1562 3.9375V5.25H7.6875V3.9375C7.6875 3.83437 7.60313 3.75 7.5 3.75H6.1875C6.08437 3.75 6 3.83437 6 3.9375V5.25H2.25C1.83516 5.25 1.5 5.58516 1.5 6V19.5C1.5 19.9148 1.83516 20.25 2.25 20.25H21.75C22.1648 20.25 22.5 19.9148 22.5 19.5V6C22.5 5.58516 22.1648 5.25 21.75 5.25ZM20.8125 18.5625H3.1875V6.9375H6V8.25C6 8.35313 6.08437 8.4375 6.1875 8.4375H7.5C7.60313 8.4375 7.6875 8.35313 7.6875 8.25V6.9375H11.1562V8.25C11.1562 8.35313 11.2406 8.4375 11.3438 8.4375H12.6562C12.7594 8.4375 12.8438 8.35313 12.8438 8.25V6.9375H16.3125V8.25C16.3125 8.35313 16.3969 8.4375 16.5 8.4375H17.8125C17.9156 8.4375 18 8.35313 18 8.25V6.9375H20.8125V18.5625Z"
        fill="#2E747C"
      />
    </svg>
  );
};