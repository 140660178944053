import { FC, SVGProps } from "react";
import { IconProps } from "./types";

export const FileInfoTypeIcon: FC<IconProps> = ({
  width = 24,
  height,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height || width}
    viewBox="0 0 24 24"
    {...props}
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M14.3273 1.70859L20.0203 7.18594C20.168 7.32656 20.25 7.52109 20.25 7.725V19.3125C20.25 19.4156 20.1656 19.5 20.0625 19.5H18.75C18.6469 19.5 18.5625 19.4156 18.5625 19.3125V8.12344L13.4297 3.1875H6.9375C6.83437 3.1875 6.75 3.10312 6.75 3V1.6875C6.75 1.58437 6.83437 1.5 6.9375 1.5H13.807C14.0016 1.5 14.1867 1.575 14.3273 1.70859ZM11.9859 4.72031L17.0297 9.76406C17.1703 9.90469 17.25 10.0969 17.25 10.2961V21.75C17.25 22.1648 16.9148 22.5 16.5 22.5H4.5C4.08516 22.5 3.75 22.1648 3.75 21.75V5.25C3.75 4.83516 4.08516 4.5 4.5 4.5H11.4562C11.6555 4.5 11.8453 4.57969 11.9859 4.72031ZM11.1094 10.6406H15.5203L11.1094 6.22969V10.6406ZM5.4375 20.8125H15.5625V12.2344H10.5C10.2389 12.2344 9.98855 12.1307 9.80394 11.9461C9.61934 11.7615 9.51562 11.5111 9.51562 11.25V6.1875H5.4375V20.8125Z"/>
  </svg>
);