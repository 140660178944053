import { heightHeaderMobile } from "@constants/size";
import { Color, ESpacing } from "@types";
import { Flex } from "antd";
import styled from "styled-components";

export const CardSecurityInfo = styled(Flex)({
  width: "100%",
  padding: 12,
  gap: 8,
  borderRadius: 12,
  border: `1px solid ${Color.PrimaryBase}`,
  backgroundColor: Color.BgPrimary,
});

export const ContainerScreenEMR = styled(Flex)({
  maxHeight: `calc(100vh - ${heightHeaderMobile}px)`,
  height: "100%",
  backgroundColor: Color.White,
  padding: ESpacing.xl,
  gap: 16,
  flex: 1,
  overflow: "hidden",
});

export const ScreenEMRContent = styled(Flex)({
  overflow: "scroll",
  gap: 16,
});
