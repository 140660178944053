import { Tabs } from "antd";
import styled, { css } from "styled-components";
import { breakpointLarge, widthTab } from "../../constants";
import { EFontSize, ELineHeight } from "../../types";
import { StyledTabsProps } from "./types";

const commonTabStyles = css`
  &.ant-tabs-content-holder {
    max-height: calc(100vh - 315px);
    height: 100%;
    overflow-y: auto;
  }
  &.ant-tabs .ant-tabs-tab {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: ${widthTab};
  }
  @media (max-width: ${breakpointLarge}px) {
    &.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
      display: flex;
      justify-content: center;
    }
    &.ant-tabs .ant-tabs-tab {
      flex: 1;
    }
  }
  &.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
    @media (max-width: ${breakpointLarge}px) {
      max-width: -webkit-fill-available;
    }
    width: 100%;
  }
`;

const TabsCardDetail = styled(Tabs)<StyledTabsProps>`
  ${commonTabStyles}
  &.ant-tabs .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
  }

  &.ant-tabs .ant-tabs-tab-btn {
    line-height: ${ELineHeight.md}px;
  }
`;

const EmergencyAccessTabs = styled(Tabs)<StyledTabsProps>`
  ${commonTabStyles}
  width: 100%;
  &.ant-tabs .ant-tabs-tab {
    width: 100%;
    height: 42px;
    margin: 0;
  }

  &.ant-tabs .ant-tabs-tab-btn {
    line-height: ${ELineHeight.md}px;
    font-size: ${EFontSize.md}px;
  }
`;

export { TabsCardDetail, EmergencyAccessTabs };
