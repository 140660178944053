import AboutPage from "@pages/about";
import AccountSettingsPage from "@pages/account-settings";
import CreatePINPage from "@pages/auth/CreatePinPage";
import CardDetailsPage from "@pages/card-details";
import MedicalRecordsPage, { EMRImportScreen } from "@pages/medical-records";
import { EMRSearchSource } from "@pages/medical-records/components";
import ProfileBasicPage from "@pages/profile-basic";
import { RedemptionPage } from "@pages/redemption";
import { EUrlPath } from "@types";
import { lazy, ReactNode } from "react";
import { Navigate, Route, RouteProps } from "react-router-dom";

const ExportPage = lazy(() => import("@pages/export-pdf"));
const AuthPage = lazy(() => import("@pages/auth"));
const WelcomePage = lazy(() => import("@pages/welcome"));
const CardListPage = lazy(() => import("@pages/card-list"));
const OverviewPage = lazy(() => import("@pages/overview"));
const EmergencyAccessLogin = lazy(() => import("@pages/emergency-access/web-view"));
const EmergencyAccessPage = lazy(() => import("@pages/emergency-access/web-app"));
const RedirectPage = lazy(() => import("@pages/redirect"));
const NotificationsPage = lazy(() => import("@pages/notifications"));

const assistanceRoutes = [
  { path: `${EUrlPath.PROFILE_BASICS}/*`, element: <Navigate to="" /> },
  { path: EUrlPath.PROFILE_BASICS, element: <ProfileBasicPage /> },
  { path: `${EUrlPath.ABOUT}/*`, element: <Navigate to="" /> },
  { path: EUrlPath.ABOUT, element: <AboutPage /> },
];

const authRoutesConfig = [
  { path: ":tempShareId/welcome", element: <WelcomePage /> },
  { path: `${EUrlPath.EXPORT}/:exportId`, element: <ExportPage /> },
  { path: `${EUrlPath.EXPORT}/:exportId/welcome`, element: <ExportPage /> },
  { path: "auth/create-pin", element: <CreatePINPage /> },
  { path: "auth/sign-in", element: <AuthPage /> },
  { path: "fasten-health/redirect", element: <RedirectPage /> },
  {
    path: `${EUrlPath.EMERGENCY_ACCESS}/:emergencyAccessId/welcome`,
    element: <EmergencyAccessLogin />,
  },
];

const mainAppRoutesConfig = [
  ...assistanceRoutes,
  { path: EUrlPath.ACCOUNT_SETTINGS, element: <AccountSettingsPage /> },
  { path: EUrlPath.NOTIFICATIONS, element: <NotificationsPage /> },
  {
    path: EUrlPath.MEDICAL_RECORDS,
    element: <MedicalRecordsPage />,
    children: [
      { path: ":connectionId", element: <EMRImportScreen /> },
      { path: "search", element: <EMRSearchSource /> },
    ],
  },
  {
    path: EUrlPath.EMERGENCY_ACCESS,
    element: <EmergencyAccessPage />,
    children: [{ path: ":cardId", element: <CardDetailsPage /> }],
  },
  {
    path: EUrlPath.OVERVIEW,
    element: <OverviewPage />,
    children: [{ path: ":cardId", element: <CardDetailsPage /> }],
  },
  {
    path: ":cardType",
    element: <CardListPage />,
    children: [{ path: ":cardId", element: <CardDetailsPage /> }],
  },
];

const temporaryRoutesConfig = [
  ...assistanceRoutes,
  {
    path: ":cardType",
    element: <CardListPage />,
    children: [{ path: ":cardId", element: <CardDetailsPage /> }],
  },
];

const emergencyAccessViewOnlyRoutesConfig = temporaryRoutesConfig;

const mainMobileRoutesConfig = [
  ...assistanceRoutes,
  { path: EUrlPath.ACCOUNT_SETTINGS, element: <AccountSettingsPage /> },
  { path: EUrlPath.NOTIFICATIONS, element: <NotificationsPage /> },
  {
    path: EUrlPath.MEDICAL_RECORDS,
    children: [
      { path: "", element: <MedicalRecordsPage /> },
      { path: ":connectionId", element: <EMRImportScreen /> },
      { path: "search", element: <EMRSearchSource /> },
    ],
  },
  {
    path: EUrlPath.EMERGENCY_ACCESS,
    children: [
      { path: "", element: <EmergencyAccessPage /> },
      { path: ":cardId", element: <CardDetailsPage /> },
    ],
  },
  {
    path: EUrlPath.OVERVIEW,
    children: [
      { path: "", element: <OverviewPage /> },
      { path: ":cardId", element: <CardDetailsPage /> },
    ],
  },
  {
    path: ":cardType",
    children: [
      { path: "", element: <CardListPage /> },
      { path: ":cardId", element: <CardDetailsPage /> },
    ],
  },
];

const mobileTemporaryRoutesConfig = [
  ...assistanceRoutes,
  {
    path: ":cardType",
    children: [
      { path: "", element: <CardListPage /> },
      { path: ":cardId", element: <CardDetailsPage /> },
    ],
  },
];

const mobileEmergencyAccessViewOnlyRoutesConfig = [
  ...assistanceRoutes,
  {
    path: ":cardType",
    children: [
      { path: "", element: <CardListPage /> },
      { path: ":cardId", element: <CardDetailsPage /> },
    ],
  },
];

const createRoutes = (routesConfig: any[]): ReactNode => {
  return routesConfig.map((route: any, index: number) => (
    <Route key={index} path={route.path} element={route.element}>
      {route.children &&
        route.children.map((child: RouteProps, idx: number) => (
          <Route
            key={`${index}-${idx}`}
            path={child.path}
            element={child.element}
          />
        ))}
    </Route>
  ));
};

export const authRoutes = createRoutes(authRoutesConfig);
export const mainAppRoutes = createRoutes(mainAppRoutesConfig);
export const temporaryRoutes = createRoutes(temporaryRoutesConfig);
export const mainMobileRoutes = createRoutes(mainMobileRoutesConfig);
export const mobileTemporaryRoutes = createRoutes(mobileTemporaryRoutesConfig);
export const emergencyAccessViewRoutes = createRoutes(
  emergencyAccessViewOnlyRoutesConfig
);
export const mobileEmergencyAccessViewRoutes = createRoutes(
  mobileEmergencyAccessViewOnlyRoutesConfig
);
