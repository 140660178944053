import { useContext } from "react";
import { useParams } from "react-router-dom";

import {
  CardItemStyled,
  CardTitle,
  KMdText700PrimaryBase,
  KMdTextSecondary65
} from "@components";
import { KContext } from "@context";
import { ECardType, EDateTimeFormat, ICardHospitalizationsRes, IItemCardProps, IParams } from "@types";
import { formatTimeRangeCard } from "@utils/date";
import { getInfoCard } from "@utils/index";
import { CardItemContainer } from "..";
import { useCustomNavigate } from "@hooks";

export const HospitalizationItem = ({ card, showIcon, showMetadata, isDetail }: IItemCardProps<ICardHospitalizationsRes>) => {
  const { navigate } = useCustomNavigate();
  const { cardId } = useParams<IParams>();
  const { _id, title, startTime, endTime, location, cardType } = card;
  const { setCardTypeSelected, navigateDetailCard } = useContext(KContext);
  const iconCardType = showIcon && getInfoCard(cardType).icon;

  const onClickItem = () => {
    if (cardId === _id) return;
    if (!isDetail) {
      return navigate(
        _id,
        undefined,
        () => { setCardTypeSelected(ECardType.Hospitalizations); },
      );
    }
    navigateDetailCard(ECardType.Hospitalizations, _id);
  };

  return (
    <CardItemStyled $isActive={cardId === _id} onClick={onClickItem}>
      <CardItemContainer showMetadata={showMetadata} card={card}>
        <CardTitle icon={iconCardType}>{title}</CardTitle>
        {location && (
          <KMdText700PrimaryBase>{location}</KMdText700PrimaryBase>
        )}
        {startTime && (
          <KMdTextSecondary65 $ellipsis numberOfLines={2}>
            {formatTimeRangeCard(
              startTime,
              endTime,
              EDateTimeFormat.FULL_MONTH_YEAR
            )}
          </KMdTextSecondary65>
        )}
      </CardItemContainer>
    </CardItemStyled>
  );
};
