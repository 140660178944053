import {
  ButtonAddCard,
  KFormUser,
  KLgText700
} from "@components";
import { QuestionItem } from "@pages/card-list/components/item";
import { getCardDetailsService } from "@services";
import {
  ECardType,
  ECardTypeName,
  ICardAppointmentRes,
  ICardPractitionerRes,
  IParams,
} from "@types";
import { Divider, Flex } from "antd";
import { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";
import { AttachmentsDetail } from "./Attachments";
import { SetReminderSection } from "./SetReminderSection";

export const AppointmentDetail = () => {
  const {
    getValues,
    watch,
    setValue,
    formState: { isDirty },
  } = useFormContext<ICardAppointmentRes>();
  const { profileId } = useParams<IParams>();

  useEffect(() => {
    const getLocation = async () => {
      const practitionerId = getValues("practitioner");
      if (!profileId || !practitionerId || !isDirty) return;
      const practitioner = await getCardDetailsService<ICardPractitionerRes>(
        profileId,
        ECardTypeName.Practitioners,
        practitionerId
      );
      if (practitioner && practitioner.location) {
        setValue("location", practitioner?.location);
      }
    };

    getLocation();
  }, [getValues("practitioner")]);

  const questions = useMemo(() => getValues("questions"), [watch("questions")]);
  return (
    <>
      <KFormUser.DateTimePicker label="Date" name="startTime" />
      <KFormUser.OptionsSelector
        label="Primary Practitioner"
        cardType={ECardType.Practitioners}
      />
      <KFormUser.TextArea label="Location" name="location" />
      <KFormUser.TextArea label="Additional Information" name="description" />
      <AttachmentsDetail />
      <SetReminderSection />
      <Divider />
      <Flex vertical gap={16}>
        <Flex justify="space-between" align="center">
          <KLgText700>Active Questions</KLgText700>
          <ButtonAddCard cardType={ECardType.Questions} />
        </Flex>
        {questions?.map((qs) => (
          <QuestionItem card={qs} key={qs._id} isDetail />
        ))}
      </Flex>
    </>
  );
};
