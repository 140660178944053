import { CloseOutlined, DeleteFilled, EditFilled } from "@ant-design/icons";
import { ShareOutIcon } from "@assets/icons";
import {
  FileTypeIcon,
  KInputBorderless,
  KButton,
  KMdText,
  KModalConfirm,
  KSemiText700,
  KSmTextSecondary65,
  KTag
} from "@components";
import { KContext } from "@context";
import DocViewer, {
  DocViewerRenderers,
  IDocument,
} from "@cyntler/react-doc-viewer";
import { useLoggingService, usePremiumPlan } from "@hooks";
import { deleteAtmCardDetail, updateNameAtm } from "@services";

import {
  Color,
  EDateTimeFormat,
  EFontSize,
  IAttachment,
  ICombinedCard,
  IParams,
  fileTypesPreview,
} from "@types";
import { cardTypeIcon } from "@utils/index";
import { Flex, InputRef, Modal, message } from "antd";
import moment from "moment";
import {
  ChangeEvent,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";
import styled from "styled-components";

export interface IModalPreview {
  open?: boolean;
  atm?: IAttachment;
}

interface IPreviewAttachment {
  setPreview: (value: IModalPreview) => void;
  preview?: IModalPreview;
}

const StyledAtmModal = styled(Modal)`
  width: 600px !important;
  &.ant-modal .ant-modal-content {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 16px;
  }
  &.ant-modal .ant-modal-header {
    margin: 0;
  }
  &.ant-modal .ant-modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
  }
  &.ant-modal .ant-modal-footer {
    text-align: left;
  }
`;

export const StyledAtmModalHeader = styled(Flex)`
  width: 100%;
  padding: 24px 20px 8px;
`;

const StyledAtmModalFooter = styled(Flex)`
  width: 100%;
  padding: 8px 24px 22px;
  span {
    word-break: break-word;
  }
`;

const DownloadBtn = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CloseModalBtn = styled(CloseOutlined)`
  cursor: pointer;
  font-size: ${EFontSize.xl}px;
`;

const DeleteAtmBtn = styled(DeleteFilled)`
  color: ${Color.RedErrorActive};
  font-size: ${EFontSize.xl}px;
  cursor: pointer;
`;

const EditAtmBtn = styled(EditFilled)`
  font-size: ${EFontSize.xl}px;
  cursor: pointer;
`;

const initialDocument: IDocument = {
  uri: "",
  fileType: "",
  fileName: "",
};

export const PreviewAttachment = ({
  setPreview,
  preview,
}: IPreviewAttachment) => {
  const { title } = useContext(KContext);
  const { cardType, tempShareId, profileId, cardId } = useParams<IParams>();
  const [activeDocument, setActiveDocument] =
    useState<IDocument>(initialDocument);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [nameAtm, setNameAtm] = useState("");
  const { getValues, reset, watch } = useFormContext<ICombinedCard>();
  const { pushInfoTypeLog } = useLoggingService();
  const inputRef = useRef<InputRef>(null);
  const isPremium = usePremiumPlan();

  const isDisableBtn = useMemo(() => {
    return nameAtm === preview?.atm?.originalName || !nameAtm;
  }, [nameAtm, preview]);

  const allowUpdateAtm = useMemo(() => tempShareId || isPremium, [tempShareId, isPremium]);

  const iconCardTypeAtm = useMemo(() => cardTypeIcon[cardType || watch("cardType") || ""]?.icon, [cardType, watch("cardType")])

  useEffect(() => {
    if (!preview?.atm) return;
    setNameAtm(preview.atm.originalName);
  }, [preview]);
  const {
    _id,
    url = "",
    extension = "",
    originalName = "",
    size = 1,
    createdTime,
  } = preview?.atm || {};

  useEffect(() => {
    if (fileTypesPreview.includes(extension)) {
      return setActiveDocument({
        uri: url,
        fileType: extension,
        fileName: originalName,
      });
    }
    setActiveDocument(initialDocument);
  }, [preview]);

  const fileInfo = useMemo(() => {
    return `${Math.floor(
      size / 1024
    )}KB • ${extension.toUpperCase()} • ${moment(createdTime).format(
      EDateTimeFormat.FULL_MONTH_YEAR
    )}`;
  }, [preview]);

  const handleIconClick = () => {
    const { current } = inputRef;
    if (current && isPremium) current.focus();
  };

  const handleChangeName = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (!allowUpdateAtm) return;
    setNameAtm(e.target.value);
  };

  const handleDeleteAtm = async () => {
    if (!profileId || !cardId || !_id || !allowUpdateAtm) return;
    try {
      setIsLoading(true);
      await deleteAtmCardDetail(profileId, _id);
      message.success("Delete attachment success!");
      const currentAtmList = getValues("attachments");
      const updatedAtmList = currentAtmList.filter(atm => atm._id !== _id);
      reset({ ...getValues(), attachments: updatedAtmList });
      pushInfoTypeLog("MODIFIED", getValues());
      setOpenConfirm(false);
      setPreview({ ...preview, open: false });
    } catch (err: any) {
      message.error(err?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateAtmName = async () => {
    if (!profileId || !cardId || !_id) return;
    try {
      setIsLoading(true);
      const atm = await updateNameAtm(profileId, _id, nameAtm);
      pushInfoTypeLog("MODIFIED", getValues());
      message.success("Update attachment name success!");
      setPreview({ ...preview, atm });
    } catch (err: any) {
      message.error(err?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <StyledAtmModal
        title={
          <StyledAtmModalHeader justify="space-between" align="center">
            <CloseModalBtn onClick={() => setPreview({ ...preview, open: false })} />
            <DownloadBtn href={url} rel="noreferrer" download={originalName}>
              <ShareOutIcon />
            </DownloadBtn>
          </StyledAtmModalHeader>
        }
        centered
        closable={false}
        open={preview?.open}
        onCancel={() => setPreview({ ...preview, open: false })}
        footer={
          <StyledAtmModalFooter vertical gap={8} align="flex-start">
            <Flex justify="space-between" style={{ width: "100%" }}>
              <Flex gap={8} align="center" flex={1}>
                {!tempShareId && <EditAtmBtn onClick={handleIconClick} />}
                <KInputBorderless
                  autoSize
                  ref={inputRef}
                  size="large"
                  readOnly={!allowUpdateAtm}
                  value={nameAtm}
                  onChange={handleChangeName}
                />
              </Flex>
              {allowUpdateAtm && (
                <DeleteAtmBtn onClick={() => setOpenConfirm(true)} />
              )}
            </Flex>
            <KSmTextSecondary65>{fileInfo}</KSmTextSecondary65>
            <Flex style={{ width: "100%" }} justify="space-between">
              <KTag icon={iconCardTypeAtm}>
                <KMdText>{title}</KMdText>
              </KTag>
              {allowUpdateAtm && (
                <KButton
                  title="Save"
                  onClick={handleUpdateAtmName}
                  loading={isLoading}
                  disabled={isDisableBtn}
                />
              )}
            </Flex>
          </StyledAtmModalFooter>
        }
      >
        {activeDocument.uri ? (
          <DocViewer
            documents={[activeDocument]}
            pluginRenderers={DocViewerRenderers}
          />
        ) : (
          <FileTypeIcon extension={extension} src={url} size={200} />
        )}
      </StyledAtmModal>
      <KModalConfirm
        confirmLoading={isLoading}
        open={openConfirm}
        onOk={handleDeleteAtm}
        onCancel={() => setOpenConfirm(false)}
        title="Delete Attachment"
      >
        <Flex vertical gap={8}>
          <KSemiText700>
            Are you sure you want to delete this attachment?
          </KSemiText700>
          <KMdText>This item will be deleted and cannot be recovered.</KMdText>
        </Flex>
      </KModalConfirm>
    </>
  );
};