import { FC } from "react";
import { IKTextProps } from "../../types";
import { KText } from "./default";

const KMdText: FC<IKTextProps> = ({ children, ...props }) => (
  <KText
    { ...props }
    size="md"
  >
    {children}
  </KText>
);

const KMdText700 = ({ children, ...props }: IKTextProps) => (
  <KMdText { ...props } fontWeight="700">
    {children}
  </KMdText>
);

const KMdTextPrimaryBase = ({ children, ...props }: IKTextProps) => (
  <KMdText { ...props } color='PrimaryBase'>
    {children}
  </KMdText>
);

const KMdTextSecondaryBase = ({ children, ...props }: IKTextProps) => (
  <KMdText {...props} color="SecondaryBase">
    {children}
  </KMdText>
);

const KMdTextSecondary65 = ({ children, ...props }: IKTextProps) => (
  <KMdText {...props} color="Secondary65">
    {children}
  </KMdText>
);

const KMdTextSecondary88 = ({ children, ...props }: IKTextProps) => (
  <KMdText {...props} color="Secondary88">
    {children}
  </KMdText>
);

const KMdText700Secondary88 = ({ children, ...props }: IKTextProps) => (
  <KMdText {...props} color="Secondary88" fontWeight="700">
    {children}
  </KMdText>
);

const KMdText700PrimaryBase = ({ children, ...props }: IKTextProps) => (
  <KMdText {...props} color="PrimaryBase" fontWeight="700">
    {children}
  </KMdText>
);

const KMdText400SecondaryBase = ({ children, ...props }: IKTextProps) => (
  <KMdText {...props} color="SecondaryBase" fontWeight="400">
    {children}
  </KMdText>
);

const KMdErrorText = ({ children, ...props }: IKTextProps) => (
  <KMdText color="ErrorBase" style={{ textAlign: "center" }} {...props}>
    {children}
  </KMdText>
);

export {
  KMdErrorText,
  KMdText,
  KMdText400SecondaryBase,
  KMdText700,
  KMdText700PrimaryBase,
  KMdText700Secondary88,
  KMdTextPrimaryBase,
  KMdTextSecondary65,
  KMdTextSecondary88,
  KMdTextSecondaryBase,
};

