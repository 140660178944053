import { SwitchProps } from "antd";
import { KSwitchStyled } from "./styles";
import { FC } from "react";

interface KSwitchProps extends SwitchProps {
  value?: boolean;
}

export const KSwitch: FC<KSwitchProps> = (props) => (
  <KSwitchStyled {...props} checked={props.value}/>
);