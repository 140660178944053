import { CheckCircleFilled } from "@ant-design/icons";
import {
  KBadgeCount,
  KButton,
  KCollapse,
  KLgText700,
  KSemiText700,
  KXlText700
} from "@components";
import { useEMRStore } from "@store";
import { Color, infoTypeTextMedRecord, IParams } from "@types";
import { Flex } from "antd";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { EMRCardImport } from "../components";

export const EMRImportSuccess = () => {
  const { cardsImported, setCardsImported } = useEMRStore();
  const { profileId } = useParams<IParams>();
  const navigate = useNavigate();

  const countRecord = useMemo(() => (
    cardsImported?.reduce((total, cur) => total + cur.items.length, 0) ?? 0
  ), [cardsImported]);

  return (
    <Flex vertical gap={24} align="center" style={{ overflow: "scroll" }}>
      <CheckCircleFilled style={{ color: Color.PrimaryBase, fontSize: 72 }} />
      <Flex vertical gap={8} align="center">
        <KXlText700>Import Complete!</KXlText700>
      </Flex>
      <Flex vertical gap={16}>
        <KButton
          size="large"
          title="Return to Medical Record Sources"
          onClick={() => {
            navigate(`/user/${profileId}/medical-records`);
            setCardsImported([]);
          }}
        />
        <KSemiText700>
          {`The following ${countRecord} record${countRecord > 1 ? 's' : ''} have been imported to your profile:`}
        </KSemiText700>
      </Flex>
      <Flex vertical flex={1} gap={8} style={{ overflow: "scroll", width: "100%" }}>
        {cardsImported?.filter(i => i.items.length)?.map((item, idx) => (
          <KCollapse
            key={idx}
            title={
              <Flex justify="space-between">
                <KLgText700>{infoTypeTextMedRecord[item.cardType]}</KLgText700>
                <KBadgeCount
                  children={<KLgText700>{item.items.length}</KLgText700>}
                  isNeedWidth={false}
                />
              </Flex>
            }
          >
            <Flex vertical gap={8}>
              {item.items.map((card) => (
                <EMRCardImport
                  key={card.medExportId}
                  showCheckBox={false}
                  card={card}
                />
              ))}
            </Flex>
          </KCollapse>
        ))}
      </Flex>
    </Flex>
  );
};
