export const ShareOutIcon = ({
  width = 24,
  height,
}: {
  height?: number;
  width?: number;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height || width}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.8437 17.0618V5.70241H15.6249C15.782 5.70241 15.871 5.5196 15.7726 5.39772L12.1476 1.07194C12.1301 1.04953 12.1076 1.03141 12.0821 1.01894C12.0565 1.00648 12.0284 1 11.9999 1C11.9715 1 11.9434 1.00648 11.9178 1.01894C11.8922 1.03141 11.8698 1.04953 11.8523 1.07194L8.22728 5.39772C8.12884 5.52194 8.21791 5.70241 8.37494 5.70241H11.1562V17.0618C11.1562 17.1649 11.2406 17.2493 11.3437 17.2493H12.6562C12.7593 17.2493 12.8437 17.1649 12.8437 17.0618ZM16.7516 9.76959V8.50866C16.7516 8.41022 16.8312 8.32819 16.932 8.32819H19.2805C19.6789 8.32819 20 8.64928 20 9.04772V21.6087C20 22.0071 19.6789 22.3282 19.2805 22.3282H4.71953C4.32109 22.3282 4 22.0071 4 21.6087V9.04537C4 8.64693 4.32109 8.32584 4.71953 8.32584H7.06797C7.16875 8.32584 7.24844 8.40787 7.24844 8.50631V9.76725C7.24844 9.86569 7.16641 9.94772 7.06797 9.94772H5.61953V20.7087H18.3805V9.95006H16.932C16.8336 9.95006 16.7516 9.86803 16.7516 9.76959Z"
      fill="#161925"
    />
  </svg>
);
