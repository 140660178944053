import { HealthInfoOutlined } from "@assets/icons";
import {
  CardTitle,
  KButton,
  KCardTypeTag,
  KMdText,
  KSmTextSecondary65,
  StyledModal
} from "@components";
import { KContext } from "@context";
import {
  cardTypeToCardItem
} from "@pages/card-list/components";
import {
  searchCardsTitleService
} from "@services";
import { useDetailCard, useToDo } from "@store";
import {
  ECardType,
  ICardTagRes,
  ICardTodoDetail,
  IParams
} from "@types";
import { AutoComplete, Flex, message } from "antd";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";

export const IncludedInfoSection = () => {
  const { cardId, tempShareId, profileId, emergencyAccessId } = useParams<IParams>();
  const { onUpdateDetailCard } = useContext(KContext);
  const [cardsInfo, setCardsInfo] = useState<ICardTagRes[]>();
  const [openModal, setOpenModal] = useState(false);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const { getValues, watch, reset, formState: { dirtyFields }, control } = useFormContext<ICardTodoDetail>();
  const { append, remove } = useFieldArray({ control, name: "linkedInfos" });
  const { editableDetail } = useDetailCard();
  const { fetchCardsLinkedInfo, cardsLinkedInfo } = useToDo();
  const { _id } = watch();

  const customOptions = useMemo(
    () => [
      ...(cardsInfo?.map((op) => ({
        value: op.title,
        label: (
          <KCardTypeTag
            title={op.title}
            $cardType={op.cardType || ECardType.ToDos}
          />
        ),
        key: op._id,
      })) || []),
    ],
    [cardsInfo]
  );

  useEffect(() => {
    if (!_id || (_id !== cardId)) return;
    if (profileId) fetchCardsLinkedInfo('USER', profileId, _id);
    if (tempShareId) fetchCardsLinkedInfo('TEMPORARY_SHARE', tempShareId, _id);
    if (emergencyAccessId) fetchCardsLinkedInfo('EMERGENCY_ACCESS', emergencyAccessId, _id);
  }, [profileId, _id, tempShareId, cardId]);

  useEffect(() => {
    if (!profileId || !cardId || !editableDetail || cardsInfo) return;
    (async () => {
      try {
        const cardsLinked = getValues("linkedInfos")?.map(({ _id }) => _id);
        const cardIds = [...cardsLinked, cardId];
        const {
          data: { result },
        } = await searchCardsTitleService(profileId, cardIds, search);
        setCardsInfo(result);
      } catch {}
    })();
  }, [search, editableDetail]);

  const onSelect = useCallback((value: string, option: any) => {
    if (!option?.key) return;
    const { key: _id, value: title } = option;
    setSearch(value);
    append({ _id, title });
  }, [watch("linkedInfos")]);

  const onSave = async () => {
    try {
      setLoading(true);
      const data = {...getValues()};
      const linkedInfos = data.linkedInfos?.map(({_id}) => _id);
      await onUpdateDetailCard({...data, linkedInfos}, reset);
      await fetchCardsLinkedInfo('USER', data.profile, data._id);
      setOpenModal(false);
    } catch (err: any) {
      message.error(err?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex vertical gap={8}>
      <CardTitle
        icon={<HealthInfoOutlined width={17} />}
        suffix={
          <KButton
            ghost
            title="Link item"
            size="small"
            onClick={() => setOpenModal(true)}
          />
        }
      >
        Included Info
      </CardTitle>
      {!!cardsLinkedInfo &&
        cardsLinkedInfo.map((c) => {
          const { CardItem } = cardTypeToCardItem[c.cardType];
          return <CardItem key={c._id} card={c} isDetail/>;
        })}
      <KSmTextSecondary65>
        Link relevant info items to this reminder like medications,
        practitioners, and more.
      </KSmTextSecondary65>
      <StyledModal
        centered
        closeIcon={false}
        open={openModal}
        onOk={onSave}
        onCancel={() => setOpenModal(false)}
        confirmLoading={loading}
        okButtonProps={{ disabled: !dirtyFields["linkedInfos"] || !search}}
        okText="Save"
      >
        <Flex flex={1} gap={8} vertical>
          <Flex gap={16} justify="space-between" align="center">
            <KMdText>Add Linked Info</KMdText>
          </Flex>
          <AutoComplete
            allowClear
            value={search}
            onSearch={setSearch}
            onClear={() => remove(-1)}
            options={customOptions}
            placeholder="Search Info"
            onSelect={onSelect}
          />
        </Flex>
      </StyledModal>
    </Flex>
  );
};
