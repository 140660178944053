import { zodResolver } from "@hookform/resolvers/zod";
import { useGoogleLogin } from "@react-oauth/google";
import { Divider, Flex, Form, message } from "antd";
import { useEffect, useMemo, useState } from "react";
import AppleSignin, { appleAuthHelpers, AppleAuthResponse } from "react-apple-signin-auth";
import { SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { z } from 'zod';

import { AppleIcon, GoogleIcon } from "@assets/icons";
import {
  KAnchor,
  KButton,
  KCheckBox,
  KErrorText,
  KFormGroup,
  KInput,
  KMdText,
  KMdTextSecondary88,
  KSmText700Secondary65,
  KXlText700Secondary88,
} from "@components";
import { homePageUrl } from "@constants/url";
import { useCustomNavigate, useFetchSummaryService } from "@hooks";
import { useAuthStore } from "@store";
import { AUTH_TYPE, EAuthErrorMsg, EAuthPage, EStatusCode, IParams } from "@types";
import { getToken as getLocalStorage, removeToken as removeLocalStorage, setToken as setLocalStorage } from "@utils/storage";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { FormItemStyled } from "components/FormGroup/styled-component";
import { authAppleConfig } from 'config';
import {
  authenticateWithEmailOrSms,
  loginWithApple,
  loginWithGoogle,
} from "services/auth";
import { BackNavFormHeader } from "./BackButton";
import { LabelRemember } from "./styled-components";

const schema = z.object({
  email: z
    .string()
    .min(1, { message: 'Email is required' })
    .email({ message: 'Email is invalid' }),
});

type FormData = z.infer<typeof schema>;

const appleAuthOptions = {
  ...authAppleConfig,
  scope: 'email name',
  state: '',
  nonce: 'nonce',
  usePopup: true,
};

export const WelcomeUser = () => {
  const { navigate } = useCustomNavigate();
  const { tempShareId } = useParams<IParams>();
  const { loginData, setCurrentPage, setLoginData, isCSLFlow } = useAuthStore();
  const { getPrincipal } = useFetchSummaryService();

  const [isThirdPartyLoginPending, setThirdPartyLoginPending] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [isRememberMe, setIsRememberMe] = useState(false);

  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
    setValue
  } = useForm<FormData>({
    defaultValues: {
      email: loginData.email,
    },
    resolver: zodResolver(schema),
    mode: 'all',
    disabled: isThirdPartyLoginPending,
  });

  useEffect(() => {
    const username = getLocalStorage("Username");
    if (!username) return
    setValue("email", username);
    setIsRememberMe(true);
  }, []);

  const onChangeRememberMe = (e: CheckboxChangeEvent) => {
    const { checked } = e.target;
    if (!checked) removeLocalStorage("Username");
    setIsRememberMe(checked);
  }

  const onErrorSubmitWithEmailOrSms = (error: any) => {
    if (+error?.response?.status === EStatusCode.FORBIDDEN) {
      setErrorMsg(EAuthErrorMsg.EMAIL_DO_NOT_MATCH);
    } else {
      message.error(error?.response?.data?.message || 'Email not found!');
    }
  };

  const onSubmitLogin =
    (type: keyof typeof AUTH_TYPE): SubmitHandler<FormData> =>
    async (data) => {
      try {
        await authenticateWithEmailOrSms({
          email: data.email,
          type: AUTH_TYPE[type],
          tempShareId,
        });
        setLoginData(data.email, AUTH_TYPE[type]);
        setCurrentPage(EAuthPage.VERIFY_OTP_LOGIN);
        if (isRememberMe) {
          setLocalStorage("Username", data.email);
        }
      } catch (error: any) {
        onErrorSubmitWithEmailOrSms(error);
      }
    };

  const handleLoginWithApple = async () => {
    setThirdPartyLoginPending(true);
    appleAuthHelpers.signIn({
      authOptions: appleAuthOptions,
      onSuccess: async (response: AppleAuthResponse) => {
        try {
          await loginWithApple({
            token: response.authorization.id_token,
            tempShareId,
          });
          setErrorMsg('');
          message.success('Login with Apple successfully');
          if (isCSLFlow && tempShareId) {
            await getPrincipal(tempShareId);
            navigate(`/${tempShareId}`, { replace: true });
          } else {
            setLocalStorage('Pin', new Date().getTime());
            navigate('/user');
          }
        } catch {
          setErrorMsg(EAuthErrorMsg.GOGGLE_APPLE_LOGIN_FAILED);
          message.error('Login with Apple failed');
        }
        setThirdPartyLoginPending(false);
      },
      onError: (error: unknown) => {
        setThirdPartyLoginPending(false);
      },
    });
  };

  const loginGoogle = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        await loginWithGoogle({
          token: response.access_token,
          tempShareId,
        });
        setErrorMsg('');
        message.success('Login with Google successfully');
        if (isCSLFlow && tempShareId) {
          await getPrincipal(tempShareId);
          navigate(`/${tempShareId}`, { replace: true });
        } else {
          setLocalStorage('Pin', new Date().getTime());
          navigate('/user');
        }
      } catch (error) {
        setErrorMsg(EAuthErrorMsg.GOGGLE_APPLE_LOGIN_FAILED);
        message.error('Login with Google failed');
      }
      setThirdPartyLoginPending(false);
    },
    onError: () => {
      setErrorMsg(EAuthErrorMsg.GOGGLE_APPLE_LOGIN_FAILED);
      setThirdPartyLoginPending(false);
    },
    onNonOAuthError: () => {
      setThirdPartyLoginPending(false);
    },
  });

  const handleLoginWithGoogle = () => {
    setThirdPartyLoginPending(true);
    loginGoogle();
  };

  const isLoginPending = useMemo(
    () => (isSubmitting || isThirdPartyLoginPending),
    [isSubmitting, isThirdPartyLoginPending]
  );

  return (
    <Flex vertical gap={16} justify="flex-start" flex={1}>
      <BackNavFormHeader screenKey={isCSLFlow ? EAuthPage.CSL_WELCOME : EAuthPage.AUTH_SCREEN} />
      <KXlText700Secondary88>Welcome back!</KXlText700Secondary88>
      <Form>
        <Flex vertical gap={16}>
          <KFormGroup label='Log in with your email'>
            <FormItemStyled control={control} name='email'>
              <KInput placeholder='Email address' size='large'/>
            </FormItemStyled>
          </KFormGroup>
          <LabelRemember>
            <KCheckBox 
              checked={isRememberMe}
              onChange={onChangeRememberMe}
            />
            <KMdText>Remember me</KMdText>
          </LabelRemember>
          <Flex gap={16} flex={1}>
            <KButton
              title='Send SMS'
              type='primary'
              size='large'
              style={{ flex: 1 }}
              htmlType='button'
              onClick={handleSubmit(onSubmitLogin("SMS"))}
              disabled={isLoginPending}
            />
            <KButton
              title='Send Email'
              type='primary'
              size='large'
              style={{ flex: 1 }}
              htmlType='button'
              onClick={handleSubmit(onSubmitLogin("EMAIL"))}
              disabled={isLoginPending}
            />
          </Flex>
        </Flex>
      </Form>
      <Divider plain>
        <KSmText700Secondary65>OR</KSmText700Secondary65>
      </Divider>
      <AppleSignin
        authOptions={appleAuthOptions}
        uiType="dark"
        buttonExtraChildren='Continue with Apple'
        onSuccess={() => { }}
        onError={() => { }}
        render={() => (
          <KButton
            icon={<AppleIcon />}
            title='Continue with Apple'
            size='large'
            onClick={handleLoginWithApple}
            disabled={isLoginPending}
            style={{
              backgroundColor: '#000',
              color: '#fff',
            }}
          />
        )}
      />
      <KButton
        icon={<GoogleIcon />}
        title='Continue with Google'
        size='large'
        type='default'
        onClick={handleLoginWithGoogle}
        disabled={isLoginPending}
      />
      {errorMsg && (
        <KErrorText>{errorMsg}</KErrorText>
      )}
      <Divider />
      <Flex vertical>
        <KMdTextSecondary88>
          Don’t have an account yet?
        </KMdTextSecondary88>
        <KAnchor
          $isUnderlined
          target="_blank"
          size="md"
          color="PrimaryBase"
          href={homePageUrl}
        >
          Download the app to create an account.
        </KAnchor>
      </Flex>
    </Flex>
  );
};
