import { useCustomNavigate } from "@hooks";
import { useRoleUser, useUser } from "@store";
import { IParams } from "@types";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

export const useMedicalRecordPermission = () => {
  const { navigate } = useCustomNavigate();
  const { profileId } = useParams<IParams>();
  const { isFreemium, userInfo } = useUser();
  const { roleOwner, role } = useRoleUser();

  useEffect(() => {
    if ((isFreemium || !roleOwner) && userInfo && role) {
      navigate(`/user/${profileId}`);
    }
  }, [isFreemium, roleOwner, userInfo]);
};
