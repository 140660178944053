import { Color, EBorderRadius, EFontSize } from "@types";
import { useState } from "react";
import ReactQuill, { ReactQuillProps } from "react-quill";
import "react-quill/dist/quill.snow.css";
import styled from "styled-components";

interface ITextEditorStyled {
  disabled?: boolean;
  showToolbar?: boolean;
}
export interface KTextEditorProps extends ReactQuillProps, ITextEditorStyled {
  value?: string;
  name?: string;
}

const modules = {
  toolbar: [
    ["bold", "italic", "underline"],
    [
      { list: "ordered" },
      { list: "bullet" },
    ],
    ["link"],
  ],
};

const StyledTextEditor = styled(ReactQuill)<ITextEditorStyled>`
  .ql-snow {
    border-radius: ${EBorderRadius.md};
    border: 1px solid ${Color.Secondary10}!important;
    color: ${Color.Secondary88};
    font-size: ${EFontSize.md}px;
    ${({ disabled }) =>
      disabled &&
      `
      background-color: ${Color.Secondary04};
      pointer-events: none;
    `}
  }
  .ql-toolbar {
    ${({ showToolbar }) => !showToolbar && `display: none;`}
  }
  .ql-tooltip {
    position: sticky;
  }
`;

export const KTextEditor = ({ value, ...props }: KTextEditorProps) => {
  const [showToolbar, setShowToolbar] = useState(false);
  return (
    <StyledTextEditor
      value={value}
      modules={modules}
      showToolbar={showToolbar}
      onFocus={() => setShowToolbar(true)}
      {...props}
    />
  );
};
