import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { PaperClipOutlinedIcon, UpgradePremiumIcon } from "@assets/icons";
import {
  KAttachment,
  KButton,
  KLgText700,
  KLgTextSecondary88,
  KMdTextPrimaryBase,
  KMdTextSecondary65,
  KMdTextSecondary88,
  KXlText700Secondary88,
} from "@components";
import { uploadFileCardDetail } from "@services";
import { useDetailCard } from "@store";
import { IAttachment, ICombinedCard, IParams } from "@types";
import { createFileObject, fileAntdToAttachment, getInfoCard } from "@utils/index";
import { Flex, Modal, UploadFile, message } from "antd";
import { UploadChangeParam } from "antd/es/upload";
import {
  StyledAttachment,
  StyledUploadBtn,
} from "components/Attachment/styles";
import { modalProps } from "config/antd";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useParams } from "react-router";
import { IModalPreview, PreviewAttachment } from ".";
import { useKContext } from "@context";
import { useLoggingService, usePremiumPlan } from "@hooks";

const UploadButton = ({ loading }: { loading: boolean }) => {
  const isPremium = usePremiumPlan();

  return !isPremium ? (
    <StyledUploadBtn><UpgradePremiumIcon /></StyledUploadBtn>
  ) : (
    <StyledUploadBtn>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <KMdTextSecondary65>
        {loading ? "Loading..." : "Upload"}
      </KMdTextSecondary65>
    </StyledUploadBtn>
  );
};

export const AttachmentsDetail = () => {
  const { profileId } = useParams<IParams>();
  const { forceUpdateList } = useKContext();
  const [openPreview, setOpenPreview] = useState<IModalPreview>();
  const [openModal, setOpenModal] = useState(false);
  const [listFiles, setListFiles] = useState<UploadFile<IAttachment>[]>([]);
  const [uploading, setUploading] = useState(false);
  const { reset, getValues, watch } = useFormContext<ICombinedCard>();
  const { editableDetail } = useDetailCard();
  const { pushInfoTypeLog } = useLoggingService();
  const isPremium = usePremiumPlan();

  useEffect(() => {
    const atm = getValues("attachments");
    if (!atm) return;
    const files: UploadFile<IAttachment>[] = atm.map(createFileObject);
    setListFiles(files);
  }, [watch("attachments")]);

  useEffect(() => {
    if (!openPreview?.atm) return;
    const { originalName, _id } = openPreview.atm;
    const name = originalName;
    setListFiles(prevList =>
      prevList.map(file => (file.uid === _id ? { ...file, name } : file))
    );
  }, [openPreview]);

  const handlePreview = (file: UploadFile) => {
    const atm = fileAntdToAttachment(file);
    setOpenPreview({ open: true, atm });
  };

  const handleUploadAtm = async (
    file: UploadChangeParam<UploadFile<IAttachment>>
  ) => {
    const { _id, profile, cardType } = getValues();
    const cardName = getInfoCard(cardType).path;
    if (!file?.file?.originFileObj || !cardName || !isPremium || uploading) return;
    const uploadedFile = file.file.originFileObj;
    try {
      setUploading(true);
      const { data } = await uploadFileCardDetail(
        profile,
        cardName,
        _id,
        uploadedFile
      );
      const currentList = getValues("attachments");
      if (_id === getValues('_id')) {
        reset({ ...getValues(), attachments: [...currentList, data] });
      };
      forceUpdateList();
      pushInfoTypeLog("MODIFIED", getValues());
      message.success("Upload file successfully!");
    } catch (err: any) {
      message.error(err?.response?.data?.message);
    } finally {
      setUploading(false);
    }
  };

  return (
    <Flex vertical gap={8}>
      <Flex justify="space-between" align="center">
        <Flex gap={4} align="center">
          <PaperClipOutlinedIcon />
          <KLgText700>Attachments</KLgText700>
        </Flex>
        <KMdTextPrimaryBase
          style={{ cursor: 'pointer' }}
          onClick={() => setOpenModal(true)}
        >
          Supported Files
        </KMdTextPrimaryBase>
      </Flex>
      <StyledAttachment
        listType="picture-card"
        {...!uploading && { onChange: handleUploadAtm }}
        disabled={!isPremium || !editableDetail}
        beforeUpload={() => !uploading}
        customRequest={() => false}
        fileList={listFiles}
        itemRender={(og, file) => {
          return (
            <KAttachment
              thumbnailUrl={file.thumbUrl}
              extension={file.fileName}
              onClick={() => handlePreview(file)}
              label={file.name}
            />
          );
        }}
      >
        {profileId && <UploadButton loading={uploading} />}
      </StyledAttachment>
      <PreviewAttachment
        preview={openPreview}
        setPreview={setOpenPreview}
      />
      <Modal
        open={openModal}
        onCancel={() => setOpenModal(false)}
        {...modalProps}
        width={446}
      >
        <Flex gap={16} vertical>
          <KXlText700Secondary88>Supported Files</KXlText700Secondary88>
          <KLgTextSecondary88>
            For security of everyone’s files, we limit the types of files you can upload and the size of each file to 200 MB.
          </KLgTextSecondary88>
          <KMdTextSecondary88>
            Currently supported image types are: png, .bmp, .heic, .jpg, .jpeg, .tiff, .tif, .webp
          </KMdTextSecondary88>
          <KMdTextSecondary88>
            Currently supported document types are: doc, .docx, .dcm, .odt, .pdf, .ppt, .pptx, .pps, .ppsx, .txt, .rtf, .xls, .xlsx
          </KMdTextSecondary88>
          <Flex justify="end">
            <KButton
              size="large"
              disabled={false}
              title="Okay"
              onClick={() => setOpenModal(false)}
            />
          </Flex>
        </Flex>
      </Modal>
    </Flex>
  );
};