import { KContext } from "@context";
import { useGlobalModals } from "@store";
import { EUrlPath } from "@types";
import { useContext } from "react";

export const UpgradePremiumIcon = ({
  width = 24,
  height,
}: {
  height?: number;
  width?: number;
}) => {
  const { setOpenUpgradeModal } = useGlobalModals();
  const { arrayPath } = useContext(KContext);
  const onClickUpgradeIcon = (e: any) => {
    if (arrayPath?.[3] === EUrlPath.ACCOUNT_SETTINGS) return;
    e.stopPropagation();
    setOpenUpgradeModal(true)
  };

  return (
    <svg
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClickUpgradeIcon}
    >
      <rect
        width="24"
        height="20"
        rx="4"
        fill="url(#paint0_linear_1177_3707)"
      />
      <path
        d="M18.0561 6.78021L15.0155 8.41947L12.0998 5.01654C12.0881 5.00285 12.0729 4.99173 12.0554 4.98407C12.0379 4.97641 12.0186 4.97243 11.999 4.97243C11.9795 4.97243 11.9602 4.97641 11.9427 4.98407C11.9252 4.99173 11.91 5.00285 11.8983 5.01654L8.9842 8.41947L5.94202 6.78021C5.85295 6.73236 5.73733 6.79662 5.75139 6.88822L6.94514 14.8261C6.96233 14.9341 7.06858 15.0175 7.19514 15.0175H16.8061C16.9311 15.0175 17.0389 14.9355 17.0545 14.8261L18.2483 6.88822C18.2608 6.79662 18.1467 6.73236 18.0561 6.78021ZM16.0873 14.0824H7.91077L7.07014 8.4851L9.25452 9.66225L11.9998 6.45756L14.7451 9.66225L16.9295 8.4851L16.0873 14.0824ZM11.9998 9.95893C11.0295 9.95893 10.2405 10.6494 10.2405 11.4984C10.2405 12.3474 11.0295 13.0378 11.9998 13.0378C12.9701 13.0378 13.7592 12.3474 13.7592 11.4984C13.7592 10.6494 12.9701 9.95893 11.9998 9.95893ZM11.9998 12.1587C11.5842 12.1587 11.2467 11.8634 11.2467 11.4984C11.2467 11.1347 11.5842 10.838 11.9998 10.838C12.4155 10.838 12.753 11.1333 12.753 11.4984C12.753 11.862 12.4155 12.1587 11.9998 12.1587Z"
        fill="#161925"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1177_3707"
          x1="12"
          y1="20"
          x2="12"
          y2="2.84362e-08"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EB9E07"/>
          <stop offset="0.739583" stopColor="#FFBD39"/>
        </linearGradient>
      </defs>
    </svg>
  );
};
