import { Divider } from "antd";

import { KSemiText700, Loading } from "@components";
import { useFetchInfoTypesService } from "@hooks";
import { useListInfoType } from "@store";
import { EFileSort, ICardConditionRes } from "@types";
import { useMemo, useState } from "react";
import { ConditionItem, SortItem } from "../item";
import { CustomSpaceList, WrapperCardList } from "../share-component";
import { getQuery } from "@utils/card";

const renderCardItems = (card: ICardConditionRes[]) => {
  return card.map((card: ICardConditionRes) => (
    <ConditionItem key={card._id} card={card} />
  ));
};

export const ListConditions = () => {
  const {
    presentConditionsList = [],
    pastConditionsList = [],
    isLoadingList,
    isLoadingInactiveList,
  } = useListInfoType();
  const [sort, setSort] = useState<EFileSort>(EFileSort.New);

  const query = useMemo(() => getQuery(sort), [sort]);

  useFetchInfoTypesService("Conditions", query);

  return (
    <WrapperCardList space={16}>
      <CustomSpaceList>
        <KSemiText700>Conditions</KSemiText700>
        <SortItem sort={sort} setSort={setSort} />
        {isLoadingList ? <Loading /> : renderCardItems(presentConditionsList)}
      </CustomSpaceList>
      <Divider />
      <CustomSpaceList>
        <KSemiText700>Past Conditions</KSemiText700>
        {isLoadingInactiveList ? (
          <Loading />
        ) : (
          renderCardItems(pastConditionsList)
        )}
      </CustomSpaceList>
    </WrapperCardList>
  );
};
