import { Flex } from "antd";
import { useContext, useMemo } from "react";
import { useParams } from "react-router-dom";

import { CardItemStyled, CardTitle, KMdTextSecondary65, KTag } from "@components";
import { KContext } from "@context";
import { ECardType, ICardAllergyRes, IItemCardProps, IParams } from "@types";
import { formatTimeRangeCard } from "@utils/date";
import { getInfoCard } from "@utils/cardTypeIcon";
import { CardItemContainer } from "../share-component";
import { useCustomNavigate } from "@hooks";

export const AllergyItem = ({ card, showIcon, showMetadata, isDetail }: IItemCardProps<ICardAllergyRes>) => {
  const { navigate } = useCustomNavigate();
  const { cardId } = useParams<IParams>();
  const { setCardTypeSelected, navigateDetailCard } = useContext(KContext);
  const { _id, title, allergySeverity, startTime, endTime, cardType } = card;
  const iconCardType = showIcon && getInfoCard(cardType).icon;
  const onClickItem = () => {
    if (cardId === _id) return;
    if (!isDetail) {
      return navigate(
        _id,
        undefined,
        () => { setCardTypeSelected(ECardType.Allergies); }
      );
    }
    navigateDetailCard(ECardType.Allergies, _id);
  };

  const dateRange = useMemo(() => (
    formatTimeRangeCard(startTime, endTime)
  ), [startTime, endTime]);

  return (
    <CardItemStyled $isActive={cardId === card._id} onClick={onClickItem}>
      <CardItemContainer showMetadata={showMetadata} card={card}>
        <Flex justify="space-between" align="center" gap={4} flex={1}>
          <CardTitle icon={iconCardType}>{title}</CardTitle>
          {allergySeverity && <KTag color="">{allergySeverity}</KTag>}
        </Flex>
        {dateRange && (
          <KMdTextSecondary65 $ellipsis numberOfLines={2}>
            {dateRange}
          </KMdTextSecondary65>
        )}
      </CardItemContainer>
    </CardItemStyled>
  );
};
