import { Flex } from "antd";

import { Loading } from "@components";
import { useFetchInfoTypesService } from "@hooks";
import { useListInfoType } from "@store";
import { EFileSort } from "@types";
import { useMemo, useState } from "react";
import { NoteItem, SortItem } from "../item";
import { getQuery } from "@utils/card";

export const ListNotes = () => {
  const { cardNoteList = [], isLoadingList } = useListInfoType();
  const [sort, setSort] = useState<EFileSort>(EFileSort.New);

  const query = useMemo(() => getQuery(sort), [sort]);

  useFetchInfoTypesService("Notes", query);

  return (
    <Flex gap={8} vertical>
      <SortItem sort={sort} setSort={setSort} />
      {isLoadingList ? (
        <Loading />
      ) : (
        cardNoteList.map((i) => <NoteItem key={i._id} card={i} showMetadata />)
      )}
    </Flex>
  );
};
