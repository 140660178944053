import styled from "styled-components";
import { heightCardItemInfo } from "../../constants";
import { Color, EBorderRadius, ESpacing } from "../../types";

interface CardButtonToggleProps {
  $isActive: boolean;
  $addable?: boolean;
  $disabled?: boolean;
}

const CardButtonToggle= styled.div<CardButtonToggleProps>`
  flex: 1;
  padding-left: ${ESpacing.md};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  width: 100%;
  max-width: 100%;
  cursor: pointer;
  height: ${heightCardItemInfo}px;
  border-radius: ${EBorderRadius.lg} ${({$addable}) => ($addable ? `0 0 ${EBorderRadius.lg}`: '' )};
  background-color: ${({ $isActive }) => ($isActive ? Color.BgPrimary : Color.White)};
  border-color: ${({ $isActive }) => ($isActive ? Color.PrimaryBase : Color.Secondary10)};
  border-style: solid;
  border-width: 1px;
  svg {
    fill: ${({ $disabled }) => ($disabled ? Color.Secondary65 : Color.Secondary88)};
  }
`;

const ButtonAddStyled = styled.div`
  width: ${heightCardItemInfo}px;
  border-radius: 0px ${EBorderRadius.lg} ${EBorderRadius.lg} 0px;
  background-color: ${Color.PrimaryBase};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContainerIconPremium = styled.div<{$active?: boolean}>`
  width: ${heightCardItemInfo}px;
  border-radius: 0px ${EBorderRadius.lg} ${EBorderRadius.lg} 0px;
  background-color: ${({ $active }) => ($active ? Color.BgPrimary : Color.White)};
  border-color: ${({ $active }) => ($active ? Color.PrimaryBase : Color.Secondary10)};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export { CardButtonToggle, ButtonAddStyled, ContainerIconPremium };