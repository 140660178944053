import { ContactOutlinedIcon, DeleteFilledIcon } from "@assets/icons";
import { KButton, KCollapse, KFormUser, KLgText700Secondary88, KMdTextPrimaryBase } from "@components";
import { useRoleUser } from "@store";
import { IProfileBasic } from "@types";
import { Divider, Flex, Modal, Space } from "antd";
import { modalProps } from "config/antd";
import { useMemo, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

export const initialDeleteEmergency = {
  id: 0,
  firstName: "",
  open: false
}

export const EmergencyContact = () => {
  const [showConfirm, setShowConfirm] = useState(initialDeleteEmergency);
  const { watch, getValues, control } = useFormContext<IProfileBasic>();
  const { remove } = useFieldArray({ control, name: "emergencyContacts"});
  const { roleEditor } = useRoleUser();

  const data = useMemo(() => getValues("emergencyContacts"), [watch("emergencyContacts")]);


  const handleConfirmRemove = (id: number, firstName: string) => {
    setShowConfirm({ id, firstName, open: true})
  };

  const handleRemove = () => {
    remove(showConfirm.id);
    setShowConfirm(initialDeleteEmergency);
  }

  return (
    <KCollapse title="Emergency Contacts" >
      <Divider style={{ margin: 0, marginTop: -8 }} />
      <Space
        direction="vertical"
        size={16}
        style={{ width: "100%", marginTop: 16 }}
      >
        {data?.map((item, idx) => (
          <Flex key={item._id} vertical gap={16}>
            {idx !== 0 && <Divider style={{ margin: 0 }} />}
            <Flex justify="space-between" align="center" gap={16}>
              <Flex gap={8} align="center">
                <ContactOutlinedIcon />
                <KMdTextPrimaryBase>Contact {idx + 1}</KMdTextPrimaryBase>
              </Flex>
              <Flex
                onClick={() => roleEditor && handleConfirmRemove(idx, item.firstName)}
                style={{ cursor: "pointer" }}
              >
                <DeleteFilledIcon />
              </Flex>
            </Flex>
            <Flex justify="space-between" gap={16}>
              <KFormUser.Input
                label="First Name"
                name={`emergencyContacts[${idx}].firstName`}
              />
              <KFormUser.Input
                label="Last Name"
                name={`emergencyContacts[${idx}].lastName`}
              />
            </Flex>
            <KFormUser.InputPhone
              name={`emergencyContacts[${idx}].phoneNumber`}
              placeholder="###-###-####"
              label="Phone"
              addonBefore="+1"
              phone
            />
            <KFormUser.InputEmail
              label="Email"
              name={`emergencyContacts[${idx}].email`}
              placeholder="Enter email"
            />
          </Flex>
        ))}
      </Space>
      <Modal open={showConfirm.open} {...modalProps} width={414}>
        <Flex gap={32} vertical align="center">
          <KLgText700Secondary88>
            Are you sure you want to delete {showConfirm.firstName} has an emergency contact? 
          </KLgText700Secondary88>
          <Flex gap={16} style={{ width: "100%" }}>
            <KButton
              style={{ flex: 1 }}
              size="large"
              title="No, go back"
              onClick={() => setShowConfirm(initialDeleteEmergency)}
            />
            <KButton
              style={{ flex: 1 }}
              size="large"
              
              title="Yes, delete"
              onClick={handleRemove}
              danger
              ghost
            />
          </Flex>
        </Flex>
      </Modal>
    </KCollapse>
  );
};
