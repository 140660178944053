import { Flex } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { Loading } from "@components";
import { useKContext } from "@context";
import {
  getCardsRelatedTopicEmergency,
  getCardsRelatedTopicTempShare,
  getCardsRelatedToTopicService,
} from "@services";
import { useProfile } from "@store";
import {
  ECardType,
  EFileSort,
  IParams
} from "@types";
import { getQuery } from "@utils/card";
import {
  AllergyItem,
  AppointmentItem,
  ConditionItem,
  FilesItem,
  HospitalizationItem,
  IdCardItem,
  MedicationItem,
  NoteItem,
  PractitionerItem,
  ProcedureItem,
  QuestionItem,
  SortItem,
  ToDoItem,
  VaccinationItem,
} from "../..";
import { MeatBallTopic } from "./MeatBallTopic";

const componentsMap = (card: any) => {
  const props = {
    card,
    key: card._id,
    showIcon: true,
  };

  return {
    [ECardType.Allergies]: <AllergyItem {...props} />,
    [ECardType.Appointments]: <AppointmentItem {...props} />,
    [ECardType.Conditions]: <ConditionItem {...props} />,
    [ECardType.Files]: <FilesItem {...props} />,
    [ECardType.Hospitalizations]: <HospitalizationItem {...props} />,
    [ECardType.IdCard]: <IdCardItem {...props} />,
    [ECardType.Medications]: <MedicationItem {...props} />,
    [ECardType.Notes]: <NoteItem {...props} />,
    [ECardType.Practitioners]: <PractitionerItem {...props} />,
    [ECardType.Procedures]: <ProcedureItem {...props} />,
    [ECardType.Questions]: <QuestionItem {...props} />,
    [ECardType.Vaccinations]: <VaccinationItem {...props} />,
    [ECardType.ToDos]: <ToDoItem {...props} showCheckbox={false} />,
  };
};

export const ListCardsRelatedTopic = () => {
  const { profileId, tempShareId, emergencyAccessId, cardType, cardId } =
    useParams<IParams>();
  const { setCardTypeSelected } = useKContext();
  const { profile } = useProfile();
  const [list, setList] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sort, setSort] = useState<EFileSort>(EFileSort.New);

  const query = useMemo(() => getQuery(sort), [sort]);

  useEffect(() => {
    if (!cardType) return;
    (async () => {
      try {
        setIsLoading(true);
        let res;
        if (tempShareId) {
          res = await getCardsRelatedTopicTempShare(tempShareId, cardType, query);
        } else if (profileId) {
          res = await getCardsRelatedToTopicService(profileId, cardType, query);
        } else if (emergencyAccessId) {
          res = await getCardsRelatedTopicEmergency(emergencyAccessId, cardType, query);
        }
        if (!res) return;
        setList(res.result);
        const item = res.result.find((i) => i._id === cardId);
        if (item) {
          setCardTypeSelected(item.cardType);
        }
      } catch {
      } finally {
        setIsLoading(false);
      }
    })();
  }, [tempShareId, profileId, emergencyAccessId, cardType, query]);

  return (
    <Flex gap={16} vertical>
      {profileId && profile?.emergencyAccessTopicId !== cardType && (
        <MeatBallTopic />
      )}
      <Flex gap={8} vertical>
        <SortItem setSort={setSort} sort={sort} />
        {isLoading ? (
          <Loading />
        ) : (
          list.map((i) => {
            const Item =
              componentsMap(i)[i.cardType as keyof typeof componentsMap];
            return Item;
          })
        )}
      </Flex>
    </Flex>
  );
};
