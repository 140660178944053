import React from "react";

import { KAnchor } from "../Text/default";
import { FooterContainer } from "./styles";
import { KMdTextSecondary88 } from "../Text";
import { getAppUrl } from "@constants/index";
  
export const KFooter: React.FC = () => {
  return (
    <FooterContainer>
      <KMdTextSecondary88>
        Want to use KinKeeper for your own profiles?
          <KAnchor fontWeight="700" style={{marginLeft: "4px"}} href={getAppUrl} target="_blank">
            Get even more features when you download the app!
          </KAnchor>
      </KMdTextSecondary88>
    </FooterContainer>
  );
};