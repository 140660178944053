import {
  CheckCircleOutlined
} from "@ant-design/icons";
import styled from "styled-components";

import { Color, EFontSize, ELineHeight, ESpacing, IKTextProps } from "../../types";
import { FC, AnchorHTMLAttributes } from "react";
import { Flex } from "antd";

const StyledText = styled.span<IKTextProps & { $anchor?: boolean }>`
  ${({ size = 'md' }) => (`
    font-size: ${EFontSize[size]}px;
    line-height: ${ELineHeight[size]}px;
  `)}

  ${({ color = "SecondaryBase" }) => `
    color: ${Color[color]};
  `}

  ${({ fontWeight = "500" }) => `
    font-weight: ${fontWeight};
  `}

  ${({ $isUnderlined }) =>
    $isUnderlined &&
    `
    text-decoration: underline;
  `}

  ${({ $anchor = false }) => (
    `cursor: ${$anchor ? "pointer" : 'default'};`
  )}

  ${({ $lineThrough }) =>
    $lineThrough &&
    `text-decoration: line-through;`}

  ${({ $align }) =>
    $align &&
    `text-align: ${$align};`}

    ${({ $wordBreak }) =>
    $wordBreak &&
    `word-break: ${$wordBreak};`}

  ${({ $ellipsis = false, numberOfLines }) => $ellipsis && `
    display: -webkit-box;
    -webkit-line-clamp: ${numberOfLines ?? 1};
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
  `}

  &:hover {
    ${({ color = "SecondaryBase", $anchor = false }) => `
      color: ${Color[$anchor ? "PrimaryBase" : color]};
    `}
  }

   ${({ $verticalAlign = "middle" }) =>
    `vertical-align: ${$verticalAlign};`}
`;

export const KText: FC<
  IKTextProps & AnchorHTMLAttributes<HTMLAnchorElement>
> = ({
  children,
  size = "md",
  color = "SecondaryBase",
  fontWeight = "500",
  numberOfLines,
  style,
  ...props
}) => {
  return (
    <StyledText
      {...props}
      size={size}
      color={color}
      fontWeight={fontWeight}
      style={{ ...style, WebkitLineClamp: numberOfLines }}
    >
      {children}
    </StyledText>
  );
};

export const KAnchor: React.FC<
  Omit<IKTextProps, "children"> & AnchorHTMLAttributes<HTMLAnchorElement>
> = (props) => {
  const {
    children,
    size = "md",
    color = "PrimaryBase",
    fontWeight = "500",
    numberOfLines,
    style,
    ...anchorProps
  } = props;

  return (
    <StyledText
      as="a"
      size={size}
      color={color}
      fontWeight={fontWeight}
      $anchor
      style={{ ...style, WebkitLineClamp: numberOfLines }}
      {...anchorProps}
    >
      {children || props.href}
    </StyledText>
  );
};

export const KAnchorWithIcon = styled(KAnchor)<
  IKTextProps & AnchorHTMLAttributes<HTMLAnchorElement> & { $gap?: number }
>`
  ${({ size = 'md' }) => (`
  svg {
    width: ${EFontSize[size] + 1}px;
    height: ${EFontSize[size] + 1}px;
  }`)}
  ${({ color = "PrimaryBase" }) => `
  svg {
    fill: ${Color[color]};
  }`}
  display: flex;
  align-items: center;
  ${({ $gap }) => $gap && `gap: ${$gap}px;`}
  cursor: pointer;
`;

interface IStyledCheckItem {
  size?: keyof typeof EFontSize;
  color?: keyof typeof Color;
}

interface IKTextWithCheckIcon extends IKTextProps {
  iconColor?: keyof typeof Color;
}

const StyledCheckItem = styled(Flex)<IStyledCheckItem>(({ size, color }) => ({
  gap: ESpacing.md,
  svg: {
    fontSize: `${EFontSize[size || "md"] + 1}px`,
    color: Color[color || "PrimaryBase"],
  },
}));

export const KTextWithCheckIcon = ({ iconColor, size,  ...props }: IKTextWithCheckIcon) => (
  <StyledCheckItem size={size} color={iconColor}>
    <CheckCircleOutlined/>
    <KText size={size} {...props}/>
  </StyledCheckItem>
);
