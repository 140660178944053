import { UserOutlined } from "@ant-design/icons";

import { KMdText700, KSmText } from "../Text";
import {
  ContainerEmptyView,
  StyleContent,
  StyledAvatar,
  StyledEmptyView,
} from "./styles";
import { KEmptyViewProps } from "./types";

export const KEmptyView: React.FC<KEmptyViewProps> = ({
  title = "Select an item to view",
  subTitle,
  icon = (<UserOutlined />)
}) => {
  return (
    <ContainerEmptyView>
      <StyledEmptyView>
        {icon && <StyledAvatar size={64} icon={icon} />}
        <StyleContent>
          {title && <KMdText700>{title}</KMdText700>}
          <KSmText>
            {subTitle}
          </KSmText>
        </StyleContent>
      </StyledEmptyView>
    </ContainerEmptyView>
  );
};

export * from "./EmptyInfoType"
