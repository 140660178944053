import { KContext } from "@context";
import { useCustomNavigate, useLoggingService } from "@hooks";
import { createCardDetail } from "@services";
import { useToDo } from "@store";
import { ECardType, ICardItemDto, IParams } from "@types";
import { getInfoCard } from "@utils/cardTypeIcon";
import { getStartTimeDefault } from "@utils/date";
import { getFieldDetail } from "@utils/detail";
import {
  AutoComplete,
  Flex,
  Input,
  InputRef,
  Modal,
  ModalProps,
  message,
} from "antd";
import { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import medicationFDA from "@assets/json/medications.json";
import { DefaultOptionType } from "antd/es/cascader";
import { KInput, KLgText700Secondary88, KSmTextSecondary50 } from "@components";
import { searchMedicationFDA } from "@utils/card";

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    display: flex;
    flex-direction: column;
  }
  .ant-modal-body {
    flex: 1;
  }
  .ant-modal-header {
    margin: 0;
  }
  .ant-btn-primary {
    box-shadow: none;
  }
`;
interface IModalAddCard extends ModalProps {
  cardType?: ECardType;
  onOpen: (value: boolean) => void;
  title?: string;
  updateMethods?: UseFormReturn<FieldValues, any, undefined>;
  linkedId?: string;
}

export const KModalAddCard = ({
  cardType = ECardType.Allergies,
  onOpen,
  updateMethods,
  linkedId,
  ...props
}: IModalAddCard) => {
  const inputRef = useRef<InputRef>(null);
  const { navigate } = useCustomNavigate();
  const {
    profileId,
    amountOfCards,
    setAmountOfCards,
    onUpdateDetailCard,
    forceUpdateList,
    setCardTypeSelected,
  } = useContext(KContext);
  const { cardType: currentCard } = useParams<IParams>();
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [titleCard, setTitleCard] = useState("");
  const [optionMedications, setOptionMedications] = useState<DefaultOptionType[]>([]);
  const { getValues, reset, setValue } = updateMethods || {};
  const { fetchCardsLinkedInfo } = useToDo();
  const { pushInfoTypeLog } = useLoggingService();

  useEffect(() => {
    const { current } = inputRef;
    if (current && openModal) current.focus();
  }, [openModal]);

  const handleAddNewCard = async () => {
    const { path } = getInfoCard(cardType);
    const { name: targetField } = getFieldDetail(cardType);
    if (!path || !titleCard || !(titleCard?.length > 0)) return;
    try {
      setLoading(true);
      const { name } = getFieldDetail(currentCard);
      const startTime = getStartTimeDefault(path);
      const payload: ICardItemDto = {
        title: titleCard,
        startTime,
        linkedId,
        ...(getValues && { [name]: getValues("_id") }),
      };
      const res = await createCardDetail(profileId, path, payload);
      pushInfoTypeLog("CREATED", res);
      const newAmountCards = amountOfCards.map((c) => {
        if (c.cardType === res.cardType) return { ...c, amount: c.amount + 1 };
        return c;
      });
      setAmountOfCards(newAmountCards);

      if (getValues) {
        await onUpdateDetailCard(
          { ...getValues(), [targetField]: res._id },
          reset
        );
      } else {
        navigate(
          `/user/${profileId}/${res.cardType}/${res._id}`,
          undefined,
          () => {
            setCardTypeSelected(res.cardType);
          }
        );
        forceUpdateList();
      }

      if (currentCard === ECardType.ToDos && linkedId) {
        fetchCardsLinkedInfo("USER", profileId, res._id);
      }

      onOpen(false);
      setTitleCard("");
      message.success(`Create new ${getFieldDetail(cardType).title} success!`);
    } catch (err: any) {
      message.error(err?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const onCancel = () => {
    const { displayName, name } = getFieldDetail(currentCard);
    if (getValues && setValue) {
      setValue(displayName, getValues(displayName));
      setValue(name, getValues(name));
    }
    setTitleCard("");
    onOpen(false);
  };

  const handleSelectMedication = (op: DefaultOptionType | string) => {
    setTitleCard(op.toString());
  }

  const handleSearchMedications = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setTitleCard(value);
    const medications = searchMedicationFDA(value);
    setOptionMedications(medications);
  };

  return (
    <StyledModal
      width={360}
      title={
        <KSmTextSecondary50>
          Add New {getFieldDetail(cardType).title}
        </KSmTextSecondary50>
      }
      onCancel={onCancel}
      okButtonProps={{ disabled: !titleCard }}
      onOk={handleAddNewCard}
      confirmLoading={loading}
      centered
      {...props}
    >
      <Flex vertical gap={8}>
        <KLgText700Secondary88>
          Give your {getFieldDetail(cardType).title} a name:
        </KLgText700Secondary88>
        {cardType === ECardType.Medications ? (
          <AutoComplete
            onFocus={() => setOpenModal(true)}
            options={optionMedications}
            onSelect={handleSelectMedication}
            value={titleCard}
            children={
              <KInput
                ref={inputRef}
                onFocus={() => setOpenModal(true)}
                onChange={handleSearchMedications}
              />
            }
          />
        ) : (
          <KInput
            ref={inputRef}
            value={titleCard}
            onFocus={() => setOpenModal(true)}
            onChange={(e) => setTitleCard(e.target.value)}
            onPressEnter={handleAddNewCard}
          />
        )}
      </Flex>
    </StyledModal>
  );
};
