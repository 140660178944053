import { apiInstance, authApiInstance } from "@apis";
import {
  EThirdPartyType,
  IAccessTokenResponse,
  IBaseRes,
  RegisterProfileProps,
} from "@types";
import { createUUID, getToken, storeAppAccessTokens } from "@utils/storage";
import {
  AuthEmailOrSmsPayload,
  IThirdPartyLogin,
  UserInfo,
  VerifyOtpCodePayload,
  VerifyOtpResponse,
} from "./auth.type";

export const authenticateWithEmailOrSms = async (
  params: AuthEmailOrSmsPayload
) => {
  const res = await authApiInstance.get(`/v1/auth/otp/${params.email}`, {
    params: {
      type: params.type,
      temporaryShareId: params.tempShareId,
    },
  });
  return res.data;
};

export const verifyEmailExist = async (email: string) => {
  const res = await authApiInstance.get(`/v1/user/check-exist-email`, {
    params: { email },
  });
  return res.data;
};

export const signUpProfile = async (
  profile: Omit<RegisterProfileProps, "reasonInput">
) => {
  const res = await apiInstance.post(`/v1/user`, profile);
  return res.data;
};

export const verifyOtpCode = async (query: VerifyOtpCodePayload) => {
  const { code, deviceToken, email } = query;
  const res = await authApiInstance.post<VerifyOtpResponse>(
    `/v1/auth/otp`,
    undefined,
    {
      params: {
        code,
        deviceToken,
        email,
        tokenMode: true,
      },
    }
  );
  return res.data;
};

export const getMe = async () => {
  const res = await apiInstance.get<IBaseRes<UserInfo>>("/v1/auth/me");
  return res?.data?.data;
};

export const createPin = async (pin: string) => {
  const res = await apiInstance.post(`/v1/user/pin?pin=${pin}`);
  return res.data;
};

export const verifyThirdPartyLogin = async (payload: IThirdPartyLogin) => {
  const res = await authApiInstance.post<IBaseRes<IAccessTokenResponse>>(
    `/v1/auth/webview/third-party`,
    undefined,
    {
      params: {
        ...payload,
        temporaryShareId: payload.tempShareId,
      },
    }
  );
  return res.data;
};

export const loginWithGoogle = async ({
  token,
  tempShareId,
}: {
  token?: string;
  tempShareId?: string;
}) => {
  if (!token) return;

  const deviceToken = createUUID();
  const { data } = await verifyThirdPartyLogin({
    deviceToken,
    token,
    type: EThirdPartyType.GOOGLE,
    tempShareId,
  });
  storeAppAccessTokens(data);
};

export const loginWithApple = async ({
  token,
  tempShareId,
}: {
  token?: string;
  tempShareId?: string;
}) => {
  if (!token) return;
  const deviceToken = createUUID();
  const { data } = await verifyThirdPartyLogin({
    deviceToken,
    token,
    type: EThirdPartyType.APPLE,
    tempShareId,
  });
  storeAppAccessTokens(data);
};

export const checkEmailOrPhoneNumberExisted = async (payload: string) => {
  const url = `/v1/auth/exist-principal/${payload}`;
  const { data } = await authApiInstance.post(url);
  return data;
};

export const logoutWebAppService = async (fcm: string) => {
  const url = `/v1/auth/logout?fcm=${fcm}`;
  const { data } = await apiInstance.post(url);
  return data;
};

export const refreshTokenService = async () => {
  const refreshToken = getToken("WebAppRefresh");
  const res = await authApiInstance.post<IBaseRes<IAccessTokenResponse>>(
    `/v1/auth/refresh-token`,
    { refreshToken }
  );
  return res?.data;
};
