import { Switch } from "antd";
import styled from "styled-components";

import { Color } from "../../types";

const switchTheme = {
    colorPrimary: Color.PrimaryBase,
};

const KSwitchStyled = styled(Switch)`
    &.ant-switch.ant-switch-disabled * {
      cursor: default;
    }
`;

export { KSwitchStyled, switchTheme };