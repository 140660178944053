import { Flex } from "antd";
import { useContext, useMemo } from "react";
import { useParams } from "react-router-dom";

import { CardItemStyled, CardTitle, KMdTextSecondary65, KTag } from "@components";
import { KContext } from "@context";
import { ECardType, EDateTimeFormat, ICardConditionRes, IItemCardProps, IParams } from "@types";
import { formatTimeRangeCard } from "@utils/date";
import { getInfoCard } from "@utils/index";
import { CardItemContainer } from "..";
import { useCustomNavigate } from "@hooks";

export const ConditionItem = ({ card, showIcon, showMetadata, isDetail }: IItemCardProps<ICardConditionRes>) => {
  const { navigate } = useCustomNavigate();
  const { cardId } = useParams<IParams>();
  const { _id, title, conditionSeverity, startTime, endTime, cardType } = card;
  const { setCardTypeSelected, navigateDetailCard } = useContext(KContext);
  const iconCardType = showIcon && getInfoCard(cardType).icon;

  const onClickItem = () => {
    if (cardId === _id) return;
    if (!isDetail) {
      return navigate(
        _id,
        undefined,
        () => { setCardTypeSelected(ECardType.Conditions); },
      );
    }
    navigateDetailCard(ECardType.Conditions, _id);
  };

  const dateRange = useMemo(() => (
    formatTimeRangeCard(startTime, endTime, EDateTimeFormat.FULL_MONTH_YEAR)
  ), [startTime, endTime]);

  return (
    <CardItemStyled $isActive={cardId === _id} onClick={onClickItem}>
      <CardItemContainer showMetadata={showMetadata} card={card}>
        <Flex justify="space-between" align="center" gap={4} flex={1} wrap="wrap">
          <CardTitle icon={iconCardType}>{title}</CardTitle>
          {conditionSeverity && <KTag color="">{conditionSeverity}</KTag>}
        </Flex>
        {dateRange && (
          <KMdTextSecondary65 $ellipsis numberOfLines={2}>
            {dateRange}
          </KMdTextSecondary65>
        )}
      </CardItemContainer>
    </CardItemStyled>
  );
};
