export const profileRelationshipSelf = 'self';

export enum modalType {
  edit = 'edit',
  delete = 'delete',
  null = 'null',
}
export enum ERelationshipType {
  MY_CHILD = 'child',
  SPOUSE = 'spouse',
  PARENT = 'parent',
  PET = 'pet',
  SIBLING = 'sibling',
  FRIEND = 'friend',
  SELF = 'self',
  GRANDPARENT = 'grandparent',
  GRAND_CHILD = 'grandchild',
  STEP_CHILD = 'stepchild',
  CARE_CHILD = 'carechild',
  CARE_PET = 'carepet',
  AUNT = 'aunt',
  UNCLE = 'uncle',
  PARTNER = 'partner',
  COUSIN = 'cousin',
}

export const relationshipList = [
  {
    label: 'My child',
    value: ERelationshipType.MY_CHILD,
  },
  {
    label: 'Spouse',
    value: ERelationshipType.SPOUSE,
  },
  {
    label: 'Parent',
    value: ERelationshipType.PARENT,
  },
  {
    label: 'Pet',
    value: ERelationshipType.PET,
  },
  {
    label: 'Sibling',
    value: ERelationshipType.SIBLING,
  },
  {
    label: 'Friend',
    value: ERelationshipType.FRIEND,
  },
  {
    label: 'Grandparent',
    value: ERelationshipType.GRANDPARENT,
  },
  {
    label: 'Grandchild',
    value: ERelationshipType.GRAND_CHILD,
  },
  {
    label: 'Step-child',
    value: ERelationshipType.STEP_CHILD,
  },
  {
    label: 'A child I care for sometimes',
    value: ERelationshipType.CARE_CHILD,
  },
  {
    label: 'A pet I care for sometimes',
    value: ERelationshipType.CARE_PET,
  },
  {
    label: 'Aunt',
    value: ERelationshipType.AUNT,
  },
  {
    label: 'Uncle',
    value: ERelationshipType.UNCLE,
  },
  {
    label: 'Partner',
    value: ERelationshipType.PARTNER,
  },
  {
    label: 'Cousin',
    value: ERelationshipType.COUSIN,
  },
  {
    label: 'Self',
    value: ERelationshipType.SELF,
  },
];

export const SSN_LENGTH = 11;

export enum EProfileRole {
  OWNER = 128,
  EDITOR = 8,
  CONTRIBUTOR = 4,
  VIEWER = 2,
};

export const profileRoleName = {
  [EProfileRole.OWNER]: 'Owner',
  [EProfileRole.EDITOR]: 'Editor',
  [EProfileRole.CONTRIBUTOR]: 'Contributor',
  [EProfileRole.VIEWER]: 'Viewer',
};

export const permissionTypeShared = {
  [EProfileRole.OWNER]: 'share link',
  [EProfileRole.EDITOR]: 'editor',
  [EProfileRole.CONTRIBUTOR]: 'contributor',
  [EProfileRole.VIEWER]: 'viewer',
};

export enum EHealthDetailKey {
  BloodType = 'bloodType',
  IsOrganDonor = 'isOrganDonor',
  Height = 'height',
  Weight = 'weight',
};
