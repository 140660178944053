import React from "react";
import { Checkbox, Flex, SpaceProps } from "antd";

import { CardTopicStyled } from "./styles";
import { KLgText700, KLgText700Secondary50 } from "components/Text";
import { ITopic } from "@types";
import { CheckboxChangeEvent } from "antd/es/checkbox";

export interface IKTopicCardItemProps extends Omit<SpaceProps, 'onChange'> {
  topic: ITopic;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  disabled?: boolean;
}

export const KTopicCardItem: React.FC<IKTopicCardItemProps> = ({
  topic,
  checked = false,
  onChange,
  disabled = false,
}) => (
  <CardTopicStyled $isActive={checked} $isDisabled={disabled}>
    {disabled ? (
      <KLgText700Secondary50>
        {topic.title}
      </KLgText700Secondary50>
    ): (
      <KLgText700>
        {topic.title}
      </KLgText700>
    )}
    <Flex>
      <Checkbox
        defaultChecked={checked}
        id={`topic-${topic._id}`}
        onChange={(e: CheckboxChangeEvent) => {
          onChange?.(e.target.checked);
        }}
        name="topics"
        checked={checked}
      />
    </Flex>
  </CardTopicStyled>
);