import { PushpinOutlined } from "@ant-design/icons";
import { AutoComplete, Flex, FlexProps, InputProps } from "antd";
import { ReactNode } from "react";

import { KDatePicker } from "../DatePicker";
import { KInput, KInputPhone, KTextEditor, KTextarea } from "../Input";
import { KSelect } from "../Select";
import { KSwitch } from "../Switch";
import { KMdText } from "../Text";
import { KTimePicker } from "../TimePicker";

type CustomFlexProps = Omit<FlexProps, "children">;
type KFormGroupProps = { label?: ReactNode } & CustomFlexProps;

interface IProps extends CustomFlexProps {
  label: ReactNode;
  value: any;
}

export const KFormGroup = ({ label, children, ...props }: { label: ReactNode, children: ReactNode } & CustomFlexProps) => (
  <Flex vertical gap={8} { ...props }>
    <KMdText>{label}</KMdText>
    {children}
  </Flex>
);

export const KFormTemporaryShare = {
  Input: ({
    label,
    disabled = false,
    value,
    suffix,
    size,
    placeholder,
    addonBefore,
    ...props
  }: InputProps & KFormGroupProps) => (
    <KFormGroup label={label} {...props}>
      <KInput
        value={value}
        suffix={suffix}
        disabled={disabled}
        size={size}
        placeholder={placeholder}
        addonBefore={addonBefore}
      />
    </KFormGroup>
  ),
  Switch: ({ label, value, isFollowUp = false, ...props }: IProps & { isFollowUp?: boolean }) => (
    <Flex justify="space-between" { ...props }>
      <KMdText>
        {label}
        {isFollowUp && <PushpinOutlined />}
      </KMdText>
      <KSwitch checked={value} />
    </Flex>
  ),
  DatePicker: ({ label, value, ...props }: IProps) => (
    <KFormGroup label={label} {...props}>
      <KDatePicker value={value} />
    </KFormGroup>
  ),
  TimePicker: ({ label, value, ...props }: IProps) => (
    <KFormGroup label={label} {...props}>
      <KTimePicker value={value} />
    </KFormGroup>
  ),
  TextArea: ({ label, value, ...props }: IProps) => (
    <KFormGroup label={label} {...props}>
      <KTextarea value={value} />
    </KFormGroup>
  ),
  Select: ({ label, value, ...props }: IProps) => (
    <KFormGroup label={label} {...props}>
      <KSelect value={value} />
    </KFormGroup>
  ),
  InputPhone: ({
    label,
    value,
    phone,
    addonBefore,
    ...props
  }: IProps & { phone?: boolean; addonBefore?: React.ReactNode }) => (
    <KFormGroup label={label} {...props}>
      <KInputPhone
        value={value}
        phone={phone}
        addonBefore={addonBefore}
      />
    </KFormGroup>
  ),
  TextEditor: ({ label, value, ...props }: IProps) => (
    <KFormGroup label={label} {...props}>
      <KTextEditor value={value} />
    </KFormGroup>
  ),
  AutoComplete: ({ label, value, ...props }: IProps) => (
    <KFormGroup label={label} {...props}>
      <AutoComplete value={value} />
    </KFormGroup>
  ),
};

export * from './Form'