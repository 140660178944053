import { Color } from "../../types";
import { IconProps } from "./types";

export const FilePdfIcon: React.FC<IconProps> = ({
  style,
  width = 64,
  height,
  color = Color.SecondaryBase,
}) => {
  return (
    <svg
      style={style}
      width={width}
      height={height || width}
      fill={color}
      viewBox={"0 0 32 32"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6031 17.95L16.6125 17.9062C16.7937 17.1594 17.0219 16.2281 16.8438 15.3844C16.725 14.7188 16.2344 14.4594 15.8156 14.4406C15.3219 14.4187 14.8813 14.7 14.7719 15.1094C14.5656 15.8594 14.75 16.8844 15.0875 18.1906C14.6625 19.2031 13.9844 20.675 13.4875 21.55C12.5625 22.0281 11.3219 22.7656 11.1375 23.6969C11.1 23.8687 11.1437 24.0875 11.2469 24.2844C11.3625 24.5031 11.5469 24.6719 11.7625 24.7531C11.8562 24.7875 11.9688 24.8156 12.1 24.8156C12.65 24.8156 13.5406 24.3719 14.7281 22.3344C14.9094 22.275 15.0969 22.2125 15.2781 22.15C16.1281 21.8625 17.0094 21.5625 17.8062 21.4281C18.6875 21.9 19.6906 22.2031 20.3719 22.2031C21.0469 22.2031 21.3125 21.8031 21.4125 21.5625C21.5875 21.1406 21.5031 20.6094 21.2188 20.325C20.8062 19.9188 19.8031 19.8125 18.2406 20.0063C17.4719 19.5375 16.9688 18.9 16.6031 17.95ZM13.175 22.6969C12.7406 23.3281 12.4125 23.6437 12.2344 23.7812C12.4437 23.3969 12.8531 22.9906 13.175 22.6969ZM15.9125 15.3375C16.075 15.6156 16.0531 16.4563 15.9281 16.8813C15.775 16.2594 15.7531 15.3781 15.8438 15.275C15.8687 15.2781 15.8906 15.2969 15.9125 15.3375ZM15.8625 19.1031C16.1969 19.6812 16.6188 20.1781 17.0844 20.5469C16.4094 20.7 15.7937 20.9531 15.2437 21.1781C15.1125 21.2313 14.9844 21.2844 14.8594 21.3344C15.275 20.5813 15.6219 19.7281 15.8625 19.1031ZM20.725 21.15C20.7281 21.1562 20.7312 21.1656 20.7125 21.1781H20.7062L20.7 21.1875C20.675 21.2031 20.4187 21.3531 19.3156 20.9188C20.5844 20.8594 20.7219 21.1469 20.725 21.15ZM26.7062 9.01875L19.9813 2.29375C19.7938 2.10625 19.5406 2 19.275 2H6C5.44687 2 5 2.44687 5 3V29C5 29.5531 5.44687 30 6 30H26C26.5531 30 27 29.5531 27 29V9.72812C27 9.4625 26.8937 9.20625 26.7062 9.01875ZM24.6938 10.1875H18.8125V4.30625L24.6938 10.1875ZM24.75 27.75H7.25V4.25H16.6875V11C16.6875 11.3481 16.8258 11.6819 17.0719 11.9281C17.3181 12.1742 17.6519 12.3125 18 12.3125H24.75V27.75Z"
        fill="#161925"
      />
    </svg>
  );
};