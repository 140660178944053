export enum ENotificationType {
  SHARE_PROFILE = 'share_profile',
  UNSHARE_PROFILE = 'unshare_profile',
  EXPIRED_SHARE_PROFILE = 'expired_share_profile',
  ACCEPT_SHARE_PROFILE = 'accept_share_profile',
  ASSIGN_TODO = 'assign_todo',
  CLAIM_TODO = 'claim_todo',
  COMPLETE_TODO = 'complete_todo',
  REQUEST_HELP_TODO = 'request_help_todo',
  DUE_TODAY_TASK = 'due_today_task',
  REMINDER = 'reminder',
  CARD = 'card',
  PAYMENT_SUCCESS = 'payment_success',
  PAYMENT_FAILED = 'payment_failed',
  SUBSCRIPTION_EXPIRED = 'subscription_expired',
  SUBSCRIPTION_UPGRADE_PREMIUM = 'subscription_upgrade_premium',
  EXPORT_SUCCESS = 'export_success',
  EXPORT_FAILED = 'export_failed',
}
