import {
  InfoCircleOutlined,
  LogoutOutlined,
  SettingOutlined,
  BellOutlined
} from "@ant-design/icons";
import { HelpIcon, KinKeeperLogo } from "@assets/index";
import {
  AvatarAccount,
  KAnchor,
  KBadgeCount,
  KButton,
  KLgText700,
  KLgTextSecondary88,
  KMdText,
  KMdTextSecondary88,
  KTextWithCheckIcon,
  KXlText700Secondary88,
  ModalConfirmLogout,
} from "@components";
import { getAppUrl, homePageUrl } from "@constants/url";
import { useKContext } from "@context";
import { useCustomNavigate } from "@hooks";
import { useUser } from "@store";
import { Color, EUrlPath, IParams } from "@types";
import { getToken } from "@utils/storage";
import { Badge, Flex, Modal } from "antd";
import { modalProps } from "config/antd";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import {
  CardUpgrade,
  ContentCardUpgrade,
  ContentItems,
  StyledDivider,
  StyledItemMenu,
  StyledLeftMenu,
  WrapperContentItems,
  WrapperLogo
} from "./styles";
import { KLeftMenuProps } from "./types";
import { getCountNotificationService } from "@services";

export const KLeftMenu: React.FC<KLeftMenuProps> = ({ isShow, onClose }) => {
  const { navigate } = useCustomNavigate();
  const { profileId, tempShareId, emergencyAccessId } = useParams<IParams>()
  const { show, hide, isOpen } = useIntercom();
  const { infoPrincipal, isValidToken } = useKContext();
  const { userInfo, setNotificationCount, notificationCount } = useUser();
  const [showConfirm, setShowConfirm] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const onClickLogout = () => {
    onClose();
    setShowConfirm(true);
  };

  const onClickAbout = useCallback(() => {
    let originPath = tempShareId;
    if (tempShareId) originPath = tempShareId;
    if (profileId) originPath = `user/${profileId}`;
    if (emergencyAccessId) originPath = `${EUrlPath.EMERGENCY_ACCESS}/${emergencyAccessId}`;
    onClose();
    navigate(`/${originPath}/${EUrlPath.ABOUT}`);
  }, [tempShareId, profileId, emergencyAccessId, onClose, navigate]);

  const onClickAccountSettings = useCallback(() => {
    onClose();
    navigate(`/user/${profileId}/${EUrlPath.ACCOUNT_SETTINGS}`);
  }, [profileId]);

  const onClickNotifications = useCallback(() => {
    onClose();
    navigate(`/user/${profileId}/${EUrlPath.NOTIFICATIONS}`);
  }, [profileId]);

  const onClickHelp = () => {
    if (infoPrincipal || userInfo || getToken('EmergencyAccess')) {
      show();
    }
    onClose();
  };

  const handleClickOutside = (event: any) => {
    if (!event.target.closest('.intercom-messenger-frame') && isOpen) {
      hide();
    }
  };

  useEffect(() => {
    isOpen && window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    if (!userInfo) return;
    (async () => {
      try {
        const res = await getCountNotificationService();
        setNotificationCount(res);
      } catch {}
    })();
  }, [userInfo]);

  return (
    <>
      <StyledLeftMenu
        title="Close"
        placement="left"
        onClose={onClose}
        open={isShow}
        width={300}
      >
        <WrapperContentItems>
          <WrapperLogo id="left-menu-wrapper-logo">
            <KinKeeperLogo width={131} height={26} id="left-menu-logo" />
          </WrapperLogo>
          <Flex vertical align="center" gap={8}>
            {isValidToken && profileId && <AvatarAccount />}
            <KXlText700Secondary88>{userInfo?.firstName}</KXlText700Secondary88>
          </Flex>
          <StyledDivider />
          <ContentItems>
            {profileId && (
               <>
               <StyledItemMenu
                 onClick={onClickNotifications}
                 justify="space-between"
               >
                 <Flex gap={8}>
                   <BellOutlined />
                   <KMdText>Notifications</KMdText>
                 </Flex>
                 <KBadgeCount
                    color={Color.White}
                    backgroundColor={Color.Magenta}
                    size="lg"
                    counter={notificationCount}
                 />
               </StyledItemMenu>
               <StyledItemMenu onClick={onClickAccountSettings}>
                 <SettingOutlined />
                 <KMdText>Account Settings</KMdText>
               </StyledItemMenu>
             </>
            )}
            <StyledItemMenu onClick={onClickHelp}>
              <HelpIcon />
              <KMdText>
                Help
              </KMdText>
            </StyledItemMenu>
              <StyledItemMenu onClick={onClickAbout}>
                <InfoCircleOutlined />
                <KMdText>
                  About
                </KMdText>
              </StyledItemMenu>
            <StyledDivider />
            {!emergencyAccessId && (
              <StyledItemMenu onClick={onClickLogout}>
                <LogoutOutlined />
                <KMdText>
                  Logout
                </KMdText>
              </StyledItemMenu>
            )}
          </ContentItems>
        </WrapperContentItems>
        <CardUpgrade>
          <KLgText700>
            What a place for your important health info?
          </KLgText700>
          <ContentCardUpgrade>
            <KTextWithCheckIcon>
              Store any health-related info for anyone you care for
            </KTextWithCheckIcon>
            <KTextWithCheckIcon>
              Attach an organize relevant documents and photos
            </KTextWithCheckIcon>
            <KTextWithCheckIcon>
              Share info anyone that need access, anytime!
            </KTextWithCheckIcon>
          </ContentCardUpgrade>
          <KButton
            type="primary"
            size="large"
            title="Download KinKeeper!"
            target="_blank"
            href={getAppUrl}
          />
        </CardUpgrade>
      </StyledLeftMenu>
      <ModalConfirmLogout
        isOpen={showConfirm}
        setIsOpen={setShowConfirm}
        handleAfterLogout={() => {
          setShowLogoutModal(true);
        }}
      />
      <ModalLogout open={showLogoutModal} />
    </>
  );
};

const ModalLogout = ({ open }: { open: boolean }) => {
  return (
    <Modal open={open} {...modalProps} width={446}>
      <Flex gap={16} vertical>
        <Flex gap={8} vertical>
          <KXlText700Secondary88>You’re logged out.</KXlText700Secondary88>
          <KLgTextSecondary88>
            Rest easy, you can always view the page again by clicking on the
            link you received in your original invitation. You will need to
            re-verify your identity with another One Time Passcode (OTP).
          </KLgTextSecondary88>
        </Flex>
        <KLgText700>Want to use KinKeeper for your own profiles?</KLgText700>
        <KButton
          size="large"
          title="Download the app"
          target="_blank"
          href={getAppUrl}
        />
        <KMdTextSecondary88>
          {`Learn more about KinKeeper `}
          <KAnchor
            $isUnderlined
            size="md"
            color="Secondary88"
            target="_blank"
            href={homePageUrl}
          >
            here
          </KAnchor>
          .
        </KMdTextSecondary88>
      </Flex>
    </Modal>
  );
};
