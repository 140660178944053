import { Color } from "@types";
import { KAvatar } from "components/Avatar/Avatar";
import styled from "styled-components";

interface StyledAvatarProfileProps {
  allowHover?: boolean;
}

const StyledAvatarProfile = styled(KAvatar)<StyledAvatarProfileProps>(
  ({ allowHover }) => ({
    ...(allowHover && {
      position: "relative",
      display: "inline-block",
      cursor: 'pointer',
      transition: 'background-color 0.3s ease',
      "&:hover::after": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: Color.White,
        opacity: '0.5',
        pointerEvents: "none",
      },
    }),
  })
);

export { StyledAvatarProfile };
