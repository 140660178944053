import { Outlet } from 'react-router';
import styled from 'styled-components';
import { Layout } from "antd";
import { useContext } from 'react';

import { KHeader } from '@components';
import { breakpointLarge, heightHeader } from '@constants/size';
import { MobileWelcomeHeader } from 'components/Header/MobileWelcomeHeader';
import { KContext } from '@context';
import ErrorResult from '@pages/error-result';

const { Content } = Layout;

const MainContent = styled(Content)`
  padding: 0 50px;
  height: calc(100vh - ${heightHeader}px);
  background-color: #fff;
  @media (max-width: ${breakpointLarge}px) {
    padding: 0;
  }
`;

const AuthLayout = () => {
  const { isMobile, showErrorResult, } = useContext(KContext);

  return (
    <Layout>
      {!isMobile ? (
        <KHeader />
      ) : (
        <MobileWelcomeHeader />
      )}
      <MainContent>
        {showErrorResult ? (
          <ErrorResult />
        ) : (
          <Outlet />
        )}
      </MainContent>
    </Layout>
  );
};

export default AuthLayout;
