import { useCallback, useContext } from "react";

import { CardItemStyled, CardTitle, KMdTextSecondary65 } from "@components";
import { KContext } from "@context";
import { ECardType, IItemCardProps, IParams, IProcedureItem } from "@types";
import { getFullDateStr } from "@utils/date";
import { getInfoCard } from "@utils/index";
import { useParams } from "react-router-dom";
import { CardItemContainer } from "..";
import { useCustomNavigate } from "@hooks";

export const ProcedureItem = ({ card, showIcon, showMetadata, isDetail }: IItemCardProps<IProcedureItem>) => {
  const { _id, title, startTime, endTime, cardType } = card;
  const { navigate } = useCustomNavigate();
  const { setCardTypeSelected, navigateDetailCard } = useContext(KContext);
  const { cardId } = useParams<IParams>();
  const iconCardType = showIcon && getInfoCard(cardType).icon;

  const onClickItem = useCallback(() => {
    if (cardId === _id) return;
    if (!isDetail) {
      return navigate(
        _id,
        undefined,
        () => { setCardTypeSelected(ECardType.Procedures); },
      );
    }
    navigateDetailCard(ECardType.Procedures, _id);
  }, [_id, cardId]);

  return (
    <CardItemStyled $isActive={cardId === _id} onClick={onClickItem}>
      <CardItemContainer showMetadata={showMetadata} card={card}>
        <CardTitle icon={iconCardType}>
          {title}
        </CardTitle>
        {startTime && (
          <KMdTextSecondary65 $ellipsis numberOfLines={2}>
            {getFullDateStr(startTime, endTime)}
          </KMdTextSecondary65>
        )}
      </CardItemContainer>
    </CardItemStyled>
  );
};
