import { BellOutlined, PushpinOutlined } from "@ant-design/icons";
import { TaskInfoTypeIcon, UpgradePremiumIcon } from "@assets/icons";
import { CardTitle, KButton, KFormUser, KModalAddCard } from "@components";
import { ECardType, EToDoRepeatStatus, IUserAccessProfile } from "@types";
import { Flex } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { useEffect, useMemo, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { SetRepeat } from "./ToDoDetail/SetRepeat";
import { UserAccessChecklist } from "./ToDoDetail/UserAccessChecklist";
import { usePremiumPlan } from "@hooks";

export const SetReminderSection = () => {
  const method = useFormContext();
  const { getValues, setValue, control, watch } = method;
  const { append } = useFieldArray({
    control,
    name: "reminder.specifiedRecipients",
  });
  const isPremium = usePremiumPlan();
  const [isSetReminder, setIsSetReminder] = useState(false);

  const recipients = useMemo(
    () => getValues("reminder.specifiedRecipients"),
    [watch("reminder.specifiedRecipients")]
  );

  const [showModal, setShowModal] = useState(false);
  const handleOpenModal = () => {
    setShowModal(true);
  };

  useEffect(() => {
    setIsSetReminder(getValues("isSetReminder"));
    if (getValues("isSetReminder") && !getValues("reminder.repeat")) {
      setValue("reminder.repeat", EToDoRepeatStatus.NEVER);
    }
  }, [watch("isSetReminder")]);

  const handleCheckRecipient = (
    e: CheckboxChangeEvent,
    user: IUserAccessProfile
  ) => {
    const { checked } = e.target;
    const { user: _id } = user;
    if (checked) {
      append(_id);
    } else {
      const newCheckList = getValues("reminder.specifiedRecipients").filter(
        (u: string) => u !== _id
      );
      setValue("reminder.specifiedRecipients", newCheckList, {
        shouldDirty: true,
      });
    }
  };

  return (
    <>
      <KFormUser.Switch
        icon={<PushpinOutlined />}
        title="Pin to Upcoming"
        name="isFollowedUp"
      />
      <KFormUser.Switch
        icon={<BellOutlined />}
        title="Set a Reminder"
        name="isSetReminder"
      />
      {isSetReminder && (
        <Flex gap={16} vertical>
          <SetRepeat time="reminder.startTime" repeat="reminder.repeat" />
          <KFormUser.Switch
            label="Only certain people"
            name="reminder.isSpecifiedRecipients"
          />
          <UserAccessChecklist
            ids={recipients}
            onCheck={handleCheckRecipient}
          />
        </Flex>
      )}
      <CardTitle
        icon={<TaskInfoTypeIcon width={17} />}
        children="Create a linked To-Do"
        suffix={
          !isPremium ? (
            <UpgradePremiumIcon />
          ) : (
            <KButton
              onClick={handleOpenModal}
              title="Add To-Do"
              size="small"
              ghost
            />
          )
        }
      />
      <KModalAddCard
        cardType={ECardType.ToDos}
        linkedId={getValues()._id}
        onOpen={setShowModal}
        open={showModal}
      />
    </>
  );
};
