import { Color } from "../../types";
import { IconProps } from "./types";

export const DoubleCaretIcon: React.FC<IconProps> = ({
  style,
  width = "16px",
  height = "16px",
  color = Color.White,
}) => {
  return (
    <svg
      style={style}
      width={width}
      height={height}
      fill={color}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3153 3.13934L17.9128 9.62999C18.1208 9.87202 17.9333 10.2266 17.5975 10.2266H6.4025C6.06672 10.2266 5.87923 9.87202 6.08717 9.62999L11.6847 3.13934C11.8466 2.95355 12.1551 2.95355 12.3153 3.13934ZM11.6847 21.0872L6.08717 14.5966C5.87923 14.3545 6.06672 14 6.4025 14H17.5975C17.9333 14 18.1208 14.3545 17.9128 14.5966L12.3153 21.0872C12.1534 21.273 11.8449 21.273 11.6847 21.0872Z"
        fill="#161925"
      />
    </svg>
  );
};
