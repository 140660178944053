import { Breadcrumb } from "antd";
import styled from "styled-components";
import { ESpacing } from "../../types/enums/style";

const StyledBreadcrumb = styled(Breadcrumb)`
  &.ant-breadcrumb .ant-breadcrumb-separator {
    margin-inline: 0;
  }
  &.ant-breadcrumb ol {
    gap: ${ESpacing.sm};
    display: flex;
    align-items: center;
  }
`;

export { StyledBreadcrumb };