import { FC, SVGProps } from "react";
import { IconProps } from "./types";

export const MedicationInfoTypeIcon: FC<IconProps> = ({
  width = 24,
  height,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height || width}
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3429 3.48668C15.3233 1.50387 18.5319 1.50387 20.5147 3.48668C22.4952 5.46949 22.4952 8.67809 20.5171 10.6609L15.246 15.932L15.2425 15.9285L10.6585 20.5125C8.67803 22.4953 5.46943 22.4929 3.48662 20.5125C1.50381 18.532 1.50381 15.3234 3.48662 13.3406L8.75771 8.0695L8.75889 8.07067L13.3429 3.48668ZM9.01475 10.2078L4.68428 14.5382C3.42334 15.7992 3.28975 17.925 4.68428 19.3195C6.07646 20.714 8.20459 20.5804 9.46553 19.3195L13.7941 14.9871L9.01475 10.2078ZM14.9878 13.7949L10.2065 9.01364L14.5382 4.68199C15.7991 3.42106 17.9272 3.28746 19.3194 4.68199C20.714 6.07653 20.5804 8.20231 19.3194 9.46325L14.9878 13.7949ZM12.1415 8.68699C12.0634 8.7651 12.0634 8.89173 12.1415 8.96984L12.9193 9.74765C12.9974 9.82576 13.124 9.82576 13.2021 9.74765L16.028 6.92176C16.1061 6.84366 16.1061 6.71702 16.028 6.63892L15.2502 5.8611C15.1721 5.783 15.0455 5.783 14.9674 5.8611L12.1415 8.68699Z"
    />
  </svg>
);
