import { Flex } from "antd";

import { KFormUser } from "@components";
import { ECardType } from "@types";
import { useEffect } from "react";
import { AttachmentsDetail } from "./Attachments";
import { SetReminderSection } from "./SetReminderSection";
import { useFormContext } from "react-hook-form";
import { isPastDate } from "@utils/date";

export const VaccinationDetails = () => {
  const { getValues, setValue, watch } = useFormContext();
  useEffect(() => {
    const endTimeValue = getValues('endTime');
    const outOfDateValue = isPastDate(endTimeValue);
    setValue('outOfDate', outOfDateValue);
  }, [watch('endTime')]);

  return (
    <>
      <Flex gap={16} vertical>
        <Flex gap={16} justify="space-between">
          <KFormUser.DatePicker label="Date Received" name="startTime" />
          <KFormUser.EndDatePicker label="Expiration Date" name="endTime" />
        </Flex>
        <KFormUser.Switch label="Out of date?" name="outOfDate" />
        <KFormUser.OptionsSelector
          label="Practitioner"
          cardType={ECardType.Practitioners}
        />
        <KFormUser.TextArea
          label="Location"
          name="location"
          placeholder="Enter location info"
        />
        <KFormUser.OptionsSelector
          label="Appointment"
          cardType={ECardType.Appointments}
        />
        <KFormUser.TextArea name="description" label="Additional Information" />
        <AttachmentsDetail />
        <SetReminderSection />
      </Flex>
    </>
  );
};
