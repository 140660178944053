import { OpenNewTabIcon } from "@assets/icons";
import { KAnchorWithIcon, KMdText700Secondary88 } from "@components";
import { getTempShareOfUser } from "@services";
import { useUser } from "@store";
import { formatPhoneNumber } from "@utils/formatPhoneNumber";
import { generateTemporaryShareUrl } from "@utils/url";
import { Divider, Flex, message } from "antd";
import { useEffect, useState } from "react";

interface ITemporaryShareIdInfo {
  emailUrl?: string;
  phoneNumberUrl?: string;
}

export const CustomShareLinkSection = () => {
  const { userInfo } = useUser();
  const [temporaryShareInfo, setTemporaryShareInfo] =
    useState<ITemporaryShareIdInfo>();
  const { emailUrl, phoneNumberUrl } = temporaryShareInfo || {};

  useEffect(() => {
    if (!userInfo?.phoneNumber && !userInfo?.email) return;
    (async () => {
      try {
        const { email, phoneNumber } = await getTempShareOfUser();
        const emailUrl = generateTemporaryShareUrl(userInfo.email, email[0]);
        const phoneNumberUrl = generateTemporaryShareUrl(
          userInfo.phoneNumber,
          phoneNumber[0]
        );
        setTemporaryShareInfo({ emailUrl, phoneNumberUrl });
      } catch (err: any) {
        message.error(err?.response?.data?.message);
      }
    })();
  }, [userInfo?.phoneNumber, userInfo?.email]);

  return emailUrl || phoneNumberUrl ? (
    <Flex vertical gap={8}>
      <Divider />
      <KMdText700Secondary88>
        Available Custom Share Links
      </KMdText700Secondary88>
      {phoneNumberUrl && (
        <KAnchorWithIcon href={phoneNumberUrl} $gap={8} target="_blank">
          View profiles shared to {formatPhoneNumber(userInfo?.phoneNumber)}
          <OpenNewTabIcon />
        </KAnchorWithIcon>
      )}
      {emailUrl && (
        <KAnchorWithIcon href={emailUrl} $gap={8} target="_blank">
          View profiles shared to {userInfo?.email}
          <OpenNewTabIcon />
        </KAnchorWithIcon>
      )}
    </Flex>
  ) : null;
};