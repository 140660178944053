import { LoadingOutlined } from "@ant-design/icons";
import {
  KButton,
  KMdTextSecondary65,
  KXlText700
} from "@components";
import { Color, IParams } from "@types";
import { Flex } from "antd";
import { useNavigate, useParams } from "react-router-dom";

export const EMRFetchingRecord = () => {
  const { profileId } = useParams<IParams>();
  const navigate = useNavigate();

  return (
    <Flex vertical gap={24} align="center" style={{ overflow: "scroll" }}>
      <LoadingOutlined style={{ color: Color.PrimaryBase, fontSize: 72 }} />
      <Flex vertical gap={8} align="center">
        <KXlText700>Fetching your records...</KXlText700>
        <KMdTextSecondary65 $align="center">
          Please be patient. It may take some time to import all of your
          records. You can leave this screen and come back later to review your
          available records and decide what you would like to import.
        </KMdTextSecondary65>
      </Flex>
      <KButton
        style={{ width: "100%" }}
        size="large"
        title="Return to Medical Record Sources"
        onClick={() => {
          navigate(`/user/${profileId}/medical-records`);
        }}
      />
    </Flex>
  );
};
