import { FC, PropsWithChildren } from 'react';
import { Flex } from 'antd';

import { useAuthStore } from '@store';
import { KAnchor } from '@components';
import { EAuthPage } from '@types';

export const BackNavFormHeader: FC<PropsWithChildren<{ screenKey: EAuthPage }>> = ({
  screenKey,
  children,
}) => {
  const { setCurrentPage } = useAuthStore();

  return (
    <Flex justify='center' style={{ position: 'relative', width: '100%', minHeight: 16 }}>
      <KAnchor
        $isUnderlined
        style={{ position: 'absolute', top: 0, left: 0 }}
        onClick={() => {
          setCurrentPage(screenKey);
        }}
      >
        Back
      </KAnchor>
      {children}
    </Flex>
  );
};
