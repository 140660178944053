import { Flex } from "antd";
import React, { useContext } from "react";

import { UpgradePremiumIcon } from "@assets/icons";
import { KContext } from "@context";
import { useCustomNavigate } from "@hooks";
import { getInfoCard } from "@utils/index";
import { KLgText700PrimaryBase } from "..";
import {
  CardButtonStyled,
  CardDetailStyled,
  IconProfileBasic,
  IconStyled,
  WrapperPremiumIcon,
} from "./styles";
import { IKCardButtonInfoProps } from "./types";

export const KCardButtonInfo: React.FC<IKCardButtonInfoProps> = ({
  type,
  requirePremium,
  disabled = false,
}) => {
  const { arrayPath } = useContext(KContext);
  const { navigate } = useCustomNavigate();
  const { icon, title } = getInfoCard(type);
  const onClickButtonInfo = () => {
    if (requirePremium || disabled) return;
    navigate(type);
  };
  return (
    <Flex onClick={onClickButtonInfo}>
      <CardDetailStyled $isActive={arrayPath?.[3] === type} $requirePremium={requirePremium} $disabled={disabled}>
        <IconProfileBasic>{icon}</IconProfileBasic>
        <KLgText700PrimaryBase>{title}</KLgText700PrimaryBase>
      </CardDetailStyled>
      {!disabled && (
        <>
          {requirePremium ? (
            <WrapperPremiumIcon>
              <UpgradePremiumIcon />
            </WrapperPremiumIcon>
          ) : (
            <CardButtonStyled>
              <IconStyled />
            </CardButtonStyled>
          )}
        </>
      )}
    </Flex>
  );
};