import { KButton } from "@components";
import { Color } from "@types";
import { Avatar } from "antd";
import styled from "styled-components";

export const ContainerAvatar = styled.div({
  position: "relative",
  width: 120,
  height: 120,
});

export const CustomBtnAvatar = styled(KButton)({
  position: "absolute",
  bottom: 0,
  right: 0,
  svg: {
    color: Color.White
  }
});

export const CustomAvatar = styled(Avatar)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  svg: {
    fontSize: 32,
  },
});