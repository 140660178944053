import { KFormUser } from "@components";
import { useDetailCard } from "@store";
import { EToDoRepeatStatus, repeatOptions } from "@types";
import { generateRoundedHour } from "@utils/index";
import { Flex } from "antd";
import { useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";

interface ISetRepeat {
  time: string;
  repeat: string;
}

export const SetRepeat = ({ time, repeat }: ISetRepeat) => {
  const { getValues, setValue, watch } = useFormContext();
  const { editableDetail } = useDetailCard();

  const disableRepeat = useMemo(()  => {
    const disabled = !getValues(time);
    return disabled;
  }, [watch(time)]);

  const onSelect = useCallback((value: string, option: any) => {
    if (value === EToDoRepeatStatus.NEVER || getValues(time)) return;
    setValue(time, generateRoundedHour(), { shouldDirty: true });
  }, [watch(time)]);

  return (
    <Flex gap={16} vertical>
      <KFormUser.DateTimePicker
        label="Due Date"
        name={time}
      />
      <KFormUser.Select
        disabled={!editableDetail || disableRepeat}
        label="Repeat"
        name={repeat}
        options={repeatOptions}
        onSelect={onSelect}
      />
    </Flex>
  );
};
