import { Checkbox } from "antd";
import styled from "styled-components";

const KCheckBoxStyled = styled(Checkbox)`
  .ant-checkbox-inner {
    height: 20px;
    width: 20px;
  }
  .ant-checkbox-inner:after {
    inset-inline-start: 26%;
  }
`;

export { KCheckBoxStyled };