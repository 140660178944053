import { IKTextProps } from "@types";
import { Divider } from "antd";
import { KSmText700Secondary65 } from "components/Text";
import styled from "styled-components";

const DividerWithText = styled(Divider)`
  height: 22px;
  &.ant-divider-with-text {
    margin: 0;
  }
`;

export const DividerLeftText = ({ children }: IKTextProps) => (
  <DividerWithText orientation="left" orientationMargin="0">
    <KSmText700Secondary65>{children}</KSmText700Secondary65>
  </DividerWithText>
);