export const GoogleIcon = ({
  width = 16,
  height,
}: {
  height?: number;
  width?: number;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height || width}
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M4.28826 9.39037L3.82415 11.123L2.12782 11.1589C1.62087 10.2186 1.33331 9.14276 1.33331 7.99953C1.33331 6.89404 1.60217 5.85154 2.07873 4.93359H2.07909L3.5893 5.21047L4.25087 6.71162C4.1124 7.11529 4.03693 7.54862 4.03693 7.99953C4.03699 8.48891 4.12563 8.95779 4.28826 9.39037Z"
      fill="#FBBB00"
    />
    <path
      d="M14.5502 6.75391C14.6268 7.15719 14.6667 7.57367 14.6667 7.99932C14.6667 8.47662 14.6165 8.94219 14.5209 9.39128C14.1964 10.9195 13.3484 12.2539 12.1737 13.1982L12.1733 13.1979L10.2711 13.1008L10.0019 11.4202C10.7814 10.9631 11.3906 10.2477 11.7115 9.39128H8.14661V6.75391H11.7635H14.5502Z"
      fill="#518EF8"
    />
    <path
      d="M12.1733 13.1983L12.1737 13.1987C11.0312 14.117 9.57988 14.6664 8.00003 14.6664C5.4612 14.6664 3.25388 13.2474 2.12787 11.1591L4.28831 9.39062C4.85131 10.8932 6.30076 11.9628 8.00003 11.9628C8.73042 11.9628 9.41469 11.7653 10.0019 11.4207L12.1733 13.1983Z"
      fill="#28B446"
    />
    <path
      d="M12.2553 2.8678L10.0956 4.63593C9.48791 4.25608 8.76957 4.03665 7.99999 4.03665C6.26225 4.03665 4.78569 5.15533 4.2509 6.71176L2.0791 4.93374H2.07874C3.18827 2.79455 5.42342 1.33301 7.99999 1.33301C9.61757 1.33301 11.1007 1.90921 12.2553 2.8678Z"
      fill="#F14336"
    />
  </svg>
);
