import { FC, SVGProps } from "react";
import { IconProps } from "./types";

export const ProcedureInfoTypeIcon: FC<IconProps> = ({
  width = 24,
  height,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height || width}
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.8369 4.31335C19.3561 4.82121 19.7714 5.42546 20.0593 6.09225C20.358 6.7862 20.5105 7.52311 20.5084 8.28151C20.5084 8.99694 20.3623 9.74245 20.0722 10.5008C19.8316 11.1346 19.4814 11.7921 19.0367 12.4559C18.332 13.5065 17.363 14.6022 16.1599 15.713C14.164 17.5542 12.1917 18.8239 12.108 18.8776L11.5988 19.2042C11.3732 19.3481 11.0832 19.3481 10.8576 19.2042L10.3484 18.8776C10.2646 18.826 8.29019 17.5542 6.29644 15.713C5.09331 14.6022 4.12437 13.5065 3.41968 12.4559C2.97495 11.7921 2.6269 11.1346 2.38413 10.5008C2.09409 9.74245 1.948 8.99694 1.948 8.28151C1.948 7.52311 2.10054 6.7862 2.39917 6.09225C2.68637 5.42503 3.1017 4.82064 3.62163 4.31335C4.1437 3.80202 4.75171 3.40026 5.42632 3.12096C6.12456 2.83092 6.86362 2.68483 7.62632 2.68483C8.6855 2.68483 9.7189 2.97487 10.6169 3.52272C10.8318 3.65377 11.0359 3.79772 11.2292 3.95456C11.4226 3.79772 11.6267 3.65377 11.8416 3.52272C12.7396 2.97487 13.773 2.68483 14.8322 2.68483C15.5872 2.68309 16.335 2.83134 17.0322 3.12096C17.7046 3.39917 18.3172 3.80396 18.8369 4.31335ZM3.58081 8.28151C3.58081 12.6042 11.2292 17.5048 11.2292 17.5048C11.2292 17.5048 18.8777 12.6042 18.8777 8.28151C18.8777 6.09225 17.0666 4.31764 14.8322 4.31764C13.2617 4.31764 11.8996 5.1942 11.2292 6.47467C10.5589 5.1942 9.19683 4.31764 7.62632 4.31764C5.39194 4.31764 3.58081 6.09225 3.58081 8.28151ZM14.4916 5.68621L15.5727 5.8894L15.4034 6.79029L16.3943 6.97653L16.1911 8.0576L15.2002 7.87136L14.9801 9.04252L15.971 9.22876L15.7678 10.3098L14.7769 10.1236L14.5568 11.2948L15.5476 11.481L15.3445 12.5621L14.3536 12.3758L14.2181 13.0965L13.137 12.8934L13.2725 12.1726L12.2814 11.9864L12.4846 10.9053L13.4757 11.0916L13.6958 9.92041L12.7047 9.73413L12.9079 8.65306L13.899 8.83933L14.1191 7.66817L13.128 7.4819L13.3312 6.40083L14.3223 6.5871L14.4916 5.68621Z"
    />
  </svg>
);
