import { CloseCircleOutlined } from "@ant-design/icons";
import { Flex, FlexProps } from "antd";
import { FC } from "react";

import { UpgradePremiumIcon } from "@assets/icons";
import { KLgText700, KTextWithCheckIcon } from "@components";
import { widthTabsAndCard } from "@constants/index";
import { useGlobalModals } from "@store";
import {
  EFontSize,
  listUpgradeBenefits
} from "@types";
import { BorderLinearGradient, StyledContainerUpgrade, StyledModalUpgrade, StyledTitleModalUpgrade } from "./styled-components";

const HeaderUpgradePremium: FC<Omit<FlexProps, "children">> = (props) => (
  <Flex gap={8} align="center" {...props}>
    <UpgradePremiumIcon />
    <KLgText700>Upgrade to Premium for more!</KLgText700>
  </Flex>
);

const ContentUpgradePremium = () => (
  <StyledContainerUpgrade vertical gap={16} justify="center">
    <Flex vertical gap={8}>
      {listUpgradeBenefits.map((val) => (
        <KTextWithCheckIcon iconColor="WarningBase" children={val}/>
      ))}
    </Flex>
    <KLgText700 $align="center">
      Open your KinKeeper mobile app to start your 14 Day free trial!
    </KLgText700>
  </StyledContainerUpgrade>
);

export const CardUpgradePremium: FC = () => {
  return (
    <BorderLinearGradient>
      <Flex vertical>
        <HeaderUpgradePremium justify="center" style={{ marginTop: 12 }}/>
        <ContentUpgradePremium />
      </Flex>
    </BorderLinearGradient>
  );
};

export const ModalUpgradePremium: FC = () => {
  const { openUpgradeModal, setOpenUpgradeModal } = useGlobalModals();
  return (
    <StyledModalUpgrade
      centered
      width={widthTabsAndCard}
      open={openUpgradeModal}
      onCancel={() => setOpenUpgradeModal(false)}
      closeIcon={false}
      footer={null}
      children={<ContentUpgradePremium />}
      title={
        <StyledTitleModalUpgrade>
          <HeaderUpgradePremium />
          <CloseCircleOutlined
            style={{ fontSize: EFontSize.semi, cursor: "pointer" }}
            onClick={() => setOpenUpgradeModal(false)}
          />
        </StyledTitleModalUpgrade>
      }
    />
  );
};
