import { KBadgeCount } from "components/Badge";
import { KButton } from "components/Button";
import styled from "styled-components";

const ContainerInfoOptions = styled.div`
  padding: 12px;
`;

const StyledButton = styled(KButton)`
  padding: 16px;
`;

const KBadgeCountStyled = styled(KBadgeCount)`
  div {
    width: auto !important
  }
`;

export { ContainerInfoOptions, KBadgeCountStyled, StyledButton };

