export const TaskInfoTypeIcon = ({
  width = 24,
  height,
}: {
  height?: number;
  width?: number;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height || width}
    viewBox="0 0 24 24"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.4378 4H19.144C19.0245 4 18.9097 4.05859 18.8394 4.15469L12.3026 13.025L9.78623 9.93906C9.71592 9.84297 9.60342 9.78437 9.48155 9.78437H8.1878C8.03545 9.78437 7.94639 9.95781 8.03545 10.082L11.998 15.1602C12.033 15.2081 12.0788 15.2471 12.1318 15.274C12.1847 15.3009 12.2433 15.3149 12.3026 15.3149C12.362 15.3149 12.4206 15.3009 12.4735 15.274C12.5264 15.2471 12.5723 15.2081 12.6073 15.1602L20.5901 4.3C20.6792 4.17344 20.5901 4 20.4378 4ZM17.5586 16.7516H18.8195C18.918 16.7516 19 16.8313 19 16.9321V19.2805C19 19.6789 18.6789 20 18.2805 20H3.71953C3.32109 20 3 19.6789 3 19.2805V4.71957C3 4.32113 3.32109 4.00004 3.71953 4.00004H13.7676L13.8001 4.00003C13.9106 4.00001 14 4.08956 14 4.20003V5.41957C14 5.53002 13.9105 5.61957 13.8 5.61957H13.7676H4.61953V18.3805H17.3781V16.9321C17.3781 16.8336 17.4602 16.7516 17.5586 16.7516Z"
    />
  </svg>
);
