import { Color } from "../../types";
import { IconProps } from "./types";

export const FilePptIcon: React.FC<IconProps> = ({
  style,
  width = 64,
  height,
  color = Color.SecondaryBase,
}) => {
  return (
    <svg
      style={style}
      width={width}
      height={height || width}
      fill={color}
      viewBox={"0 0 32 32"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.25 14.875C13.1125 14.875 13 14.9875 13 15.125V23.75C13 23.8875 13.1125 24 13.25 24H14.2656C14.4031 24 14.5156 23.8875 14.5156 23.75V20.7656H16.4937C18.35 20.7656 19.5 19.55 19.5 17.825C19.5 16.1219 18.3656 14.8781 16.5 14.8781H13.25V14.875ZM17.9562 17.8219C17.9562 19.1781 17.1281 19.5187 15.7312 19.5187H14.5156V16.1313H16.2719C17.3281 16.1313 17.9562 16.7469 17.9562 17.8219ZM26.7062 9.01875L19.9813 2.29375C19.7938 2.10625 19.5406 2 19.275 2H6C5.44687 2 5 2.44687 5 3V29C5 29.5531 5.44687 30 6 30H26C26.5531 30 27 29.5531 27 29V9.72812C27 9.4625 26.8937 9.20625 26.7062 9.01875ZM24.6938 10.1875H18.8125V4.30625L24.6938 10.1875ZM24.75 27.75H7.25V4.25H16.6875V11C16.6875 11.3481 16.8258 11.6819 17.0719 11.9281C17.3181 12.1742 17.6519 12.3125 18 12.3125H24.75V27.75Z"
        fill="#161925"
      />
    </svg>
  );
};