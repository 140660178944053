import { apiInstance } from "@apis";
import { ECardTypeName, EOrderBy, IBaseRes, IPagable, ITopic, EOrder, ICardQuery } from "@types";

const getBaseUrl = (profileId: string) => `/v1/profile/${profileId}/topic`;

const getTopicsUserService = async (
  profileId: string
): Promise<ITopic[]> => {
  const paramsTopic: ICardQuery = {
    size: 1000,
    order: EOrder.ASC,
    orderBy: EOrderBy.TITLE,
  };
  const { data } = await apiInstance.get(
    getBaseUrl(profileId),
    { params: paramsTopic }
  );
  return data?.data?.result;
};

const createTopicService = async (
  profileId: string,
  topicName: string,
): Promise<ITopic> => {
  const { data } = await apiInstance.post(
    getBaseUrl(profileId),
    { title: topicName },
  );
  return data?.data;
};

const updateTopicService = async (topic: ITopic) => {
  const { data } = await apiInstance.put(
    `${getBaseUrl(topic.profile)}/${topic._id}`,
    { title: topic.title },
  );
  return data?.data;
};

const deleteTopicService = async (profileId: string, topicId: string) => {
  await apiInstance.delete(
    `${getBaseUrl(profileId)}/${topicId}`,
  );
};

const updateTopicOfCardTypeService = async (
  profileId: string,
  cardType: ECardTypeName,
  id: string,
  topicIds: string[],
) => {
  const { data } = await apiInstance.put(
    `/v1/profile/${profileId}/card/${cardType}/${id}/topics`,
    { ids: topicIds },
  );
  return data?.data;
}

export const getCardsRelatedToTopicService = async (profileId: string, topicId: string, query?: ICardQuery) => {
  const { data } = await apiInstance.get<IBaseRes<IPagable<any>>>(
    `${getBaseUrl(profileId)}/${topicId}/cards-related-to-topic`,
    {
      params: { size: 1000, ...query },
    }
  );
  return data.data;
};

export {
  getTopicsUserService,
  createTopicService,
  updateTopicOfCardTypeService,
  updateTopicService,
  deleteTopicService,
};