import { useCallback, useContext } from "react";
import { useParams } from "react-router-dom";

import { PractitionerInfoTypeIcon, VaccinationInfoTypeIcon } from "@assets/icons";
import { CardItemStyled, CardTitle, KMdText700PrimaryBase, KMdTextSecondary65, KTag } from "@components";
import { KContext } from "@context";
import { ECardType, EDateTimeFormat, IItemCardProps, IParams, IVaccinationItemList } from "@types";
import { CardItemContainer } from "../share-component";
import { useCustomNavigate } from "@hooks";
import { formatTimeRangeCard } from "@utils/date";

export const VaccinationItem = ({ card, showMetadata, isDetail }: IItemCardProps<IVaccinationItemList>) => {
  const { _id, title, startTime, endTime, location, practitionerName, practitioner } = card;
  const { cardId } = useParams<IParams>();
  const { navigate } = useCustomNavigate();
  const { setCardTypeSelected, navigateDetailCard } = useContext(KContext);

  const onClickItem = useCallback(() => {
    if (cardId === _id) return;
    if (!isDetail) {
      return navigate(
        _id,
        undefined,
        () => { setCardTypeSelected(ECardType.Vaccinations); },
      );
    }
    navigateDetailCard(ECardType.Vaccinations, _id);
  }, [_id, cardId]);

  return (
    <CardItemStyled $isActive={cardId === _id} onClick={onClickItem}>
      <CardItemContainer showMetadata={showMetadata} card={card}>
        <CardTitle icon={<VaccinationInfoTypeIcon width={20} height={20} />}>
          {title}
        </CardTitle>
        {startTime && (
          <KMdTextSecondary65>
            {formatTimeRangeCard(startTime, endTime, EDateTimeFormat.FULL_MONTH_YEAR)}
          </KMdTextSecondary65>
        )}
        {!!practitioner && (
          <KTag icon={<PractitionerInfoTypeIcon width={16} height={16} />}>
            {practitionerName}
          </KTag>
        )}
        {!!location && (
          <KMdText700PrimaryBase $ellipsis numberOfLines={2}>
            {location}
          </KMdText700PrimaryBase>
        )}
      </CardItemContainer>
    </CardItemStyled>
  );
};
