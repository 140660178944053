import { UserAddOutlined } from "@ant-design/icons";
import {
  KButton,
  KFormUser,
  KModalQuickAdd,
  KText,
  Loading,
} from "@components";
import { KContext } from "@context";
import { zodResolver } from "@hookform/resolvers/zod";
import { createProfileDetail, getSwitchProfiles } from "@services";
import { useSwitchProfiles, useUser } from "@store";
import {
  EErrorMessage,
  EUrlPath,
  ICreateProfile,
  IParams,
  IProfileSwitch,
  birthdayRegex,
  relationshipList,
} from "@types";
import { Flex, Form, Modal, ModalProps, message } from "antd";
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { z } from "zod";
import { ListProfileSection } from "./ListProfileSection";
import { useCustomNavigate, useLoggingService } from "@hooks";
import { CustomShareLinkSection } from "./CustomShareLinkSection";

const createProfileSchema = z.object({
  firstName: z.string().min(1, { message: EErrorMessage.REQUIRED }),
  lastName: z.string().min(1, { message: EErrorMessage.REQUIRED }),
  birthDate: z.string().refine((value) => birthdayRegex.test(value), {
    message: EErrorMessage.REQUIRED,
  }),
  relationship: z.string(),
});

export const KProfileSwitch: FC<ModalProps> = ({ ...props }) => {
  const { isUserPath, isMobile } = useContext(KContext);
  const { userInfo } = useUser();
  const { setOpenSwitch, openSwitch, resetSwitch, setResetSwitch } =
    useSwitchProfiles();
  const [switchProfiles, setSwitchProfiles] = useState<IProfileSwitch[]>([]);
  const [openCreateProfile, setOpenCreateProfile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const [page, setPage] = useState(1);
  const scrollRef = useRef<HTMLDivElement>(null);
  const { navigate } = useCustomNavigate();
  const { pushProfileInfoLog } = useLoggingService();
  const { profileId } = useParams<IParams>();

  const methods = useForm<ICreateProfile>({
    resolver: zodResolver(createProfileSchema),
    mode: "onTouched",
  });

  const {
    handleSubmit,
    formState: { isValid, isSubmitting },
    getValues,
    reset,
  } = methods;

  useEffect(() => {
    if (!isUserPath) return;
    (async () => {
      try {
        setLoading(true);
        const {
          result,
          hasNextPage: nextPage,
          itemCount,
        } = await getSwitchProfiles(page);
        if (page === 1) setSwitchProfiles(result);
        else setSwitchProfiles((prev) => [...prev, ...result]);
        setHasNextPage(nextPage);
        if (!itemCount) {
          setOpenSwitch(false);
          setOpenCreateProfile(true);
        }
      } catch {
      } finally {
        setLoading(false);
      }
    })();
  }, [page, resetSwitch]);

  useEffect(() => {
    if (!isScrolledToBottom || !hasNextPage) return;
    setPage((prevPage) => prevPage + 1);
  }, [isScrolledToBottom]);

  const isOpenSwitchProfileModal = useMemo(
    () => !!userInfo && (openSwitch || !profileId)
  , [userInfo, profileId, openSwitch]);

  const onSubmitCreate: SubmitHandler<ICreateProfile> = async () => {
    try {
      const res = await createProfileDetail(getValues());
      pushProfileInfoLog("CREATED", res);
      message.success("Create profile successfully!");
      reset();
      navigate(`user/${res._id}/${EUrlPath.PROFILE_BASICS}`);
      setOpenCreateProfile(false);
      openSwitch && setOpenSwitch(false);
      setResetSwitch(res._id);
    } catch (err: any) {
      message.error(err?.response?.data?.message);
    }
  };

  const onScroll = useCallback(() => {
    const container = scrollRef.current;
    if (!container) return;
    const handleScroll = (e: Event) => {
      const { scrollTop, scrollHeight, clientHeight } = container;
      const bottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;
      setIsScrolledToBottom(bottom);
    };
    container.addEventListener("scroll", handleScroll);
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const onCloseCreate = () => {
    setOpenCreateProfile(false);
    reset();
  };

  const onClickProfile = (id: string) => {
    navigate(`/user/${id}${isMobile ? "" : "/overview"}`);
    setOpenSwitch(false);
  };

  return (
    <>
      <KModalQuickAdd
        title="Create New Profile"
        onCancel={onCloseCreate}
        open={openCreateProfile}
      >
        <FormProvider {...methods}>
          <Form onFinish={handleSubmit(onSubmitCreate)}>
            <Flex gap={16} vertical>
              <KText>
                Set up a profile for yourself, a child, pet, or other person you
                care for. You’ll own it and manage how it gets shared with
                others.
              </KText>
              <Flex gap={16}>
                <KFormUser.Input label="First Name" name="firstName" />
                <KFormUser.Input label="Last Name" name="lastName" />
              </Flex>
              <KFormUser.DatePicker label="Date of Birth" name="birthDate" />
              <KFormUser.Select
                label="Relationship?"
                name="relationship"
                options={relationshipList}
              />
              <KButton
                htmlType="submit"
                disabled={!isValid}
                loading={isSubmitting}
                size="large"
                type="primary"
                title="Save profile"
              />
            </Flex>
          </Form>
        </FormProvider>
      </KModalQuickAdd>
      <Modal
        closeIcon={false}
        footer={null}
        centered
        open={isOpenSwitchProfileModal}
        onCancel={() => setOpenSwitch(false)}
        {...props}
      >
        <Flex vertical gap={16}>
          <Flex
            ref={scrollRef}
            vertical
            style={{
              height: "400px",
              overflowY: "scroll",
              scrollbarWidth: "thin",
            }}
            onScroll={onScroll}
          >
            {loading && page === 1 ? (
              <Loading />
            ) : (
              <ListProfileSection
                profiles={switchProfiles}
                loading={loading}
                onClickProfile={onClickProfile}
              />
            )}
          </Flex>
          <KButton
            icon={<UserAddOutlined />}
            onClick={() => setOpenCreateProfile(true)}
            title="Create New Profile"
            size="large"
          />
          <CustomShareLinkSection />
        </Flex>
      </Modal>
    </>
  );
};
