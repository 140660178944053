import { MenuOutlined } from "@ant-design/icons";
import { Badge, Input, Layout } from "antd";
import styled from "styled-components";

import { KButton } from "..";
import {
  breakpointLarge,
  heightHeader,
  heightHeaderMobile,
  widthBreadcrumb,
} from "../../constants";
import { Color, ESpacing } from "../../types";


const { Header } = Layout;

const HeaderContainer = styled(Header)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background-color: ${Color.White};
  height: ${heightHeader}px;
  border-bottom: 1px solid ${Color.Secondary10};
  @media (max-width: ${breakpointLarge}px) {
    padding: 1px ${ESpacing.lg} 2px ${ESpacing.lg};
    height: ${heightHeaderMobile}px;
  }
`;

const HeaderButton = styled(KButton)`
  @media (max-width: ${breakpointLarge}px) {
    display: none;
  }
`;

const HeaderLogo = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${breakpointLarge}px) {
    display: none;
  }
`;

const HamburgerMenu = styled(MenuOutlined)`
  font-size: 24px;
  padding:${ESpacing.md};
  cursor: pointer;
`;

const HeaderBreadcrumbWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: ${widthBreadcrumb}px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  @media (min-width: ${breakpointLarge}px) {
    display: none;
  }
`;

const BadgeMenu = styled(Badge)`
  .ant-badge-dot {
    width: 12px;
    height: 12px;
    border: 2px solid ${Color.White};
  }
`;

const MobileHeaderContainer = styled.div`
  background-color: #fff;
  box-shadow: 0px -1px 0px 0px #EEE inset;
  padding: 0px 16px;
  height: ${heightHeaderMobile}px;
  display: flex;
  align-items: center;
`;



const StyledInputSearch = styled(Input.Search)({
  ".ant-btn-lg": {
    width: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  ".ant-btn-sm": {
    width: 32,
  },
  ".ant-input-affix-wrapper:focus-within": {
    boxShadow: "none",
  }
});

const StyledDropDown = styled.div`
  .rc-virtual-list-holder {
    max-height: 500px!important;
    min-height: 50px;
  }
`;

export {
    HeaderContainer,
    HeaderButton,
    HeaderLogo,
    HamburgerMenu,
    HeaderBreadcrumbWrapper,
    BadgeMenu,
    MobileHeaderContainer,
    StyledInputSearch,
    StyledDropDown
};