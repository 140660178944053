import { EStatusCode } from "types/enum";

export enum E {
  ADMIN = 'admin',
  MEMBER = 'member',
}

const statusResultMessage:{ [key: number | string ]: string } = {
  [EStatusCode.NOT_FOUND]: 'Sorry, the page you visited does not exist.',
  [EStatusCode.BAD_REQUEST]: 'Sorry, the server is reporting an error.',
  [EStatusCode.FORBIDDEN]: 'Sorry, you are not authorized to access this page.',
  [EStatusCode.INFO]: '',
};

export const getResultMessage = (status: number | string): string => {
  return statusResultMessage?.[status] ?? 'Sorry, something went wrong.';
};

