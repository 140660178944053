import { SVGProps } from "react";
import { IconProps } from "./types";

export const PractitionerInfoTypeIcon: React.FC<IconProps> = ({
  width = 24,
  height,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height || width}
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M18.5625 2.625C16.8049 2.625 15.375 4.05506 15.375 5.8125C15.375 7.2438 16.3236 8.45749 17.625 8.85885V15.9375C17.625 18.3153 15.6905 20.25 13.3125 20.25C10.9345 20.25 9 18.3153 9 15.9375V15.2923C11.7598 14.8418 13.875 12.4477 13.875 9.5625V1.875H10.5V3.75H12V9.5625C12 11.7337 10.2336 13.5 8.0625 13.5C5.8914 13.5 4.125 11.7337 4.125 9.5625V3.75H5.625V1.875H2.25V9.5625C2.25 12.4477 4.36523 14.8418 7.125 15.2923V15.9375C7.125 19.3491 9.90071 22.125 13.3125 22.125C16.7243 22.125 19.5 19.3491 19.5 15.9375V8.85885C20.8014 8.45749 21.75 7.2438 21.75 5.8125C21.75 4.05506 20.3201 2.625 18.5625 2.625ZM18.5625 7.125C17.8389 7.125 17.25 6.53614 17.25 5.8125C17.25 5.08886 17.8389 4.5 18.5625 4.5C19.2861 4.5 19.875 5.08886 19.875 5.8125C19.875 6.53614 19.2861 7.125 18.5625 7.125Z" />
  </svg>
);
