import { FC } from "react";
import { SelectInputStyled, SelectStyled } from "./styles";
import { KSelectProps } from "./type";

export const KSelect: FC<KSelectProps> = ({ value, ...props }) => (
  <SelectStyled value={value} {...props}/>
);

export const KSelectWithInput: FC<KSelectProps> = ({ value, ...props }) => (
  <SelectInputStyled value={value} {...props}/>
);
