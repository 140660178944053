import { Divider, Flex, Form, Space, TabsProps } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import styled from "styled-components";

import {
  KButton,
  KFormUser,
  KSmTextSecondary50,
  KTabs,
  KTopicCount,
  Loading,
  KCardMeatBall,
} from "@components";
import { useKContext } from "@context";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  getInfoCard,
  getSchemaDetail,
  searchMedicationFDA,
  truncateName,
} from "@utils/index";
import { ReactElement } from "react";

import { UpgradePremiumIcon } from "@assets/icons";
import {
  breakpointLarge,
  heightHeaderMobile,
  widthPercentCard,
} from "@constants/size";
import { usePremiumPlan, useUnsavedChangesWarning } from "@hooks";
import {
  getCardDetailEmergencyService,
  getCardDetails,
  getCardDetailsService,
  getUserAccessEmergencyAccess,
  getUserAccessTemporaryShare,
  getUsersAccessService,
} from "@services";
import { useDetailCard, useProfile, useRoleUser, useUser } from "@store";
import {
  Color,
  ECardType,
  ESpacing,
  EUrlPath,
  IParams,
  IPrescription,
  MedicationTabName,
  PractitionersTabName,
  cardTypeRequirePremium,
} from "@types";
import { FormProvider, useForm } from "react-hook-form";
import { RelevantInfo } from "./RelevantInfo";
import {
  AllergyDetail,
  AppointmentDetail,
  ConditionDetail,
  EMRDetail,
  FilesDetail,
  HospitalizationDetail,
  IdCardDetail,
  MedicationDetail,
  NoteDetails,
  PractitionersDetail,
  ProcedureDetail,
  QuestionDetails,
  ToDoDetail,
  TopicTab,
  VaccinationDetails,
  WrapperCardDetail,
} from "./components";
import { Prescription } from "./prescription";

const CardDetailsContainer = styled(Space)`
  width: ${100 - widthPercentCard * 2}%;
  background: ${Color.White};
  padding: ${ESpacing.xl};
  overflow-y: hidden;
  @media (max-width: ${breakpointLarge}px) {
    width: 100%;
    min-height: calc(100vh - ${heightHeaderMobile}px);
  }
`;

type ComponentDetailsMap = {
  [key in ECardType | string]: () => ReactElement | null;
};

const componentDetailsMap: ComponentDetailsMap = {
  [ECardType.Procedures]: () => <ProcedureDetail />,
  [ECardType.Allergies]: () => <AllergyDetail />,
  [ECardType.Conditions]: () => <ConditionDetail />,
  [ECardType.Medications]: () => <MedicationDetail />,
  [ECardType.Appointments]: () => <AppointmentDetail />,
  [ECardType.Hospitalizations]: () => <HospitalizationDetail />,
  [ECardType.Vaccinations]: () => <VaccinationDetails />,
  [ECardType.Questions]: () => <QuestionDetails />,
  [ECardType.IdCard]: () => <IdCardDetail />,
  [ECardType.Practitioners]: () => <PractitionersDetail />,
  [ECardType.Notes]: () => <NoteDetails />,
  [ECardType.Files]: () => <FilesDetail />,
  [ECardType.ToDos]: () => <ToDoDetail />,
};

const CardDetailsPage = () => {
  const [pres, setPres] = useState<IPrescription>({});
  const [topicIds, setTopicIds] = useState<string[]>([]);
  const [activeTabKey, setActiveTabKey] = useState<string>("1");
  const { cardType, cardId, tempShareId, profileId, emergencyAccessId } =
    useParams<IParams>();
  const { pathname } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const {
    onUpdateDetailCard,
    cardTypeSelected,
    setTitle,
    setShowErrorResult,
    showErrorResult,
    setProfileId,
    topics,
    isValidToken,
  } = useKContext();
  const { roleEditor } = useRoleUser();
  const { setUsersAccessProfile } = useProfile();
  const { setEditableDetail, editableDetail, forceUpdateDetailIndex } = useDetailCard();
  const { userInfo } = useUser();
  const isPremium = usePremiumPlan();

  const cardTypeParam = useMemo(() => {
    const isTopic = topics.findIndex((i) => i._id === cardType) >= 0;
    const isCardType = Object.values(ECardType).includes(cardType as ECardType);
    const isOverview = pathname.includes(EUrlPath.OVERVIEW);
    const isEmergencyAccess = pathname.includes(EUrlPath.EMERGENCY_ACCESS);
    if (isTopic || isOverview || isEmergencyAccess) return cardTypeSelected;
    return isCardType ? cardType : "";
  }, [cardTypeSelected, cardType, pathname]);

  const method = useForm<any>({
    resolver: zodResolver(getSchemaDetail(cardTypeParam)),
    mode: "onTouched",
  });

  const {
    handleSubmit,
    formState: { isValid, isSubmitting, isDirty },
    getValues,
    reset,
    watch,
  } = method;

  useUnsavedChangesWarning(isDirty && !isLoading && !!profileId);

  useEffect(() => {
    if (
      (!tempShareId && !profileId && !emergencyAccessId) ||
      !cardId ||
      !cardTypeParam
    )
      return reset();
    const fetchDetails = async () => {
      try {
        setIsLoading(true);
        let data: any;
        if (tempShareId) {
          data = await getCardDetails(
            tempShareId,
            cardTypeParam as ECardType,
            cardId
          );
        }
        if (profileId) {
          const cardName = getInfoCard(cardTypeParam).path;
          if (!cardName) return;
          data = await getCardDetailsService(profileId, cardName, cardId);
          setProfileId(profileId);
        }
        if (emergencyAccessId) {
          data = await getCardDetailEmergencyService(
            emergencyAccessId,
            cardTypeParam as ECardType,
            cardId
          );
        }
        if (data) {
          const { title, topics } = data;
          if (cardType === ECardType.Medications) {
            setPres(data.prescription || {});
          }
          reset(data);
          setTitle(title ?? "");
          setTopicIds(topics ?? []);
        }
        if (showErrorResult) setShowErrorResult(0);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };
    const timeoutId = setTimeout(fetchDetails, 0);
    return () => clearTimeout(timeoutId);
  }, [
    tempShareId,
    profileId,
    emergencyAccessId,
    cardId,
    cardTypeParam,
    forceUpdateDetailIndex,
  ]);

  useEffect(() => {
    if (!isValidToken) return;
    (async () => {
      try {
        let res;
        if (profileId) {
          res = await getUsersAccessService(profileId);
        }
        if (tempShareId) {
          res = await getUserAccessTemporaryShare(tempShareId);
        }
        if (emergencyAccessId) {
          res = await getUserAccessEmergencyAccess(emergencyAccessId);
        }
        if (res) {
          setUsersAccessProfile(res.data);
        }
      } catch {}
    })();
  }, [profileId, tempShareId, emergencyAccessId, isValidToken]);

  const isCardHas3Tab = useMemo(
    () =>
      cardType &&
      [ECardType.Practitioners, ECardType.Medications].includes(
        cardType as ECardType
      ),
    [cardType]
  );

  const TopicLabel = useMemo(() => {
    const isActive = activeTabKey === (isCardHas3Tab ? "3" : "2");

    return (
      <Flex gap={2}>
        Topics
        <KTopicCount
          counter={(watch("topics") || []).length}
          isActive={isActive}
        />
      </Flex>
    );
  }, [activeTabKey, watch("topics")]);

  const CardItem = useMemo(() => {
    const cardTypeDetail = cardTypeParam;
    const isCardType = Object.values(ECardType).includes(
      cardTypeDetail as ECardType
    );
    if (!isCardType || !cardTypeDetail) return null;
    const selectedComponent = componentDetailsMap[cardTypeDetail] || null;
    return selectedComponent();
  }, [cardTypeParam]);

  const PresItem = useMemo(() => {
    switch (cardType) {
      case ECardType.Medications:
        return <Prescription />;
      case ECardType.Practitioners:
        return <RelevantInfo />;
      default:
        return <></>;
    }
  }, [pres]);

  const { createdBy, updatedBy, originalData, source, createdTime } =
    getValues() || {};

  const detailTab: TabsProps["items"] = [
    {
      key: "1",
      label: "Details",
      children: (
        <WrapperCardDetail>
          <EMRDetail
            source={source}
            originalData={originalData}
            createdTime={createdTime}
          />
          {CardItem}
          <Divider />
          <Flex vertical>
            {createdBy?.firstName && (
              <KSmTextSecondary50 $align="center">
                Created by:{" "}
                {truncateName(createdBy?.firstName, createdBy?.lastName)}
              </KSmTextSecondary50>
            )}
            {updatedBy?.firstName && (
              <KSmTextSecondary50 $align="center">
                Last modified by:{" "}
                {truncateName(updatedBy?.firstName, updatedBy?.lastName)}
              </KSmTextSecondary50>
            )}
          </Flex>
        </WrapperCardDetail>
      ),
    },
  ];

  const item2Tabs: TabsProps["items"] = [
    ...detailTab,
    {
      key: "2",
      label: TopicLabel,
      children: <TopicTab isEditable={editableDetail} addable={roleEditor} />,
    },
  ];

  const item3Tabs: TabsProps["items"] = [
    ...detailTab,
    {
      key: "2",
      label:
        cardType === ECardType.Medications
          ? MedicationTabName.Prescription
          : PractitionersTabName.RelevantInfo,
      children: PresItem,
    },
    {
      key: "3",
      label: TopicLabel,
      children: <TopicTab isEditable={editableDetail} addable={roleEditor} />,
    },
  ];

  const itemTabs = useMemo(() => {
    if (isCardHas3Tab) {
      return item3Tabs;
    }

    return item2Tabs;
  }, [cardType, item2Tabs, item3Tabs, topicIds]);

  const isFreemiumDetail = useMemo(() => {
    return (
      cardTypeRequirePremium.includes(getValues().cardType) &&
      !isPremium &&
      !tempShareId
    );
  }, [watch(), isPremium, tempShareId]);

  useEffect(() => {
    const editable =
      (roleEditor || userInfo?._id === getValues("createdBy")?._id) &&
      !isFreemiumDetail;
    setEditableDetail(editable);
  }, [roleEditor, userInfo, watch("createdBy"), isFreemiumDetail]);

  const onResetCardDetail = (value: any) => {
    reset(getValues());
  };

  return isLoading || !CardItem ? (
    <Loading />
  ) : (
    <CardDetailsContainer direction="vertical">
      <FormProvider {...method}>
        <Form
          onFinish={handleSubmit(() =>
            onUpdateDetailCard(getValues(), onResetCardDetail)
          )}
          disabled={!!tempShareId || !editableDetail}
        >
          <Flex justify="space-between" align="center">
            <KFormUser.InputBorderless
              isAutocomplete={watch("cardType") === ECardType.Medications}
              options={searchMedicationFDA(watch("title"))}
              style={{ fontWeight: 700 }}
              size="large"
              label={getInfoCard(watch("cardType")).icon}
              name="title"
            />
            {!emergencyAccessId && !tempShareId && !isFreemiumDetail && (
              <Flex justify="right" gap={8}>
                <KButton
                  htmlType="submit"
                  title="Save"
                  size="small"
                  loading={isSubmitting}
                  disabled={!isDirty || !isValid || !editableDetail}
                />
                <KCardMeatBall />
              </Flex>
            )}
            {isFreemiumDetail && <UpgradePremiumIcon />}
          </Flex>
          <KTabs
            itemsTabs={itemTabs}
            onTabClick={(key: string) => setActiveTabKey(key)}
          />
        </Form>
      </FormProvider>
    </CardDetailsContainer>
  );
};

export default CardDetailsPage;
