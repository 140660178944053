export enum ESpacing {
    sm = "4px",
    md = "8px",
    lg = "16px",
    xl = "24px",
    xxl = "32px",
}

export enum EBorderRadius {
    sm = "4px",
    md = "6px",
    lg = "8px",
    xl = "12px",
    xxl = "16px",
}