import { IconProps } from "./types";

export const KinKeeperLogo: React.FC<IconProps> = ({
  style,
  width = 121,
}) => {
  return (
    <img
      src={require('../imgs/kinkeeper_logo.png')}
      width={width}
      style={style}
      alt="KinKeeper Logo"
    />
  );
};
