import { PhoneNumberUtil } from 'google-libphonenumber';

const validatePhoneNumber = (input: string) => {
  const national = input.trim().replaceAll('-', '');
  if (national.length !== 10) return false;
  const phoneUtil = PhoneNumberUtil.getInstance();
  return phoneUtil.isValidNumberForRegion(phoneUtil.parse(national, 'US'), 'US');
};

const validator = {
  validatePhoneNumber,
};

export default validator;
