import { IKTextProps } from '../../types';
import { FC } from 'react';
import { KText } from './default';

const K2XlText: FC<IKTextProps> = ({ children, ...props }) => (
  <KText { ...props } size="xxl">
    {children}
  </KText>
);

const K2XlText700Secondary88 = ({ children, ...props }: IKTextProps) => (
  <K2XlText { ...props } fontWeight="700" color="Secondary88">
    {children}
  </K2XlText>
);

export {
  K2XlText,
  K2XlText700Secondary88,
};