import { EDocumentFile, EExcelFile, EImageFile, EMediaFile, EMedicalFile, EPDFFile, EPowerPointFile, ETextFile } from "@types";
import {
  FileAudioIcon,
  FileExcelIcon,
  FileMedicalIcon,
  FileOutlineIcon,
  FilePdfIcon,
  FilePptIcon,
  FileTxtIcon,
  FileWordIcon,
} from "../../assets/icons";
import { ImgUpload, WrapperItemUpload } from "./styles";
import { FileIconProps } from "./types";

export const FileTypeIcon = ({ extension, src, size }: FileIconProps) => {
  switch (extension?.toLowerCase()) {
    case EDocumentFile.DOC:
    case EDocumentFile.DOCX:
      return <FileWordIcon width={size} />;
    case EExcelFile.XLS:
    case EExcelFile.XLSX:
      return <FileExcelIcon width={size} />;
    case EPowerPointFile.PPT:
    case EPowerPointFile.PPTX:
    case EPowerPointFile.PPS:
    case EPowerPointFile.PPSX:
      return <FilePptIcon width={size} />;
    case EPDFFile.PDF:
      return <FilePdfIcon width={size} />;
    case ETextFile.TXT:
    case ETextFile.ODT:
    case ETextFile.RTF:
      return <FileTxtIcon width={size} />;
    case EMediaFile.MP3:
    case EMediaFile.MP4:
      return <FileAudioIcon width={size} />;
    case EMedicalFile.DCM:
    case EMedicalFile.DICOM:
      return <FileMedicalIcon width={size} />;
    case EImageFile.JPG:
    case EImageFile.JPEG:
    case EImageFile.PNG:
    case EImageFile.TIFF:
    case EImageFile.TIF:
    case EImageFile.BMP:
    case EImageFile.HEIC:
    case EImageFile.WEBP:
      return <ImgUpload src={src} />;
    default:
      return <FileOutlineIcon width={size}/>;
  }
};

export const FileIcon: React.FC<FileIconProps> = ({ extension, src, size }) => {
  return (
    <WrapperItemUpload>
      <FileTypeIcon extension={extension} src={src} size={size} />
    </WrapperItemUpload>
  );
};
