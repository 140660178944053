import { PhoneFilled } from "@ant-design/icons";
import { Color } from "@types";
import { Input, InputProps } from "antd";
import React from "react";
import styled from "styled-components";

export interface IInputPhoneProps extends InputProps {
  value?: string;
  phone?: boolean;
}

const StyledPhoneIcon = styled(PhoneFilled)`
  color: ${Color.PrimaryBase};
  font-size: 16px;
`;

const StyledTabInput = styled(Input)`
  .ant-input-affix-wrapper:focus {
    box-shadow: none;
  }
  .ant-input {
    color: ${Color.PrimaryBase};
  }
`;

export const KInputPhone: React.FC<IInputPhoneProps> = ({
  value,
  phone = false,
  ...props
}) => {
  const handlePhoneIconClick = () => {
    const cleanedNumber = value?.replace(/[^0-9]/g, "");
    if (!cleanedNumber) return;
    window.location.href = `tel:${cleanedNumber}`;
  };
  return (
    <StyledTabInput
      style={{ color: Color.PrimaryBase }}
      suffix={phone && <StyledPhoneIcon onClick={handlePhoneIconClick} />}
      value={value}
      {...props}
    />
  );
};
