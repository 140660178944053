import { Flex } from "antd";
import { Loading } from "./Loading";
import styled from "styled-components";
import { Color } from "@types";

const Backdrop = styled(Flex)`
  background-color: ${Color.Mask};
  position: fixed;
  inset: 0;
  z-index: 1001;
`;

export const BackdropLoading = () => (
  <Backdrop align="center" justify="center">
    <Loading />
  </Backdrop>
);
