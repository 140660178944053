import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Flex } from "antd";
import { ModalProps } from "antd/es/modal";

import {
  KLgText700,
  KMdText,
  StyledModal
} from "@components";
import {
  Color
} from "@types";

const cancelModalProps: ModalProps = {
  centered: true,
  closeIcon: false,
  width: 450,
  okText: "Yes, cancel import",
  cancelText: "No, go back",
  okButtonProps: {
    color: Color.ErrorBase,
    ghost: true,
    danger: true,
  },
  cancelButtonProps: {
    type: "primary",
    style: { backgroundColor: Color.PrimaryBase },
  },
};

interface IModalConfirmCancel extends ModalProps {
  title?: string;
  descriptions?: string;
}

export const ModalConfirmCancel = ({
  title,
  descriptions,
  ...props
}: IModalConfirmCancel) => {
  return (
    <StyledModal {...cancelModalProps} {...props}>
      <Flex gap={16} align="start">
        <ExclamationCircleOutlined
          style={{ color: Color.ErrorBase, fontSize: 24 }}
        />
        <Flex vertical gap={8}>
          <KLgText700>{title}</KLgText700>
          <KMdText>{descriptions}</KMdText>
        </Flex>
      </Flex>
    </StyledModal>
  );
};
