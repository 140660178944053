import { KButtonProps } from "./types";
import { StyledButton } from "./styles";

export const KButton = ({ type = "primary", ...props}: KButtonProps) => {
  const buttonStyle = {
    ...props.style,
    ...(type === "text" && { boxShadow: "none" }),
  };

  return (
    <StyledButton type={type} {...props} style={buttonStyle}>
      {props.title}
    </StyledButton>
  );
};

export * from "./ButtonAddCard"