import moment from "moment";
import { useContext, useMemo } from "react";
import { useParams } from "react-router-dom";

import { PractitionerInfoTypeIcon } from "@assets/icons";
import {
  CardItemStyled,
  CardTitle,
  KMdText,
  KMdTextSecondary65,
  KTag
} from "@components";
import { KContext } from "@context";
import { ECardType, EDateTimeFormat, ICardAppointmentRes, IItemCardProps, IParams } from "@types";
import { getInfoCard } from "@utils/index";
import { CardItemContainer } from "..";
import { useCustomNavigate } from "@hooks";

export const AppointmentItem = ({ card, showIcon, showMetadata, isDetail, showBorder }: IItemCardProps<ICardAppointmentRes>) => {
  const { navigate } = useCustomNavigate();
  const { cardId } = useParams<IParams>();
  const { setCardTypeSelected, navigateDetailCard } = useContext(KContext);
  const { _id, title, startTime, practitionerName, cardType } = card;
  const iconCardType = showIcon && getInfoCard(cardType).icon;

  const fomartStartTime = useMemo(() => {
    if (!startTime) {
      return "";
    }
    const formattedDate = moment(startTime).format(
      EDateTimeFormat.FULL_MONTH_YEAR_WITH_TIME_AM_PM
    );
    return moment(
      formattedDate,
      EDateTimeFormat.FULL_MONTH_YEAR_WITH_TIME_AM_PM,
      true
    ).isValid()
      ? formattedDate
      : "";
  }, [startTime]);

  const onClickItem = () => {
    if (cardId === _id) return;
    if (!isDetail) {
      return navigate(
        _id,
        undefined,
        () => { setCardTypeSelected(ECardType.Appointments); },
      );
    };
    navigateDetailCard(ECardType.Appointments, _id);
  };

  return (
    <CardItemStyled $isActive={cardId === _id} onClick={onClickItem} $showBorder={showBorder}>
      <CardItemContainer showMetadata={showMetadata} card={card}>
        <CardTitle icon={iconCardType}>{title}</CardTitle>
        {practitionerName && (
          <KTag
            icon={<PractitionerInfoTypeIcon width={16} height={16} />}
          >
            <KMdText>{practitionerName}</KMdText>
          </KTag>
        )}
        {fomartStartTime && (
          <KMdTextSecondary65 $ellipsis numberOfLines={2}>
            {fomartStartTime}
          </KMdTextSecondary65>
        )}
      </CardItemContainer>
    </CardItemStyled>
  );
};
