import {
  K2XlText700Secondary88,
  KAnchor,
  KButton,
  KErrorText,
  KLgTextSecondary88,
  KPin,
} from '@components';
import { useCustomNavigate } from '@hooks';
import { useUser } from "@store";
import { EAuthErrorMsg } from "@types";
import {  setToken as setLocalStorage} from "@utils/storage";
import { Flex, message } from "antd";
import { useState } from "react";
import { createPin } from "services/auth";

interface IProps {
  setOpenRequestModal?: (val: boolean) => void;
}

const CreatePIN = ({ setOpenRequestModal }: IProps) => {
  const { navigate } = useCustomNavigate();
  const [PIN, setPIN] = useState("");
  const [confirmPIN, setConfirmPIN] = useState("");
  const [isConfirmPIN, setIsConfirmPIN] = useState(false);
  const [error, setError] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const { fetchUserInfo } = useUser();

  const handleCreatePIN = async (value: string) => {
    try {
      await createPin(value);
      message.success("PIN created successfully!");
      await fetchUserInfo();
    } catch (error) {
      message.error("Failed to create PIN");
    }
  };

  const handleSubmit = async () => {
    setSubmitting(true);
    if (!isConfirmPIN) {
      setIsConfirmPIN(true);
      setSubmitting(false);
      return;
    }

    if (PIN !== confirmPIN) {
      setError(true);
    } else {
      setError(false);
      await handleCreatePIN(PIN);
      setOpenRequestModal && setOpenRequestModal(false);
      setLocalStorage("Pin", new Date().getTime());
      navigate("/user");
    }
    setSubmitting(false);
  };

  return (
    <Flex vertical gap={16}>
      {isConfirmPIN && (
        <Flex
          justify="center"
          style={{ position: "relative", width: "100%", minHeight: 16 }}
        >
          <KAnchor
            $isUnderlined
            style={{ position: "absolute", top: 0, left: 0 }}
            onClick={() => {
              setIsConfirmPIN(false);
              setError(false);
              setConfirmPIN("");
              setPIN("");
            }}
          >
            Back
          </KAnchor>
        </Flex>
      )}

      {isConfirmPIN ? (
        <K2XlText700Secondary88>Confirm your PIN ✅</K2XlText700Secondary88>
      ) : (
        <K2XlText700Secondary88>Create your PIN 🔐</K2XlText700Secondary88>
      )}
      <KLgTextSecondary88>{EAuthErrorMsg.INPUT_PIN}</KLgTextSecondary88>

      {isConfirmPIN ? (
        <Flex style={{ padding: "30px 0 30px 0", alignSelf: "center" }}>
          <KPin
            codeLength={4}
            code={confirmPIN}
            setCode={setConfirmPIN}
            isError={false}
            type="password"
          />
        </Flex>
      ) : (
        <Flex style={{ padding: "30px 0 30px 0", alignSelf: "center" }}>
          <KPin
            codeLength={4}
            code={PIN}
            setCode={setPIN}
            isError={false}
            type="password"
          />
        </Flex>
      )}

      <KButton
        size="large"
        title="Save PIN"
        type="primary"
        disabled={PIN.length !== 4 || isSubmitting}
        onClick={() => handleSubmit()}
      />
      {error && (
        <KErrorText style={{ textAlign: "center" }}>
          "This PIN does not match. Please retry or go back to start over."
        </KErrorText>
      )}
    </Flex>
  );
};

export default CreatePIN;
