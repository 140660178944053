import { InfoCircleOutlined, UserOutlined, SettingOutlined, BellOutlined } from "@ant-design/icons";
import {
    AllergyInfoTypeIcon,
    AppointmentInfoTypeIcon,
    ConditionInfoTypeIcon,
    FileInfoTypeIcon,
    HospitalizationInfoTypeIcon,
    IdCardInfoTypeIcon,
    MedicationInfoTypeIcon,
    NoteInfoTypeIcon,
    PractitionerInfoTypeIcon,
    ProcedureInfoTypeIcon,
    ProfileSummaryIcon,
    QuestionInfoTypeIcon,
    TaskInfoTypeIcon,
    VaccinationInfoTypeIcon,
    EMRSyncIcon,
    EmergencyAccessIcon
} from "@assets/icons";
import { ECardType, ECardTypeName, EUrlPath } from "@types";
import { ReactNode } from "react";

export interface IInfoCardType {
    title: string;
    icon: ReactNode;
    path?: ECardTypeName;
}

export const cardTypeIcon: { [key: ECardType | string]: IInfoCardType } = {
    [ECardType.Allergies]: { title: "Allergies", icon: <AllergyInfoTypeIcon />, path: ECardTypeName.Allergy },
    [ECardType.Notes]: { title: "Notes", icon: <NoteInfoTypeIcon />, path: ECardTypeName.Notes },
    [ECardType.Questions]: { title: "Questions", icon: <QuestionInfoTypeIcon />, path: ECardTypeName.Questions },
    [ECardType.Medications]: { title: "Medications", icon: <MedicationInfoTypeIcon />, path: ECardTypeName.Medications },
    [ECardType.Appointments]: { title: "Appointments", icon: <AppointmentInfoTypeIcon />, path: ECardTypeName.Appointment },
    [ECardType.Conditions]: { title: "Conditions", icon: <ConditionInfoTypeIcon />, path: ECardTypeName.Condition },
    [ECardType.Hospitalizations]: { title: "Hospitalizations", icon: <HospitalizationInfoTypeIcon />, path: ECardTypeName.Hospitalization },
    [ECardType.Procedures]: { title: "Procedures", icon: <ProcedureInfoTypeIcon />, path: ECardTypeName.Procedures },
    [ECardType.Vaccinations]: { title: "Vaccinations", icon: <VaccinationInfoTypeIcon />, path: ECardTypeName.Vaccinations },
    [ECardType.IdCard]: { title: "ID Card", icon: <IdCardInfoTypeIcon />, path: ECardTypeName.IdCard },
    [ECardType.Practitioners]: { title: "Practitioners", icon: <PractitionerInfoTypeIcon />, path: ECardTypeName.Practitioners },
    [ECardType.Files]: { title: "Files", icon: <FileInfoTypeIcon />, path: ECardTypeName.FileGroup },
    [ECardType.ToDos]: { title: "To-Dos", icon: <TaskInfoTypeIcon />, path: ECardTypeName.ToDos },
    [EUrlPath.PROFILE_BASICS]: { title: "Profile Basics", icon: <ProfileSummaryIcon /> },
    [EUrlPath.ABOUT]: { title: "About", icon: <InfoCircleOutlined /> },
    [EUrlPath.OVERVIEW]: { title: "Overview", icon: <UserOutlined /> },
    [EUrlPath.ACCOUNT_SETTINGS]: { title: "Account Settings", icon: <SettingOutlined /> },
    [EUrlPath.MEDICAL_RECORDS]: { title: "Medical Records", icon: <EMRSyncIcon /> },
    [EUrlPath.EMERGENCY_ACCESS]: { title: "Emergency Access", icon: <EmergencyAccessIcon /> },
    [EUrlPath.NOTIFICATIONS]: { title: "Notifications", icon: <BellOutlined /> },
};

export const getInfoCard = (cardType?: string): IInfoCardType => {
    return cardTypeIcon[cardType || ""] || { title: "", icon: "", path: "" }
}