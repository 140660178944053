import { Divider } from "antd";

import { KSemiText700, Loading } from "@components";
import { useFetchInfoTypesService } from "@hooks";
import { useListInfoType } from "@store";
import { EFileSort, ICardIdCardRes } from "@types";
import { useMemo, useState } from "react";
import { IdCardItem, SortItem } from "../item";
import { CustomSpaceList, WrapperCardList } from "../share-component";
import { getQuery } from "@utils/card";

export const ListIdCards = () => {
  const {
    pastCardIdList = [],
    presentCardIdList = [],
    isLoadingList,
    isLoadingInactiveList,
  } = useListInfoType();
  const [sort, setSort] = useState<EFileSort>(EFileSort.New);

  const query = useMemo(() => getQuery(sort), [sort]);

  useFetchInfoTypesService("IdCard", query);

  const renderCardItems = (card: ICardIdCardRes[] = []) => {
    return card.map((card: ICardIdCardRes) => (
      <IdCardItem key={card._id} card={card} />
    ));
  };

  return (
    <WrapperCardList space={16}>
      <CustomSpaceList>
        <KSemiText700>ID Cards</KSemiText700>
        <SortItem sort={sort} setSort={setSort} />
        <CustomSpaceList space={8}>
          {isLoadingList ? <Loading /> : renderCardItems(presentCardIdList)}
        </CustomSpaceList>
      </CustomSpaceList>
      <Divider />
      <CustomSpaceList>
        <KSemiText700>Expired ID Cards</KSemiText700>
        <CustomSpaceList space={8}>
          {isLoadingInactiveList ? (
            <Loading />
          ) : (
            renderCardItems(pastCardIdList)
          )}
        </CustomSpaceList>
      </CustomSpaceList>
    </WrapperCardList>
  );
};
