import { Tag } from "antd";
import styled from "styled-components";
import { Color, EFontSize, ELineHeight, ESpacing } from "../../types";
import React from "react";

interface IStyledTagProps {
  icon?: React.ReactNode;
  $active?: boolean;
}

const WhiteTag = `
  background-color: ${Color.White};
  border: 0.5px solid ${Color.BorderGray};
`;

const StyledTag = styled(Tag) <IStyledTagProps>`
  overflow: hidden;
  font-size: ${EFontSize.md}px;
  line-height: ${ELineHeight.md}px;
  width: fit-content;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  & > :nth-child(2) {
    width: calc(100% - 16px);
    text-overflow: ellipsis;
  }
  gap: ${ESpacing.sm};
  border-radius: ${props => props.icon ? "2px" : "4px"};
  padding: 0 ${props => props.icon ? "2px" : "4px"};
  ${props => props.icon && WhiteTag}
  ${({ $active }) => $active && `
    background-color: ${Color.BgPrimary};
    border-color: ${Color.PrimaryBase};
    color: ${Color.PrimaryBase};
    font-weight: 500;
  `}
  &.ant-tag >.anticon+span {
    margin: 0;
  }
`;

const StyledCardTypeTag = styled(StyledTag)`
  svg {
    height: 16px;
    width: 16px;
  }
`;

export { StyledTag, StyledCardTypeTag };
