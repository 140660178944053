import { KText } from "@components";
import { IKTextProps } from "@types";
import {
  truncateText
} from "@utils/profile";
import { FC } from "react";

interface KNameDisplayProps extends Omit<IKTextProps, "children"> {
  firstName?: string;
  lastName?: string;
}

export const KNameDisplay: FC<KNameDisplayProps> = ({
  firstName,
  lastName,
  size,
  style,
  ...props
}) => {
  return (
    <KText
      size={size}
      fontWeight="700"
      style={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        flex: 1,
        ...style,
      }}
      {...props}
    >
      {truncateText(firstName, 15)} {lastName?.charAt(0)}.
    </KText>
  );
};
