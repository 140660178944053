export enum Color {
  PrimaryBase = 'rgba(46, 116, 124, 1)',
  SecondaryBase = 'rgba(22, 25, 37, 1)',
  Secondary88 = 'rgba(22, 25, 37, 0.88)',
  Secondary65 = 'rgba(22, 25, 37, 0.65)',
  Secondary50 = 'rgba(22, 25, 37, 0.50)',
  Secondary10 = 'rgba(22, 25, 37, 0.1)',
  Secondary12 = 'rgba(22, 25, 37, 0.12)',
  Secondary02 = 'rgba(22, 25, 37, 0.02)',
  Secondary04 = 'rgba(22, 25, 37, 0.04)',
  Secondary25 = 'rgba(22, 25, 37, 0.25)',
  Secondary06 = 'rgba(22, 25, 37, 0.06)',
  White = 'rgba(255, 255, 255, 1)',
  ErrorBase = 'rgba(230, 55, 84, 1)',
  ErrorHover = 'rgba(242, 97, 116, 1)',
  Black = 'rgba(0, 0, 0, 1)',
  SuccessBorder = 'rgba(159, 203, 204, 1)',
  SuccessBg = 'rgba(228, 242, 241, 1)',
  ErrorBorder = 'rgba(255, 184, 188, 1)',
  ErrorBg = 'rgba(255, 241, 240, 1)',
  BgLayout = 'rgba(250, 250, 250, 1)',
  Magenta = 'rgba(216, 17, 89, 1)',
  BgAvatar = 'rgba(143, 45, 86, 1)',
  BgPrimary = 'rgba(228, 242, 241, 1)',
  Border = 'rgba(238, 238, 238, 1)',
  BorderGray = 'rgba(115, 117, 124, 1)',
  GoldBorder = 'rgba(255, 229, 143, 1)',
  GoldBg = 'rgba(255, 251, 230, 1)',
  GoldText = 'rgba(135, 77, 0, 1)',
  RedBorder = 'rgba(255, 184, 188, 1)',
  RedBg = 'rgba(255, 241, 240, 1)',
  RedText = 'rgba(140, 0, 37, 1)',
  RedErrorActive = 'rgba(179, 5, 46, 1)',
  PurpleBorder = 'rgba(211, 173, 247, 1)',
  PurpleBg = 'rgba(249, 240, 255, 1)',
  PurpleText = 'rgba(57, 16, 133, 1)',
  Gray = 'rgba(115, 117, 124, 1)',
  MagentaBorder = 'rgba(255, 143, 173, 1)',
  MagentaBg = 'rgba(255, 230, 235, 1)',
  MagentaText = 'rgba(140, 0, 61, 1)',
  Mask = 'rgba(0,0,0,0.2)',
  BgOrangeAmber = 'rgba(255, 189, 57, 1)',
  BgOrangeBrown = 'rgba(235, 158, 7, 1)',
  WarningBase = 'rgba(250, 173, 20, 1)'
}

export type ColorType = keyof typeof Color;