import { getToken } from ".";
import { kinkeeperTemporaryShare } from "@constants/url";

export const generateTemporaryShareUrl = (
  principal: string,
  tempShareId?: string
) => {
  const token = getToken("WebApp");
  const url = tempShareId
    ? `${kinkeeperTemporaryShare}/${tempShareId}?principal=${principal}&token=${token}`
    : undefined;
  return url;
};

export const generateHostUrl = (url?: string): string => {
  return url ? new URL(url).hostname : '';
};
