import { FC, ImgHTMLAttributes } from "react";
import styled from "styled-components";

type StyledAppKinKeeperProps = {
  $shape?: 'circle' | 'square';
}

const StyledAppKinKeeper = styled.img<StyledAppKinKeeperProps>`
  border-radius: ${({ $shape }) => ($shape === 'circle' ? '50%' : '12px')};
`;

interface AppKinKeeperIconProps extends ImgHTMLAttributes<HTMLImageElement> {
  shape?: 'circle' | 'square';
}

export const AppKinKeeperIcon: FC<AppKinKeeperIconProps> = ({
  width = 72,
  shape = 'square',
  ...props
}) => {
  return (
    <StyledAppKinKeeper
      src={require('../imgs/app_kinkeeper_icon.png')}
      width={width}
      alt="KinKeeper Logo"
      $shape={shape}
      {...props}
    />
  );
};
