import { FC } from "react";
import { IconProps } from "./types";
import { Color } from "@types";

export const OpenInNewIcon: FC<IconProps> = ({ width = 16, height, color = Color.PrimaryBase }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height || width}
    viewBox="0 0 16 16"
    fill={color}
  >
    <path
      d="M14 9V12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V3.33333C2 2.97971 2.14048 2.64057 2.39052 2.39052C2.64057 2.14048 2.97971 2 3.33333 2H7C7.08841 2 7.17319 2.03512 7.2357 2.09763C7.29821 2.16014 7.33333 2.24493 7.33333 2.33333V3C7.33333 3.08841 7.29821 3.17319 7.2357 3.2357C7.17319 3.29821 7.08841 3.33333 7 3.33333H3.33333V12.6667H12.6667V9C12.6667 8.91159 12.7018 8.82681 12.7643 8.7643C12.8268 8.70179 12.9116 8.66667 13 8.66667H13.6667C13.7551 8.66667 13.8399 8.70179 13.9024 8.7643C13.9649 8.82681 14 8.91159 14 9ZM13.8533 2.33333L13.6733 2.15333C13.584 2.06028 13.4622 2.00535 13.3333 2H9C8.91159 2 8.82681 2.03512 8.7643 2.09763C8.70179 2.16014 8.66667 2.24493 8.66667 2.33333V3C8.66667 3.08841 8.70179 3.17319 8.7643 3.2357C8.82681 3.29821 8.91159 3.33333 9 3.33333H11.7267L6.1 8.96C6.06876 8.99099 6.04396 9.02785 6.02704 9.06847C6.01011 9.10909 6.0014 9.15266 6.0014 9.19667C6.0014 9.24067 6.01011 9.28424 6.02704 9.32486C6.04396 9.36548 6.06876 9.40235 6.1 9.43333L6.56667 9.9C6.59765 9.93124 6.63452 9.95604 6.67514 9.97296C6.71576 9.98989 6.75933 9.9986 6.80333 9.9986C6.84734 9.9986 6.89091 9.98989 6.93153 9.97296C6.97215 9.95604 7.00901 9.93124 7.04 9.9L12.6667 4.28V7C12.6667 7.08841 12.7018 7.17319 12.7643 7.2357C12.8268 7.29821 12.9116 7.33333 13 7.33333H13.6667C13.7551 7.33333 13.8399 7.29821 13.9024 7.2357C13.9649 7.17319 14 7.08841 14 7V2.66667C13.9964 2.53874 13.9439 2.41705 13.8533 2.32667V2.33333Z"
      fill="#2E747C"
    />
  </svg>
);
