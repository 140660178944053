import { EDateTimeFormat } from "@types";
import moment from "moment";
import { FC, useMemo } from "react";
import { StyledDatePickerInput } from "./styles";
import { KDatePickerProps } from "./types";

export const KDatePicker: FC<KDatePickerProps> = ({
  value = "",
  format = "DATE",
  ...props
}) => {
  const validValue = useMemo(() => {
    return moment(value).isValid() ? moment(value) : null;
  }, [value]);

  return (
    <StyledDatePickerInput
      picker="date"
      value={validValue}
      format={EDateTimeFormat[format]}
      {...props}
    />
  );
};
