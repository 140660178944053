import { Flex } from "antd";
import { FC } from "react";
import { FileIcon } from "./FileIcon";
import { ImageWrapper, StyledLabel } from "./styles";

interface IKAttachment {
  thumbnailUrl?: string;
  extension?: string;
  label: string;
  onClick?: () => void;
}

export const KAttachment: FC<IKAttachment> = ({
  thumbnailUrl,
  extension,
  label,
  onClick,
}) => {
  return (
    <Flex vertical gap={2} onClick={onClick}>
      <ImageWrapper>
        <FileIcon
          extension={extension}
          src={thumbnailUrl}
        />
      </ImageWrapper>
      {label && <StyledLabel>{label}</StyledLabel>}
    </Flex>
  );
};

export * from "./FileIcon";

