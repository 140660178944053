import { Flex, message, Modal, ModalProps } from 'antd';
import { useContext, useState, useEffect } from 'react';

import { KContext } from '@context';
import CreatePIN from './components/create-pin/CreatePIN';
import { getMe } from 'services/auth';
import { useUser } from 'store/user';
import { removeToken } from '@utils/storage';
import { useCustomNavigate } from '@hooks';

const modalProps: ModalProps = {
  centered: true,
  closable: false,
  footer: null,
  width: 446,
  styles: { content: { padding: 32 } },
};

const CreatePINPage = () => {
  const { isMobile } = useContext(KContext);
  const [openRequestModal, setOpenRequestModal] = useState(true);
  const { setUserInfo } = useUser();
  const { navigate } = useCustomNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getMe();
        setUserInfo(res);
      } catch (error) {
        message.error("You are not logged in");
        navigate("/auth/sign-in");
        removeToken("WebApp");
        setUserInfo(null);
      }
    };

    fetchData();
  }, []);

  return isMobile ? (
    <Flex vertical align="stretch" gap={8} style={{ padding: 16 }}>
      <CreatePIN />
    </Flex>
  ) : (
    <Modal open={openRequestModal} {...modalProps}>
      <CreatePIN setOpenRequestModal={setOpenRequestModal} />
    </Modal>
  );
};

export default CreatePINPage;
