import { Color } from "@types";
import styled from "styled-components";

const NewTopicContainer = styled.div({
  display: 'flex',
  border: `1px solid ${Color.Secondary10}`,
  borderRadius: `8px`,
  height: `40px`,
  alignItems: `stretch`,
  justifyContent: `space-between`,
  span: {
    padding: `8px`,
  },
});

export {
  NewTopicContainer,
};
