import { IKTextProps } from '../../types';
import { FC } from 'react';
import { KText } from './default';

const KSmText: FC<IKTextProps> = ({ children, ...props }) => (
  <KText size="sm" { ...props }>
    {children}
  </KText>
);

const KSmTextPrimaryBase = ({ children, ...props }: IKTextProps) => (
  <KSmText { ...props } color="PrimaryBase">
    {children}
  </KSmText>
);

const KSmTextSecondary50 = ({ children, ...props }: IKTextProps) => (
  <KSmText { ...props } color="Secondary50">
    {children}
  </KSmText>
);

const KSmTextSecondary65 = ({ children, ...props }: IKTextProps) => (
  <KSmText { ...props } color="Secondary65">
    {children}
  </KSmText>
);

const KSmTextSecondary88 = ({ children, ...props }: IKTextProps) => (
  <KSmText { ...props } color="Secondary88">
    {children}
  </KSmText>
);

const KSmText700Secondary65 = ({ children, ...props }: IKTextProps) => (
  <KSmText { ...props } color="Secondary65" fontWeight="700">
    {children}
  </KSmText>
);

const KErrorText = ({ children, ...props }: IKTextProps) => (
  <KSmText color="ErrorBase" style={{ textAlign: 'center' }} { ...props }>
    {children}
  </KSmText>
);

export {
  KSmText,
  KSmTextPrimaryBase,
  KSmTextSecondary50,
  KSmTextSecondary65,
  KSmTextSecondary88,
  KSmText700Secondary65,
  KErrorText,
};