import { EMeasurement } from "@types";
import { Flex, Input, InputProps } from "antd";
import { KSelectWithInput } from "components/Select";
import styled from "styled-components";

interface IKSelectWithInput extends InputProps {
  selectValue: EMeasurement;
}

const StyledInputWithSelect = styled(Input)`
  height: 32px;
  padding: 0 12px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`;

export const KInputSelect = ({disabled = true, selectValue, ...props}: IKSelectWithInput) => (
  <Flex align="center">
    <StyledInputWithSelect
      disabled={disabled}
      {...props}
    />
    <KSelectWithInput value={selectValue} />
  </Flex>
);
