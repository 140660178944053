import { TabsProps } from "antd";

import { TabsCardDetail, EmergencyAccessTabs } from "./styles";

interface KTabProps extends TabsProps {
  itemsTabs?: TabsProps["items"];
}

export const KTabs: React.FC<KTabProps> = ({ itemsTabs, ...props }) => (
  <TabsCardDetail
    defaultActiveKey="1"
    items={itemsTabs}
    tabs={itemsTabs ? itemsTabs.length : 1}
    {...props}
  />
);

export const KEmergencyAccessTabs: React.FC<TabsProps> = ({ ...props }) => (
  <EmergencyAccessTabs {...props} />
);
