import { apiInstance } from "@apis";
import { EOrder, EOrderBy, IBaseRes, IEmergencyAccessHistory, IEmergencyInfo, IPagable } from "@types";
import { getTimezone } from "@utils/date";

const getBaseUrl = (profileId: string) =>
  `/v1/profile/${profileId}/temporary-share/emergency-access`;

const getEmergencyAccessInfoService = async (
  profileId: string
): Promise<IEmergencyInfo> => {
  const url = `${getBaseUrl(profileId)}/info`;
  const { data } = await apiInstance.get(url, {
    params: {
      page: 1,
      size: 1000,
      order: EOrder.ASC,
      orderBy: EOrderBy.TITLE,
    },
  });
  const topics = data?.data?.topics?.result;
  return {
    ...data?.data,
    topics,
  };
};

const toggleEmergencyAccessService = async (profileId: string) => {
  const url = `${getBaseUrl(profileId)}/activate`;
  await apiInstance.put(url);
};

const getAccessHistoriesEmergencyAccessService = async (profileId: string, page: number) => {
  const url = `${getBaseUrl(profileId)}/access-history`;
  const { data } = await apiInstance.get<IBaseRes<IPagable<IEmergencyAccessHistory>>>(url, {
    params: {
      page,
      size: 10,
    },
  });
  return data;
};

const exportEmergencyAccessService = async (profileId: string) => {
  const url = `${getBaseUrl(profileId)}/export-pdf`;
  await apiInstance.post(url, undefined, { params: { timezone: getTimezone() } });
};

export { exportEmergencyAccessService, getAccessHistoriesEmergencyAccessService, getEmergencyAccessInfoService, toggleEmergencyAccessService };

