import {
  KAvatar,
  KButton,
  KCheckBox,
  KLgText700Secondary88,
} from "@components";
import { useLoggingService, usePremiumPlan } from "@hooks";
import { assignOrClaimToDoService } from "@services";
import { useDetailCard, useProfile, useToDo, useUser } from "@store";
import {
  Color,
  EBorderRadius,
  EFontSize,
  ELineHeight,
  ESpacing,
  ETodoType,
  IAvatar,
  ICardTodoDetail,
  IParams,
  IUserAccessProfile,
} from "@types";
import { Flex, message } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { EAvatarSize } from "components/enums";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const CardUserAssigned = styled.label`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px ${ESpacing.md};
  border-width: 1px;
  border-style: solid;
  border-radius: ${EBorderRadius.lg};
  height: 48px;
  border-color: ${Color.Secondary10};
  background: ${Color.White};
  color: ${Color.PrimaryBase};
  font-weight: 700;
  font-size: ${EFontSize.lg}px;
  line-height: ${ELineHeight.lg}px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
`;

interface IUserCheckList {
  ids: string[];
  onCheck: (e: CheckboxChangeEvent, user: IUserAccessProfile) => void;
  assignMode?: boolean;
  disabled?: boolean;
}

export const UserAccessChecklist = ({
  ids,
  onCheck,
  assignMode,
  disabled
}: IUserCheckList) => {
  const { tempShareId } = useParams<IParams>();
  const { getValues, reset } = useFormContext<ICardTodoDetail>();
  const { editableDetail } = useDetailCard();
  const { userInfo } = useUser();
  const { isAssigned } = useToDo();
  const { usersAccessProfile } = useProfile();
  const { pushInfoTypeLog } = useLoggingService();
  const isPremium = usePremiumPlan();

  const onClaimToDo = async () => {
    try {
      const { _id, profile } = getValues();
      const { assigned } = await assignOrClaimToDoService(
        profile,
        _id,
        ETodoType.CLAIM
      );
      const resetAssigned: IAvatar[] = assigned?.map((_id) => {
        return { _id, firstName: "", lastName: "" };
      });
      pushInfoTypeLog("MODIFIED", getValues());
      reset({ ...getValues(), assigned: resetAssigned });
      message.success("Claim To-Do successfully!");
    } catch (err: any) {
      message.error(err?.response?.data?.message);
    }
  };

  const claimButton = useMemo(() => {
    return (assignMode && isAssigned && !editableDetail && isPremium) ? (
      <KButton
        title="Claim"
        size="small"
        disabled={false}
        onClick={onClaimToDo}
      />
    ) : null;
  }, [assignMode, editableDetail, isPremium, isAssigned, getValues]);

  const displayCheckbox = useMemo(() => {
    return (ownUser: boolean) =>
      tempShareId || editableDetail || (ownUser && !isAssigned);
  }, [isAssigned, editableDetail, tempShareId])

  return (
    <Flex gap={8} vertical>
      {usersAccessProfile?.map((u) => {
        const ownUser = userInfo?._id === u.user;
        return (
          <CardUserAssigned key={u.user}>
            <Flex gap={8} align="center">
              <KAvatar
                firstName={u.firstName}
                lastName={u.lastName}
                imgUrl={u.avatar}
                size={EAvatarSize.medium}
              />
              <KLgText700Secondary88>
                {u.firstName} {u.lastName.charAt(0)}.
              </KLgText700Secondary88>
            </Flex>
            {displayCheckbox(ownUser) && (
              <KCheckBox
                checked={ids && ids.includes(u.user)}
                onChange={(e) => onCheck(e, u)}
                disabled={disabled || !editableDetail}
              />
            )}
            {ownUser && claimButton}
          </CardUserAssigned>
        );
      })}
    </Flex>
  );
};
