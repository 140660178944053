import React from "react";
import {
  BadgeCounterInfo,
  BadgeWrapper,
  TopicCountInfo,
  TopicCountWrapper,
} from "./styles";
import { KBadgeCountProps } from "./types";

export const KBadgeCount: React.FC<KBadgeCountProps> = ({
  counter,
  prefix,
  children,
  isNeedWidth = true,
  size = "md",
  backgroundColor,
  color,
}) => {
  return (
    <BadgeWrapper $isNeedWidth={isNeedWidth}>
      <BadgeCounterInfo
        $size={size}
        $backgroundColor={backgroundColor}
        $color={color}
      >
        {prefix && counter && counter > 0 ? prefix : null}
        {counter}
        {children}
      </BadgeCounterInfo>
    </BadgeWrapper>
  );
};

export const KTopicCount: React.FC<KBadgeCountProps> = ({
  counter,
  isActive = false,
}) => {
  return (
    <TopicCountWrapper>
      <TopicCountInfo $isActive={isActive}>{counter}</TopicCountInfo>
    </TopicCountWrapper>
  );
};
