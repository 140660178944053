import styled from "styled-components";
import { widthTabsAndCard } from "../../constants";
import { Color, ESpacing } from "../../types";
import { Avatar } from "antd";

const ContainerEmptyView = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  flex: 1;
`;

const StyledEmptyView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: ${widthTabsAndCard}px;
  border-radius: 12px;
  padding: ${ESpacing.lg} 12px 12px 12px;
  gap: ${ESpacing.lg};
  background-color: ${Color.Secondary06};
  svg {
    fill: ${Color.White};
    height: 32px;
    width: 32px;
  }
`;

const StyleContent = styled.div`
  gap:${ESpacing.md};
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledAvatar = styled(Avatar)`
  background-color: ${Color.Secondary06};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export {
  ContainerEmptyView,
  StyledEmptyView,
  StyleContent,
  StyledAvatar,
};