import { Flex } from "antd";

import { KFormUser } from "@components";
import { useDetailCard } from "@store";
import { AttachmentsDetail } from "./Attachments";
import { SetReminderSection } from "./SetReminderSection";

export const NoteDetails = () => {
  const { editableDetail } = useDetailCard();
  return (
    <Flex gap={16} vertical flex={1}>
      <KFormUser.TextEditor
        label="Note"
        name="description"
        disabled={!editableDetail}
      />
      <KFormUser.DateTimePicker label="Date" name="startTime" />
      <AttachmentsDetail />
      <SetReminderSection />
    </Flex>
  );
};
