import { MailFilled } from "@ant-design/icons";
import { OpenNewTabIcon } from "@assets/icons";
import {
    KAnchor,
    KLgText700Secondary88,
    KLgTextSecondary88,
    KMdTextSecondary88,
    KXlText700Secondary88,
} from "@components";
import { kinkeeperEmail, policyUrl, termsUrl } from "@constants/index";
import { Color, EFontSize, ESpacing, IKTextProps } from "@types";
import { Divider, Flex } from "antd";
import { appVersion } from "config/app";
import styled from "styled-components";

const StyledAnchor = styled(KAnchor)`
  display: flex;
  align-items: center;
  gap: 5px;
  text-decoration: underline;
  width: fit-content;
  &:hover {
    color: ${Color.Secondary88};
    text-decoration: underline;
  };
`;

const ExternalLink = (props: IKTextProps & React.AnchorHTMLAttributes<HTMLAnchorElement>) => {
  return (
    <StyledAnchor {...props} color="Secondary88" size="lg" fontWeight="700" target="_blank">
      <OpenNewTabIcon width={EFontSize.lg} color={Color.Secondary88}/>
      {props.children}
    </StyledAnchor>
  );
};

const AboutPage = () => {
  return (
    <Flex vertical gap={20} flex={1} style={{backgroundColor: Color.White, padding: ESpacing.xl, overflowY: 'auto', height: "100%" }}>
      <KXlText700Secondary88>ABOUT</KXlText700Secondary88>
      <Flex gap={10} vertical>
        <KLgText700Secondary88>VERSION INFO</KLgText700Secondary88>
        <KLgTextSecondary88>Version {appVersion}</KLgTextSecondary88>
        <KMdTextSecondary88>
          You’re enjoying access to KinKeeper before anyone else! We’re
          constantly rolling out new features and improvements, so please reach
          out to our team should you have any questions or issues.
        </KMdTextSecondary88>
        <KAnchor href={`mailto:${kinkeeperEmail}`} fontWeight="700">
          <MailFilled /> {kinkeeperEmail}
        </KAnchor>
        <Divider style={{margin: "6px 0"}}/>
        <KLgText700Secondary88>OUR POLICIES</KLgText700Secondary88>
        <KMdTextSecondary88>
          By using KinKeeper, you accept the policies outlined below:
        </KMdTextSecondary88>
        <ExternalLink href={policyUrl}>Privacy Policy</ExternalLink>
        <ExternalLink href={termsUrl}>Terms of Use</ExternalLink>
      </Flex>
    </Flex>
  );
};

export default AboutPage;
