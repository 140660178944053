import { FileInfoTypeIcon } from "@assets/icons";
import { CardTitle, FileTypeIcon, KSmTextSecondary65 } from "@components";
import { KContext } from "@context";
import {
  Color,
  ECardType,
  EDateTimeFormat,
  EDocumentFile,
  EExcelFile,
  EImageFile,
  EMediaFile,
  EMedicalFile,
  EPDFFile,
  EPowerPointFile,
  ETextFile,
  ICardFileRes,
  IItemCardProps,
  IParams
} from "@types";
import { Badge, Flex } from "antd";
import moment from "moment";
import { useCallback, useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { CardItemContainer } from "../share-component";
import { useCustomNavigate } from "@hooks";
import { getExtensionFromUrl } from "@utils/index";

interface ICardFileProps {
  $isActive?: boolean;
}

const CardFile = styled.div<ICardFileProps>`
  max-width: 100%;
  display: flex;
  align-items: start;
  padding: 8px;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid ${Color.Secondary10};
  background-color: ${({ $isActive }) =>
    $isActive ? Color.BgPrimary : Color.White};
  border-color: ${({ $isActive }) =>
    $isActive ? Color.PrimaryBase : Color.Secondary10};
  cursor: pointer;
`;

const StyledThumbnail = styled.div`
  height: 42px;
  width: 42px;
  padding: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const fileExtensions = (etx: string) => {
  switch (etx) {
    case EDocumentFile.DOC:
    case EDocumentFile.DOCX:
      return EDocumentFile.DOC;
    case EExcelFile.XLS:
    case EExcelFile.XLSX:
      return EExcelFile.XLS;
    case EPowerPointFile.PPT:
    case EPowerPointFile.PPTX:
    case EPowerPointFile.PPS:
    case EPowerPointFile.PPSX:
      return EPowerPointFile.PPT;
    case EPDFFile.PDF:
      return EPDFFile.PDF;
    case ETextFile.TXT:
    case ETextFile.ODT:
    case ETextFile.RTF:
      return ETextFile.TXT;
    case EMediaFile.MP3:
    case EMediaFile.MP4:
      return EMediaFile.MP3;
    case EMedicalFile.DCM:
    case EMedicalFile.DICOM:
      return EMedicalFile.DCM;
    case EImageFile.JPG:
    case EImageFile.JPEG:
    case EImageFile.PNG:
    case EImageFile.TIFF:
    case EImageFile.TIF:
    case EImageFile.BMP:
    case EImageFile.HEIC:
    case EImageFile.WEBP:
      return EImageFile.JPG;
    default:
      return "default";
  }
};

export const FilesItem = ({
  card,
  showMetadata,
  isDetail = false,
}: IItemCardProps<ICardFileRes>) => {
  const { _id, title, description, attachments, startTime } = card;
  const { cardId } = useParams<IParams>();
  const { navigate } = useCustomNavigate();
  const { setCardTypeSelected, navigateDetailCard } = useContext(KContext);

  const { thumbnailUrl, url } = attachments[0] || [];

  const extension = useMemo(() => getExtensionFromUrl(url), [url])

  const isUniqueFile = useMemo(() => {
    if (attachments.length < 2) {
      return true;
    }
    const uniqueExtensions = attachments.map((item) => (
      fileExtensions(item.extension)
    ));
    const isAllUnique = uniqueExtensions.every(
      (item) => item === uniqueExtensions[0]
    );
    return isAllUnique;
  }, [attachments]);

  const onClickItem = useCallback(() => {
    if (cardId === _id) return;
    if (!isDetail) {
      return navigate(
        _id,
        undefined,
        () => { setCardTypeSelected(ECardType.Files); },
      );
    }
    navigateDetailCard(ECardType.Files, _id);
  }, [_id, cardId]);

  return (
    <CardFile $isActive={cardId === _id} onClick={onClickItem}>
      <CardItemContainer showMetadata={showMetadata} card={card}>
        <Flex gap={8}>
          <Badge
            count={attachments.length}
            offset={[-7, 34]}
            size="small"
            color={Color.PrimaryBase}
          >
            <StyledThumbnail>
              {isUniqueFile ? (
                <FileTypeIcon
                  extension={extension}
                  src={thumbnailUrl || url}
                  size={40}
                />
              ) : (
                <FileTypeIcon extension="default" size={40} />
              )}
            </StyledThumbnail>
          </Badge>
          <Flex vertical>
            <Flex vertical>
              <Flex align="center" gap={4} style={{ maxWidth: "100%" }}>
                <FileInfoTypeIcon width={20} />
                <CardTitle>{title}</CardTitle>
              </Flex>
              {startTime && (
                <KSmTextSecondary65 $ellipsis numberOfLines={2}>
                  {moment(startTime).format(EDateTimeFormat.FULL_MONTH_YEAR)}
                </KSmTextSecondary65>
              )}
            </Flex>
            {description && (
              <KSmTextSecondary65 $ellipsis numberOfLines={2}>
                {description}
              </KSmTextSecondary65>
            )}
          </Flex>
        </Flex>
      </CardItemContainer>
    </CardFile>
  );
};
