import { Avatar, AvatarProps, Image, ImageProps } from "antd";
import styled from "styled-components";

interface IEMRAvatarProps extends AvatarProps {
  brandId?: string;
  name?: string;
}

const StyledAvtEMR = styled(Avatar)({
  display: "flex",
  alignItems: "center",
  backgroundColor: 'white',
  'img': {
    objectFit: 'contain',
  },
  '&.ant-avatar .ant-avatar-square': {
    borderRadius: 0,
    backgroundColor: 'white'
  }
});

export const EMRAvatar = ({
  brandId,
  name = "",
  ...props
}: IEMRAvatarProps) => {
  return (
    <StyledAvtEMR
      {...(brandId && {
        src: `https://cdn.fastenhealth.com/logos/sources/${brandId}.png`,
      })}
      shape="square"
      size={40}
      {...props}
    />
  );
};
