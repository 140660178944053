import { Collapse } from "antd";
import styled from "styled-components";
import { Color, EFontSize, ELineHeight, ESpacing } from "../../types";

const StyledCollapse = styled(Collapse)`
  width: 100%;
  &.ant-collapse {
    background-color: ${Color.White};
    .ant-collapse-content>.ant-collapse-content-box {
      padding: 0;
    }
  }

  &.ant-collapse-ghost
    > .ant-collapse-item
    > .ant-collapse-content
    > .ant-collapse-content-box {
    padding: ${ESpacing.md} 0 0 0;
  }

  &.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    gap: ${ESpacing.md};
    padding: 12px 0;
    border-color: ${Color.Secondary10};
    border-style: solid;
    border-width: 0;
    border-radius: 0;
    .ant-collapse-header-text {
      font-weight: 700;
      font-size: ${EFontSize.lg}px;
      line-height: ${ELineHeight.lg}px;
    }

    .ant-collapse-expand-icon {
      padding: 0;
    }

    .ant-collapse-arrow svg {
      font-size: ${EFontSize.sm}px;
      color: ${Color.Secondary88};
    }
  }
`;

export { StyledCollapse };