import { IMedicalRecord } from "@services";
import { EEMRPage, IMedicalRecordImport } from "@types";
import { create } from "zustand";

type CardsImportedType = IMedicalRecord["data"];

type State = {
  pageEMR?: EEMRPage;
  setPageEMR: (page: EEMRPage) => void;
  cardsImported: CardsImportedType;
  setCardsImported: (cardsImported: CardsImportedType) => void;
  indexListMedRecord: number;
  forceUpdateListMedRecord: () => void;
  importedBrandIds: string[];
  setImportedBrandIds: (med: IMedicalRecordImport[]) => void;
  indexDetailMedRecord: number;
  forceUpdateDetailMedRecord: () => void;
};

export const useEMRStore = create<State>((set, get) => ({
  setPageEMR: (pageEMR) => set({ pageEMR }),
  cardsImported: [],
  setCardsImported: (cardsImported) => set({ cardsImported }),
  indexListMedRecord: 0,
  forceUpdateListMedRecord: () =>
    set({ indexListMedRecord: get().indexListMedRecord + 1 }),
  importedBrandIds: [],
  setImportedBrandIds: (med) => {
    const importedBrandIds = med?.map(({ brandId }) => brandId);
    importedBrandIds && set({ importedBrandIds });
  },
  indexDetailMedRecord: 0,
  forceUpdateDetailMedRecord: () =>
    set({ indexDetailMedRecord: get().indexDetailMedRecord + 1 }),
}));
