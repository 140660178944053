import { useKContext } from "@context";
import { Color, EUrlPath, IParams } from "@types";
import { cardTypeIcon } from "@utils/index";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import { KProfileSelector } from "../ProfileSelector";
import { KMdText } from "../Text";
import { StyledBreadcrumb } from "./styles";

interface BreadcrumbItem {
  key?: number;
  title?: React.ReactNode;
}

const initialBreadcrumb: BreadcrumbItem[] = [
  {
    key: 0,
    title: <KProfileSelector />,
  },
];

const BreadcrumbCard = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  svg {
    width: 18px;
    height: 18px;
    color: ${Color.SecondaryBase};
  }
`;

export const KBreadcrumb: React.FC = () => {
  const { pathname } = useLocation();
  const { title, topics, arrayPath } = useKContext();
  const { cardType, cardId, profileId, emergencyAccessId } = useParams<IParams>();
  const [breadcrumb, setBreadcrumb] =
    useState<BreadcrumbItem[]>(initialBreadcrumb);

  useEffect(() => {
    if (!arrayPath) return;
    const iconPath = (profileId || emergencyAccessId) ? arrayPath[3] : arrayPath[2];
    const breadcrumbCardType = cardTypeIcon[iconPath || ""];
    const breadcrumbTopic = topics.find(
      (i) => i._id?.toLowerCase() === cardType?.toLowerCase()
    );

    if (breadcrumbCardType || breadcrumbTopic) {
      const newBreadcrumb: BreadcrumbItem[] = [
        {
          key: 1,
          title: (
            <BreadcrumbCard>
              {breadcrumbCardType?.icon}
              <KMdText>
                {breadcrumbCardType?.title || breadcrumbTopic?.title}
              </KMdText>
            </BreadcrumbCard>
          ),
        },
      ];

      if (cardId) {
        newBreadcrumb.push({
          key: 2,
          title: (
            <KMdText $ellipsis style={{ maxWidth: "250px" }}>
              {title}
            </KMdText>
          ),
        });
      }

      if (arrayPath[4] && arrayPath[3] === EUrlPath.MEDICAL_RECORDS) {
        newBreadcrumb.push({
          key: 2,
          title: (
            <KMdText $ellipsis style={{ maxWidth: "250px" }}>
              Import
            </KMdText>
          ),
        });
      }
      setBreadcrumb([...initialBreadcrumb, ...newBreadcrumb]);
    } else {
      setBreadcrumb(initialBreadcrumb);
    }
  }, [title, pathname, topics, arrayPath]);

  return <StyledBreadcrumb items={breadcrumb} />;
};
