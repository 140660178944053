import { AUTH_TYPE, ERelationshipType, relationshipList } from "@types";
import moment from "moment";

export type submitProps = {
  email: string;
  type: AUTH_TYPE;
  screen: SCREEN_AUTH;
};

export enum SCREEN_AUTH {
  LOGIN = "login",
  REGISTER = "register",
}

const calculateAge = (birthdate: string) => {
  const today = moment();
  const birthMoment = moment(birthdate);
  const ageInYears = today.diff(birthMoment, "years");
  const ageInMonths = today.diff(birthMoment, "months");

  if (ageInYears >= 2) {
    return `${ageInYears} Years old`;
  } else {
    return `${ageInMonths} Months`;
  }
};

const truncateText = (text = "", maxLength: number) => {
  if (text.length > maxLength) {
    return `${text.substring(0, maxLength)}...`;
  } else {
    return text;
  }
};

const findRelationshipLabel = (rl: ERelationshipType | string) => {
  const relationship = relationshipList.find((item) => item.value === rl);
  return relationship ? relationship.label : rl;
};
export { calculateAge, truncateText, findRelationshipLabel };
