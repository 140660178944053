import { KHeader } from "@components";
import { breakpointLarge, heightHeader } from "@constants/size";
import { useKContext } from "@context";
import { Color } from "@types";
import { Layout } from "antd";
import { Content } from "antd/es/layout/layout";
import { MobileWelcomeHeader } from "components/Header/MobileWelcomeHeader";
import { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import {
  KRedemptionFooter,
  RedemptionCode,
  RedemptionVerify,
} from "./components";

const MainContent = styled(Content)`
  background-color: ${Color.BgLayout};
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 32px 0;
  height: calc(100vh - ${heightHeader}px);
  @media (max-width: ${breakpointLarge}px) {
    align-items: center;
    height: 100%;
    padding: 0;
  }
`;

export const RedemptionPage = () => {
  const { isMobile } = useKContext();
  const [code, setCode] = useState("");
  const [searchParams] = useSearchParams();
  const employerNickname = useMemo(() => {
    return searchParams.get("nickname");
  }, [searchParams]);

  return (
    <Layout>
      {isMobile ? <MobileWelcomeHeader /> : <KHeader />}
      <MainContent>
        {code ? (
          <RedemptionCode code={code} />
        ) : (
          <RedemptionVerify
            setCode={setCode}
            employerNickname={employerNickname}
          />
        )}
      </MainContent>
      <KRedemptionFooter />
    </Layout>
  );
};
