export const HealthInfoOutlined = ({
  width = 16,
  height,
}: {
  height?: number;
  width?: number;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height || width}
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.16699 2H14.8337C15.1102 2 15.3337 2.22344 15.3337 2.5V14C15.3337 14.2766 15.1102 14.5 14.8337 14.5H1.16699C0.89043 14.5 0.666992 14.2766 0.666992 14V2.5C0.666992 2.22344 0.89043 2 1.16699 2ZM11.3337 9.25H14.2087V7.25H11.3337V9.25ZM1.79199 13.375H14.2087V10.25H10.8337C10.5571 10.25 10.3337 10.0266 10.3337 9.75V6.75C10.3337 6.47344 10.5571 6.25 10.8337 6.25H14.2087V3.125H1.79199V13.375ZM12.3292 8.69194C12.212 8.57473 12.1462 8.41576 12.1462 8.25C12.1462 8.08424 12.212 7.92527 12.3292 7.80806C12.4464 7.69085 12.6054 7.625 12.7712 7.625C12.9369 7.625 13.0959 7.69085 13.2131 7.80806C13.3303 7.92527 13.3962 8.08424 13.3962 8.25C13.3962 8.41576 13.3303 8.57473 13.2131 8.69194C13.0959 8.80915 12.9369 8.875 12.7712 8.875C12.6054 8.875 12.4464 8.80915 12.3292 8.69194ZM5.26699 5.33333C4.99085 5.33333 4.76699 5.55719 4.76699 5.83333V6.93333H3.66699C3.39085 6.93333 3.16699 7.15719 3.16699 7.43333V9.23333C3.16699 9.50948 3.39085 9.73333 3.66699 9.73333H4.76699V10.8333C4.76699 11.1095 4.99085 11.3333 5.26699 11.3333H7.06699C7.34314 11.3333 7.56699 11.1095 7.56699 10.8333V9.73333H8.66699C8.94313 9.73333 9.16699 9.50948 9.16699 9.23333V7.43333C9.16699 7.15719 8.94313 6.93333 8.66699 6.93333H7.56699V5.83333C7.56699 5.55719 7.34313 5.33333 7.06699 5.33333H5.26699ZM4.16699 8.73333V7.93333H5.76699V6.33333H6.56699V7.93333H8.16699V8.73333H6.56699V10.3333H5.76699V8.73333H4.16699Z"
      fill="#161925"
    />
  </svg>
);
