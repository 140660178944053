import { Checkbox } from "antd";
import { FC, useState } from "react";

import {
  KAnchor,
  KButton,
  KErrorText,
  KLgText700Secondary88,
  KMdTextSecondary88,
} from "@components";
import { homePageUrl, policyUrl, termsUrl } from "@constants/url";
import { Color } from "@types";
import { appName } from "config/app";
import styled from "styled-components";

interface IProps {
  contact?: string;
  error?: string;
  handleSendCode: () => Promise<void>;
  loading?: boolean;
}

const TermsCheckbox = styled(Checkbox)`
  .ant-checkbox-inner {
    border-color: ${Color.BorderGray};
  }
`;

export const TermsSection: FC<IProps> = ({
  contact = "",
  handleSendCode,
  error,
}) => {
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [ageAccepted, setAgeAccepted] = useState(false);
  const [policyAccepted, setPolicyAccepted] = useState(false);

  return (
    <>
      <TermsCheckbox
        checked={policyAccepted}
        onChange={() => setPolicyAccepted(!policyAccepted)}
      >
        <KMdTextSecondary88>
          {`I consent to the processing of personal information, including health information, as set forth in ${appName}'s `}
          <KAnchor
            $isUnderlined
            target="_blank"
            size="md"
            color="Secondary88"
            href={policyUrl}
          >
            Privacy Policy
          </KAnchor>
        </KMdTextSecondary88>
      </TermsCheckbox>
      <TermsCheckbox
        checked={termsAccepted}
        onChange={() => setTermsAccepted(!termsAccepted)}
      >
        <KMdTextSecondary88>
          {`I agree to ${appName}'s `}
          <KAnchor
            $isUnderlined
            target="_blank"
            size="md"
            color="Secondary88"
            href={termsUrl}
          >
            Terms of Use
          </KAnchor>
          {` and confirm I have the legal authority to collect, use, and share health information for myself or another person`}
        </KMdTextSecondary88>
      </TermsCheckbox>
      <TermsCheckbox
        checked={ageAccepted}
        onChange={() => setAgeAccepted(!ageAccepted)}
      >
        <KMdTextSecondary88>I am 16 years of age or older</KMdTextSecondary88>
      </TermsCheckbox>
      <KLgText700Secondary88>
        Great, now we’d like to verify your device by sending you a code.
      </KLgText700Secondary88>
      <KButton
        size="large"
        title={`Send code to ${contact}`}
        type="primary"
        disabled={!termsAccepted || !ageAccepted || !policyAccepted || !!error}
        onClick={handleSendCode}
      />
      {!!error && <KErrorText>{error}</KErrorText>}
      <KMdTextSecondary88>
        {`Learn more about ${appName} `}
        <KAnchor
          $isUnderlined
          target="_blank"
          size="md"
          color="Secondary88"
          href={homePageUrl}
        >
          here
        </KAnchor>
        .
      </KMdTextSecondary88>
    </>
  );
};
