import { AppKinKeeperIcon, VectorRightIcon } from "@assets/icons";
import {
  BackdropLoading,
  KButton,
  KMdText700,
  KSmTextSecondary65,
  KTextWithCheckIcon,
  KXlText700,
} from "@components";
import { initiateConnectFTHService } from "@services";
import {
  Color,
  IParams,
  ISourceFTH,
  privacyPoliciesSourceImport,
} from "@types";
import { generateHostUrl } from "@utils/url";
import { Flex, Modal, ModalProps } from "antd";
import { modalProps } from "config";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { EMRAvatar } from ".";
import { CardSecurityInfo } from "../styled-components";
import { setToken as setStorage } from "@utils/storage";

interface IModalConnectSource extends ModalProps {
  source?: ISourceFTH;
}

export const ModalConnectSource = ({
  source,
  onCancel,
  ...props
}: IModalConnectSource) => {
  const { profileId } = useParams<IParams>();
  const [loading, setLoading] = useState(false);
  const [loadingBackdrop, setLoadingBackdrop] = useState(false);
  const { id: brand_id, name, brand_website, portals } = source || {};
  const { id: portal_id, endpoints } = portals?.[0] || {};
  const { id: endpoint_id } = endpoints?.[0] || {};

  const onClickConnect = async (e?: any) => {
    if (!profileId || !endpoint_id || !portal_id || !brand_id) return;
    const external_id = profileId;
    try {
      setLoading(true);
      setStorage('Profile', profileId);
      const res = await initiateConnectFTHService({
        endpoint_id,
        portal_id,
        brand_id,
        external_id,
      });
      window.location.href = res;
      setLoadingBackdrop(true);
    } catch {
    } finally {
      setLoading(false);
      onCancel && onCancel(e);
    }
  };

  useEffect(() => {
    if (loadingBackdrop) {
      setLoadingBackdrop(false);
    }
    return () => setLoadingBackdrop(false);
  }, []);

  return (
    <>
      {loadingBackdrop && <BackdropLoading />}
      <Modal
        {...props}
        {...modalProps}
        onCancel={() => !loading && onCancel}
        styles={{ content: { padding: "16px 24px" } }}
        width={500}
      >
        <Flex gap={24} vertical>
          <Flex gap={8} justify="center" align="center">
            <EMRAvatar brandId={brand_id} name={name} size={72} />
            <VectorRightIcon width={32} color={Color.PrimaryBase} />
            <AppKinKeeperIcon />
          </Flex>
          <Flex gap={16} vertical align="center">
            <Flex gap={4} vertical align="center">
              <KXlText700 $ellipsis>{name}</KXlText700>
              <KSmTextSecondary65 $ellipsis>
                {generateHostUrl(brand_website)}
              </KSmTextSecondary65>
            </Flex>
            <KSmTextSecondary65 $align="center">
              Clicking “Connect” will open your provider’s patient portal login
              and request permission to allow KinKeeper to access your records.
            </KSmTextSecondary65>
            <CardSecurityInfo vertical>
              <KMdText700>We keep your data safe. Here’s how:</KMdText700>
              {privacyPoliciesSourceImport.map((val, idx) => (
                <KTextWithCheckIcon key={idx} children={val} />
              ))}
            </CardSecurityInfo>
          </Flex>
          <Flex vertical gap={8}>
            <KButton
              size="large"
              title="Connect"
              onClick={onClickConnect}
              loading={loading}
            />
            <KButton
              type="default"
              size="large"
              title="Cancel"
              onClick={onCancel}
              loading={loading}
            />
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};
