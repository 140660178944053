import { CopyOutlined } from "@ant-design/icons";
import { KinKeeperLogo } from "@assets/icons";
import {
  KButton,
  KLgText700PrimaryBase,
  KMdText,
  KSemiText,
  KSemiText700,
  KText,
  RedemptionWrapper,
} from "@components";
import { useKContext } from "@context";
import { Divider, Flex, QRCode } from "antd";

export const RedemptionCode = ({ code }: { code: string }) => {
  const { isMobile } = useKContext();
  return (
    <RedemptionWrapper>
      <Flex
        style={{
          padding: isMobile ? 16 : 32,
        }}
        align="center"
        justify="space-between"
        gap={32}
        vertical={isMobile}
      >
        <Flex gap={24} vertical>
          <Flex gap={8} vertical>
            <KText size="xxxl" color="Secondary88" fontWeight="700">
              Great, here’s your code!
            </KText>
            <Flex gap={4} align="center">
              <KSemiText color="PrimaryBase">Your code is:</KSemiText>
              <KSemiText700 color="PrimaryBase">{code}</KSemiText700>
              <KButton
                title="Copy"
                icon={<CopyOutlined />}
                type="primary"
                onClick={() => {
                  navigator.clipboard.writeText(code);
                }}
                ghost
                size="small"
              />
            </Flex>
          </Flex>
          <Divider />
          <Flex gap={16} vertical>
            <KSemiText700>FAQs:</KSemiText700>
            <Flex gap={2} vertical>
              <KLgText700PrimaryBase>
                How to redeem your code:
              </KLgText700PrimaryBase>
              <KMdText>
                In your mobile app, follow prompts to Upgrade to Premium. Below
                the payment options, select “I have a code to redeem”. Select
                your organization and enter the code above.
              </KMdText>
            </Flex>
            <KMdText>
              Once your free premium access expires, you can choose to upgrade
              via the app store to continue using premium features.
            </KMdText>
            <Flex gap={2} vertical>
              <KLgText700PrimaryBase>
                Not the KinKeeper of your family?
              </KLgText700PrimaryBase>
              <KMdText>
                No problem! Share this code with anyone you like. Anyone can
                sign up for a free account, so you’ll still be able to
                collaborate on profiles they create with their premium account.
              </KMdText>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          gap={24}
          style={{
            textAlign: "center",
          }}
          vertical
          align="center"
        >
          <KinKeeperLogo width={220} />
          <KSemiText700 color="PrimaryBase" style={{ textAlign: "center" }}>
            Download the KinKeeper app to get started!
          </KSemiText700>
          <Flex gap={30} vertical={isMobile}>
            <Flex gap={16} vertical>
              <img
                src={require("../../../assets/imgs/apple_download.png")}
                alt=""
              />
              <QRCode value={"https://apple.co/3Z70g5n"} />
            </Flex>
            <Flex gap={16} vertical>
              <img
                src={require("../../../assets/imgs/google_download.png")}
                alt=""
              />
              <QRCode value={"https://bit.ly/3Z70sS9"} />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </RedemptionWrapper>
  );
};
