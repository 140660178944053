import { KinKeeperLogo } from "@assets/icons";
import { MobileHeaderContainer } from "./styles";

export const MobileWelcomeHeader = () => {

  return (
    <MobileHeaderContainer>
        <KinKeeperLogo />
    </MobileHeaderContainer>
  );
};
