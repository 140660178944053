import { Divider, Flex } from "antd";

import { KSemiText700, Loading } from "@components";
import { useFetchInfoTypesService } from "@hooks";
import { useListInfoType } from "@store";
import { EFileSort } from "@types";
import { useMemo, useState } from "react";
import { MedicationItem, SortItem } from "../item";
import { getQuery } from "@utils/card";

export const ListMedication = () => {
  const {
    pastMedicationList = [],
    presentMedicationList = [],
    isLoadingList,
    isLoadingInactiveList,
  } = useListInfoType();
  const [sort, setSort] = useState<EFileSort>(EFileSort.New);

  const query = useMemo(() => getQuery(sort), [sort]);

  useFetchInfoTypesService("Medications", query);

  return (
    <Flex gap={8} vertical={true}>
      <KSemiText700>Active Medications</KSemiText700>
      <SortItem sort={sort} setSort={setSort} />
      {isLoadingList ? (
        <Loading />
      ) : (
        presentMedicationList.map((item) => (
          <MedicationItem key={item._id} card={item} />
        ))
      )}
      <Divider />
      <KSemiText700>Previous Medications</KSemiText700>
      {isLoadingInactiveList ? (
        <Loading />
      ) : (
        pastMedicationList.map((item) => (
          <MedicationItem key={item._id} card={item} />
        ))
      )}
    </Flex>
  );
};
