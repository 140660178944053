import { ReactNode } from "react";

import { ICardTodoRes } from "@types";
import { CardTitle, KLgText700 } from "@components";

type IProps = ICardTodoRes & { icon?: ReactNode }

export const CardToDoTitle = ({ title, isMissed, isCompleted, icon }: IProps) => (
  isCompleted || isMissed ? (
    <KLgText700 color={isMissed ? "RedErrorActive" : "Secondary65"} $lineThrough>{title}</KLgText700>
  ) : (
    <CardTitle icon={icon}>{title}</CardTitle>
  )
);