import { CheckOutlined } from "@ant-design/icons";
import { Color, EBorderRadius, EFontSize, ELineHeight, ESpacing } from "@types";
import styled from "styled-components";
import { IItemProfileWrapper } from "./types";

const ItemProfile = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: ${ESpacing.lg};
`;

const ItemProfileWrapper = styled.div<IItemProfileWrapper>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${ESpacing.md};
  height: 32px;
  padding: 5px 12px;
  border-radius: 4px;
  cursor: pointer;
  background-color: ${({ $isActive }) =>
    $isActive ? Color.BgPrimary : Color.White};
  &: hover {
    background-color: ${Color.BgPrimary};
  }
`;

const BlankSpace = styled.div`
  height: 20px;
  width: 20px;
`;

const CheckOutlinedStyled = styled(CheckOutlined)`
  fontsize: 20px;
  color: ${Color.PrimaryBase};
`;

const CardProfileSwitch = styled.div<{ $active?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px ${ESpacing.md};
  border-width: 1px;
  border-style: solid;
  border-radius: ${EBorderRadius.lg};
  height: 56px;
  ${({ $active }) => `border-color: ${$active ? Color.SuccessBorder : Color.Secondary10};`}
  background: ${Color.White};
  color: ${Color.PrimaryBase};
  font-weight: 700;
  font-size: ${EFontSize.lg}px;
  line-height: ${ELineHeight.lg}px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
`;

export { ItemProfile, ItemProfileWrapper, BlankSpace, CheckOutlinedStyled, CardProfileSwitch };
