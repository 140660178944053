import {
  ICardAllergyRes,
  ICardAppointmentRes,
  ICardConditionRes,
  ICardFileRes,
  ICardHospitalizationsRes,
  ICardIdCardRes,
  ICardPractitionerRes,
  ICardTodoRes,
  IMedicationItem,
  INote,
  IProcedureItem,
  IQuestionItemList,
  IVaccinationItemList,
} from "@types";
import { create } from "zustand";

export type InfoTypeProps = {
  pastAllergyList?: ICardAllergyRes[];
  presentAllergyList?: ICardAllergyRes[];
  cardFileList?: ICardFileRes[];
  pastAppointmentList?: ICardAppointmentRes[];
  upcomingAppointmentList?: ICardAppointmentRes[];
  pastConditionsList?: ICardConditionRes[];
  presentConditionsList?: ICardConditionRes[];
  hospitalizationsList?: ICardHospitalizationsRes[];
  pastCardIdList?: ICardIdCardRes[];
  presentCardIdList?: ICardIdCardRes[];
  pastPractitionerList?: ICardPractitionerRes[];
  presentPractitionerList?: ICardPractitionerRes[];
  pastMedicationList?: IMedicationItem[];
  presentMedicationList?: IMedicationItem[];
  cardNoteList?: INote[];
  pastProcedureList?: IProcedureItem[];
  upcomingProcedureList?: IProcedureItem[];
  validVaccinationList?: IVaccinationItemList[];
  outDateVaccinationList?: IVaccinationItemList[];
  unansweredQsList?: IQuestionItemList[];
  otherQsList?: IQuestionItemList[];
  activeToDoList?: ICardTodoRes[];
  completedToDoList?: ICardTodoRes[];
};

interface IListInfoTypeProps extends InfoTypeProps {
  isLoadingList: boolean;
  isLoadingInactiveList: boolean,
  setLoadingList: (val: boolean) => void;
  setLoadingInactiveList: (val: boolean) => void;
  setListInfoType: (data: InfoTypeProps) => void;
  clearListInfoType: () => void;
}

export const useListInfoType = create<IListInfoTypeProps>((set, get) => ({
  isLoadingList: false,
  isLoadingInactiveList: false,
  setLoadingList: (val) => set({ isLoadingList: val }),
  setLoadingInactiveList: (val) => set({ isLoadingInactiveList: val }),
  setListInfoType: (data) => set(data),
  clearListInfoType: () => set({})
}));
