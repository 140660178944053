import { ExclamationCircleOutlined } from "@ant-design/icons";
import { KButton, KMdTextSecondary65, KXlText700 } from "@components";
import { Color, IParams } from "@types";
import { Flex } from "antd";
import { useNavigate, useParams } from "react-router-dom";

export const EMRSyncFailed = () => {
  const { profileId } = useParams<IParams>();
  const navigate = useNavigate();

  return (
    <Flex vertical gap={24} align="center" style={{ overflow: "scroll" }}>
      <ExclamationCircleOutlined
        style={{ color: Color.ErrorBase, fontSize: 72 }}
      />
      <Flex vertical gap={8} align="center">
        <KXlText700>Cannot fetch record data</KXlText700>
        <KMdTextSecondary65 $align="center">
          Unfortunately we were unable to fetch the medical record data from
          this source at this time. Please try again or try a different source.
        </KMdTextSecondary65>
      </Flex>
      <KButton
        style={{ width: "100%" }}
        size="large"
        title="Return to Medical Record Sources"
        onClick={() => {
          navigate(`/user/${profileId}/medical-records`);
        }}
      />
    </Flex>
  );
};
