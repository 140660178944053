import { ESexBirth } from "@types";
import { Radio, RadioGroupProps } from "antd";
import { RadioGroupItem } from "./style";

interface IKRadioGroupSexBirth extends RadioGroupProps {
  value?: ESexBirth;
}

export const KRadioGroupSexBirth = ({
  value = ESexBirth.MALE,
  ...props
}: IKRadioGroupSexBirth) => {
  return (
    <Radio.Group {...props} value={value} style={{ display: "flex" }}>
      <RadioGroupItem value={ESexBirth.MALE} >{ESexBirth.MALE}</RadioGroupItem>
      <RadioGroupItem value={ESexBirth.FEMALE} >{ESexBirth.FEMALE}</RadioGroupItem>
    </Radio.Group>
  );
};
