import { IAvatar } from "@types";
import { Avatar } from "antd";
import { KAvatar } from "components/Avatar/Avatar";
import { EAvatarSize } from "components/enums";

export const KAvatarGroups = ({
  groups,
  shape = "circle",
  maxCount = 3,
  size = EAvatarSize.small,
  backgroundColor = "#8F2D56",
}: {
  groups: IAvatar[];
  shape?: "circle" | "square" | undefined;
  size?: EAvatarSize;
  maxCount?: number;
  backgroundColor?: string;
}) => {
  return (
    <Avatar.Group
      shape={shape}
      maxCount={maxCount}
      maxStyle={{ backgroundColor: `${backgroundColor}` }}
    >
      {groups?.length > 0 &&
        groups.map((u) => (
          <KAvatar
            key={u._id}
            firstName={u.firstName}
            lastName={u.lastName}
            imgUrl={u.avatar}
            size={size}
            shape={shape}
          />
        ))}
    </Avatar.Group>
  );
};
