import { Flex } from "antd";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";

import {
  KLgTextSecondary88,
  KText,
  KXlText700Secondary88,
  TermsSection
} from "@components";
import { appName } from "config/app";
import { EAuthPage, EStatusCode, IParams } from "@types";
import { requestOTP } from "@services";
import { KContext } from "@context";
import { useAuthStore } from "@store";

export const RequestOtp = () => {
  const { infoPrincipal } = useContext(KContext);
  const { tempShareId } = useParams<IParams>();

  const [error, setError] = useState("");

  const { setCurrentPage, setIsLoadingCSL } = useAuthStore();

  const handleSendCode = async () => {
    if (!tempShareId) return;
    try {
      setIsLoadingCSL(true);
      await requestOTP(tempShareId, true, true, true);
      setCurrentPage(EAuthPage.VERIFY_OTP);
      // Remove error message of the BadRequest on RequestOtp & VerifyOtp screens
      setError("");
    } catch (error: any) {
      if (error?.response?.data?.code === EStatusCode.BAD_REQUEST) {
        // Set error message of the BadRequest for RequestOtp & VerifyOtp screens.
        setError(error?.response?.data?.message);
      }
      console.log('error-RequestOTP', error);
    } finally {
      setIsLoadingCSL(false);
    }
  };

  return (
    <>
      <KText size='xxxl' color="Secondary88" fontWeight="700">Welcome! 👋</KText>
      <br />
      <KXlText700Secondary88>
        {`${infoPrincipal?.principal?.owner} has shared a profile with you in ${appName}.`}
      </KXlText700Secondary88>
      <Flex vertical gap={16}>
        <KLgTextSecondary88>
          You can use this link to view what was shared with, but first you’ll need to check a few boxes.
        </KLgTextSecondary88>
        <TermsSection
          handleSendCode={handleSendCode}
          error={error}
          contact={infoPrincipal?.principal?.principal}
        />
      </Flex>
    </>
  );
};
