
import { EAvatarSize, KAvatar, KAvatarGroup } from "@components";
import { MarkCompleteToDo } from "@pages/components";
import { ICardTodoRes } from "@types";

export const AssignedAndCompletion = (card: ICardTodoRes) => {
  const { assigned } = card || {};
  return (
    <>
      <KAvatarGroup maxCount={3} size="small" shape="circle">
        {assigned?.length > 0 && assigned.map((u) => (
          <KAvatar
            key={u._id}
            firstName={u.firstName}
            lastName={u.lastName}
            imgUrl={u.avatar}
            size={EAvatarSize.small}
            shape="circle"
          />
        ))}
      </KAvatarGroup>
      <MarkCompleteToDo card={card}/>
    </>
  );
};