import {
  AllergyInfoTypeIcon,
  AppointmentInfoTypeIcon,
  ConditionInfoTypeIcon,
  HospitalizationInfoTypeIcon,
  MedicationInfoTypeIcon,
  NoteInfoTypeIcon,
  PractitionerInfoTypeIcon,
  ProcedureInfoTypeIcon,
  QuestionInfoTypeIcon,
  VaccinationInfoTypeIcon
} from "@assets/icons";
import { IconProps } from "@assets/icons/types";
import { KEmptyView, KSemiText700, Loading } from "@components";
import { KContext } from "@context";
import {
  AllergyItem,
  AppointmentItem,
  ConditionItem,
  HospitalizationItem,
  MedicationItem,
  NoteItem,
  ProcedureItem,
  QuestionItem,
  VaccinationItem,
} from "@pages/card-list/components";
import { CustomSpaceList } from "@pages/card-list/components/share-component";
import { getRelevantPractitioners, getUserRelevantPractitioners } from "@services";
import { Color, ECardType, IBaseRes, IParams, IRelevantInfoRes } from "@types";
import { Divider } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { WrapperCardDetail } from "../components/share-component";

const TitleListCard = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

interface IRelevantInfoItem {
  cards: any;
  icon: React.ComponentType<IconProps>;
  title: string;
  Component: React.ComponentType<any>;
}

const cardTypeToComponent: {
  [key: string]: {
    component: React.ComponentType<any>;
    title: string;
    icon: React.ComponentType<IconProps>;
  };
} = {
  [ECardType.Allergies]: {
    component: AllergyItem,
    title: "Allergies",
    icon: AllergyInfoTypeIcon,
  },
  [ECardType.Notes]: {
    component: NoteItem,
    title: "Notes",
    icon: NoteInfoTypeIcon,
  },
  [ECardType.Questions]: {
    component: QuestionItem,
    title: "Active Questions",
    icon: QuestionInfoTypeIcon,
  },
  [ECardType.Medications]: {
    component: MedicationItem,
    title: "Active Medications",
    icon: MedicationInfoTypeIcon,
  },
  [ECardType.Appointments]: {
    component: AppointmentItem,
    title: "Upcoming Appointments",
    icon: AppointmentInfoTypeIcon,
  },
  [ECardType.Conditions]: {
    component: ConditionItem,
    title: "Conditions",
    icon: ConditionInfoTypeIcon,
  },
  [ECardType.Hospitalizations]: {
    component: HospitalizationItem,
    title: "Hospitalizations",
    icon: HospitalizationInfoTypeIcon,
  },
  [ECardType.Procedures]: {
    component: ProcedureItem,
    title: "Procedures",
    icon: ProcedureInfoTypeIcon,
  },
  [ECardType.Vaccinations]: {
    component: VaccinationItem,
    title: "Vaccinations",
    icon: VaccinationInfoTypeIcon,
  },
};

const RelevantInfoItem = ({
  cards,
  icon,
  title,
  Component,
}: IRelevantInfoItem) => {
  return (
    <CustomSpaceList space={16}>
      <TitleListCard>
        {icon && React.createElement(icon, { width: 16 })}
        <KSemiText700>{title}</KSemiText700>
      </TitleListCard>
      {cards.map((item: any) => (
        <Component key={item._id} card={item} isDetail/>
      ))}
    </CustomSpaceList>
  );
};

export const RelevantInfo = () => {
  const { cardId, tempShareId } = useParams<IParams>();
  const [isLoading, setIsLoading] = useState(false);
  const { cardTypeSelected, profileId } = useContext(KContext);
  const [relevantInfo, setRelevantInfo] = useState<any>(null);

  useEffect(() => {
    if ((!profileId && !tempShareId) || !cardId || !cardTypeSelected) return;
    (async () => {
      try {
        setIsLoading(true);
        const res: IBaseRes<IRelevantInfoRes[]> = tempShareId
          ? await getRelevantPractitioners(tempShareId, cardTypeSelected, cardId)
          : await getUserRelevantPractitioners(profileId, cardId);
        if (res) {
          setRelevantInfo(res.data);
        }
      } catch (error) {
        console.log("error-getRelevantInfo --- ", error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [cardId, cardTypeSelected]);

  return isLoading ? (
    <Loading />
  ) : (
    <WrapperCardDetail gap={24}>
      {relevantInfo &&
        relevantInfo.map((item: IRelevantInfoRes, i: number) => {
          const typeInfo = cardTypeToComponent[item.type];
          if (!typeInfo) return null;
          const { component, title, icon } = typeInfo;
          return (
            <>
              {i > 0 && <Divider />}
              <RelevantInfoItem
                key={item.type}
                cards={item.cards}
                icon={icon}
                title={title}
                Component={component}
              />
            </>
          );
        })}
      {!relevantInfo?.length && (
        <KEmptyView
          icon={<PractitionerInfoTypeIcon color={Color.White} />}
          title="No related info yet"
          subTitle="Other types of info where you’ve indicated this practitioner will be summarized here."
        />
      )}
    </WrapperCardDetail>
  );
};
