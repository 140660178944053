import { EInviteType, EReasonType, IInfoPrincipal } from "@types";
import { DataAttributes } from "react-use-intercom";
import { UserInfo } from "services";

export const getBootIntercom = (info?: IInfoPrincipal) => {
  const { method, principal, recipientId = null } = info || {};
  const bootParams: DataAttributes = {};

  switch (method) {
    case EInviteType.EMAIL:
      bootParams.email = principal;
      if (recipientId) bootParams.userId = recipientId;
      break;
    case EInviteType.SMS:
      bootParams.phone = principal;
      break;
    default:
      bootParams.name = principal;
      break;
  }

  return bootParams;
};

export const convertBootIntercomUser = (user?: UserInfo | null) => {
  if (!user) return;
  const { _id, email, phoneNumber, firstName, lastName, reason } = user || {};
  const reason_own_health = reason.includes(EReasonType.OwnHealth);
  const reason_family_member = reason.includes(EReasonType.FamilyMember);
  const reason_pet_health = reason.includes(EReasonType.PetHealth);
  const reason_shared_withme = reason.includes(EReasonType.SharedWithMe);
  const reason_something_else = reason.filter(
    (rs) =>
      ![
        EReasonType.OwnHealth,
        EReasonType.FamilyMember,
        EReasonType.PetHealth,
        EReasonType.SharedWithMe,
      ].includes(rs as EReasonType)
  )[0];

  const bootParams: DataAttributes = {
    email,
    userId: _id,
    phone: phoneNumber,
    name: firstName + " " + lastName,
    customAttributes: {
      reason_own_health,
      reason_family_member,
      reason_pet_health,
      reason_shared_withme,
      reason_something_else,
    },
  };

  return bootParams;
};
