import { SVGAttributes } from "react";

interface IIconProps extends SVGAttributes<SVGSVGElement> {
  height?: number;
  width?: number;
}

export const IndeterminateIcon = ({
  width = 19,
  height,
  ...props
}: IIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height || width}
    viewBox="0 0 20 20"
    fill="none"
    cursor="pointer"
    {...props}
  >
    <rect
      x="0.5"
      y="0.5"
      width={width}
      height={height || width}
      rx="3.5"
      fill="white"
      stroke="#2E747C"
    />
    <path
      d="M12.3656 4.95391C11.6625 4.3375 10.7344 4 9.75 4C8.76562 4 7.8375 4.33984 7.13438 4.95391C6.40312 5.59375 6 6.45391 6 7.375V7.55313C6 7.65625 6.08437 7.74063 6.1875 7.74063H7.3125C7.41562 7.74063 7.5 7.65625 7.5 7.55313V7.375C7.5 6.34141 8.51016 5.5 9.75 5.5C10.9898 5.5 12 6.34141 12 7.375C12 8.10391 11.4844 8.77188 10.6852 9.07891C10.1883 9.26875 9.76641 9.60156 9.46406 10.0375C9.15703 10.4828 8.99766 11.0172 8.99766 11.5586V12.0625C8.99766 12.1656 9.08203 12.25 9.18516 12.25H10.3102C10.4133 12.25 10.4977 12.1656 10.4977 12.0625V11.5305C10.4989 11.3029 10.5686 11.0811 10.6978 10.8938C10.827 10.7065 11.0096 10.5624 11.2219 10.4805C12.6047 9.94844 13.4977 8.72969 13.4977 7.375C13.5 6.45391 13.0969 5.59375 12.3656 4.95391ZM8.8125 14.6875C8.8125 14.9361 8.91127 15.1746 9.08709 15.3504C9.2629 15.5262 9.50136 15.625 9.75 15.625C9.99864 15.625 10.2371 15.5262 10.4129 15.3504C10.5887 15.1746 10.6875 14.9361 10.6875 14.6875C10.6875 14.4389 10.5887 14.2004 10.4129 14.0246C10.2371 13.8488 9.99864 13.75 9.75 13.75C9.50136 13.75 9.2629 13.8488 9.08709 14.0246C8.91127 14.2004 8.8125 14.4389 8.8125 14.6875Z"
      fill="#2E747C"
    />
  </svg>
);
