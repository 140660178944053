import { Flex, Popover, message } from "antd";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { MeatBallIcon } from "@assets/icons";
import {
  KButton,
  KFormGroupNoControl,
  KInput,
  KLgText700, KMdText,
  KModalConfirm,
  KModalQuickAdd,
  KSemiText700
} from "@components";
import { KContext } from "@context";
import { useCustomNavigate, useLoggingService } from "@hooks";
import { deleteTopicService, updateTopicService } from "@services";
import { IParams } from "@types";

export const MeatBallTopic = () => {
  const { cardType: topicId, profileId } = useParams<IParams>();
  const { topics, fetchTopics } = useContext(KContext);
  const { navigate } = useCustomNavigate();
  const { pushInfoTypeLog } = useLoggingService();

  const [topicName, setTopicName] = useState('');
  const [showTopicOptions, setShowTopicOptions] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const topic = topics.find((t) => t._id === topicId);
    if (!topic) return;
    setTopicName(topic.title);
  }, [topics, topicId, showEditModal]);

  useEffect(() => {
    if (showEditModal) return;
    setTopicName('');
  }, [showEditModal]);

  const handleDeleteTopic = async () => {
    if (!profileId || !topicId) return;
    try {
      setIsLoading(true);
      await deleteTopicService(profileId, topicId);
      const topicDeleted = { _id: topicId }
      pushInfoTypeLog("DELETED", topicDeleted, "TOPIC");
      message.success('Delete topic successfully!');
      setShowConfirmModal(false);
      await fetchTopics();
      navigate(`/user/${profileId}`);
    } catch (error: any) {
      message.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const onOpenModalRename = () => {
    setShowTopicOptions(false);
    setShowEditModal(true);
  };

  const onOpenConfirmModal = () => {
    setShowTopicOptions(false);
    setShowConfirmModal(true);
  };

  const handleSaveTopic = async () => {
    if (!profileId || !topicId) return;
    try {
      setIsLoading(true);
      const res = await updateTopicService({
        _id: topicId,
        title: topicName,
        profile: profileId,
      });
      pushInfoTypeLog("MODIFIED", res, "TOPIC");
      message.success('Update topic successfully!');
      setShowEditModal(false);
      await fetchTopics();
    } catch (error: any) {
      message.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Flex vertical align="flex-end">
        <Popover
          placement="bottomRight"
          open={showTopicOptions}
          trigger="click"
          onOpenChange={setShowTopicOptions}
          content={(
            <Flex gap={16} vertical style={{ padding: 16 }} justify="center">
              <Flex style={{ justifyContent: 'center' }}>
                <KLgText700>Topic Options</KLgText700>
              </Flex>
              <KButton
                ghost
                size="large"
                type="primary"
                title="Rename Topic"
                onClick={onOpenModalRename}
              />
              <KButton
                ghost
                size="large"
                danger
                title="Delete Topic"
                onClick={onOpenConfirmModal}
              />
            </Flex>
          )}
        >
          <Flex vertical align="flex-end" style={{ cursor: "pointer", padding: '10px 0' }}>
            <MeatBallIcon />
          </Flex>
        </Popover>
      </Flex>

      <KModalQuickAdd
        open={showEditModal}
        title="Edit Topic"
        onCancel={() => setShowEditModal(false)}
      >
        <Flex gap={16} vertical>
          <KLgText700>What would you like to name this topic?</KLgText700>
          <KFormGroupNoControl label="Topic Name">
            <KInput
              value={topicName}
              onChange={(e) => { setTopicName(e.target.value); }}
            />
          </KFormGroupNoControl>
          <KButton
            htmlType="button"
            loading={isLoading}
            size="large"
            type="primary"
            title="Save"
            onClick={handleSaveTopic}
          />
        </Flex>
      </KModalQuickAdd>
      <KModalConfirm
        title="Delete Topic"
        open={showConfirmModal}
        onOk={handleDeleteTopic}
        onCancel={() => { setShowConfirmModal(false); }}
      >
        <Flex vertical gap={8}>
          <KSemiText700>
            Are you sure you want to delete this topic?
          </KSemiText700>
          <KMdText>
            This topic will be deleted and cannot be recovered.
          </KMdText>
        </Flex>
      </KModalConfirm>
    </>
  );
};
