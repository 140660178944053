import { FC } from "react";
import { IconProps } from "./types";

export const FolderOpenOutlinedIcon: FC<IconProps> = ({
  width = 16,
  height = 16,
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 17 16" fill="none">
    <path d="M15 6.9375H13.3125V5.1625C13.3125 4.88594 13.0891 4.6625 12.8125 4.6625H7.89062L6.05781 2.90937C6.03449 2.88754 6.00382 2.87527 5.97188 2.875H2C1.72344 2.875 1.5 3.09844 1.5 3.375V12.625C1.5 12.9016 1.72344 13.125 2 13.125H12.9062C13.1094 13.125 13.2937 13.0016 13.3703 12.8125L15.4641 7.625C15.4875 7.56563 15.5 7.50156 15.5 7.4375C15.5 7.16094 15.2766 6.9375 15 6.9375ZM2.625 4H5.57031L7.43906 5.7875H12.1875V6.9375H4.21875C4.01562 6.9375 3.83125 7.06094 3.75469 7.25L2.625 10.05V4ZM12.5516 12H2.98438L4.59844 8H14.1672L12.5516 12Z" fill="#2E747C"/>
  </svg>
);
