import {
  KFormUser,
  KLgText700Secondary88,
  KSmTextSecondary50,
  KSmTextSecondary65,
  KText,
  StyledModal,
} from "@components";
import { zodResolver } from "@hookform/resolvers/zod";
import { useLoggingService } from "@hooks";
import { requestOTPUpdateService, updateUserContactService } from "@services";
import { useUser } from "@store";
import { CODE_LENGTH, EContactType } from "@types";
import { setToken } from "@utils/storage";
import { userInfoRules } from "Schema";
import { Flex, Form, ModalProps, message } from "antd";
import { ComponentType, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { UserInfo } from "services/auth";
import { ZodRawShape, z } from "zod";

interface IUpdateContact extends ModalProps {
  contact?: EContactType;
  onOpen: (value: boolean) => void;
}

const contactInputs: {
  [key: EContactType | string]: {
    title: string;
    InputComp: ComponentType<any>;
    schema: ZodRawShape;
  };
} = {
  [EContactType.EMAIL]: {
    title: "Email",
    InputComp: KFormUser.Input,
    schema: userInfoRules.email,
  },
  [EContactType.PHONE_NUMBER]: {
    title: "Phone Number",
    InputComp: KFormUser.InputPhone,
    schema: userInfoRules.phoneNumber,
  },
};

export const UpdateContact = ({
  contact = EContactType.EMAIL,
  onOpen,
  ...props
}: IUpdateContact) => {
  const [openVerifyOtp, setOpenVerifyOtp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");
  const { title, schema, InputComp } = contactInputs[contact];
  const { userInfo, setUserInfo } = useUser();
  const { pushInfoTypeLog } = useLoggingService();

  const method = useForm<UserInfo>({
    resolver: zodResolver(z.object(schema)),
    mode: "onChange",
  });
  const { formState: { isValid }, getValues } = method;

  const onRequestOTPUpdate = async () => {
    try {
      setLoading(true);
      await requestOTPUpdateService(getValues(contact), contact);
      onOpen(false);
      setOpenVerifyOtp(true);
    } catch (err: any) {
      message.error(err?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const onUpdateUserContact = async () => {
    if (!userInfo) return;
    try {
      setLoading(true);
      const payload = {[contact]: getValues(contact)};
      const { accessToken } = await updateUserContactService(payload, code);
      pushInfoTypeLog("MODIFIED", userInfo, "ACCOUNT_SETTINGS");
      setCode("");
      accessToken && setToken('WebApp', accessToken);
      userInfo && setUserInfo({...userInfo, ...payload});
      message.success(`Update ${title} successfully!`);
      setOpenVerifyOtp(false);
    } catch (err: any) {
      message.error(err?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const onCancelVerifyOTP = () => {
    setOpenVerifyOtp(false);
    onOpen(true);
  };

  return (
    <>
      <StyledModal
        width={360}
        title={<KSmTextSecondary50>Step 1</KSmTextSecondary50>}
        okButtonProps={{ disabled: !isValid }}
        onOk={onRequestOTPUpdate}
        okText="Send Code"
        cancelText="Go Back"
        confirmLoading={loading}
        onCancel={() => onOpen(false)}
        centered
        {...props}
      >
        <Flex vertical gap={8}>
          <KLgText700Secondary88>Edit {title}</KLgText700Secondary88>
          <KText>
            We’ll need to verify a new {title} before we can update your
            account.
          </KText>
          <FormProvider {...method}>
            <Form children={<InputComp label={`New ${title}`} name={contact}/>}/>
          </FormProvider>
        </Flex>
      </StyledModal>
      <StyledModal
        width={360}
        title={<KSmTextSecondary50>Step 2</KSmTextSecondary50>}
        open={openVerifyOtp}
        okButtonProps={{ disabled: code.length < CODE_LENGTH }}
        onCancel={onCancelVerifyOTP}
        onOk={onUpdateUserContact}
        confirmLoading={loading}
        okText="Confirm"
        cancelText="Go Back"
        centered
      >
        <Flex vertical gap={8}>
          <KLgText700Secondary88>Edit {title}</KLgText700Secondary88>
          <KText>Enter the code we sent to {getValues(contact)}.</KText>
          <Flex vertical>
            <KFormUser.Pin
              label="6-Digit Code"
              code={code}
              setCode={setCode}
              size="md"
            />
            <KSmTextSecondary65>
              Didn’t receive a code? Go Back to try again.
            </KSmTextSecondary65>
          </Flex>
        </Flex>
      </StyledModal>
    </>
  );
};
