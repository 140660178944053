import { SearchOutlined } from "@ant-design/icons";
import { KButton, Loading, StyledInputSearch } from "@components";
import { searchMedicalSourcesService } from "@services";
import { ISourceFTH } from "@types";
import { Flex } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { EMRProgress, EMRSourceItem, ModalConnectSource } from "../components";
import { ContainerScreenEMR } from "../styled-components";
import { useEMRStore } from "@store";
import { useMedicalRecordPermission } from "@hooks";

export const EMRSearchSource = () => {
  const { importedBrandIds } = useEMRStore();
  const scrollRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState<string>();
  const [sources, setSources] = useState<ISourceFTH[]>([]);
  const [sourceSelected, setSourceSelected] = useState<ISourceFTH>();
  const [openConnect, setOpenConnect] = useState(false);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const [searchAfter, setSearchAfter] = useState<(string | number)[]>([]);
  const [hasNextPage, setHasNextPage] = useState(true);

  useMedicalRecordPermission();

  const onSubmitSearch = useCallback((isScrolledToBottom?: boolean) => {
    if (loading) return;
    (async () => {
      try {
        setLoading(true);
        const nextPagePayload = isScrolledToBottom ? searchAfter : [];
        const res = await searchMedicalSourcesService(search, nextPagePayload);
        if (res?.hits) {
          const { hits } = res;
          const list = hits
            .filter(({ _source: { id } }) => !importedBrandIds.includes(id))
            .map(({ _source }) => _source);

          if (!isScrolledToBottom || !searchAfter.length) setSources(list);
          else {
            setSources((prev) => [...prev, ...list]);
          }
          setSearchAfter(hits[hits.length - 1]?.sort || []);
          setHasNextPage(!!hits.length);
        }
      } catch {
      } finally {
        setLoading(false);
      }
    })();
  }, [search, loading, isScrolledToBottom, searchAfter]);

  const onScroll = useCallback(() => {
    const container = scrollRef.current;
    if (!container) return;
    const handleScroll = (e: Event) => {
      const { scrollTop, scrollHeight, clientHeight } = container;
      const bottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;
      setIsScrolledToBottom(bottom);
    };
    container.addEventListener("scroll", handleScroll);
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if ((!isScrolledToBottom && searchAfter.length > 0) || !hasNextPage) return;
    onSubmitSearch(true);
  }, [isScrolledToBottom]);

  const onClickSource = (data: ISourceFTH) => {
    setSourceSelected(data);
    setOpenConnect(true);
  };

  return (
    <ContainerScreenEMR vertical flex={1}>
      <EMRProgress showInfo={false} success={{ percent: 100 }} percent={33} />
      <StyledInputSearch
        allowClear
        onChange={(e) => setSearch(e.target.value)}
        placeholder="Search hospitals, clinics, etc."
        onPressEnter={() => onSubmitSearch()}
        size="large"
        enterButton={
          <KButton
            size="large"
            icon={<SearchOutlined />}
            onClick={() => onSubmitSearch()}
            loading={loading}
          />
        }
      />
      {loading && (search || !searchAfter.length) ? (
        <Loading />
      ) : (
        <Flex
          vertical
          gap={16}
          style={{ overflow: "scroll", padding: "0 12px" }}
          ref={scrollRef}
          onScroll={onScroll}
        >
          {sources.map((data) => (
            <EMRSourceItem
              key={data.id}
              source={data}
              onClickSource={onClickSource}
            />
          ))}
        </Flex>
      )}
      <ModalConnectSource
        source={sourceSelected}
        open={openConnect}
        onCancel={() => setOpenConnect(false)}
      />
    </ContainerScreenEMR>
  );
};
