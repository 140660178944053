import { ECardType } from "@types";
import {
  schemaAllergies,
  schemaConditions,
  schemaFile,
  schemaHospitalizations,
  schemaIdCard,
  schemaMedications,
  schemaNotes,
  schemaPractitioners,
  schemaProcedures,
  schemaQuestions,
  schemaToDos,
  schemaVaccinations,
} from "Schema";
import { ZodType } from "zod";

const schemaCardType: { [key: ECardType | string]: ZodType } = {
  [ECardType.Files]: schemaFile,
  [ECardType.Allergies]: schemaAllergies,
  [ECardType.IdCard]: schemaIdCard,
  [ECardType.Procedures]: schemaProcedures,
  [ECardType.Hospitalizations]: schemaHospitalizations,
  [ECardType.Notes]: schemaNotes,
  [ECardType.Questions]: schemaQuestions,
  [ECardType.Vaccinations]: schemaVaccinations,
  [ECardType.Conditions]: schemaConditions,
  [ECardType.Medications]: schemaMedications,
  [ECardType.Practitioners]: schemaPractitioners,
  [ECardType.ToDos]: schemaToDos,
};

export function getSchemaDetail(key?: ECardType | string): ZodType {
  return schemaCardType[key || ''] || schemaFile;
}
