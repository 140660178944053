import { KMdText, KModalConfirm, KSemiText700 } from "@components";
import { KContext } from "@context";
import { useCustomNavigate, useLoggingService } from "@hooks";
import { deleteAccountService, logoutWebAppService } from "@services";
import { useProfile, useUser } from "@store";
import { getToken, removeToken } from "@utils/storage";
import { Flex } from "antd";
import { Dispatch, FC, SetStateAction, useContext } from "react";
import { useIntercom } from "react-use-intercom";

interface IProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const DeleteModal: FC<IProps> = ({ isOpen, setIsOpen }) => {
  const { navigate } = useCustomNavigate();
  const { hardShutdown } = useIntercom();
  const { setProfile } = useProfile();
  const { setTopics, setAmountOfCards } = useContext(KContext);
  const { userInfo } = useUser();
  const { pushInfoTypeLog } = useLoggingService();

  const handleDeleteAccount = async () => {
    try {
      if (!userInfo) return;
      const tokenFCM = getToken("FCM");
      setIsOpen(false);
      hardShutdown();
      setTopics([]);
      setAmountOfCards([]);
      setProfile(null);
      removeToken("TempShare");
      removeToken("Pin");
      pushInfoTypeLog("DELETED", userInfo, "ACCOUNT_SETTINGS");
      await deleteAccountService();
      await logoutWebAppService(tokenFCM);
      navigate("auth/sign-in");
      if (tokenFCM) {
        removeToken("FCM");
      }
      removeToken("WebApp");
    } catch (err) {}
  };

  return (
    <KModalConfirm
      open={isOpen}
      onOk={handleDeleteAccount}
      onCancel={() => {
        setIsOpen(false);
      }}
      okText="Yes, delete account"
      okButtonProps={{ danger: true, size: "large", type: "default" }}
    >
      <Flex vertical gap={8}>
        <KSemiText700>
          Are you sure you want to delete your KinKeeper account?
        </KSemiText700>
        <KMdText>
          All profiles and data will immediately be permanently deleted and
          cannot be restored.
        </KMdText>
      </Flex>
    </KModalConfirm>
  );
};
