import { apiInstance } from "@apis";
import { IEmployee } from "@types";

const baseUrl = "/v1/employee";

export const verifyEmployee = async (
  payload: Pick<IEmployee, "employeeInfo" | "employerId">
) => {
  const { data } = await apiInstance.post(`${baseUrl}/verify`, payload);
  return data;
};
