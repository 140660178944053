import { FC, SVGProps } from "react";
import { IconProps } from "./types";

export const ConditionInfoTypeIcon: FC<IconProps> = ({
  width = 24,
  height,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height || width}
    viewBox="0 0 24 24"
    {...props}
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M3.25 22.199C3.25 22.6413 3.60856 23 4.05104 23H20.5511C20.9931 23 21.3521 22.6417 21.3521 22.199V3.13057C21.3521 2.68817 20.9935 2.32953 20.5511 2.32953H17.1123V1.80103C17.1123 1.35856 16.7536 0.999992 16.3112 0.999992H8.34072C7.89832 0.999992 7.53968 1.35863 7.53968 1.80103V2.32953H4.05104C3.60895 2.32953 3.25 2.68786 3.25 3.13057V22.199ZM19.7567 3.92496H17.1123V5.78297C17.1123 6.22537 16.7536 6.58401 16.3112 6.58401H8.34072C7.89839 6.58401 7.53968 6.22545 7.53968 5.78297V3.92496H4.84543V21.4046H19.7567V3.92496ZM9.13511 4.98858V2.59543H15.5168V4.98858H9.13511ZM10.65 9C10.3739 9 10.15 9.22386 10.15 9.5V11.4H8.25C7.97386 11.4 7.75 11.6239 7.75 11.9V15.1C7.75 15.3761 7.97386 15.6 8.25 15.6H10.15V17.5C10.15 17.7761 10.3739 18 10.65 18H13.85C14.1261 18 14.35 17.7761 14.35 17.5V15.6H16.25C16.5261 15.6 16.75 15.3761 16.75 15.1V11.9C16.75 11.6239 16.5261 11.4 16.25 11.4H14.35V9.5C14.35 9.22386 14.1261 9 13.85 9H10.65ZM9.25 14.1V12.9H11.65V10.5H12.85V12.9H15.25V14.1H12.85V16.5H11.65V14.1H9.25Z"/>
  </svg>
);
