import { apiInstance } from "@apis";
import { ELogType, EProfileRole, IBaseRes, IInfoChangeLog, profileRoleName } from "@types";
import { getToken as getLocalStorage } from "@utils/storage";
import moment from "moment";

const baseUrl = "/v1/logging";

type ILoggingType = IInfoChangeLog;

const createPinLogging = async (
  userId: string,
  activeProfile: string,
  role: EProfileRole
) => {
  const timeSetPin = moment(getLocalStorage("Pin"));
  const createdTime = timeSetPin.isValid() ? timeSetPin.toDate() : new Date();
  await apiInstance.post(baseUrl, {
    type: ELogType.PIN_ENTRY,
    list: [
      {
        userId,
        activeProfile,
        profileRole: role ? profileRoleName[role] : "unknown",
        createdTime,
      },
    ],
  });
};

const createLogsService = async (type: ELogType, payload: ILoggingType[]) => {
  await apiInstance.post<any, IBaseRes<ILoggingType>>(baseUrl, {
    type,
    list: payload,
  });
};

export { createLogsService, createPinLogging };
