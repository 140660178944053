import { SVGProps } from "react";

export const EmergencyAccessIcon = ({
  width = 24,
  height,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height || width}
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.4 2.5C7.4 2.22386 7.62386 2 7.9 2H15.1C15.3761 2 15.6 2.22386 15.6 2.5V7.4H20.5C20.7761 7.4 21 7.62386 21 7.9V15.1C21 15.3761 20.7761 15.6 20.5 15.6H15.6V20.5C15.6 20.7761 15.3761 21 15.1 21H7.9C7.62386 21 7.4 20.7761 7.4 20.5V15.6H2.5C2.22386 15.6 2 15.3761 2 15.1V7.9C2 7.62386 2.22386 7.4 2.5 7.4H7.4V2.5ZM3.5 8.9V14.1H8.9V19.5H14.1V14.1H19.5V8.9H14.1V3.5H8.9V8.9H3.5Z"
    />
  </svg>
);
