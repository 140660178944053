import { EProfileRole, IProfileBasic } from "@types";
import { getToken } from "@utils/storage";
import { isFuture } from "@utils/date";
import { UserInfo, getMe } from "services/auth";
import { create } from "zustand";

type State = {
  userInfo: UserInfo | null;
  fetchUserInfo: () => Promise<void>;
  isFreemium: boolean;
  setUserInfo: (data: UserInfo | null) => void;
  notificationCount: number;
  setNotificationCount: (val: number) => void;
};

type RoleUserState = {
  role?: EProfileRole;
  roleViewer: boolean;
  roleContributor: boolean;
  roleEditor: boolean;
  roleOwner: boolean;
  setRoleUser: (profile: IProfileBasic | null, id?: string) => void;
};

export const useUser = create<State>((set) => {
  return {
    userInfo: null,
    isFreemium: false,
    setUserInfo: (data) => set({ userInfo: data }),
    fetchUserInfo: async () => {
      const token = getToken("WebApp");
      if (!token) return;
      try {
        const res = await getMe();
        const isExpired = isFuture(res.subscription?.expiresDate);
        set({ userInfo: res, isFreemium: !isExpired });
      } catch (error) {
        console.error(error);
      }
    },
    notificationCount: 0,
    setNotificationCount: (val) => set({ notificationCount: val }),
  };
});

export const useRoleUser = create<RoleUserState>((set) => {
  return {
    roleViewer: false,
    roleContributor: false,
    roleEditor: false,
    roleOwner: false,
    setRoleUser: (profile, id) => {
      if (!profile) return;
      const role = profile?.acl?.find((i) => i.accessor === id)?.role || 0;
      set({
        roleViewer: EProfileRole.VIEWER <= Number(role),
        roleContributor: EProfileRole.CONTRIBUTOR <= Number(role),
        roleEditor: EProfileRole.EDITOR <= Number(role),
        roleOwner: EProfileRole.OWNER <= Number(role),
        role,
      });
    },
  };
});
