import { Color } from "../../types";
import { IconProps } from "./types";

export const FileAudioIcon: React.FC<IconProps> = ({
  style,
  width = 64,
  height,
  color = Color.SecondaryBase,
}) => {
  return (
    <svg
      style={style}
      width={width}
      height={height || width}
      fill={color}
      viewBox={"0 0 32 32"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9813 2.29375L26.7062 9.01875C26.8937 9.20625 27 9.4625 27 9.72812V29C27 29.5531 26.5531 30 26 30H6C5.44687 30 5 29.5531 5 29V3C5 2.44687 5.44687 2 6 2H19.275C19.5406 2 19.7938 2.10625 19.9813 2.29375ZM18.8125 10.1875H24.6938L18.8125 4.30625V10.1875ZM7.25 27.75H24.75V12.3125H18C17.6519 12.3125 17.3181 12.1742 17.0719 11.9281C16.8258 11.6819 16.6875 11.3481 16.6875 11V4.25H7.25V27.75ZM20.0532 14.6687C20.1608 14.6576 20.2682 14.692 20.3493 14.7636C20.43 14.8359 20.4761 14.9391 20.4761 15.0474V23.0474C20.4761 23.8882 19.6216 24.5712 18.5713 24.5712C17.5211 24.5712 16.6666 23.8878 16.6666 23.0474C16.6666 22.207 17.521 21.5236 18.5713 21.5236C18.9736 21.5213 19.3688 21.629 19.7142 21.8352V17.3781L14.4762 17.9998V23.7522C14.4762 24.593 13.6218 25.276 12.5715 25.276C11.5212 25.276 10.6667 24.5926 10.6667 23.7522C10.6667 22.9118 11.5212 22.2284 12.5715 22.2284C12.9739 22.2264 13.3691 22.3345 13.7144 22.5412V15.7522C13.7143 15.558 13.8604 15.3949 14.0534 15.3735L20.0532 14.6687Z"
        fill="#161925"
      />
    </svg>
  );
};