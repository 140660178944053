import { FC } from 'react';
import { IKTextProps } from '../../types';
import { KText } from './default';

const KSemiText: FC<IKTextProps> = ({
  children,
  color,
  $isUnderlined,
  fontWeight,
  numberOfLines,
  ...props
}) => (
  <KText
    size="semi"
    color={color}
    $isUnderlined={$isUnderlined}
    fontWeight={fontWeight}
    numberOfLines={numberOfLines}
    {...props}
  >
    {children}
  </KText>
);

const KSemiText700 = ({
  children,
  $isUnderlined,
  numberOfLines,
  ...props
}: IKTextProps) => (
  <KSemiText
    fontWeight="700"
    $isUnderlined={$isUnderlined}
    numberOfLines={numberOfLines}
    {...props}
  >
    {children}
  </KSemiText>
);

export { KSemiText, KSemiText700 };
