import { heightAvatarProfileSmall, heightAvatarProfileLarge, heightAvatarProfileXLarge } from "@constants/size";
import { EAvatarSize } from "components/enums";
import { EFontSize, ELineHeight } from "types/enum";
import { EBorderRadius } from "./style";

export const sizeAvatar:{ [key: EAvatarSize | string ]: { fontSize: number; lineHeight: number, size: number, borderRadius: string } } = {
  [EAvatarSize.small]: { fontSize: EFontSize.sm, lineHeight: ELineHeight.sm, size: heightAvatarProfileSmall, borderRadius: EBorderRadius.sm },
  [EAvatarSize.medium]: { fontSize: EFontSize.md, lineHeight: ELineHeight.md, size: 32, borderRadius: EBorderRadius.md },
  [EAvatarSize.semi]: { fontSize: EFontSize.semi, lineHeight: ELineHeight.semi, size: 40, borderRadius: EBorderRadius.md },
  [EAvatarSize.large]: { fontSize: EFontSize.xl, lineHeight: ELineHeight.xl, size: heightAvatarProfileLarge, borderRadius: EBorderRadius.md },
  [EAvatarSize.extra_large]: { fontSize: EFontSize.xxxxl, lineHeight: ELineHeight.xxxxl, size: heightAvatarProfileXLarge, borderRadius: EBorderRadius.xxl }
}