import { KEmptyView } from "@components";
import { useProfile } from "@store";

export const EmptyInfoType = () => {
  const { profile } = useProfile();

  return profile ? (
    <KEmptyView
      subTitle={`View ${profile?.basicInformation.firstName}'s info by selecting something from the left.`}
    />
  ) : null;
};
