import { Color, EFontSize, EHeightInput, ELineHeight } from "@types";

export const containerStyle = (isError: boolean, focus: boolean, size?: keyof typeof EFontSize) => ({
  width: "auto",
  height: `${EHeightInput[size || "xl"]}px`,
  padding: "0 12px",
  fontSize: `${EFontSize[size || "xl"]}px`,
  letterSpacing: "14.4px",
  fontWeight: "700",
  lineHeight: `${ELineHeight[size || "xl"]}px`,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: "1px solid",
  borderRadius: "8px",
  color: isError ? Color.ErrorBase : (focus ? Color.PrimaryBase : Color.Secondary10),
  transition: "color 0.3s",
})

export const inputStyle = (isError: boolean, size?: keyof typeof EFontSize) => ({
  border: "none",
  outline: "none",
  fontSize: `${EFontSize[size || "xl"]}px`,
  fontWeight: "700",
  textAlign: "center" as any,
  height: "100%",
  flex: 1,
  margin: "0 2px",
  color: isError ? Color.ErrorBase : Color.PrimaryBase,
});