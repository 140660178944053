import { IconProps } from "./types";

export const MeatBallIcon: React.FC<IconProps> = ({
    width = 16,
    height = 4,
    color = "#161925"
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 1.07779e-06C14.3956 1.07779e-06 14.7822 0.117299 15.1111 0.337062C15.44 0.556825 15.6964 0.869183 15.8478 1.23463C15.9991 1.60009 16.0387 2.00222 15.9616 2.39018C15.8844 2.77814 15.6939 3.13451 15.4142 3.41422C15.1345 3.69392 14.7781 3.8844 14.3902 3.96157C14.0022 4.03874 13.6001 3.99914 13.2346 3.84776C12.8692 3.69639 12.5568 3.44004 12.3371 3.11114C12.1173 2.78224 12 2.39556 12 2C12 1.46957 12.2107 0.960861 12.5858 0.585788C12.9609 0.210715 13.4696 1.07779e-06 14 1.07779e-06ZM10 2C10 1.60444 9.8827 1.21776 9.66294 0.888861C9.44318 0.559963 9.13082 0.303617 8.76537 0.152242C8.39992 0.000866562 7.99778 -0.0387401 7.60982 0.0384304C7.22186 0.115601 6.86549 0.306083 6.58579 0.585788C6.30608 0.865493 6.1156 1.22186 6.03843 1.60982C5.96126 1.99778 6.00087 2.39992 6.15224 2.76537C6.30362 3.13082 6.55996 3.44318 6.88886 3.66294C7.21776 3.8827 7.60444 4 8 4C8.53043 4 9.03914 3.78929 9.41421 3.41422C9.78929 3.03914 10 2.53044 10 2ZM4 2C4 1.60444 3.8827 1.21776 3.66294 0.888861C3.44318 0.559963 3.13082 0.303617 2.76537 0.152242C2.39992 0.000866562 1.99778 -0.0387401 1.60982 0.0384304C1.22186 0.115601 0.865492 0.306083 0.585787 0.585788C0.306082 0.865493 0.115601 1.22186 0.0384303 1.60982C-0.0387401 1.99778 0.000866562 2.39992 0.152242 2.76537C0.303617 3.13082 0.559963 3.44318 0.888861 3.66294C1.21776 3.8827 1.60444 4 2 4C2.53043 4 3.03914 3.78929 3.41421 3.41422C3.78929 3.03914 4 2.53044 4 2Z"
        fill={color}
      />
    </svg>
  );
};
