import { Color } from "../../types";
import { IconProps } from "./types";

export const FileMedicalIcon: React.FC<IconProps> = ({
  style,
  width = 64,
  height,
  color = Color.SecondaryBase,
}) => {
  return (
    <svg
      style={style}
      width={width}
      height={height}
      fill={color}
      viewBox={"0 0 32 32"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.7062 9.01875L19.9813 2.29375C19.7938 2.10625 19.5406 2 19.275 2H6C5.44687 2 5 2.44687 5 3V29C5 29.5531 5.44687 30 6 30H26C26.5531 30 27 29.5531 27 29V9.72812C27 9.4625 26.8937 9.20625 26.7062 9.01875ZM24.6938 10.1875H18.8125V4.30625L24.6938 10.1875ZM24.75 27.75H7.25V4.25H16.6875V11C16.6875 11.3481 16.8258 11.6819 17.0719 11.9281C17.3181 12.1742 17.6519 12.3125 18 12.3125H24.75V27.75Z"
        fill="#161925"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.9193 15.7114C21.255 16.0372 21.5235 16.4248 21.7097 16.8526C21.9028 17.2978 22.0014 17.7705 22 18.257C22 18.716 21.9055 19.1942 21.718 19.6808C21.5624 20.0873 21.336 20.5091 21.0485 20.935C20.5929 21.6089 19.9664 22.3118 19.1886 23.0244C17.8981 24.2056 16.623 25.0201 16.5688 25.0546L16.2396 25.2641C16.0938 25.3564 15.9062 25.3564 15.7604 25.2641L15.4312 25.0546C15.377 25.0215 14.1005 24.2056 12.8114 23.0244C12.0336 22.3118 11.4071 21.6089 10.9515 20.935C10.664 20.5091 10.4389 20.0873 10.282 19.6808C10.0945 19.1942 10 18.716 10 18.257C10 17.7705 10.0986 17.2978 10.2917 16.8526C10.4774 16.4245 10.7459 16.0368 11.0821 15.7114C11.4196 15.3834 11.8127 15.1256 12.2489 14.9465C12.7003 14.7604 13.1781 14.6667 13.6713 14.6667C14.3561 14.6667 15.0242 14.8527 15.6048 15.2042C15.7437 15.2883 15.8757 15.3806 16.0007 15.4812C16.1257 15.3806 16.2577 15.2883 16.3966 15.2042C16.9772 14.8527 17.6453 14.6667 18.3301 14.6667C18.8182 14.6656 19.3017 14.7607 19.7525 14.9465C20.1872 15.1249 20.5833 15.3846 20.9193 15.7114ZM12.9665 20.6667H15.1165V22.8167C15.1165 22.8729 15.1728 22.9167 15.2415 22.9167H16.7415C16.8103 22.9167 16.8665 22.8729 16.8665 22.8135V20.6667H19.0165C19.0728 20.6667 19.1165 20.6104 19.1165 20.5417V19.0417C19.1165 18.9729 19.0728 18.9167 19.0134 18.9167H16.8665V16.7667C16.8665 16.7104 16.8103 16.6667 16.7415 16.6667H15.2415C15.1728 16.6667 15.1165 16.7104 15.1165 16.7667V18.9167H12.9665C12.9103 18.9167 12.8665 18.9729 12.8665 19.0417V20.5417C12.8665 20.6104 12.9103 20.6667 12.9665 20.6667Z"
        fill="#161925"
      />
    </svg>
  );
};