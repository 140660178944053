import { useCallback, useEffect, useReducer, useState } from "react";

import { useDebounce } from "@hooks";
import { useUser } from "@store";
import {
  getToken as getLocalStorage,
  setToken as setLocalStorage,
} from "@utils/storage";

const NOT_USE_TIME = 15 * 60 * 1000; // 15 minutes
const DEBOUNCE_TIME = 2000; // 2 seconds

const useShowPin = () => {
  const [isFocused, setIsFocused] = useState(true);
  const [showModal, setShowModal] = useState(true);
  const [eventIndex, forceUpdateEvent] = useReducer((x) => x + 1, 0);
  const [updatedPinIndex, forceUpdatePin] = useReducer((x) => x + 1, 0);
  const { userInfo } = useUser();

  useEffect(() => {
    const lastEnterPin = getLocalStorage("Pin");
    if (lastEnterPin && new Date().getTime() - lastEnterPin < NOT_USE_TIME) {
      setShowModal(false);
    }

    const timer = setTimeout(() => {
      if (!isFocused) {
        setShowModal(true);
      }
    }, NOT_USE_TIME);

    window.onfocus = () => {
      if (
        !lastEnterPin ||
        new Date().getTime() - lastEnterPin >= NOT_USE_TIME
      ) {
        setShowModal(true);
      }
      setIsFocused(true);
      clearTimeout(timer);
    };

    window.onblur = () => {
      setIsFocused(false);
    };

    return () => {
      clearTimeout(timer);
    };
  }, [isFocused, updatedPinIndex]);

  const updatePinTime = useCallback(() => {
    if (showModal || !userInfo?.isSetupPin) return;
    setLocalStorage("Pin", new Date().getTime() - DEBOUNCE_TIME);
    forceUpdatePin();
  }, [showModal, userInfo?.isSetupPin]);

  useDebounce(updatePinTime, DEBOUNCE_TIME, [eventIndex]);

  useEffect(() => {
    const events = [
      "scroll",
      "keydown",
      "mousedown",
      "touchstart",
      "resize",
      "click",
      "dblclick",
      "wheel",
    ];
    if (showModal) return;
    events.forEach((event) => window.addEventListener(event, forceUpdateEvent));
    return () => {
      events.forEach((event) =>
        window.removeEventListener(event, forceUpdateEvent)
      );
    };
  }, [forceUpdateEvent, showModal]);

  const handleClose = () => setShowModal(false);

  return { showModal, handleClose };
};

export { useShowPin };

