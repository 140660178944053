import {
  KAnchor,
  KButton,
  KFormUser,
  KMdTextSecondaryBase,
  KSemiText700,
  KText,
  RedemptionWrapper,
} from "@components";
import { breakpointLarge } from "@constants/size";
import { useKContext } from "@context";
import { verifyEmployee, verifyEmployerNickname } from "@services";
import { IEmployee } from "@types";
import { Divider, Flex, Form } from "antd";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useIntercom } from "react-use-intercom";
import styled from "styled-components";

interface IProps {
  employerNickname: null | string;
  setCode: (val: string) => void;
}

export const ImageContainer = styled(Flex)`
  @media (max-width: ${breakpointLarge}px) {
    max-width: 100%;
  }
`;

export const RedemptionVerify = ({ setCode, employerNickname }: IProps) => {
  const { isMobile } = useKContext();
  const [info, setInfo] =
    useState<Pick<IEmployee, "employerId" | "employerName">>();
  const { show, boot, hardShutdown } = useIntercom();

  const methods = useForm<IEmployee>({
    mode: "onTouched",
    defaultValues: { employerNickname: "" },
  });

  const {
    getValues,
    reset,
    setValue,
    formState: { isDirty, isSubmitting },
    setError,
    handleSubmit,
    watch,
  } = methods;

  const transformInput = (value: string) =>
    value.toUpperCase().replace(/\s+/g, "");

  const handleVerifyEmployerNickname = async (employerNickname: string) => {
    try {
      const res = await verifyEmployerNickname(employerNickname.toUpperCase());
      setInfo(res);
    } catch (err: any) {
      setValue("employerNickname", employerNickname.toUpperCase());
      const { message: messageError } = err?.response?.data || {};
      setError("employerNickname", {
        type: "manual",
        message: messageError || "Verification failed",
      });
    }
  };

  useEffect(() => {
    hardShutdown();
    if (!employerNickname) return;
    handleVerifyEmployerNickname(employerNickname);
  }, [employerNickname]);

  useEffect(() => {
    setValue("employerNickname", transformInput(watch("employerNickname")));
  }, [watch("employerNickname")]);

  const onSubmit = async () => {
    try {
      const data = getValues();
      if (data && info) {
        const res = await verifyEmployee({
          employeeInfo: data.employeeInfo,
          employerId: info.employerId,
        });
        setCode(res.data);
      } else {
        const res = await verifyEmployerNickname(
          data.employerNickname.toUpperCase()
        );
        setInfo(res);
      }
      reset();
    } catch (err: any) {
      const { message: messageError } = err?.response?.data || {};
      if (info) {
        setError("employeeInfo", {
          type: "manual",
          message: messageError || "Verification failed",
        });
      } else {
        setError("employerNickname", {
          type: "manual",
          message: messageError || "Verification failed",
        });
      }
    }
  };

  const onOpenIntercom = () => {
    boot();
    show();
  };

  return (
    <RedemptionWrapper>
      <Flex
        style={{
          padding: isMobile ? 16 : 32,
        }}
        align="center"
        justify="space-between"
        gap={32}
        vertical={isMobile}
      >
        <Flex gap={16} flex={1} vertical>
          <KText size="xxxl" color="Secondary88" fontWeight="700">
            Welcome {info && `, ${info.employerName} Member`}! 👋
          </KText>
          <KSemiText700>
            Let’s retrieve your promotional offer code for KinKeeper Premium.
            Make sure you have your organization nickname and unique ID handy!
          </KSemiText700>
          <FormProvider {...methods}>
            {info ? (
              <Form>
                <KFormUser.Input
                  label="Enter your unique ID:"
                  name="employeeInfo"
                  placeholder="Enter unique ID"
                />
              </Form>
            ) : (
              <Form>
                <KFormUser.Input
                  label="Enter your organization nickname:"
                  name="employerNickname"
                  placeholder="Enter nickname"
                />
              </Form>
            )}
          </FormProvider>
          <KButton
            title="Submit"
            onClick={handleSubmit(onSubmit)}
            disabled={!isDirty}
            loading={isSubmitting}
          />
          <Divider />
          <KMdTextSecondaryBase>
            Don’t have this information? Reach out to your organization or{" "}
            <KAnchor $isUnderlined $verticalAlign="unset" onClick={onOpenIntercom}>
              contact us
            </KAnchor>{" "}
            for assistance.
          </KMdTextSecondaryBase>
        </Flex>
        <ImageContainer>
          <img
            src={require("../../../assets/imgs/welcome_redemption_kinkeeper.png")}
            alt=""
            style={{ width: "100%" }}
          />
        </ImageContainer>
      </Flex>
    </RedemptionWrapper>
  );
};
