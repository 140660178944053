import { LeftOutlined } from "@ant-design/icons";
import { EmergencyMedicalIcon, KinKeeperLogo } from "@assets/index";
import { getAppUrl } from "@constants/url";
import { KContext } from "@context";
import { useCustomNavigate } from "@hooks";
import { Color, EFontSize, ESpacing, EUrlPath, IParams } from "@types";
import { getToken } from "@utils/storage";
import { Flex } from "antd";
import { FC, useCallback, useContext, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  KButton,
  KCardMeatBall,
  KLeftMenu,
  KProfileSelector,
  KXlText700ErrorBase,
} from "..";
import { SearchInfoType } from "./SearchInfoType";
import {
  BadgeMenu,
  HamburgerMenu,
  HeaderBreadcrumbWrapper,
  HeaderButton,
  HeaderContainer,
  HeaderLogo,
} from "./styles";
import { useUser } from "@store";

export const KHeader: FC = () => {
  const {
    isViewingProfile,
    isMobile,
    showErrorResult,
    arrayPath = [],
  } = useContext(KContext);
  const { cardId, tempShareId, profileId, cardType } =
    useParams<IParams>();
  const { pathname } = useLocation();
  const { navigate } = useCustomNavigate();
  const { notificationCount } = useUser();
  const [openMenu, setOpenMenu] = useState(false);

  const isAssistanceScreen = useMemo(() => (
    [EUrlPath.OVERVIEW, EUrlPath.ACCOUNT_SETTINGS].includes(arrayPath[3] as EUrlPath) || isViewingProfile
  ), [arrayPath, isViewingProfile]);

  const isShowBackButton = useMemo(() => {
    return isMobile && (cardType || isAssistanceScreen || arrayPath.length > 3);
  }, [isMobile, cardType, isAssistanceScreen, arrayPath]);

  const handleBackNavigation = useCallback(() => {
    const currentPath = pathname;
    const pathSegments = currentPath.split("/").filter(Boolean);
    if (pathSegments.length > 1) {
      pathSegments.pop();
      const newPath = "/" + pathSegments.join("/");
      navigate(newPath);
    };
  }, [pathname, navigate]);

  const renderBackButton = useMemo(() => {
    return cardId || isAssistanceScreen ? (
      <KButton type="link" title="Done" onClick={handleBackNavigation} />
    ) : (
      <LeftOutlined
        color={Color.SecondaryBase}
        style={{ fontSize: EFontSize.xl, padding: ESpacing.md }}
        onClick={handleBackNavigation}
      />
    );
  }, [cardId, isAssistanceScreen, handleBackNavigation]);

  const showDrawer = () => {
    const haveToken = 
      getToken('TempShare') || 
      getToken('WebApp') || 
      getToken('EmergencyAccess');
    if (haveToken && !showErrorResult) {
      setOpenMenu(!openMenu);
    };
  };

  return (
    <HeaderContainer>
      <Flex wrap="wrap" gap="small" align="center">
        {isShowBackButton ? (
          renderBackButton
        ) : (
          <BadgeMenu dot={+notificationCount > 0} offset={[-10, 11]}>
            {!pathname.includes(EUrlPath.EMPLOYEE_REDEMPTION) && (
              <HamburgerMenu color={Color.SecondaryBase} onClick={showDrawer} />
            )}
          </BadgeMenu>
        )}
        <HeaderBreadcrumbWrapper>
          <KProfileSelector />
        </HeaderBreadcrumbWrapper>
        <HeaderLogo>
          <KinKeeperLogo style={{ marginTop: "5px" }} />
        </HeaderLogo>
      </Flex>
      {tempShareId && (
        <HeaderButton
          ghost
          type="primary"
          title="Create your own KinKeeper"
          href={getAppUrl}
          target="_blank"
        />
      )}
      {arrayPath?.[1] === EUrlPath.EMERGENCY_ACCESS &&
        (isMobile ? (
          <EmergencyMedicalIcon />
        ) : (
          <Flex gap={32} align="center">
            <EmergencyMedicalIcon />
            <KXlText700ErrorBase>Emergency Access</KXlText700ErrorBase>
            <EmergencyMedicalIcon />
          </Flex>
        ))}
      <Flex flex={1} gap={4} justify="end">
        {profileId && <SearchInfoType />}
        {isMobile && profileId && <KCardMeatBall type="profile" />}
      </Flex>
      <KLeftMenu isShow={openMenu} onClose={showDrawer} />
    </HeaderContainer>
  );
};

export * from "./styles"
