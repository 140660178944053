import { DoubleCaretIcon } from "@assets/index";
import { KAvatar, KMdText, KMdTextSecondary88 } from "@components";
import { getProfilesTemporary } from "@services";
import { useProfile, useSwitchProfiles } from "@store";
import {
  IParams,
  IProfilesSelectRes
} from "@types";
import { getToken, truncateName, truncateText } from "@utils/index";
import { Flex, Popover, Space } from "antd";
import { EAvatarSize } from "components/enums";
import { FC, useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { KContext } from "../../context";
import { BlankSpace, CheckOutlinedStyled, ItemProfile, ItemProfileWrapper } from "./style";
import { useCustomNavigate } from "@hooks";

export const KProfileSelector: FC = () => {
  const { isValidToken, isSwitchToProfile, isMobile, setShowErrorResult } = useContext(KContext);
  const { setProfileSelected, profileSelected, profile } = useProfile();
  const { setOpenSwitch } = useSwitchProfiles();
  const [allProfiles, setAllProfiles] = useState<IProfilesSelectRes[]>();
  const [isOpenProfiles, setIsOpenProfiles] = useState(false);
  const { tempShareId, profileId, emergencyAccessId } = useParams<IParams>();
  const { navigate } = useCustomNavigate();

  const { firstName = "", lastName = "" } = profileSelected?.profile.basicInformation || profile?.basicInformation || {};

  const avatarUrl = useMemo(() => (
    profileSelected?.avatar || profile?.avatar
  ), [profileSelected?.avatar, profile?.avatar]);

  useEffect(() => {
    const token = getToken('TempShare');
    if (!token || !isValidToken || !tempShareId) return;
    (async () => {
      try {
        const { data }: { data: IProfilesSelectRes[] } =
          await getProfilesTemporary();
        setAllProfiles(data);
        setProfileSelected(data.find(i => i.temporaryShareId === tempShareId) || null);
        if (!data.length && tempShareId) {
          setShowErrorResult("info");
        }
        if (isSwitchToProfile && data.length > 0) {
          navigate(data[0]._id);
        }
      } catch (error) {
        console.log("log-error --- ", error);
      }
    })();
  }, [isValidToken, isSwitchToProfile]);

  const handleSelectProfile = (id: string) => {
    navigate(id);
    setIsOpenProfiles(false);
  };

  const content = useMemo(() => {
    return allProfiles?.map((prof) => {
      const { firstName, lastName } = prof.profile.basicInformation;
      const isActive = prof._id === tempShareId;
      return (
        <ItemProfileWrapper
          $isActive={isActive}
          key={prof._id}
          onClick={() => handleSelectProfile(prof._id)}
        >
          <ItemProfile>
            <KAvatar
              firstName={firstName}
              lastName={lastName}
              size={EAvatarSize.small}
              {...(prof.avatar && { imgUrl: prof.avatar })}
            />
            <KMdText>{truncateName(firstName, lastName)}</KMdText>
          </ItemProfile>
          {isActive ? <CheckOutlinedStyled /> : <BlankSpace />}
        </ItemProfileWrapper>
      );
    });
  }, [allProfiles, tempShareId]);

  return (profileSelected?.profile || profile) ? (
    <>
      <Popover
        placement={isMobile ? "bottom" : "bottomRight"}
        content={content}
        trigger="click"
        onOpenChange={(open) => tempShareId && setIsOpenProfiles(open)}
        open={isOpenProfiles}
      >
        <Flex
          align="center"
          style={{ cursor: "pointer" }}
          onClick={() => profileId && !emergencyAccessId && setOpenSwitch(true)}
        >
          <Space size={4}>
            <KAvatar
              firstName={firstName}
              lastName={lastName}
              size={EAvatarSize.small}
              {...(avatarUrl && {
                imgUrl: avatarUrl,
              })}
            />
            {firstName && (
              <KMdTextSecondary88>
                {truncateText(firstName, 10)}
              </KMdTextSecondary88>
            )}
          </Space>
          {!emergencyAccessId && (
            <DoubleCaretIcon style={{ fontSize: "16px" }} />
          )}
        </Flex>
      </Popover>
    </>
  ) : null;
};
