import { PaperClipOutlined } from "@ant-design/icons";
import { Flex } from "antd";
import { useContext, useMemo } from "react";
import { useParams } from "react-router-dom";

import {
  CardItemStyled,
  CardTitle,
  KMdTextSecondary65,
  KTag
} from "@components";
import { KContext } from "@context";
import { ECardType, EDateTimeFormat, ICardIdCardRes, IItemCardProps, IParams } from "@types";
import { formatTimeRangeCard } from "@utils/date";
import { getInfoCard } from "@utils/index";
import { CardItemContainer } from "..";
import { useCustomNavigate } from "@hooks";

export const IdCardItem = ({ card, showIcon, showMetadata, isDetail }: IItemCardProps<ICardIdCardRes>) => {
  const { navigate } = useCustomNavigate();
  const { _id, title, type, attachments, startTime, endTime, cardType } = card;
  const { setCardTypeSelected, navigateDetailCard } = useContext(KContext);
  const { cardId } = useParams<IParams>();
  const iconCardType = showIcon && getInfoCard(cardType).icon;

  const dateRange = useMemo(() => {
    return formatTimeRangeCard(
      startTime,
      endTime,
      EDateTimeFormat.FULL_MONTH_YEAR
    );
  }, [startTime, endTime]);

  const onClickItem = () => {
    if (cardId === _id) return;
    if (!isDetail) {
      return navigate(
        _id,
        undefined,
        () => { setCardTypeSelected(ECardType.IdCard); },
      );
    }
    navigateDetailCard(ECardType.IdCard, _id);
  };

  return (
    <CardItemStyled $isActive={cardId === _id} onClick={onClickItem}>
      <CardItemContainer showMetadata={showMetadata} card={card}>
        <Flex justify="space-between" align="center" gap={4} flex={1}>
          <CardTitle icon={iconCardType}>{title}</CardTitle>
          {type && <KTag color="">{type}</KTag>}
        </Flex>
        {dateRange && (
          <KMdTextSecondary65 $ellipsis numberOfLines={2}>
            {dateRange}
          </KMdTextSecondary65>
        )}
        {attachments.length > 0 && (
          <Flex gap={4} wrap="wrap">
            {attachments.map((atm) => (
              <KTag key={atm._id} icon={<PaperClipOutlined />}>
                {atm.originalName}
              </KTag>
            ))}
          </Flex>
        )}
      </CardItemContainer>
    </CardItemStyled>
  );
};
