const heightHeader = 72;
const heightHeaderMobile = 45;
const heightFooter = 66;
const widthBreadcrumb = 234;
const heightBreadcrumb = 56;
const breakpointLarge = 1024;
const heightCardItemInfo = 40;
const heightCardItem = 70;
const sizeIconFileUpload = "64px";
const widthPercentCard = 28.5;
const widthPercentCardLg = 100 - widthPercentCard * 2;
const widthPercentProfileBasic = 70;
const widthTabsAndCard = 382;
const heightAvatarProfileLarge = 64;
const heightAvatarProfileSmall = 24;
const heightAvatarProfileXLarge = 160;
const widthTab = `${widthTabsAndCard / 3}px`;
const sizeAttachment = 116;
const byteToGB = 1073741824;

export {
    heightHeader,
    heightHeaderMobile,
    widthBreadcrumb,
    heightBreadcrumb,
    breakpointLarge,
    heightFooter,
    widthPercentCard,
    widthPercentCardLg,
    widthPercentProfileBasic,
    heightAvatarProfileLarge,
    heightAvatarProfileSmall,
    heightAvatarProfileXLarge,
    heightCardItemInfo,
    heightCardItem,
    widthTabsAndCard,
    sizeIconFileUpload,
    widthTab,
    sizeAttachment,
    byteToGB
}