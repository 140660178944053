import { CameraFilled, UserOutlined } from "@ant-design/icons";
import { uploadAvatarUserService } from "@services";
import { useUser } from "@store";
import { IParams } from "@types";
import { isImageFile } from "@utils/files";
import { Upload, UploadFile, message } from "antd";
import { UploadChangeParam } from "antd/es/upload";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { ContainerAvatar, CustomAvatar, CustomBtnAvatar } from "./style";

export const AvatarAccount = () => {
  const { profileId } = useParams<IParams>();
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const { userInfo, setUserInfo } = useUser();

  const handleUploadAvatar = async (file: UploadChangeParam<UploadFile>) => {
    if (!profileId || !file?.file?.originFileObj || !userInfo) return;
    const uploadedFile = file.file.originFileObj;
    if (!isImageFile(uploadedFile)) {
      message.error("Invalid file type. Please upload an image.");
      return;
    }
    try {
      setIsLoadingBtn(true);
      const { url } = await uploadAvatarUserService(uploadedFile);
      setUserInfo({ ...userInfo, avatar: url });
      message.success("Update avatar successfully!");
    } catch (err: any) {
      message.error(err?.response?.data?.message);
    } finally {
      setIsLoadingBtn(false);
    }
  };

  return userInfo ? (
    <ContainerAvatar>
      <CustomAvatar src={userInfo.avatar} icon={<UserOutlined />} size={120}/>
      <Upload
        onChange={handleUploadAvatar}
        customRequest={(op) => false}
        maxCount={1}
        multiple={false}
        showUploadList={false}
      >
        <CustomBtnAvatar
          icon={<CameraFilled />}
          loading={isLoadingBtn}
          size="large"
        />
      </Upload>
    </ContainerAvatar>
  ) : null;
};
