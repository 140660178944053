import { ThemeConfig } from "antd";

import { Color, EFontSize, ELineHeight } from "@types";

export const theme: ThemeConfig = {
  token: {
    fontFamily: "'Figtree', sans-serif",
    colorPrimary: Color.PrimaryBase,
    colorBgContainerDisabled: Color.Secondary04,
    colorBorder: Color.Secondary10,
    colorTextDisabled: Color.Secondary65,
  },
  components: {
    Modal: {
      padding: 32,
    },
    Divider: {
      colorSplit: Color.Secondary06,
      marginLG: 0,
      margin: 0,
    },
    Typography: {
      "fontFamilyCode": "'Figtree'",
      "fontWeightStrong": 700,
      "fontSize": 15,
      "fontSizeHeading5": 13,
      "fontSizeHeading4": 17,
      "fontSizeHeading3": 20,
      "fontSizeHeading2": 24,
      "fontSizeHeading1": 28,
      "colorText": "rgba(22, 25, 37, 0.88)",
      "titleMarginBottom": "0"
    },
    Input: {
      fontSize: EFontSize.md,
      sizeLG: EFontSize.semi,
    },
    Button: {
      colorLink: Color.PrimaryBase,
      fontSize: EFontSize.md,
      fontSizeLG: EFontSize.lg,
      lineHeightLG: EFontSize.lg/10,
      colorIcon: Color.PrimaryBase,
      lineHeight: ELineHeight.md/10,
    },
    Checkbox: {
      colorBorder: Color.Secondary50,
    },
    Select: {
      padding: 4,
      boxShadow: "none",
    },
    Badge: {
      dotSize: 8,
      colorError: Color.Magenta,
    }
  }
}