import styled from "styled-components";
import { CalendarOutlined } from "@ant-design/icons";
import { Color } from "../../types";
import { styledInputIcon } from "../Input/styles";
import { DatePicker } from "antd";
import { Moment } from "moment";
import momentGenerateConfig from "rc-picker/lib/generate/moment";

const IconDatePicker = styled(CalendarOutlined)`
  color: ${Color.SecondaryBase};
  font-size: 16px;
  margin: 0px;
`;

const StyledDatePicker = styled.div`
  width: 80%;
  overflow: hidden;
`;

const StyledDatePickerInput = styled(DatePicker.generatePicker<Moment>(momentGenerateConfig))`
  ${styledInputIcon}
`

export { IconDatePicker, StyledDatePicker, StyledDatePickerInput };