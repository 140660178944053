import { Color } from "../../types";
import { IconProps } from "./types";

export const FileWordIcon: React.FC<IconProps> = ({
  style,
  width = 64,
  height,
  color = Color.SecondaryBase,
}) => {
  return (
    <svg
      style={style}
      width={width}
      height={height || width}
      fill={color}
      viewBox={"0 0 32 32"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.7062 9.01875L19.9813 2.29375C19.7938 2.10625 19.5406 2 19.275 2H6C5.44687 2 5 2.44687 5 3V29C5 29.5531 5.44687 30 6 30H26C26.5531 30 27 29.5531 27 29V9.72812C27 9.4625 26.8937 9.20625 26.7062 9.01875ZM24.6938 10.1875H18.8125V4.30625L24.6938 10.1875ZM24.75 27.75H7.25V4.25H16.6875V11C16.6875 11.3481 16.8258 11.6819 17.0719 11.9281C17.3181 12.1742 17.6519 12.3125 18 12.3125H24.75V27.75ZM16.5031 14.75H15.4969C15.325 14.75 15.175 14.8656 15.1344 15.0344L13.5813 21.25L12.1406 15.0406C12.1 14.8719 11.95 14.75 11.775 14.75H10.6687C10.6113 14.7501 10.5546 14.7634 10.503 14.7888C10.4515 14.8143 10.4065 14.8512 10.3715 14.8968C10.3364 14.9424 10.3124 14.9954 10.301 15.0518C10.2897 15.1081 10.2915 15.1663 10.3062 15.2219L12.625 23.8469C12.6687 24.0094 12.8188 24.125 12.9875 24.125H13.9875C14.1562 24.125 14.3063 24.0125 14.35 23.8469L16 17.6906L17.65 23.8469C17.6937 24.0094 17.8438 24.125 18.0125 24.125H19.0063C19.175 24.125 19.325 24.0125 19.3687 23.8469L21.6938 15.2219C21.7083 15.1663 21.71 15.1082 21.6987 15.0519C21.6873 14.9955 21.6632 14.9426 21.6282 14.897C21.5932 14.8515 21.5483 14.8145 21.4968 14.7891C21.4453 14.7636 21.3887 14.7502 21.3312 14.75H20.2188C20.0437 14.75 19.8937 14.8719 19.8531 15.0406L18.4219 21.2625L16.8656 15.0344C16.825 14.8656 16.675 14.75 16.5031 14.75Z"
        fill="#161925"
      />
    </svg>
  );
};