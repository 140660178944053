import { Color, EBorderRadius, EFontSize, ESpacing } from "@types";
import { Flex, Modal } from "antd";
import styled from "styled-components";

export const StyledContainerUpgrade = styled(Flex)({
  alignItems: "center",
  padding: `${ESpacing.lg} 12px 12px 12px`,
  borderRadius: `0px 0px ${EBorderRadius.xl} ${EBorderRadius.xl}`,
  backgroundColor: "white",
});

export const BorderLinearGradient = styled.div({
  width: 800,
  background: `linear-gradient(to bottom right, ${Color.BgOrangeAmber}, ${Color.BgOrangeBrown})`,
  borderRadius: EBorderRadius.xl,
  padding: 1,
  overflow: "hidden",
  div: {
    backgroundColor: "white",
    borderRadius: EBorderRadius.xl,
  },
});

export const StyledModalUpgrade = styled(Modal)({
  ".ant-modal-content": {
    background: `linear-gradient(to bottom right, ${Color.BgOrangeAmber}, ${Color.BgOrangeBrown})`,
    borderRadius: EBorderRadius.xl,
    padding: 1,
  },
  ".ant-modal-close": {
    color: Color.SecondaryBase,
    fontSize: EFontSize.semi,
  },
  ".ant-modal-header": {
    margin: 0,
    borderRadius: `${EBorderRadius.xl} ${EBorderRadius.xl} 0px 0px;`,
  },
});

export const StyledTitleModalUpgrade = styled(Flex)({
  padding: "12px 12px 0 12px",
  borderRadius: EBorderRadius.xl,
  justifyContent: "space-between",
  alignItems: "center",
});