import { KLgText700, KSmTextSecondary65 } from "@components";
import { IMedicalRecordImport, ISourceFTH } from "@types";
import { generateHostUrl } from "@utils/url";
import { Flex, FlexProps } from "antd";
import { EMRAvatar } from "./EMRAvatar";
import { useMemo } from "react";

interface IEMRSourceItem extends Omit<FlexProps, "children"> {
  isMedicalRecord?: boolean;
  source?: IMedicalRecordImport | ISourceFTH;
  onClickSource?: (data: ISourceFTH) => void;
}

export const EMRSourceItem = ({
  source,
  onClickSource,
  isMedicalRecord = false,
  ...props
}: IEMRSourceItem) => {
  const { brandId, sourceName, brandWebsite } = useMemo(() => {
    let newSource;
    if (isMedicalRecord) {
      newSource = source as IMedicalRecordImport;
      return {
        brandId: newSource.brandId,
        sourceName: newSource.sourceName,
        brandWebsite: newSource.brandWebsite,
      };
    }
    newSource = source as ISourceFTH;
    return {
      brandId: newSource.id,
      sourceName: newSource.name,
      brandWebsite: newSource.brand_website,
    };
  }, [isMedicalRecord, source]);

  return (
    <Flex
      gap={16}
      justify="center"
      align="center"
      style={{ cursor: "pointer", minHeight: "46px" }}
      {...(source && onClickSource && { onClick: () => onClickSource(source as ISourceFTH) })}
      {...props}
    >
      <EMRAvatar brandId={brandId} name={sourceName} />
      <Flex vertical justify="space-between" flex={1}>
        <KLgText700 $ellipsis>{sourceName}</KLgText700>
        {brandWebsite && (
          <KSmTextSecondary65 $ellipsis>
            {generateHostUrl(brandWebsite)}
          </KSmTextSecondary65>
        )}
      </Flex>
    </Flex>
  );
};
