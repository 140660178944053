import { TagProps } from "antd";
import { useMemo } from "react";
import { AllergySeverity } from "../../types";
import { StyledTag } from "./styles";

interface KTagProps extends TagProps {
  $active?: boolean; 
}

export const KTag: React.FC<KTagProps> = ({ children, color, icon, ...props }) => {
  const severityColor = useMemo(() => {
    switch (children) {
      case AllergySeverity.MILD:
        return "gold";
      case AllergySeverity.MODERATE:
        return "purple";
      case AllergySeverity.SEVERE:
        return "red";
      default:
        return "";
    }
  }, [children]);

  return (
    <StyledTag
      color={severityColor || color}
      icon={icon}
      {...props}
    >
      {children}
    </StyledTag>
  );
};

export * from "./CardTypeTag"