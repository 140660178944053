import { create } from "zustand";

type State = {
  openUpgradeModal: boolean;
  setOpenUpgradeModal: (val: boolean) => void;
};

export const useGlobalModals = create<State>((set) => ({
  openUpgradeModal: false,
  setOpenUpgradeModal: (val) => set({ openUpgradeModal: val }),
}));
