import { Color } from "../../types";
import { IconProps } from "./types";

export const FileTxtIcon: React.FC<IconProps> = ({
  style,
  width = 64,
  height,
  color = Color.SecondaryBase,
}) => {
  return (
    <svg
      style={style}
      width={width}
      height={height || width}
      fill={color}
      viewBox={"0 0 32 32"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9813 2.29375L26.7062 9.01875C26.8937 9.20625 27 9.4625 27 9.72812V29C27 29.5531 26.5531 30 26 30H6C5.44687 30 5 29.5531 5 29V3C5 2.44687 5.44687 2 6 2H19.275C19.5406 2 19.7938 2.10625 19.9813 2.29375ZM18.8125 10.1875H24.6938L18.8125 4.30625V10.1875ZM7.25 27.75H24.75V12.3125H18C17.6519 12.3125 17.3181 12.1742 17.0719 11.9281C16.8258 11.6819 16.6875 11.3481 16.6875 11V4.25H7.25V27.75ZM15.0227 17.1V24H16.6667V17.1H19.1747V15.6H12.5027V17.1H15.0227Z"
        fill="#161925"
      />
    </svg>
  );
};