import { useKContext } from "@context";
import { Color } from "@types";
import { Progress, ProgressProps } from "antd";
import styled from "styled-components";

const StyledProgressStep = styled(Progress)({
  "&.ant-progress .ant-progress-steps-item": {
    flex: 1,
  },
});

export const EMRProgress = ({ percent = 33 }: ProgressProps) => {
  return (
    <StyledProgressStep
      steps={3}
      percent={percent}
      strokeColor={Color.PrimaryBase}
    />
  );
};
