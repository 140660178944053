import styled from "styled-components";
import { Color, EFontSize, ELineHeight } from "../../types";
import { Flex, Input } from "antd";

const inputStyles = `
  width: 100%;
  font-weight: 500;
  font-size: ${EFontSize.md}px;
  line-height: ${ELineHeight.md}px;
  &:disabled {
    cursor: default;
  };
`;

const StyledKTextarea = styled(Input.TextArea)`
  ${inputStyles}
  min-height: 54px;
  border-radius: 6px;
  padding: 5px 12px;
  resize: none;
  overflow-y: hidden;
`;

const styledInputIcon = `
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  justify-content: space-between;
  line-height: ${ELineHeight.md}px;
  font-size: ${EFontSize.md}px;
  border-radius: 6px;
`;

const styledInputIconWithSelect = `
  width: 100%;
  max-width: 68px;
  height: 32px;
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: space-between;
  line-height: ${ELineHeight.md}px;
  font-size: ${EFontSize.md}px;
  color: ${Color.Secondary65};
  background-color: ${Color.Secondary04};
  border-radius: 6px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`;

const StyledKInputSelect = styled(Flex)`
  align-items: center;
`;

const TextEditorStyled = styled.div`
  ${inputStyles}
  cursor: default;
  border-radius: 6px;
  border: 1px solid ${Color.Secondary10};
  min-height: 32px;
  padding: 8px 12px;
  background-color: ${Color.Secondary04};
  overflow-wrap: break-word;
  ol, ul {
    list-style-position: inside;
  }
  i, span {
    overflow-wrap: break-word;
  }
`;

export {
  StyledKTextarea,
  styledInputIcon,
  StyledKInputSelect,
  styledInputIconWithSelect,
  TextEditorStyled,
};