import {
  AllergyInfoTypeIcon,
  AppointmentInfoTypeIcon,
  ConditionInfoTypeIcon,
  FileInfoTypeIcon,
  HospitalizationInfoTypeIcon,
  IdCardInfoTypeIcon,
  MedicationInfoTypeIcon,
  NoteInfoTypeIcon,
  PractitionerInfoTypeIcon,
  ProcedureInfoTypeIcon,
  QuestionInfoTypeIcon,
  VaccinationInfoTypeIcon,
  PaperClipOutlinedIcon,
  TaskInfoTypeIcon
  }
from "@assets/icons";
import
{ ECardType } from "@types";

export const infoCardTypes = [
  {
    title: 'Allergies',
    icon: <AllergyInfoTypeIcon />,
    cardType: ECardType.Allergies,
    amount: 0,
  },
  {
    title: 'Appointments',
    icon: <AppointmentInfoTypeIcon />,
    cardType: ECardType.Appointments,
    amount: 0,
  },
  {
    title: 'Conditions',
    icon: <ConditionInfoTypeIcon />,
    cardType: ECardType.Conditions,
    amount: 0,
  },
  {
    title: 'Files',
    icon: <FileInfoTypeIcon />,
    cardType: ECardType.Files,
    amount: 0,
  },
  {
    title: 'Hospitalizations',
    icon: <HospitalizationInfoTypeIcon />,
    cardType: ECardType.Hospitalizations,
    amount: 0,
  },
  {
    title: 'ID Cards',
    icon: <IdCardInfoTypeIcon />,
    cardType: ECardType.IdCard,
    amount: 0,
  },
  {
    title: 'Medications',
    icon: <MedicationInfoTypeIcon />,
    cardType: ECardType.Medications,
    amount: 0,
  },
  {
    title: 'Notes',
    icon: <NoteInfoTypeIcon />,
    cardType: ECardType.Notes,
    amount: 0,
  },
  {
    title: 'Practitioners',
    icon: <PractitionerInfoTypeIcon />,
    cardType: ECardType.Practitioners,
    amount: 0,
  },
  {
    title: 'Procedures',
    icon: <ProcedureInfoTypeIcon />,
    cardType: ECardType.Procedures,
    amount: 0,
  },
  {
    title: 'Questions',
    icon: <QuestionInfoTypeIcon />,
    cardType: ECardType.Questions,
    amount: 0,
  },
  {
    title: 'To-Dos',
    icon: <TaskInfoTypeIcon />,
    cardType: ECardType.ToDos,
    amount: 0,
  },
  {
    title: 'Vaccinations',
    icon: <VaccinationInfoTypeIcon />,
    cardType: ECardType.Vaccinations,
    amount: 0,
  },
  {
    title: 'Attachments',
    icon: <PaperClipOutlinedIcon />,
    cardType: ECardType.Attachments,
    amount: 0,
  },
];
