import { createGlobalStyle } from "styled-components";
import RegularFont from "../../assets/fonts/Figtree-Regular.ttf";
import MediumFont from "../../assets/fonts/Figtree-Medium.ttf";
import BoldFont from "../../assets/fonts/Figtree-Bold.ttf";
import { ESpacing } from "@types";

const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'Figtree';
        src: url(${RegularFont}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Figtree';
        src: url(${MediumFont}) format('truetype');
        font-weight: 500;
        font-style: normal;
    }

    @font-face {
        font-family: 'Figtree';
        src: url(${BoldFont}) format('truetype');
        font-weight: 700;
        font-style: normal;
    }

    @font-face {
        font-family: 'Figtree';
        src: url(${BoldFont}) format('truetype');
        font-weight: bold;
        font-style: normal;
    }

    * { 
        font-family: 'Figtree', sans-serif;
        box-sizing: border-box;
        font-weight: 500;
        margin: 0;
        padding: 0;
    }

    .ant-popover .ant-popover-inner {
        padding: ${ESpacing.sm};
    }

    .ant-result.ant-result-403 {
        background: #fff;
        & .ant-result-extra {
            display: flex;
            justify-content: center;
        }
    }
`;

export default GlobalStyle;
