import { Flex } from "antd";
import {
  breakpointLarge,
  heightHeaderMobile,
  widthPercentCard,
  widthPercentCardLg,
} from "@constants/size";
import styled from "styled-components";
import { CardItemProps, CardWrapperProps } from "./types";
import { Color, EBorderRadius, ESpacing } from "@types";

const CardWrapper = styled(Flex)<CardWrapperProps>`
  height: 100%;
  padding: ${({ $paddingSize = "xl" }) => ESpacing[$paddingSize]};
  background: ${Color.White};
  border-right: 1px solid ${Color.Secondary10};
  width: ${({ $size }) => $size === 'lg' ? widthPercentCardLg : widthPercentCard}%;
  overflow: hidden;
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
  @media (max-width: ${breakpointLarge}px) {
    width: 100%;
    border-right: none;
    min-height: calc(100vh - ${heightHeaderMobile}px);
  }
`;

const ContentWrapper = styled(Flex)`
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  flex-direction: column;
`;

const CardItemStyled = styled(Flex)<CardItemProps>`
  flex-direction: column;
  width: 100%;
  height: fit-content;
  text-align: start;
  padding: 12px;
  margin: 0;
  cursor: ${({ $showCheckBox }) => $showCheckBox ? 'pointer' : 'default'};
  border-radius: ${EBorderRadius.lg};
  border-radius: ${({ $borderRadius = 8 }) => $borderRadius}px;
  background-color: ${({ $isActive }) =>
    $isActive ? Color.BgPrimary : Color.White};
  border-color: ${({ $isActive }) =>
    $isActive ? Color.PrimaryBase : Color.Secondary10};
  border-style: solid;
  border-width: ${({ $showBorder = true }) => $showBorder ? `1px` : `0`};
`;

export { CardWrapper, CardItemStyled, ContentWrapper };
