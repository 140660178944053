import { FC } from 'react';
import { IKTextProps } from '../../types';
import { KText } from './default';

const KLgText: FC<IKTextProps> = ({ children, ...props }) => (
  <KText { ...props } size="lg">
    {children}
  </KText>
);

const KLgText700 = ({ children, ...props }: IKTextProps) => (
  <KLgText fontWeight="700" { ...props }>
    {children}
  </KLgText>
);

const KLgTextSecondary50 = ({ children, ...props }: IKTextProps) => (
  <KLgText { ...props } color="Secondary50">
    {children}
  </KLgText>
);

const KLgTextSecondary88 = ({ children, ...props }: IKTextProps) => (
  <KLgText { ...props } color="Secondary88">
    {children}
  </KLgText>
);

const KLgText700Secondary65 = ({ children, ...props }: IKTextProps) => (
  <KLgText { ...props } color="Secondary65" fontWeight="700">
    {children}
  </KLgText>
);

const KLgText700Secondary88 = ({ children, ...props }: IKTextProps) => (
  <KLgText { ...props } color="Secondary88" fontWeight="700">
    {children}
  </KLgText>
);

const KLgText700Secondary50 = ({ children, ...props }: IKTextProps) => (
  <KLgText { ...props } fontWeight="700" color="Secondary50">
    {children}
  </KLgText>
);

const KLgText700PrimaryBase = ({ children, ...props }: IKTextProps) => (
  <KLgText { ...props } fontWeight="700" color="PrimaryBase">
    {children}
  </KLgText>
);

export {
  KLgText,
  KLgText700,
  KLgTextSecondary50,
  KLgTextSecondary88,
  KLgText700Secondary65,
  KLgText700Secondary88,
  KLgText700Secondary50,
  KLgText700PrimaryBase,
};