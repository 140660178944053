export const formatPhoneNumber = (input?: string) => {
  const cleaned = ('' + input).replace(/[^0-9]/g, '');
  const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
  if (match) {
    const formatted = !match[2]
      ? match[1]
      :  match[1] + '-' + match[2] + (match[3] ? '-' + match[3] : '');
    return formatted;
  } else {
    return input;
  }
};

export const formatSNN = (input: string) => {
  const cleaned = ('' + input).replace(/[^0-9]/g, '');
  const match = cleaned.match(/^(\d{0,3})(\d{0,2})(\d{0,4})$/);
  if (match) {
    const formatted = !match[2]
      ? match[1]
      :  match[1] + '-' + match[2] + (match[3] ? '-' + match[3] : '');
    return formatted;
  } else {
    return input;
  }
};

export const truncateName = (firstName = "", lastName = "", maxLength = 15) => {
  const truncatedFirstName = (firstName?.substring(0, maxLength) || firstName || '') + (firstName?.length > maxLength ? '...' : '');
  const formattedLastName = lastName?.charAt(0) ? `${lastName?.charAt(0)}.` : '';
  return `${truncatedFirstName} ${formattedLastName}`;
};