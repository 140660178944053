import { FC, SVGProps } from "react";
import { IconProps } from "./types";

export const IdCardInfoTypeIcon: FC<IconProps> = ({
  width = 24,
  height,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height || width}
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M22.25 3H1.75C1.33516 3 1 3.33516 1 3.75V20.75C1 21.1648 1.33516 21.5 1.75 21.5H22.25C22.6648 21.5 23 21.1648 23 20.75V3.75C23 3.33516 22.6648 3 22.25 3ZM21.3125 19.8125H2.6875V4.6875H21.3125V19.8125ZM13.8039 11.4062H17.6961C17.7266 11.4062 17.75 11.3219 17.75 11.2188V10.0938C17.75 9.99062 17.7266 9.90625 17.6961 9.90625H13.8039C13.7734 9.90625 13.75 9.99062 13.75 10.0938V11.2188C13.75 11.3219 13.7734 11.4062 13.8039 11.4062ZM13.9164 14.7812H19.2687C19.3602 14.7812 19.4352 14.6969 19.4352 14.5938V13.4688C19.4352 13.3656 19.3602 13.2812 19.2687 13.2812H13.9164C13.825 13.2812 13.75 13.3656 13.75 13.4688V14.5938C13.75 14.6969 13.825 14.7812 13.9164 14.7812ZM4.75 16.0234H5.77891C5.87734 16.0234 5.95703 15.9461 5.96406 15.8477C6.05312 14.6641 7.04219 13.7266 8.24219 13.7266C9.44219 13.7266 10.4313 14.6641 10.5203 15.8477C10.5273 15.9461 10.607 16.0234 10.7055 16.0234H11.7344C11.7598 16.0235 11.785 16.0183 11.8084 16.0083C11.8317 15.9983 11.8528 15.9837 11.8704 15.9652C11.8879 15.9468 11.9015 15.925 11.9104 15.9012C11.9192 15.8774 11.9231 15.852 11.9219 15.8266C11.8562 14.5773 11.1719 13.4898 10.1734 12.8711C10.6137 12.3871 10.857 11.7559 10.8555 11.1016C10.8555 9.65078 9.68594 8.47656 8.24453 8.47656C6.80313 8.47656 5.63359 9.65078 5.63359 11.1016C5.63359 11.7836 5.89141 12.4023 6.31562 12.8711C5.80733 13.1861 5.38283 13.6194 5.07836 14.1341C4.77389 14.6487 4.59851 15.2294 4.56719 15.8266C4.55781 15.9344 4.64219 16.0234 4.75 16.0234ZM8.24219 9.88281C8.91016 9.88281 9.45391 10.4289 9.45391 11.1016C9.45391 11.7742 8.91016 12.3203 8.24219 12.3203C7.57422 12.3203 7.03047 11.7742 7.03047 11.1016C7.03047 10.4289 7.57422 9.88281 8.24219 9.88281Z"/>
  </svg>
);
