import { StyledKTextarea } from "./styles";
import { KTextAreaProps } from "./types";

export const KTextarea: React.FC<KTextAreaProps> = (
  props,
  rows = 4,
) => {
  return (
    <StyledKTextarea
      style={{ minHeight: "54px" }}
      rows={rows}
      autoSize
      {...props}
    />
  );
};