import { apiInstance } from "@apis";
import { IBaseRes, IEmployee, IEmployer, IPagable } from "@types";

const baseUrl = "/v1/employer";

export const getEmployerListForRedemption = async () => {
  const { data } = await apiInstance.get<IBaseRes<IPagable<IEmployer>>>(
    baseUrl
  );
  return data?.data;
};

export const verifyEmployerNickname = async (employerNickname: string) => {
  const { data } = await apiInstance.post<
    IBaseRes<Pick<IEmployee, "employerId" | "employerName">>
  >(`${baseUrl}/verify`, {
    employerNickname,
  });
  return data.data;
};