import styled from "styled-components";
import { DownOutlined } from "@ant-design/icons";
import { Color } from "../../types";
import { styledInputIcon, styledInputIconWithSelect } from "../Input/styles";
import { Select } from "antd";

const IconSelect = styled(DownOutlined)`
  color: ${Color.Secondary65};
  font-size: 16px;
`;

const IconSelectWithInput = styled(DownOutlined)`
  color: ${Color.Secondary65};
  font-size: 12px;
`;

const SelectStyled = styled(Select)`
  ${styledInputIcon}
`;

const SelectInputStyled = styled(Select)`
  ${styledInputIconWithSelect}
`;

export { IconSelect, IconSelectWithInput, SelectStyled, SelectInputStyled };