import { KFormUser } from "@components";
import { ConditionSeverityOptions, ConditionTypeOptions } from "@constants/options";
import { ECardType } from "@types";
import { Flex } from "antd";
import { AttachmentsDetail } from "./Attachments";
import { SetReminderSection } from "./SetReminderSection";

export const ConditionDetail = () => {
  return (
    <>
      <KFormUser.Select
        label="Type"
        name="type"
        options={ConditionTypeOptions}
      />
      <KFormUser.Select
        label="Severity"
        name="conditionSeverity"
        options={ConditionSeverityOptions}
      />
      <Flex gap={16} justify="space-between">
        <KFormUser.DatePicker label="Start Date" name="startTime" />
        <KFormUser.EndDatePicker label="End Date" name="endTime" />
      </Flex>
      <KFormUser.Switch
        label="No longer exprericing?"
        name="isNoLongerExperiencing"
      />
      <KFormUser.OptionsSelector
        label="Primary Practitioner"
        cardType={ECardType.Practitioners}
      />
      <KFormUser.TextArea label="Additional Information" name="description" />
      <AttachmentsDetail />
      <SetReminderSection />
    </>
  );
};
