import { Card, Divider, Drawer, Flex } from "antd";
import styled from "styled-components";
import { breakpointLarge } from "../../constants";
import { Color, EFontSize, ELineHeight } from "../../types";
import { ESpacing } from "../../types/enums";

const StyledLeftMenu = styled(Drawer)`
  .ant-drawer-wrapper-body {
    display: flex;
    flex-direction: column;
    gap: ${ESpacing.xl};
    padding: ${ESpacing.lg} 0;
  }
  .ant-drawer-body {
    padding: 0 ${ESpacing.xl};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: ${breakpointLarge}px) {
      padding: 0 ${ESpacing.lg};
    }
  }
  .ant-drawer-header {
    display: flex;
    flex: 0;
    align-items: center;
    padding: 11px ${ESpacing.xl};
    font-size: ${ESpacing.lg};
    gap:${ESpacing.md};
    border: 0;
    @media (max-width: ${breakpointLarge}px) {
      padding: 11px ${ESpacing.lg};
    }
  }
  .ant-drawer-title {
    height: fit-content;
    font-size: ${EFontSize.md}px;
    line-height: ${ELineHeight.md}px;
    font-weight: 500;
    color: ${Color.Secondary88};
  }
  .anticon {
    font-size: ${ESpacing.lg};
    color: ${Color.Secondary88};
  }
`;

const WrapperLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledDivider = styled(Divider)`
  margin: 0 -${ESpacing.xl};
  width: calc(100% + 48px);
  height: 2px;
  @media (max-width: ${breakpointLarge}px) {
    margin: 0 -${ESpacing.lg};
    width: calc(100% + 32px);
  }
`;

const WrapperContentItems = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: ${ESpacing.xl};
`;

const ContentItems = styled.div`
  display: flex;
  flex-direction: column;
  gap:${ESpacing.md};
`;

const StyledItemMenu = styled(Flex)`
  cursor: pointer;
  height: 40px;
  padding: 0 ${ESpacing.sm};
  display: flex;
  align-items: center;
  gap:${ESpacing.md};
`;

const CardUpgrade = styled(Card)`
  width: auto;
  border-radius: 12px;
  border: 1px solid ${Color.PrimaryBase};
  .ant-card-body {
    display: flex;
    flex-direction: column;
    padding: 12px;
  }
  .ant-card-body > :first-child {
    margin-top: 0;
  }
  .ant-card-body > :last-child {
    margin-bottom: 0;
  }
  .ant-card-body > * {
    margin-top: ${ESpacing.lg};
  }
`;

const ContentCardUpgrade = styled.div`
  display: flex;
  flex-direction: column;
  gap:${ESpacing.md};
`;

export {
  StyledLeftMenu,
  WrapperLogo,
  StyledDivider,
  WrapperContentItems,
  ContentItems,
  StyledItemMenu,
  CardUpgrade,
  ContentCardUpgrade,
};