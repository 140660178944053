export enum EUrlPath {
  WELCOME = "welcome",
  PROFILE_BASICS = "profile-basics",
  ABOUT = "about",
  OVERVIEW = "overview",
  ACCOUNT_SETTINGS = "account-settings",
  EXPORT = "export",
  MEDICAL_RECORDS = "medical-records",
  EMERGENCY_ACCESS = "emergency-access",
  NOTIFICATIONS = "notifications",
  EMPLOYEE_REDEMPTION = "employee-redemption",
};

export enum EQueryParams {
    PRINCIPAL = 'principal',
    TOKEN = 'token',
};

export enum EPermissionView {
    USER = 'user',
    TEMPORARY_SHARE = 'temporaryShare',
    EMERGENCY_ACCESS = 'emergencyAccess'
};
