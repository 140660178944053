import { Divider, Flex } from "antd";

import { Loading } from "@components";
import { useFetchInfoTypesService } from "@hooks";
import { useListInfoType } from "@store";
import { EFileSort } from "@types";
import { useMemo, useState } from "react";
import { SortItem, VaccinationItem } from "../item";
import { Section } from "../share-component";
import { getQuery } from "@utils/card";

export const ListVaccinations = () => {
  const {
    validVaccinationList = [],
    outDateVaccinationList = [],
    isLoadingList,
    isLoadingInactiveList
  } = useListInfoType();
  const [sort, setSort] = useState<EFileSort>(EFileSort.New);

  const query = useMemo(() => getQuery(sort), [sort]);

  useFetchInfoTypesService("Vaccinations", query);

  return (
    <Flex gap={16} vertical>
      <Section title="Vaccinations">
        <SortItem sort={sort} setSort={setSort} />
        {isLoadingList ? (
          <Loading />
        ) : (
          validVaccinationList.map((i) => (
            <VaccinationItem key={i._id} card={i} />
          ))
        )}
      </Section>
      <Divider />
      <Section title="Out of Date Vaccinations">
        {isLoadingInactiveList ? (
          <Loading />
        ) : (
          outDateVaccinationList.map((i) => (
            <VaccinationItem key={i._id} card={i} />
          ))
        )}
      </Section>
    </Flex>
  );
};
