import { KCollapse, KFormUser } from "@components";
import {
  bloodTypes,
  heightTypes,
  weightTypes,
  yesOrNoOptions
} from "@types";
import { Divider, Flex, Space } from "antd";

export const HealthDetailInfo = () => {
  return (
    <KCollapse title="Health Details">
      <Divider style={{ margin: 0, marginTop: -8 }} />
      <Space
        direction="vertical"
        size={16}
        style={{ width: "100%", marginTop: 16 }}
      >
        <Flex justify="space-between" gap={16}>
          <Flex vertical gap={8} flex={1}>
            <KFormUser.InputUnit
              name="healthDetail.height"
              label="Height"
              options={heightTypes}
            />
          </Flex>
          <Flex vertical gap={8} flex={1}>
            <KFormUser.InputUnit
              name="healthDetail.weight"
              label="Weight"
              options={weightTypes}
            />
          </Flex>
        </Flex>
        <KFormUser.Select
          name="healthDetail.bloodType"
          label="Blood Type"
          options={bloodTypes}
        />
        <KFormUser.Select
          name="healthDetail.isOrganDonor"
          label="Organ Donor?"
          options={yesOrNoOptions}
        />
      </Space>
    </KCollapse>
  );
};
