import { CheckCircleFilled } from "@ant-design/icons";
import { IParams, IProfileSwitch } from "@types";
import { Flex } from "antd";
import { KAvatarProfile, Loading } from "@components";
import { useParams } from "react-router-dom";
import { CardProfileSwitch } from "../ProfileSelector/style";
export const ListProfileSection = ({
  profiles,
  loading,
  onClickProfile
}: {
  profiles?: IProfileSwitch[];
  loading: boolean;
  onClickProfile: (id: string) => void
}) => {
  const { profileId } = useParams<IParams>();

  return (
    <Flex vertical gap={8}>
      {profiles?.map(
        ({ _id, isOwner, profile, role, user, ...rest }, idx) => {
          const active = profileId === profile;
          return (
            <CardProfileSwitch
              key={idx}
              $active={active}
              onClick={() => onClickProfile(profile)}
            >
              <KAvatarProfile size="semi" {...rest}/>
              {active && <CheckCircleFilled style={{ fontSize: 21 }}/>}
            </CardProfileSwitch>
          );
        }
      )}
      {loading && (
        <Flex vertical gap={8}>
          {Array.from({ length: 3 }).map((_, idx) => (
            <CardProfileSwitch key={idx} children={<Loading/>}/>
          ))}
        </Flex>
      )}
    </Flex>
  );
};
