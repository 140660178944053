import { ModalProps } from "antd";

export const modalProps: ModalProps = {
  centered: true,
  closable: false,
  footer: null,
  styles: { content: { padding: 32 } },
};

export const modalTodoProps: ModalProps = {
  centered: true,
  closeIcon: false,
  cancelButtonProps: { style: { flex: 1 } },
  cancelText: "No, go back",
};