import { useKContext } from "@context";
import {
  getSummaryEmergencyAccessService,
  getSummaryTemporaryShare,
  getSummaryCardService,
  getTopicsUserService,
  getPrincipalOfUser,
} from "@services";
import { useAuthStore } from "@store";
import { EAuthPage, EStatusCode, ISummaryRes } from "@types";
import { infoCardTypes } from "@utils/index";
import { getToken, setToken, removeToken } from "@utils/storage";
import { useCallback } from "react";
import { useCustomNavigate } from "./useCustomNavigate";
import { useLocation } from "react-router-dom";

export const useFetchSummaryService = () => {
  const {
    setIsValidToken,
    setIsSwitchToProfile,
    setAmountOfCards,
    setTopics,
    setShowProfileBasic,
    setExpiredTime,
    showErrorResult,
    setShowErrorResult,
    setLoadingSummary,
    setInfoPrincipal,
  } = useKContext();
  const {
    setCurrentPage,
    setIsCSLFlow,
    setCanAccess,
    setShowModal,
    setIsAuthenticated,
  } = useAuthStore();
  const { navigate } = useCustomNavigate();
  const { pathname } = useLocation();

  const handleSummaryError = useCallback(
    (error: any) => {
      switch (+error?.response?.data?.code) {
        case EStatusCode.FORBIDDEN:
          removeToken("EmergencyAccess");
          navigate(`${pathname}/welcome`, { replace: true });
          break;
        case EStatusCode.BAD_REQUEST:
          setIsValidToken(true);
          setIsSwitchToProfile(true);
          break;
        default:
          setIsValidToken(false);
          break;
      }
    },
    [setShowErrorResult, setIsValidToken, setIsSwitchToProfile]
  );

  const handleSummaryResponse = useCallback(
    (data: ISummaryRes) => {
      const updatedInfoCardTypes = infoCardTypes
        .map((cardType) => {
          const dataItem = data.cards.find(
            (item) => item.cardType === cardType.cardType
          );
          return dataItem ? { ...cardType, amount: dataItem.amount } : cardType;
        })
        .filter((cardType) => cardType.amount);

      setIsValidToken(true);
      setIsSwitchToProfile(false);
      setAmountOfCards(updatedInfoCardTypes);
      setTopics(data.topics);
      setShowProfileBasic(data.isShowProfileBasic);
      setExpiredTime(data.expiredTime || "");
      if (showErrorResult) setShowErrorResult(0);
    },
    [
      infoCardTypes,
      setIsValidToken,
      setIsSwitchToProfile,
      setAmountOfCards,
      setTopics,
      setShowProfileBasic,
      setExpiredTime,
      showErrorResult,
      setShowErrorResult,
    ]
  );

  const getPrincipal = async (
    tempShareId: string,
    setShowModalConfirm?: (val: boolean) => void
  ) => {
    const webAppToken = getToken("WebApp");
    const data = await getPrincipalOfUser(tempShareId);
    if (!data) return;
    setIsCSLFlow(true);
    setInfoPrincipal(data);
    showErrorResult && setShowErrorResult(0);
    if (data?.existUser) {
      if (webAppToken) {
        if (data?.isSamePrincipal) {
          setToken("TempShare", data?.accessToken);
          setShowModal(false);
          navigate(`/${tempShareId}`, { replace: true });
          await fetchSummaryTemporaryShare(tempShareId);
          return;
        } else {
          setShowModal(false);
          setShowModalConfirm?.(true);
          return;
        }
      } else {
        setCanAccess(data?.canAccess ?? true);
        setCurrentPage(EAuthPage.CSL_WELCOME);
        navigate(`/${tempShareId}/welcome`);
        return;
      }
    } else {
      if (webAppToken) {
        setShowModal(false);
        setShowModalConfirm?.(true);
        return;
      } else if (!data?.canAccess) {
        setCurrentPage(EAuthPage.REQUEST_OTP);
        navigate(`/${tempShareId}/welcome`);
        return;
      }
    }
    setIsAuthenticated(true);
    setCanAccess(data?.canAccess ?? true);
  };

  const fetchSummaryCardUser = useCallback(
    async (profileId: string) => {
      try {
        const data = await getSummaryCardService(profileId);
        if (!data) return;
        const updatedInfoCardTypes = infoCardTypes.map((cardType) => {
          const dataItem = data.find(
            (item) => item.cardType === cardType.cardType
          );
          return dataItem ? { ...cardType, amount: dataItem.amount } : cardType;
        });
        setIsValidToken(true);
        setIsSwitchToProfile(false);
        setAmountOfCards(updatedInfoCardTypes);
      } catch {}
    },
    [setAmountOfCards, setIsSwitchToProfile, setIsValidToken]
  );

  const fetchSummaryData = useCallback(
    async (fetchFunction: () => Promise<{ data: ISummaryRes }>) => {
      try {
        setLoadingSummary(true);
        const { data } = await fetchFunction();
        if (data) {
          handleSummaryResponse(data);
        }
      } catch (error: any) {
        handleSummaryError(error);
      } finally {
        setLoadingSummary(false);
      }
    },
    [handleSummaryResponse, handleSummaryError]
  );

  const fetchSummaryEmerAccess = useCallback(
    async (emergencyAccessId?: string) => {
      if (!emergencyAccessId) return;
      fetchSummaryData(() =>
        getSummaryEmergencyAccessService(emergencyAccessId)
      );
    },
    [fetchSummaryData]
  );

  const fetchSummaryTemporaryShare = useCallback(
    async (temporaryShareId?: string) => {
      if (!temporaryShareId || !getToken("TempShare")) return;
      fetchSummaryData(() => getSummaryTemporaryShare(temporaryShareId));
    },
    [fetchSummaryData]
  );

  const fetchSummaryUser = useCallback(
    async (profileId?: string) => {
      if (!profileId || !getToken("WebApp")) return;
      try {
        setLoadingSummary(true);
        fetchSummaryCardUser(profileId);
        const topics = await getTopicsUserService(profileId);
        setTopics(topics);
        if (showErrorResult) setShowErrorResult(0);
      } catch (error: any) {
        handleSummaryError(error);
      } finally {
        setLoadingSummary(false);
      }
    },
    [
      handleSummaryError,
      showErrorResult,
      infoCardTypes,
      setIsValidToken,
      setIsSwitchToProfile,
      setAmountOfCards,
      setTopics,
      fetchSummaryCardUser,
    ]
  );

  return {
    fetchSummaryTemporaryShare,
    fetchSummaryUser,
    fetchSummaryEmerAccess,
    getPrincipal,
    fetchSummaryCardUser,
  };
};
