import { KFormUser } from "@components";
import { IDCardTypeOptions } from "@constants/index";
import { Flex } from "antd";
import { AttachmentsDetail } from "./Attachments";
import { SetReminderSection } from "./SetReminderSection";

export const IdCardDetail = () => {
  return (
    <>
      <KFormUser.Select label="Type" name="type" options={IDCardTypeOptions} />
      <Flex gap={16} justify="space-between">
        <KFormUser.DatePicker label="Issue Date" name="startTime" />
        <KFormUser.EndDatePicker label="Expiration Date" name="endTime" />
      </Flex>
      <KFormUser.TextArea label="Additional Information" name="description" />
      <AttachmentsDetail />
      <SetReminderSection />
    </>
  );
};
