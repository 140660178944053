import 'firebase/messaging';
import 'firebase/analytics';
import firebase from 'firebase/app';
import { firebaseConfig } from '@constants/fcm';
interface IMessageNoti {
  notification: {
    title: string;
    body: string;
  };
}

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app(); // if already initialized, use that one
}

let messaging: firebase.messaging.Messaging;

if (typeof window !== 'undefined') {
  if (firebase.messaging.isSupported()) {
    messaging = firebase.messaging();
  }
  firebase.analytics.isSupported().then((isSupported) => {
    if (isSupported) {
      firebase.analytics();
    }
  });
}

export const getMessagingToken = async () => {
  let currentToken = '';
  if (!messaging) return;
  try {
    currentToken = await messaging.getToken({
      vapidKey: process.env.REACT_APP_FIREBASE_FCM_VAPID_KEY,
    });
  } catch (error) {
    console.log('An error occurred while retrieving token. ', error);
  }
  return currentToken;
};

export const onMessageListener = () =>
  new Promise<IMessageNoti>((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });
