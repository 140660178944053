import styled from "styled-components";
import { Color, EFontSize, ELineHeight, ESpacing } from "../../types";

interface IBadgeWrapper {
  $isActive?: boolean;
  $isNeedWidth?: boolean;
}

interface IBadgeCounterInfo {
  $size?: "sm" | "md" | "lg";
  $color?: string;
  $backgroundColor?: string;
}

const BadgeWrapper = styled.div<IBadgeWrapper>`
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  ${({ $isNeedWidth }) =>
    `width: ${$isNeedWidth ? '60px' : 'auto' };`}
  height: 100%;
`;

const BadgeCounterInfo = styled.span<IBadgeCounterInfo>`
  ${({ $size = 'md' }) => `font-size: ${EFontSize[$size]}px;`}
  ${({ $size = 'md' }) => `height: ${ELineHeight[$size]}px;`}
  ${({ $color = Color.SecondaryBase }) => `color: ${$color};`}
  ${({ $backgroundColor = Color.Secondary06 }) => `background-color: ${$backgroundColor};`}
  font-weight: 700;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  line-height: 18px;
  padding: 0 8px;
  gap: 10px;
  border-radius: 16px;
  width: max-content;
  white-space: nowrap;
`;

const TopicCountWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  padding: ${ESpacing.md + "12px" + ESpacing.md + ESpacing.md};
  width: 30px;
  height: 100%;
`;

const TopicCountInfo = styled.span<IBadgeWrapper>`
  ${({ $isActive }) =>
    `background-color: ${$isActive ? Color.PrimaryBase : Color.Black};`}
  color: ${Color.White};
  font-size: ${EFontSize.sm}px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 20px;
  height: 18px;
  line-height: ${ELineHeight.sm}px;
  font-weight: 700;
  padding: 0px ${ESpacing.sm};
  gap: 10px;
  border-radius: 16px;
`;

export { BadgeWrapper, BadgeCounterInfo, TopicCountWrapper, TopicCountInfo };
