import styled from "styled-components";
import { Color, sizeAvatar } from "../../types";
import { EAvatarSize } from "../enums";
import { Avatar } from "antd";
import { CSSProperties } from "react";

const styledAvatar = (size: EAvatarSize, shape: string): CSSProperties  => ({
  fontSize: `${sizeAvatar[size].fontSize}px`,
  lineHeight: `${sizeAvatar[size].lineHeight}px`,
  ...(shape !== "circle" && {borderRadius: sizeAvatar[size].borderRadius}),
  verticalAlign: "middle",
  textAlign: "center",
  border: 0,
  fontWeight: 700,
  backgroundColor: Color.BgAvatar,
  color: Color.White,
});

const KAvatarGroup = styled(Avatar.Group)`
  &.ant-avatar-group .ant-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${Color.White};
    background-color: ${Color.BgAvatar};
    border: 0px;
    span {
      color: ${Color.White};
    }
  }
`;

export { styledAvatar, KAvatarGroup };