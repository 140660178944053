import { StyledCollapse } from "./styles";
import { KCollapseProps } from "./types";

export const KCollapse: React.FC<KCollapseProps> = ({ title, children, ...props }) => {
  return (
    <StyledCollapse
      defaultActiveKey={["0"]}
      ghost
      items={[
        { 
          key: 0,
          label: title,
          children: children,
        },
      ]}
      {...props}
    />
  );
};