import { createLogsService } from "@services";
import { useProfile, useRoleUser, useUser } from "@store";
import {
  ECardType,
  EChangeType,
  EInfoType,
  ELogType,
  ICardItemRes,
  IInfoChangeLog,
  IProfileBasic,
  profileRoleName,
} from "@types";
import { handleMapText } from "@utils/function-helper";
import { useCallback } from "react";

interface ICardInfoLog extends Pick<ICardItemRes, "_id"> {
  cardType?: ECardType;
}

const useLoggingService = () => {
  const { userInfo } = useUser();
  const { profile } = useProfile();
  const { role } = useRoleUser();

  const pushInfoTypeLog = useCallback(
    async (
      type: keyof typeof EChangeType,
      card: ICardInfoLog,
      infoType?: keyof typeof EInfoType
    ) => {
      if (!userInfo || !profile || !card) return;
      try {
        const logPayload: IInfoChangeLog = {
          userId: userInfo._id,
          profile: profile._id,
          profileRole: role ? profileRoleName[role] : "unknown",
          relationship: profile.relationship || "unknown",
          infoId: card._id,
          changeType: EChangeType[type],
          infoType: infoType
            ? EInfoType[infoType]
            : (handleMapText(card.cardType, false) as EInfoType),
          createdTime: new Date(),
        };
        await createLogsService(ELogType.INFO_CHANGE, [logPayload]);
      } catch { }
    },
    [userInfo, profile, role]
  );

  const pushProfileInfoLog = useCallback(
    async (type: keyof typeof EChangeType, profile: IProfileBasic) => {
      if (!userInfo || !profile) return;
      try {
        const logPayload: IInfoChangeLog = {
          userId: userInfo._id,
          profile: profile._id,
          profileRole: role ? profileRoleName[role] : "unknown",
          relationship: profile.relationship || "unknown",
          infoId: profile._id,
          changeType: EChangeType[type],
          infoType: EInfoType.PROFILE_BASICS,
          createdTime: new Date(),
        };
        await createLogsService(ELogType.INFO_CHANGE, [logPayload]);
      } catch { }
    },
    [userInfo, role]
  );

  return { pushInfoTypeLog, pushProfileInfoLog };
};

export { useLoggingService };
