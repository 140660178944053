
import { Layout } from "antd";
import styled from "styled-components";

import { heightFooter, breakpointLarge } from "@constants/size";
import { Color, ESpacing } from "@types";

const { Footer } = Layout;

const FooterContainer = styled(Footer)`
  text-align: center;
  bottom: 0;
  height: ${heightFooter}px;
  width: 100%;
  padding: ${ESpacing.xl} 0px;
  background-color: transparent;
  @media (max-width: ${breakpointLarge}px) {
    display: none;
  }
`;

export {
    FooterContainer
}