import { IKTextProps } from '../../types';
import { FC } from 'react';
import { KText } from './default';

const KXlText: FC<IKTextProps> = ({ children, ...props }) => (
  <KText { ...props } size="xl">
    {children}
  </KText>
);

const KXlText700 = ({ children, ...props }: IKTextProps) => (
  <KXlText { ...props } fontWeight="700">
    {children}
  </KXlText>
);

const KXlText700Secondary25 = ({ children, ...props }: IKTextProps) => (
  <KXlText { ...props } fontWeight="700" color="Secondary25">
    {children}
  </KXlText>
);

const KXlText700Secondary88 = ({ children, ...props }: IKTextProps) => (
  <KXlText { ...props } fontWeight="700" color="Secondary88">
    {children}
  </KXlText>
);

const KXlText700PrimaryBase = ({ children, ...props }: IKTextProps) => (
  <KXlText { ...props } fontWeight="700" color="PrimaryBase">
    {children}
  </KXlText>
);

const KXlText700ErrorBase = ({ children, ...props }: IKTextProps) => (
  <KXlText { ...props } fontWeight="700" color="ErrorBase">
    {children}
  </KXlText>
);

export {
  KXlText,
  KXlText700,
  KXlText700Secondary25,
  KXlText700Secondary88,
  KXlText700PrimaryBase,
  KXlText700ErrorBase,
};
