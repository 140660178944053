import { Layout } from "antd";
import { useEffect, useState } from "react";
import { Outlet } from "react-router";
import { useLocation, useParams } from "react-router-dom";
import styled from "styled-components";

import {
  BackdropLoading,
  KBreadcrumb,
  KCardMeatBall,
  KFooter,
  KHeader,
  KMdTextSecondary65,
  ModalEnterPin,
} from "@components";
import { breakpointLarge, heightHeader } from "@constants/size";
import { useKContext } from "@context";
import {
  useFetchProfileService,
  useFetchSummaryService,
  useShowPin,
} from "@hooks";
import ErrorResult from "@pages/error-result";
import { createPinLogging } from "@services";
import { useAuthStore, useProfile, useRoleUser, useUser } from "@store";
import { Color, ESpacing, EUrlPath, IParams } from "@types";
import { formatDate } from "@utils/date";
import { convertBootIntercomUser } from "@utils/third-party";
import { useIntercom } from "react-use-intercom";
import { UserModeHandler } from "./components/UserModeHandler";

const { Content } = Layout;

const MainContent = styled(Content)`
  padding: 0 50px;
  height: calc(100vh - ${heightHeader}px);
  background-color: ${Color.BgLayout};
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: ${breakpointLarge}px) {
    padding: 0;
  }
`;

const ContainerBreadcrumb = styled.div`
  padding: ${ESpacing.lg} 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${breakpointLarge}px) {
    display: none;
  }
`;

const MainLayout = () => {
  const { tempShareId, profileId, emergencyAccessId } = useParams<IParams>();
  const { pathname } = useLocation();
  const { boot } = useIntercom();
  const {
    fetchSummaryEmerAccess,
    fetchSummaryTemporaryShare,
    fetchSummaryUser,
  } = useFetchSummaryService();
  const {
    expiredTime,
    showErrorResult,
    setProfileId,
    setTemporaryShareId,
    isValidToken,
    isInitialized,
    isUserPath,
  } = useKContext();
  const { setRoleUser, role } = useRoleUser();
  const { profile } = useProfile();
  const { userInfo } = useUser();
  const { isAuthenticated } = useAuthStore();
  const { showModal, handleClose } = useShowPin();

  const [isLoggingPushed, setIsLoggingPushed] = useState(true);

  useEffect(() => {
    setTemporaryShareId(tempShareId ?? "");
    if (!isAuthenticated) return;
    fetchSummaryTemporaryShare(tempShareId);
  }, [isAuthenticated, tempShareId]);

  useEffect(() => {
    if (!emergencyAccessId) return;
    fetchSummaryEmerAccess(emergencyAccessId);
  }, [emergencyAccessId]);

  useFetchProfileService();

  useEffect(() => {
    setProfileId(profileId ?? "");
    (async () => {
      if (!profileId || !isInitialized) return;
      fetchSummaryUser(profileId);
    })();
  }, [profileId, isInitialized]);

  useEffect(() => {
    setRoleUser(profile, userInfo?._id);
  }, [profile, userInfo]);

  useEffect(() => {
    if (!pathname.includes("user")) return;
    const bootIntercom = convertBootIntercomUser(userInfo);
    boot(bootIntercom);
  }, [userInfo]);

  useEffect(() => {
    if (!pathname.includes(EUrlPath.EMERGENCY_ACCESS) || !isValidToken) return;
    boot();
  }, [isValidToken]);

  useEffect(() => {
    if (isLoggingPushed || !userInfo || !profile || !role) return;
    (async () => {
      await createPinLogging(userInfo?._id, profile?._id, role);
      setIsLoggingPushed(true);
    })();
  }, [isLoggingPushed, profile, userInfo, role]);

  return (
    <Layout>
      <KHeader />
      {showErrorResult ? (
        <ErrorResult />
      ) : !isInitialized ? (
        <BackdropLoading />
      ) : (
        <MainContent>
          <UserModeHandler />
          {userInfo?.isSetupPin && showModal && isUserPath ? (
            <ModalEnterPin
              open={showModal}
              handleClose={handleClose}
              setIsLoggingPushed={setIsLoggingPushed}
            />
          ) : (
            <>
              <ContainerBreadcrumb>
                <KBreadcrumb />
                {tempShareId && (
                  <KMdTextSecondary65>
                    Access Expires:{" "}
                    {formatDate(
                      expiredTime,
                      "FULL_DAY_MONTH_YEAR_WITH_SHORT_MONTH_AND_TIME_AM_PM"
                    ) && "Does not expire"}
                  </KMdTextSecondary65>
                )}
                {profileId && <KCardMeatBall type="profile" />}
              </ContainerBreadcrumb>
              <Outlet />
              <KFooter />
            </>
          )}
        </MainContent>
      )}
    </Layout>
  );
};

export default MainLayout;
