import { FC, useContext, useEffect, useState } from "react";
import { Flex } from "antd";
import { useFormContext } from "react-hook-form";

import { KLgText700, KMdTextSecondary88, KTopicCardItem, ModalAddTopic } from "@components";
import { KContext } from "@context";
import { WrapperCardDetail } from "./share-component";
import { AddPlusIcon } from "@assets/icons";
import { ButtonAddStyled } from "components/CardItemInfo/styles";
import { NewTopicContainer } from "../styles";
import { getInfoCard } from "@utils/cardTypeIcon";
import { updateTopicOfCardTypeService } from "@services";

interface IProps {
  isEditable?: boolean;
  addable: boolean;
}

export const TopicTab: FC<IProps> = ({ isEditable = false, addable = false }) => {
  const { topics, fetchTopics, cardTypeSelected, profileId } = useContext(KContext);
  const { setValue, getValues, watch } = useFormContext();

  const [isOpenModal, setIsOpenModal] = useState(false);

  useEffect(() => {
    fetchTopics();
  }, []);

  const onChange = (checked: boolean, id: string) => {
    const topics = getValues('topics') ?? [];

    if (!Array.isArray(topics)) return;
    const newTopics = checked
      ? [...topics, id]
      : topics.filter(i => i !== id);
    setValue('topics', newTopics, { shouldDirty: true });
  };

  const handleAfterCreate = async (topicId: string) => {
    const cardId = watch('_id');
    const topics = watch('topics') || [];
    const cardName = getInfoCard(cardTypeSelected).path;
    const newTopics = [...topics, topicId];
    if (!cardId || !cardName) {
      return;
    }
    await updateTopicOfCardTypeService(profileId, cardName, cardId, newTopics);
    setValue('topics', newTopics, { shouldDirty: false });
  };

  return (
    <>
      <WrapperCardDetail>
        {addable && (
          <>
            <KMdTextSecondary88>
              Organize this info by adding it topics.
            </KMdTextSecondary88>

            <NewTopicContainer>
              <KLgText700>New Topic</KLgText700>
              <ButtonAddStyled onClick={() => { setIsOpenModal(true); }}>
                <AddPlusIcon />
              </ButtonAddStyled>
            </NewTopicContainer>
          </>
        )}

        {topics.length ? (
          <Flex vertical gap={8}>
            {(topics ?? []).map((i) => (
              <KTopicCardItem
                key={i._id}
                topic={i}
                disabled={!isEditable}
                checked={(watch('topics') ?? []).includes(i._id)}
                onChange={(checked: boolean) => onChange(checked, i._id)}
              />
            ))}
          </Flex>
        ) : null}
      </WrapperCardDetail>
      <ModalAddTopic
        isOpen={isOpenModal}
        onCancel={() => { setIsOpenModal(false); }}
        handleAfterCreate={handleAfterCreate}
      />
    </>
  );
};
