import { Space } from "antd";
import styled from "styled-components";

import { heightCardItemInfo } from "@constants/size";
import { ESpacing, EBorderRadius, EFontSize, ELineHeight, Color } from "@types";

interface ICardDetailStyledProps {
  $isChecked: boolean;
}

export const CardDetailStyled = styled(Space)<ICardDetailStyledProps>`
  width: 100%;
  padding: 0px ${ESpacing.md};
  border-width: 1px;
  border-style: solid;
  border-radius: ${EBorderRadius.lg};
  height: ${heightCardItemInfo}px;
  border-color: ${({ $isChecked }) => ($isChecked ? Color.PrimaryBase : Color.Secondary10)};
  color: ${Color.Secondary88};
  font-weight: 700;
  font-size: ${EFontSize.lg}px;
  line-height: ${ELineHeight.lg}px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  justify-content: space-between;
  cursor: pointer;
`;
