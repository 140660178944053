import { FC, useState, useEffect } from "react";
import { Flex, Modal, message } from "antd";

import { KinKeeperLogo } from "@assets/icons";
import { modalProps } from "config/antd";
import { PIN_LENGTH } from "@types";
import { createPinLogging, verifyPinService } from "@services";
import {
  BackdropLoading,
  KAnchor,
  KErrorText,
  KLgTextSecondary88,
  KPin,
  KXlText700Secondary88,
  ModalConfirmLogout,
} from "@components";
import { useProfile, useRoleUser, useUser } from "@store";
import { setToken as setLocalStorage } from "@utils/storage";

interface IProps {
  open: boolean;
  handleClose: () => void;
  setIsLoggingPushed: (val: boolean) => void;
}

export const ModalEnterPin: FC<IProps> = ({ open, handleClose, setIsLoggingPushed }) => {
  const { userInfo } = useUser();
  const { profile } = useProfile();
  const { role } = useRoleUser();

  const [pin, setPin] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenLogoutModal, setIsOpenLogoutModal] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        if (pin.length !== PIN_LENGTH) {
          setError('');
          return;
        }
        setIsLoading(true);
        await verifyPinService(pin);
        setLocalStorage('Pin', new Date().getTime());
        if (userInfo?._id && profile?._id && role) {
          createPinLogging(
            userInfo?._id,
            profile?._id,
            role,
          );
          setIsLoggingPushed(true);
        } else {
          setIsLoggingPushed(false);
        }
        handleClose();
      } catch (error: any) {
        const msg = error?.response?.data?.message;
        if (msg) {
          setError(msg);
        } else {
          message.error('Something went wrong! Please try again.');
        }
      } finally {
        setIsLoading(false);
      }
    })();
  }, [pin]);

  useEffect(() => {
    setPin('');
  }, [open]);

  return (
    <>
      <Modal open={open} {...modalProps} closable={false} width={446}>
        {isLoading && (
          <BackdropLoading />
        )}
        <Flex vertical gap={16} justify="center" align="center" style={{ textAlign: 'center' }}>
          <Flex
            justify='center'
            style={{ position: 'relative', width: '100%', minHeight: 16 }}
          >
            <KAnchor
              style={{ position: 'absolute', top: 0, left: 0 }}
              onClick={() => { setIsOpenLogoutModal(true); }}
            >
              Log Out
            </KAnchor>
          </Flex>
          <KinKeeperLogo width={184} />
          <Flex vertical gap={8}>
            <KLgTextSecondary88>
              Hi there! Please enter your PIN to access KinKeeper.
            </KLgTextSecondary88>
            <KXlText700Secondary88>Enter PIN 🔓</KXlText700Secondary88>
          </Flex>
          <KPin
            codeLength={PIN_LENGTH}
            code={pin}
            setCode={setPin}
            type='password'
            isError={!!error}
          />
          {error && (
            <KErrorText style={{ textAlign: 'center' }}>
              {error}
            </KErrorText>
          )}
        </Flex>
      </Modal>
      <ModalConfirmLogout
        isOpen={isOpenLogoutModal}
        setIsOpen={setIsOpenLogoutModal}
      />
    </>
  );
};
