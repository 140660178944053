import { KMdText } from "@components";
import { getInfoCard } from "@utils/index";
import { StyledCardTypeTag } from "./styles";
import { IKCardTypeTag } from "./types";

export const KCardTypeTag = ({ ...prop }: IKCardTypeTag) => {
  return (
    <StyledCardTypeTag
      icon={getInfoCard(prop.$cardType).icon}
      style={{ cursor: "pointer" }}
      {...prop}
    >
      <KMdText>{prop.title}</KMdText>
    </StyledCardTypeTag>
  );
};
