import { IconProps } from "./types";

export const PaperClipOutlinedIcon: React.FC<IconProps> = ({
  style,
  width = "16px",
  height = "16px",
}) => {
  return (
    <svg
      style={style}
      width={width}
      height={height}
    >
      <path d="M12.1758 3.07238C10.704 1.60051 8.30709 1.60051 6.83678 3.07238L2.75866 7.14738C2.73209 7.17394 2.71803 7.20988 2.71803 7.24738C2.71803 7.28488 2.73209 7.32082 2.75866 7.34738L3.33522 7.92394C3.36157 7.95018 3.39725 7.96491 3.43444 7.96491C3.47163 7.96491 3.5073 7.95018 3.53366 7.92394L7.61178 3.84894C8.11803 3.34269 8.79147 3.06457 9.50709 3.06457C10.2227 3.06457 10.8962 3.34269 11.4008 3.84894C11.9071 4.35519 12.1852 5.02863 12.1852 5.74269C12.1852 6.45832 11.9071 7.13019 11.4008 7.63644L7.24459 11.7911L6.57116 12.4646C5.94147 13.0943 4.91803 13.0943 4.28834 12.4646C3.98366 12.1599 3.81647 11.7552 3.81647 11.3239C3.81647 10.8927 3.98366 10.488 4.28834 10.1833L8.41178 6.06144C8.51647 5.95832 8.65397 5.90051 8.80084 5.90051H8.80241C8.94928 5.90051 9.08522 5.95832 9.18834 6.06144C9.29303 6.16613 9.34928 6.30363 9.34928 6.45051C9.34928 6.59582 9.29147 6.73332 9.18834 6.83644L5.81803 10.2036C5.79147 10.2302 5.77741 10.2661 5.77741 10.3036C5.77741 10.3411 5.79147 10.3771 5.81803 10.4036L6.39459 10.9802C6.42095 11.0064 6.45662 11.0212 6.49381 11.0212C6.531 11.0212 6.56668 11.0064 6.59303 10.9802L9.96178 7.61144C10.2727 7.30051 10.443 6.88801 10.443 6.44894C10.443 6.00988 10.2712 5.59582 9.96178 5.28644C9.31959 4.64426 8.27584 4.64582 7.63366 5.28644L7.23366 5.68801L3.51178 9.40832C3.25917 9.65944 3.05894 9.95822 2.92268 10.2873C2.78643 10.6164 2.71686 10.9693 2.71803 11.3255C2.71803 12.0489 3.00084 12.7286 3.51178 13.2396C4.04147 13.7677 4.73522 14.0318 5.42897 14.0318C6.12272 14.0318 6.81647 13.7677 7.34459 13.2396L12.1758 8.41144C12.8868 7.69894 13.2805 6.75051 13.2805 5.74269C13.2821 4.73332 12.8883 3.78488 12.1758 3.07238Z" fill="#161925"/>
    </svg>
  );
};
