import styled from "styled-components";

import { heightCardItemInfo } from "@constants/size";
import { Color, EBorderRadius, EFontSize, ELineHeight, ESpacing } from "@types";

interface ICardTopic {
  $isActive: boolean;
  $isDisabled: boolean;
}

export const CardTopicStyled = styled.label<ICardTopic>(({ $isActive, $isDisabled }) => ({
  width: '100%',
  padding: `0px ${ESpacing.md}`,
  border: '1px solid transparent',
  borderRadius: EBorderRadius.lg,
  height: `${heightCardItemInfo}px`,
  borderColor: $isActive ? Color.SuccessBorder : Color.Secondary10,
  color: Color.Secondary88,
  fontWeight: 700,
  fontSize: `${EFontSize.lg}px`,
  lineHeight: `${ELineHeight.lg}px`,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  justifyContent: 'space-between',
  display: 'flex',
  alignItems: 'center',
  cursor: $isDisabled ? 'default' : 'pointer',
}));
