import { refreshTokenService } from "@services";
import { ELocalStorageKey, IAccessTokenResponse } from "@types";

const COOKIE_KEY = "device_Id";

export const getToken = (key: keyof typeof ELocalStorageKey) => {
  try {
    const json = localStorage.getItem(ELocalStorageKey[key]);
    if (!json) return null;
    return JSON.parse(json);
  } catch (error) {
    removeToken(key);
    return null;
  }
};

export const setToken = (key: keyof typeof ELocalStorageKey, token: any) => {
  localStorage.setItem(ELocalStorageKey[key], JSON.stringify(token));
};

export const removeToken = (key: keyof typeof ELocalStorageKey) => {
  localStorage.removeItem(ELocalStorageKey[key]);
};

export const storeAppAccessTokens = ({
  accessToken,
  refreshToken,
}: IAccessTokenResponse) => {
  localStorage.setItem(ELocalStorageKey.WebApp, JSON.stringify(accessToken));
  localStorage.setItem(
    ELocalStorageKey.WebAppRefresh,
    JSON.stringify(refreshToken)
  );
};

export const removeAppAccessTokens = () => {
  localStorage.removeItem(ELocalStorageKey.WebApp);
  localStorage.removeItem(ELocalStorageKey.WebAppRefresh);
};

export const getAndSaveAppAccessTokens = async () => {
  const { data } = await refreshTokenService();
  storeAppAccessTokens(data);
  return data;
};

export const createUUID = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const setIdCookie = (id: string) => {
  const date = new Date();
  date.setFullYear(date.getFullYear() + 1000);
  document.cookie = `${COOKIE_KEY}=${id}; expires=${date.toUTCString()}; path=/;`;
};

export const getIdCookie = () => {
  const cookie = document.cookie
    .split(";")
    .find((cookie) => cookie.includes(COOKIE_KEY));
  if (!cookie) return null;
  return cookie.split("=")[1];
};

export const removeIdCookie = () => {
  document.cookie = `${COOKIE_KEY}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

export const createRandomUUID = () => {
  const timestamp = Math.floor(Date.now() / 1000).toString(16);
  const randomValue = Array.from({ length: 10 }, () =>
    Math.floor(Math.random() * 16).toString(16)
  ).join("");
  const counter = Array.from({ length: 6 }, () =>
    Math.floor(Math.random() * 16).toString(16)
  ).join("");
  return timestamp + randomValue + counter;
};
