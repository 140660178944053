import { Color } from "../../types";
import { IconProps } from "./types";

export const FileExcelIcon: React.FC<IconProps> = ({
  style,
  width = 64,
  height,
  color = Color.SecondaryBase,
}) => {
  return (
    <svg
      style={style}
      width={width}
      height={height || width}
      fill={color}
      viewBox={"0 0 32 32"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.7062 9.01875L19.9813 2.29375C19.7938 2.10625 19.5406 2 19.275 2H6C5.44687 2 5 2.44687 5 3V29C5 29.5531 5.44687 30 6 30H26C26.5531 30 27 29.5531 27 29V9.72812C27 9.4625 26.8937 9.20625 26.7062 9.01875ZM24.6938 10.1875H18.8125V4.30625L24.6938 10.1875ZM24.75 27.75H7.25V4.25H16.6875V11C16.6875 11.3481 16.8258 11.6819 17.0719 11.9281C17.3181 12.1742 17.6519 12.3125 18 12.3125H24.75V27.75ZM16.0656 18.1281L14.1344 14.9281C14.0656 14.8156 13.9438 14.7469 13.8125 14.7469H12.6125C12.5406 14.7469 12.4719 14.7656 12.4125 14.8062C12.2375 14.9156 12.1844 15.1469 12.2969 15.325L14.8687 19.4L12.2625 23.55C12.2271 23.6069 12.2075 23.6723 12.2058 23.7393C12.2042 23.8063 12.2204 23.8725 12.2529 23.9312C12.2854 23.9898 12.333 24.0387 12.3907 24.0728C12.4484 24.1068 12.5142 24.1249 12.5813 24.125H13.6594C13.7906 24.125 13.9094 24.0562 13.9781 23.9469L15.9375 20.775L17.8844 23.9438C17.9531 24.0562 18.075 24.1219 18.2031 24.1219H19.375C19.4469 24.1219 19.5156 24.1 19.5781 24.0625C19.7531 23.95 19.8031 23.7188 19.6906 23.5438L17.0656 19.4688L19.7313 15.3281C19.7673 15.2714 19.7875 15.206 19.7898 15.1388C19.792 15.0716 19.7762 15.005 19.744 14.946C19.7118 14.8869 19.6643 14.8376 19.6066 14.8032C19.5488 14.7687 19.4829 14.7503 19.4156 14.75H18.3C18.1687 14.75 18.0469 14.8187 17.9781 14.9312L16.0656 18.1281Z"
        fill="#161925"
      />
    </svg>
  );
};