import { getLinkedInfoToDoEmergency, getLinkedInfoToDoService, getLinkedInfoToDos } from "@services";
import { EPermissionView, ICombinedCard } from "@types";
import { create } from "zustand";

type State = {
  isAssigned: boolean;
  setUserAssigned: (value: boolean) => void;
  cardsLinkedInfo: ICombinedCard[] | null;
  fetchCardsLinkedInfo: (
    type: keyof typeof EPermissionView,
    id: string,
    cardId: string
  ) => Promise<void>;
};

export const useToDo = create<State>((set) => ({
  isAssigned: false,
  setUserAssigned: (value) => set({ isAssigned: value }),
  cardsLinkedInfo: null,
  fetchCardsLinkedInfo: async (type, id, cardId) => {
    try {
      if (EPermissionView[type] === "user") {
        const res = await getLinkedInfoToDoService(id, cardId);
        set({ cardsLinkedInfo: res.data });
      }
      if (EPermissionView[type] === "temporaryShare") {
        const res = await getLinkedInfoToDos(id, cardId);
        set({ cardsLinkedInfo: res.data });
      }
      if (EPermissionView[type] === "emergencyAccess") {
        const res = await getLinkedInfoToDoEmergency(id, cardId);
        set({ cardsLinkedInfo: res.data });
      }
    } catch (error) {}
  },
}));
