import { Button } from "antd";
import styled from "styled-components";
import { KButtonProps } from "./types";
import { ESpacing } from "../../types";

const StyledButton = styled(Button) <KButtonProps>`
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 ${ESpacing.lg};
  font-weight: 500;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.02);
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &.ant-btn-link {
    box-shadow: none;
    padding: 0 8px;
  };
`;

export {
    StyledButton
};