import { KSmTextPrimaryBase, KSmTextSecondary65 } from "@components";
import { Color, EFontSize, EMRExportStatus } from "@types";
import { Button, ButtonProps } from "antd";

const SyncAgainButton = ({ ...props }: ButtonProps) => {
  return (
    <Button
      type="link"
      size="small"
      className="btn-sync-again"
      style={{ height: 16, lineHeight: "16px", fontSize: EFontSize.sm }}
      {...props}
    >
      Sync Again
    </Button>
  );
};

const SyncFailedButton = ({ ...props }: ButtonProps) => {
  return (
    <Button
      type="link"
      size="small"
      className="btn-sync-again"
      style={{
        height: 16,
        lineHeight: "16px",
        fontSize: EFontSize.sm,
        color: Color.RedErrorActive,
      }}
      {...props}
    >
      Failed. Sync Again.
    </Button>
  );
};

const FetchingButton = () => {
  return <KSmTextSecondary65 children="Fetching Records..." />;
};

const ReadyReviewButton = () => {
  return <KSmTextPrimaryBase children="Ready to Review" />;
};

const mapSyncButton: {
  [key: string]: { SyncButton: React.ComponentType<any> };
} = {
  [EMRExportStatus.EXPORT_FAILED]: { SyncButton: SyncFailedButton },
  [EMRExportStatus.EXPORT_SUCCESS]: { SyncButton: ReadyReviewButton },
  [EMRExportStatus.PENDING]: { SyncButton: FetchingButton },
  [EMRExportStatus.SUCCESS]: { SyncButton: SyncAgainButton },
};

interface EMRSyncButtonProps extends ButtonProps {
  status: EMRExportStatus;
}

export const EMRSyncButton = ({ status, ...props }: EMRSyncButtonProps) => {
  const { SyncButton } = mapSyncButton[status];
  return SyncButton ? <SyncButton {...props} /> : null;
};
