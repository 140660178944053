import { IAttachment } from "@types";
import { UploadFile } from "antd/es/upload";
import moment from "moment";

const getFileExtension = (filename: string): string => {
  const parts: string[] = filename.split('.');
  if (parts.length > 1) {
    return parts[parts.length - 1];
  } else {
    return "";
  }
};

const isImageFile = (file: File): boolean => {
  return file.type.startsWith('image/');
};

const createFileObject = (atm: IAttachment): UploadFile<IAttachment> => ({
  uid: atm._id,
  name: atm.originalName,
  fileName: atm.extension,
  status: "done",
  size: atm.size,
  url: atm.url,
  thumbUrl: atm.thumbnailUrl,
  lastModifiedDate: moment(atm.createdTime).toDate(),
});

const fileAntdToAttachment = (file: UploadFile): IAttachment => {
  const { uid, url = "", fileName = "", lastModifiedDate = "", size = 0, type = "", name } = file;
  return {
    _id: uid,
    url: url,
    extension: fileName,
    createdTime: lastModifiedDate,
    size: size,
    mineType: type,
    originalName: name,
  };
};

const getExtensionFromUrl = (url?: string): string => {
  if (!url) return '';
  const pathname = new URL(url).pathname;
  const filename = pathname.split('/').pop() || '';
  const extension = filename.split('.').pop() || '';
  return extension;
};

export {
  createFileObject,
  fileAntdToAttachment,
  getExtensionFromUrl, getFileExtension,
  isImageFile
};

