import { ECardType, ECardTypeName, IKSelectOption } from "@types";

export const handleMapOptions = (value: any): IKSelectOption[] => {
  return Object.values(value).map((val) => ({
    value: val,
    label: val,
  })) as IKSelectOption[];
};

export const handleMapText = (
  value: ECardType | undefined,
  isUseCapitalizeFirstLetter: boolean = true
) => {
  if (!value) return;
  const textParsed = {
    [ECardType.Allergies]: ECardTypeName.Allergy,
    [ECardType.Conditions]: ECardTypeName.Condition,
    [ECardType.Attachments]: ECardTypeName.Attachments,
    [ECardType.Appointments]: ECardTypeName.Appointment,
    [ECardType.Files]: ECardTypeName.File,
    [ECardType.Hospitalizations]: ECardTypeName.Hospitalization,
    [ECardType.IdCard]: ECardTypeName.IdCard,
    [ECardType.Medications]: ECardTypeName.Medications,
    [ECardType.Notes]: ECardTypeName.Notes,
    [ECardType.Practitioners]: ECardTypeName.Practitioners,
    [ECardType.Procedures]: ECardTypeName.Procedures,
    [ECardType.Questions]: ECardTypeName.Questions,
    [ECardType.ToDos]: ECardTypeName.ToDos,
    [ECardType.Vaccinations]: ECardTypeName.Vaccinations,
    [ECardType.Reminder]: ECardTypeName.Reminder,
  };
  return isUseCapitalizeFirstLetter
    ? capitalizeFirstLetter(textParsed[value])
    : textParsed[value];
};

export const capitalize = (text?: string): string => {
  return text?.toUpperCase() ?? '';
};

export const capitalizeFirstLetter = (text?: string) => {
  if (!text?.length) return;
  return text.charAt(0).toUpperCase() + text.slice(1);
};
