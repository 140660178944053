import { sizeAvatar } from "@types";
import { Avatar } from "antd";
import { FC } from "react";
import { EAvatarSize } from "../enums";
import { styledAvatar } from "./styles";
import { IKAvatarProps } from "./types";

export const KAvatar: FC<IKAvatarProps> = ({
  imgUrl,
  firstName = "A",
  lastName = "B",
  size = EAvatarSize.large,
  shape = "square",
  style,
  ...props
}) => {
  return (
    <Avatar
      style={{ ...styledAvatar(size, shape), ...style }}
      shape={shape}
      size={sizeAvatar[size].size}
      {...props}
      src={imgUrl}
    >
      {firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase()}
    </Avatar>
  );
};