import { Divider } from "antd";
import { useMemo, useState } from "react";

import { KSemiText700, Loading } from "@components";
import { useFetchInfoTypesService } from "@hooks";
import { useListInfoType } from "@store";
import { EFileSort, ICardAllergyRes } from "@types";
import { AllergyItem, SortItem } from "../item";
import { CustomSpaceList, WrapperCardList } from "../share-component";
import { getQuery } from "@utils/card";

const renderCardItems = (list: ICardAllergyRes[] = []) => {
  return list.map((card: ICardAllergyRes) => (
    <AllergyItem key={card._id} card={card} />
  ));
};

export const ListAllergies = () => {
  const {
    pastAllergyList,
    presentAllergyList,
    isLoadingList,
    isLoadingInactiveList,
  } = useListInfoType();
  const [sort, setSort] = useState<EFileSort>(EFileSort.New);

  const query = useMemo(() => getQuery(sort), [sort]);

  useFetchInfoTypesService("Allergies", query);

  return (
    <WrapperCardList space={16}>
      <CustomSpaceList>
        <KSemiText700>Current Allergies</KSemiText700>
        <SortItem sort={sort} setSort={setSort} />
        {isLoadingList ? <Loading /> : renderCardItems(presentAllergyList)}
      </CustomSpaceList>
      <Divider />
      <CustomSpaceList>
        <KSemiText700>No Longer Experiencing</KSemiText700>
        {isLoadingInactiveList ? <Loading /> : renderCardItems(pastAllergyList)}
      </CustomSpaceList>
    </WrapperCardList>
  );
};
