import { breakpointLarge, heightHeaderMobile } from "@constants/size";
import { Flex } from "antd";
import styled from "styled-components";

const StyledCardDetail = styled(Flex)`
  max-height: calc(100vh - 330px);
  overflow-y: auto;
  @media (max-width: ${breakpointLarge}px) {
    max-height: calc(100vh - ${heightHeaderMobile + 142}px);
  }
`;

export const WrapperCardDetail = ({
  children,
  gap = 16,
}: {
  children: React.ReactNode;
  gap?: number;
}) => {
  return (
    <StyledCardDetail
      vertical
      gap={gap}
    >
      {children}
    </StyledCardDetail>
  );
};
