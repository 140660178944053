import { Modal, ModalProps } from "antd";
import styled from "styled-components";

const ModalConfirmStyled = styled(Modal)`
  .ant-modal-footer {
    margin-top: 32px;
    display: flex;
    justify-content: end;
    gap: 8px;
  }
  .ant-btn-primary {
    box-shadow: none;
  }
`;

export const KModalConfirm = (props: ModalProps) => {
  return (
    <ModalConfirmStyled
      okText="Yes, delete"
      cancelText="No, go back"
      okButtonProps={{ danger: true, size: "large" }}
      cancelButtonProps={{ ghost: true, type: "primary", size: "large" }}
      closeIcon={null}
      centered
      width={414}
      {...props}
    />
  );
};