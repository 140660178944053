import { EyeFilled } from "@ant-design/icons";
import { EMRSyncIcon } from "@assets/icons";
import {
  KLgText700,
  KMdText,
  KMdText700,
  KMdTextSecondary65,
  KSmTextPrimaryBase,
  StyledModal,
} from "@components";
import { Color } from "@types";
import { formatDate } from "@utils/date";
import { Flex } from "antd";
import { useState } from "react";

interface IEMRDetailProps {
  source?: string;
  originalData?: string;
  createdTime: string;
}

const renderOriginalData = (originalData: string) => {
  const data = JSON.parse(originalData);
  return Object.entries<string[]>(data).map(([key, value]) => (
    <Flex key={key} vertical gap={2}>
      <KMdText>{key}:</KMdText>
      <KMdText>{value}</KMdText>
    </Flex>
  ));
};

export const EMRDetail = ({
  source,
  originalData,
  createdTime,
}: IEMRDetailProps) => {
  const [openModalSource, setOpenModalSource] = useState(false);

  return originalData ? (
    <Flex align="center" justify="space-between">
      <Flex gap={4} align="center">
        <EMRSyncIcon fill={Color.Secondary65} width={20} />
        <KMdTextSecondary65>{source}</KMdTextSecondary65>
      </Flex>
      <Flex
        gap={4}
        align="center"
        style={{ cursor: "pointer" }}
        onClick={() => setOpenModalSource(true)}
      >
        <EyeFilled style={{ color: Color.PrimaryBase, fontSize: 20 }} />
        <KSmTextPrimaryBase>View Original</KSmTextPrimaryBase>
      </Flex>
      <StyledModal
        open={openModalSource}
        onCancel={() => setOpenModalSource(false)}
        styles={{ content: { padding: 24 } }}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ type: "primary" }}
        cancelText="Back"
        centered
      >
        <Flex vertical gap={16}>
          <KLgText700 $align="center">Original Source Import</KLgText700>
          <Flex vertical gap={8}>
            <KMdText700>{source}</KMdText700>
            <KMdTextSecondary65>
              Imported On:{" "}
              {formatDate(createdTime, "MONTH_DAY_YEAR_WITH_SPLASH")}
            </KMdTextSecondary65>
          </Flex>
          {renderOriginalData(originalData)}
        </Flex>
      </StyledModal>
    </Flex>
  ) : null;
};
