import { Flex, FlexProps, Space, Typography } from "antd";
import { EllipsisConfig } from "antd/es/typography/Base";
import { ReactNode } from "react";

const { Title, Text, Paragraph } = Typography;

interface ICardTitleProps extends FlexProps {
  icon?: ReactNode;
  children: ReactNode;
  ellipsis?: boolean | EllipsisConfig;
  suffix?: ReactNode;
}

export const CardTitle = ({ children, icon, ellipsis, suffix, ...props }: ICardTitleProps) => (
  <Flex gap={4} align="center" justify="space-between" {...props}>
    <Space size={4} align="center" direction="horizontal">
      {!!icon && (
        <Flex>{icon}</Flex>
      )}
      <Title ellipsis={ellipsis} level={4}>
        {children}
      </Title>
    </Space>
    {suffix}
  </Flex>
);
