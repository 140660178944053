import { KAnchor, KMdTextSecondary88 } from "@components";
import { getAppUrl } from "@constants/url";
import { useKContext } from "@context";
import { Flex } from "antd";
import { useMemo } from "react";
import {
  RedemptionFooterContainer,
  RedemptionFooterContainerMobile,
} from "./styled-components";

export const KRedemptionFooter: React.FC = () => {
  const { isMobile } = useKContext();
  const Container = useMemo(() => {
    return isMobile
      ? RedemptionFooterContainerMobile
      : RedemptionFooterContainer;
  }, [isMobile]);
  return (
    <Container>
      <Flex gap={16} justify="center" vertical={isMobile}>
        <Flex align="center" justify="center" vertical={isMobile} gap={4}>
          <KMdTextSecondary88>
            Want to learn more about KinKeeper?
          </KMdTextSecondary88>
          <KAnchor
            fontWeight="700"
            href={getAppUrl}
            target="_blank"
            $isUnderlined
          >
            www.kinkeeper.app
          </KAnchor>
        </Flex>
        {!isMobile && <KMdTextSecondary88>|</KMdTextSecondary88>}
        <KMdTextSecondary88>
          © 2024 Kith + Kin. All rights reserved.
        </KMdTextSecondary88>
      </Flex>
    </Container>
  );
};
