import { AddPlusIcon, UpgradePremiumIcon } from "@assets/index";
import { useRoleUser } from "@store";
import { Flex, Space } from "antd";
import React from "react";
import { KBadgeCount, KLgText700 } from "..";
import { ButtonAddStyled, CardButtonToggle, ContainerIconPremium } from "./styles";
import { IKCardItemInfoProps } from "./types";

export const KCardItemInfo: React.FC<IKCardItemInfoProps> = ({
  iconLeft,
  title,
  counter,
  isActive = false,
  onClick,
  onAddCard,
  addable = true,
  isFreemium
}) => {
  const { roleContributor } = useRoleUser();
  const handleAddCard = (e: any) => {
    e.stopPropagation();
    onAddCard && onAddCard();
  };

  return (
    <Space.Compact block size="small" {...{ onClick }}>
      <CardButtonToggle $isActive={isActive} $addable={addable && !isFreemium} $disabled={isFreemium}>
        <Space align="center">
          {iconLeft && (
            <Flex align="center">
              {iconLeft}
            </Flex>
          )}
          <KLgText700 $ellipsis {...isFreemium && { color: "Secondary65" }}>
            {title}
          </KLgText700>
        </Space>
        <Flex style={{ height: "100%" }}>
          <Flex>
            {counter !== undefined && <KBadgeCount counter={counter}/>}
          </Flex>
          {(isFreemium && roleContributor) && (
            <ContainerIconPremium $active={isActive}>
              <UpgradePremiumIcon/>
            </ContainerIconPremium>
          )}
        </Flex>
      </CardButtonToggle>
      {(!isFreemium && addable) && (
        <ButtonAddStyled onClick={handleAddCard}>
          <AddPlusIcon/>
        </ButtonAddStyled>
      )}
    </Space.Compact>
  );
};