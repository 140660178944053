import { Divider, Flex } from "antd";

import { Loading } from "@components";
import { useFetchInfoTypesService } from "@hooks";
import { useListInfoType } from "@store";
import { EFileSort } from "@types";
import { useMemo, useState } from "react";
import { QuestionItem, SortItem } from "../item";
import { Section } from "../share-component";
import { getQuery } from "@utils/card";

export const ListQuestions = () => {
  const {
    unansweredQsList = [],
    otherQsList = [],
    isLoadingList,
    isLoadingInactiveList,
  } = useListInfoType();
  const [sort, setSort] = useState<EFileSort>(EFileSort.New);

  const query = useMemo(() => getQuery(sort), [sort]);

  useFetchInfoTypesService("Questions", query);

  return (
    <Flex gap={16} vertical>
      <Section title="Active Questions">
        <SortItem sort={sort} setSort={setSort} />
        {isLoadingList ? (
          <Loading />
        ) : (
          unansweredQsList.map((i) => <QuestionItem key={i._id} card={i} />)
        )}
      </Section>
      <Divider />
      <Section title="Answered Questions">
        {isLoadingInactiveList ? (
          <Loading />
        ) : (
          otherQsList.map((i) => <QuestionItem key={i._id} card={i} />)
        )}
      </Section>
    </Flex>
  );
};
