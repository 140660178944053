import { Loading } from "@components";
import { useMedicalRecordPermission } from "@hooks";
import {
  getDetailMedicalRecordService,
  IMedicalRecord
} from "@services";
import { useEMRStore } from "@store";
import { EEMRPage, EMRExportStatus, IParams } from "@types";
import { message } from "antd";
import { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  EMRFetchingRecord,
  EMRImportSelect,
  EMRImportSuccess,
  EMRProgress,
  EMRSyncFailed,
} from "../components";
import { ContainerScreenEMR, ScreenEMRContent } from "../styled-components";

const medicalRecordsPages: {
  [key: string]: { PageItem: React.ComponentType<any> | null };
} = {
  [EEMRPage.IMPORT_SELECT]: { PageItem: EMRImportSelect },
  [EEMRPage.IMPORT_SUCCESS]: { PageItem: EMRImportSuccess },
  [EEMRPage.FETCHING_RECORD]: { PageItem: EMRFetchingRecord },
  [EEMRPage.SYNC_FAILED]: { PageItem: EMRSyncFailed },
};

export const getMedRecordPage = (page?: EEMRPage) => {
  return page ? medicalRecordsPages[page] : { PageItem: null };
};

export const EMRImportScreen = () => {
  const { profileId, connectionId } = useParams<IParams>();
  const { pageEMR, setPageEMR, forceUpdateListMedRecord, indexDetailMedRecord } = useEMRStore();
  const { PageItem } = getMedRecordPage(pageEMR);
  const intervalIdRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const [medicalRecord, setMedicalRecord] = useState<IMedicalRecord>();
  const [loading, setLoading] = useState(false);

  const fetchData = async (profileId: string, connectionId: string) => {
    try {
      const medRecord = await getDetailMedicalRecordService(
        profileId,
        connectionId
      );
      if (!medRecord) return;
      const { status } = medRecord;
      setMedicalRecord(medRecord);

      if (status !== EMRExportStatus.PENDING && intervalIdRef.current) {
        forceUpdateListMedRecord();
        clearInterval(intervalIdRef.current);
        if (status === EMRExportStatus.EXPORT_FAILED) {
          setPageEMR(EEMRPage.SYNC_FAILED);
          return;
        }
        setPageEMR(EEMRPage.IMPORT_SELECT);
        return;
      }

      setPageEMR(EEMRPage.FETCHING_RECORD);
    } catch (error) {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    }
  };

  useMedicalRecordPermission();

  useEffect(() => {
    if (!connectionId || !profileId) return;
    (async () => {
      try {
        setLoading(true);
        await fetchData(profileId, connectionId);
      } catch (error: any) {
        const { code, message: errorMessage } = error?.response?.data || {};
        if (code === 403 || code === 503) {
          message.error(errorMessage);
        }
      } finally {
        setLoading(false);
      }
    })();

    intervalIdRef.current = setInterval(() => {
      fetchData(profileId, connectionId);
    }, 5000);

    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    };
  }, [connectionId, profileId, indexDetailMedRecord]);

  const percent = useMemo(() => {
    switch (pageEMR) {
      case EEMRPage.IMPORT_SELECT:
      case EEMRPage.FETCHING_RECORD:
        return 66;
      case EEMRPage.IMPORT_SUCCESS:
        return 100;
      default:
        return 0;
    }
  }, [pageEMR]);

  return (
    <ContainerScreenEMR vertical>
      <ScreenEMRContent vertical flex={1}>
        {pageEMR !== EEMRPage.SYNC_FAILED && (
          <EMRProgress
            showInfo={false}
            success={{ percent: 100 }}
            percent={percent}
          />
        )}
        {loading ? <Loading /> : PageItem && <PageItem {...medicalRecord} />}
      </ScreenEMRContent>
    </ContainerScreenEMR>
  );
};
