import { Flex } from "antd";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import {
  KAnchor,
  KBadgeCount,
  KButton,
  KCollapse,
  KLgText700,
  KLgText700PrimaryBase,
  KMdText,
  KSemiText700,
  Loading,
  ModalConfirmCancel,
} from "@components";
import {
  useCustomNavigate,
  useFetchSummaryService,
  useUnsavedChangesWarning,
} from "@hooks";
import {
  ICardImport,
  IMedicalRecord,
  importCardsFromMedicalRecord,
} from "@services";
import { useEMRStore } from "@store";
import {
  EEMRPage,
  EMRExportStatus,
  EUrlPath,
  infoTypeTextMedRecord,
  IParams,
} from "@types";
import { EMRCardImport } from ".";

export const EMRImportSelect = (medicalRecord: IMedicalRecord) => {
  const { fetchSummaryCardUser } = useFetchSummaryService();
  const { setPageEMR, setCardsImported, forceUpdateListMedRecord } =
    useEMRStore();
  const { navigate } = useCustomNavigate();
  const { profileId } = useParams<IParams>();
  const [loading, setLoading] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const [selectedCardIds, setSelectedCardIds] = useState<string[]>([]);

  const onOkCancelImport = () => {
    setSelectedCardIds([]);
    setOpenCancel(false);
    navigate(`/user/${profileId}/${EUrlPath.MEDICAL_RECORDS}`);
  };

  useUnsavedChangesWarning(selectedCardIds.length > 0 && !openCancel);

  const onChangeCheckbox = (id: string) => {
    setSelectedCardIds((p) => {
      if (p.includes(id)) {
        return p.filter((i) => i !== id);
      }
      return [...p, id];
    });
  };

  const allCard = useMemo(
    () =>
      medicalRecord?.data?.reduce<ICardImport[]>(
        (total, cur) => [...total, ...cur.items],
        []
      ) ?? [],
    [JSON.stringify(medicalRecord?.data)]
  );

  const onSelectAll = () => {
    if (loading) return;
    const notImportedList = allCard.filter((i: ICardImport) => !i.isImported);
    if (notImportedList.length === selectedCardIds.length) return;
    setSelectedCardIds(notImportedList.map((i: ICardImport) => i.medExportId));
  };

  const handleImport = async () => {
    if (!profileId || !medicalRecord?._id) return;
    try {
      setLoading(true);
      await importCardsFromMedicalRecord(
        profileId,
        medicalRecord?._id,
        selectedCardIds
      );
      const cards = medicalRecord.data?.map((i) => ({
        ...i,
        items: i.items.filter((c: ICardImport) =>
          selectedCardIds.includes(c.medExportId)
        ),
      }));
      if (medicalRecord.status !== EMRExportStatus.SUCCESS) {
        forceUpdateListMedRecord();
      }
      setCardsImported(cards);
      setPageEMR(EEMRPage.IMPORT_SUCCESS);
      fetchSummaryCardUser(profileId);
    } catch {
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ModalConfirmCancel
        open={openCancel}
        onCancel={() => setOpenCancel(false)}
        onOk={onOkCancelImport}
        title="Are you sure you want to cancel this import?"
        descriptions="Since you’ve already connected to the provider, this will count as an
          import on your account."
      />
      <Flex vertical gap={4}>
        <KSemiText700>Select Records to Import</KSemiText700>
        <Flex justify="space-between" align="center">
          <KLgText700PrimaryBase>Available Records</KLgText700PrimaryBase>
          <KBadgeCount isNeedWidth={false}>
            <KLgText700>{allCard?.length}</KLgText700>
          </KBadgeCount>
        </Flex>
      </Flex>
      {loading ? (
        <Loading />
      ) : (
        !!medicalRecord?.data?.length && (
          <>
            <KButton
              size="large"
              disabled={!selectedCardIds.length}
              title={`Import Selected (${selectedCardIds.length})`}
              onClick={handleImport}
            />
            <KMdText>
              We are working hard to import a complete set of information from
              your medical records. Currently, we support:
            </KMdText>
            <Flex vertical flex={1} gap={8}>
              <KAnchor $align="right" onClick={onSelectAll}>
                Select All
              </KAnchor>
              {medicalRecord.data.map((item, idx) => (
                <KCollapse
                  key={idx}
                  title={
                    <Flex justify="space-between">
                      <KLgText700>
                        {infoTypeTextMedRecord[item.cardType]}
                      </KLgText700>
                      <KBadgeCount
                        children={<KLgText700>{item.items.length}</KLgText700>}
                        isNeedWidth={false}
                      />
                    </Flex>
                  }
                >
                  <Flex vertical gap={8}>
                    {item.items.map((card) => (
                      <EMRCardImport
                        key={card.medExportId}
                        showCheckBox={true}
                        card={card}
                        checked={selectedCardIds.includes(card.medExportId)}
                        onChangeCheckbox={() => {
                          onChangeCheckbox(card.medExportId);
                        }}
                      />
                    ))}
                  </Flex>
                </KCollapse>
              ))}
            </Flex>
            <Flex vertical gap={8}>
              <KButton
                size="large"
                disabled={!selectedCardIds.length}
                title={`Import Selected (${selectedCardIds.length})`}
                onClick={handleImport}
              />
              <KButton
                type="default"
                size="large"
                title="Cancel"
                onClick={() => setOpenCancel(true)}
              />
            </Flex>
          </>
        )
      )}
    </>
  );
};
