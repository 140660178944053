import { VectorRightIcon } from "@assets/icons";
import { heightCardItemInfo } from "@constants/index";
import { Color, EBorderRadius, ESpacing } from "@types";
import { Space } from "antd";
import { shareCardStyle } from "components/styled-component";
import styled from "styled-components";

interface CardDetailProps {
  $isActive: boolean;
  $requirePremium?: boolean;
  $disabled?: boolean; 
}

const CardDetailStyled = styled(Space)<CardDetailProps>`
  width: ${({ $disabled }) => $disabled ? '100%' : `calc(100% - ${heightCardItemInfo}px)`};
  padding: 0px ${ESpacing.md};
  border-width: 1px;
  border-right: ${({ $disabled }) => $disabled ? '1px' : 0};
  border-top-left-radius: ${EBorderRadius.lg};
  border-bottom-left-radius: ${EBorderRadius.lg};
  border-top-right-radius: ${({ $disabled }) => $disabled ? EBorderRadius.lg : 0};
  border-bottom-right-radius: ${({ $disabled }) => $disabled ? EBorderRadius.lg : 0};
  height: ${heightCardItemInfo}px;
  border-color: ${({ $isActive }) =>
    $isActive ? Color.PrimaryBase : Color.Secondary10};
  background: ${({ $disabled }) => $disabled ? Color.Secondary06 : Color.BgPrimary};
  color: ${Color.PrimaryBase};
  ${shareCardStyle};
  cursor: ${({ $requirePremium, $disabled }) => ($requirePremium || $disabled) ? 'default' : `pointer`};
  span, svg {
    color: ${({ $disabled }) => $disabled ? Color.Secondary65 : Color.PrimaryBase} !important;
    fill: ${({ $disabled }) => $disabled ? Color.Secondary65 : Color.PrimaryBase};
  }
`;

const CardButtonStyled = styled.div`
  width: ${heightCardItemInfo}px;
  border-radius: 0px ${EBorderRadius.lg} ${EBorderRadius.lg} 0px;
  background-color: ${Color.PrimaryBase};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconStyled = styled(VectorRightIcon)`
  width: 20px;
  height: 20px;
  margin-left: ${ESpacing.md};
`;

const IconProfileBasic = styled.span`
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const WrapperPremiumIcon = styled.div<{ $active?: boolean }>`
  width: ${heightCardItemInfo}px;
  border-radius: 0px ${EBorderRadius.lg} ${EBorderRadius.lg} 0px;
  background-color: ${Color.BgPrimary};
  border-width: 1px 1px 1px 0px;
  border-color: ${Color.Secondary10};
  border-style: solid;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export {
  CardButtonStyled,
  CardDetailStyled, IconProfileBasic, IconStyled, WrapperPremiumIcon
};
