import { Divider, Flex } from "antd";
import styled from "styled-components";
import { FC, useContext, useEffect } from "react";
import { UserOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";

import {
  KAnchor,
  KButton,
  KLgText700PrimaryBase,
  KMdTextSecondary88,
  KSmText,
  KSmText700Secondary65,
  KXlText700Secondary88,
} from "@components";
import { Color, EAuthPage, IParams } from "@types";
import { UserFilled } from "@assets/icons";
import { homePageUrl } from "@constants/url";
import { useAuthStore } from "@store";
import { getToken } from "@utils/storage";
import { KContext } from "@context";
import { useCustomNavigate } from "@hooks";

const OptionContainer = styled.div({
  border: `1px solid ${Color.PrimaryBase}`,
  borderRadius: 12,
});

const OptionTitle = styled.div({
  display: 'flex',
  padding: '8px 12px',
  gap: 4,
  flexDirection: 'row',
  alignItems: 'center',
});

const OptionBody = styled.div({
  display: 'flex',
  padding: 12,
  gap: 12,
  flexDirection: 'column',
});

export enum ESignInType {
  USER = 'USER',
  GUEST = 'GUEST',
}

const LoginOption: FC<{
  type: ESignInType;
  onClick: () => void;
}> = ({ type, onClick }) => {
  return (
    <OptionContainer>
      <OptionTitle>
        {type === ESignInType.USER ? (
          <UserFilled />
        ) : (
          <UserOutlined style={{ color: "#2E747C" }} />
        )}
        <KLgText700PrimaryBase>
          {type === ESignInType.USER ? (
            'I’m already a user'
          ) : (
            'I’m not a registered user'
          )}
        </KLgText700PrimaryBase>
      </OptionTitle>
      <Divider />
      <OptionBody>
        <KSmText>
          {type === ESignInType.USER ? (
            'If you’ve already created an KinKeeper account on the mobile app, log in to your account.'
          ) : (
            'If you received a view link from someone and have not created a KinKeeper account, click below or open the link you received to view the information.'
          )}
        </KSmText>
        <KButton
          type="primary"
          size="large"
          title={type === ESignInType.USER ? "Log in to my account" : "View what was shared with me"}
          onClick={onClick}
        />
      </OptionBody>
    </OptionContainer>
  )
};

export const Welcome: FC<{ hasAccount?: boolean }> = ({ hasAccount = false }) => {
  const {
    setCurrentPage,
    setLoginData,
    canAccess,
    isRecognize,
    setIsRecognize,
    setIsAuthenticated,
  } = useAuthStore();
  const { navigate } = useCustomNavigate();
  const { tempShareId } = useParams<IParams>();
  const { infoPrincipal } = useContext(KContext);

  useEffect(() => {
    if (hasAccount) return;
    setLoginData('');
  }, [hasAccount]);

  return (
    <Flex vertical gap={16}>
      <KXlText700Secondary88>
        {hasAccount ? 'Look like you have an account' : 'Welcome to KinKeeper!'}
      </KXlText700Secondary88>
      <LoginOption
        type={ESignInType.USER}
        onClick={() => {
          if (isRecognize) {
            setIsRecognize(false);
          }
          setCurrentPage(EAuthPage.WELCOME_USER);
        }}
      />
      {(!isRecognize || !hasAccount) && (
        <>
          <Divider>
            <KSmText700Secondary65>OR</KSmText700Secondary65>
          </Divider>
          <LoginOption
            type={ESignInType.GUEST}
            onClick={() => {
              if (!infoPrincipal?.principal?.owner && hasAccount) return;
              if (hasAccount && getToken('TempShare') && tempShareId && canAccess) {
                setIsAuthenticated(true);
                navigate(`/${tempShareId}`, { replace: true });
                return;
              }
              setCurrentPage(hasAccount ? EAuthPage.REQUEST_OTP : EAuthPage.WELCOME_GUEST);
            }}
          />
        </>
      )}
      <Divider />
      <Flex gap={2} vertical>
        <KMdTextSecondary88>
          Don’t have an account yet?
        </KMdTextSecondary88>
        <KAnchor
          $isUnderlined
          target="_blank"
          size="md"
          color="PrimaryBase"
          href={homePageUrl}
        >
          Download the app to create an account.
        </KAnchor>
      </Flex>
    </Flex>
  );
};
