import {
  breakpointLarge,
  heightBreadcrumb,
  heightFooter,
  heightHeader,
} from "@constants/size";
import { Color, EFontSize, ELineHeight } from "@types";
import { Flex } from "antd";
import styled from "styled-components";

export const SectionWrapper = styled(Flex)`
  display: flex;
  height: calc(
    100vh - ${heightHeader}px - ${heightFooter}px - ${heightBreadcrumb}px
  );
  background: ${Color.White};
  border: 1px solid ${Color.Secondary10};
  border-radius: 4px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.02);
  width: 100%;
  @media (max-width: ${breakpointLarge}px) {
    flex-direction: column;
    align-items: stretch;
    height: auto;
    border: none;
  }
`;

export const RedemptionWrapper = styled(Flex)`
  background: ${Color.White};
  border: 1px solid ${Color.Secondary10};
  border-radius: 8px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.02);
  width: 816px;
  height: fit-content;
  @media (max-width: ${breakpointLarge}px) {
    width: 100%;
    border: 0;
  }
`;

export const shareCardStyle = `
  border-style: solid;
  font-weight: 700;
  font-size: ${EFontSize.lg}px;
  line-height: ${ELineHeight.lg}px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
`;
