import axios, { CreateAxiosDefaults } from "axios";

export const apiBaseConfig: CreateAxiosDefaults = {
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
};

export const apiInstance = axios.create(apiBaseConfig);

export const authApiInstance = axios.create(apiBaseConfig);

export * from "./interceptor";
export * from "./thirdParty";
