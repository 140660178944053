import { ECardType } from "@types";

export interface IInfoDetail {
  title: string;
  name: string;
  displayName: string;
}

export const fieldDetail: { [key: ECardType | string]: IInfoDetail } = {
  [ECardType.Allergies]: { title: "Allergy", name: "allergy", displayName: "allergyName" },
  [ECardType.Notes]: { title: "Note", name: "note", displayName: "noteName" },
  [ECardType.Questions]: { title: "Question", name: "question", displayName: "questionName" },
  [ECardType.Medications]: { title: "Medication", name: "medication", displayName: "medicationName" },
  [ECardType.Appointments]: { title: "Appointment", name: "appointment", displayName: "appointmentName" },
  [ECardType.Conditions]: { title: "Condition", name: "condition", displayName: "conditionName" },
  [ECardType.Hospitalizations]: { title: "Hospitalization", name: "hospitalization", displayName: "hospitalizationName" },
  [ECardType.Procedures]: { title: "Procedure", name: "procedure", displayName: "procedureName" },
  [ECardType.Vaccinations]: { title: "Vaccination", name: "vaccination", displayName: "vaccinationName" },
  [ECardType.IdCard]: { title: "ID Card", name: "idCard", displayName: "idCardName" },
  [ECardType.Practitioners]: { title: "Practitioner", name: "practitioner", displayName: "practitionerName" },
  [ECardType.Files]: { title: "File", name: "file", displayName: "fileName" },
  [ECardType.ToDos]: { title: "To-Do", name: "toDo", displayName: "toDoName" },
};

export const getFieldDetail = (cardType?: string): IInfoDetail => {
  return fieldDetail[cardType || ""] || { title: "", displayName: "", name: "" };
}
