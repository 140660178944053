import { useMemo } from "react";
import { UseFormGetValues, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { FormItemProps } from "react-hook-form-antd";
import { InputNumber, InputNumberProps, Select } from "antd";

import { IKSelectOption } from "@types";
import { KFormGroupNoControl } from "../FormGroup";

export type IInputWithDropdownProps =
  InputNumberProps<string> &
  Omit<FormItemProps, "control" | "children"> &
  {
    options: IKSelectOption[];
    selectName?: any;
  }

type IProps = IInputWithDropdownProps & {
  setValue: UseFormSetValue<any>;
  getValues: UseFormGetValues<any>;
  watch: UseFormWatch<any>;
}

export const KInputWithDropdownFormField = ({
  label,
  name,
  options,
  setValue,
  getValues,
  watch,
  selectName,
  ...props
}: IProps) => {
  const amount = useMemo(
    () => {
      const val = `${watch(name)}`?.split(' ');
      return val?.[0];
    },
    [watch(name)]
  );

  const unit = useMemo(
    () => {
      const val = `${watch(name)}`?.split(' ');
      const selectVal = watch(selectName);
      return (selectName ? selectVal : val?.[1]) ?? '';
    },
    [watch(name), watch(selectName)]
  );

  return (
    <KFormGroupNoControl label={label}>
      <InputNumber
        style={{ width: "100%" }}
        value={amount}
        type="number"
        onChange={(value) => {
          if (selectName) {
            setValue(name, value !== null ? +value : '', { shouldDirty: true });
            return;
          }
          const formVal = `${getValues(name)}`?.split(' ');
          setValue(name, `${value} ${formVal?.[1] || ''}`.trim(), { shouldDirty: true });
        }}
        addonAfter={
          <Select
            style={{ width: "100px" }}
            options={options}
            defaultValue={options[0].value}
            value={unit}
            onChange={(value) => {
              if (selectName) {
                setValue(selectName, value, { shouldDirty: true });
                return;
              }
              const formVal = `${getValues(name)}`?.split(' ');
              setValue(name, `${formVal?.[0] || ''} ${value}`.trim(), { shouldDirty: true });
            }}
          />
        }
        {...props}
      />
    </KFormGroupNoControl>
  );
};
