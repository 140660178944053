export enum ECardType {
  Allergies = 'allergies',
  Appointments = 'appointments',
  Conditions = 'conditions',
  Files = 'files',
  Hospitalizations = 'hospitalizations',
  IdCard = 'id_card',
  Medications = 'medications',
  Notes = 'notes',
  Practitioners = 'practitioners',
  Procedures = 'procedures',
  Questions = 'questions',
  Reminder = 'reminder',
  Vaccinations = 'vaccinations',
  Attachments = 'attachments',
  ToDos = 'to_do',
}

export enum ECardTypeName {
  Allergy = 'allergy',
  Appointment = 'appointment',
  Condition = 'condition',
  File = 'file',
  Hospitalization = 'hospitalization',
  IdCard = 'id-card',
  Medications = 'medication',
  Notes = 'note',
  Practitioners = 'practitioner',
  Procedures = 'procedure',
  Questions = 'question',
  Reminder = 'reminder',
  Vaccinations = 'vaccination',
  Attachments = 'attachment',
  FileGroup = 'file-group',
  ToDos = 'to-do',
}

export enum BaseTabName {
  Details = 'Details',
  Topics = 'Topics',
}

export const AllergiesTabName = {
  ...BaseTabName,
};

export const PractitionersTabName = {
  ...BaseTabName,
  RelevantInfo: 'Related Info',
};

export const NotesTabName = {
  ...BaseTabName,
};

export const MedicationTabName = {
  ...BaseTabName,
  Prescription: 'Prescription',
};

export const ADD_ID = 'add';

export enum EAllergiesType {
  FOOD = 'Food',
  MEDICATION = 'Medication',
  OTHER = 'Other',
}

export enum ESeverity {
  MILD = 'Mild',
  MODERATE = 'Moderate',
  SEVERE = 'Severe',
}

export enum EQuestionStatus {
  UNANSWERED = 'Unanswered',
  ANSWERED = 'Answered',
  NO_LONGER_RELEVANT = 'No longer relevant',
  OTHER = 'Others',
}

export enum EMedicationType {
  TABLET = 'Tablet',
  CAPSULE = 'Capsule',
  DROPS = 'Drops',
  INHALER = 'Inhaler',
  INJECTION = 'Injection',
  IMPLANT = 'Implant',
  LIQUID = 'Liquid',
  PATCH = 'Patch',
  TOPICAL = 'Topical',
  OTHER = 'Other',
}

export enum EMedicationStatus {
  ACTIVELY_TAKING = 'Actively Taking',
  STOPPED_TAKING = 'Stopped Taking',
  DOCTOR_CANCELLED = 'Doctor Cancelled',
  COMPLETED = 'Completed',
}

export enum EConditionType {
  ACUTE = 'Acute (Sudden or onset symptoms)',
  CHRONIC = 'Chronic (Persistent or ongoing symptoms)',
  EPISODIC = 'Episodic (Fluctuating symptoms)',
}

export enum EConditionSeverity {
  MILD = 'Mild',
  MODERATE = 'Moderate',
  SEVERE = 'Severe',
}

export enum EHospitalizationType {
  EMERGENCY_ONLY = 'Emergency Only',
  EMERGENCY_ADMITTED = 'Emergency to Admitted',
  SCHEDULED_ADMITTED = 'Scheduled Admitted',
}

export enum EProcedureType {
  INPATIENT = 'Inpatient',
  OUTPATIENT = 'Outpatient',
  OTHER = 'Other',
}

export enum ESpecialtyType {
  AddictionSpecialistSponsor = 'Addiction Specialist/Sponsor',
  AllergyImmunologist = 'Allergy & Immunologist',
  Anesthesiologist = 'Anesthesiologist',
  BehavioralTherapist = 'Behavioral Therapist',
  BirthCoachDoula = 'Birth Coach (Doula)',
  Cardiologist = 'Cardiologist',
  Chiropractor = 'Chiropractor',
  ColonRectalSurgeon = 'Colon & Rectal Surgeon',
  Counselor = 'Counselor',
  CriticalCareMedicineSpecialist = 'Critical Care Medicine Specialist',
  Dentist = 'Dentist',
  Dermatologist = 'Dermatologist',
  EmergencyMedicine = 'Emergency Medicine',
  Endocrinologist = 'Endocrinologist',
  FamilyMedicine = 'Family Medicine',
  Gastroenterologist = 'Gastroenterologist',
  Geneticist = 'Geneticist',
  GeriatricMedicineSpecialist = 'Geriatric Medicine Specialist',
  HealthCoach = 'Health Coach',
  Hematologist = 'Hematologist',
  HomeCareRegisteredNurse = 'Home Care Registered Nurse',
  HospicePalliativeSpecialist = 'Hospice and Palliative Specialist',
  InfectiousDiseaseSpecialist = 'Infectious Disease Specialist',
  InternalMedicine = 'Internal Medicine',
  LactationSpecialist = 'Lactation Specialist',
  LicensedPracticalNurse = 'Licensed Practical Nurse (LPN)',
  MassageTherapist = 'Massage Therapist',
  Nephrologist = 'Nephrologist',
  Neurologist = 'Neurologist',
  NuclearMedicine = 'Nuclear Medicine',
  NursePractitioner = 'Nurse Practitioner (NP)',
  Nutritionist = 'Nutritionist',
  ObstetricianGynecologist = 'Obstetrician & Gynecologist (OB/GYN)',
  Oncologist = 'Oncologist',
  Ophthalmologist = 'Ophthalmologist',
  Orthodontist = 'Orthodontist',
  OrthopedicSurgeon = 'Orthopedic Surgeon',
  Osteopath = 'Osteopath',
  OtherDoctor = 'Other Doctor',
  OtherNurse = 'Other Nurse',
  OtolaryngologistENT = 'Otolaryngologist (ENT)',
  Pathologist = 'Pathologist',
  Pediatrician = 'Pediatrician',
  Periodontist = 'Periodontist',
  PhysicalMedicineRehabilitation = 'Physical Medicine and Rehabilitation',
  PhysicalTherapist = 'Physical Therapist',
  PlasticSurgeon = 'Plastic Surgeon',
  Podiatrist = 'Podiatrist',
  PreventiveMedicine = 'Preventive Medicine',
  PsychiatricNursePractitioner = 'Psychiatric Nurse Practitioner',
  PsychiatricPA = 'Psychiatric PA',
  Psychiatrist = 'Psychiatrist',
  Psychologist = 'Psychologist',
  Pulmonologist = 'Pulmonologist',
  Radiologist = 'Radiologist',
  RegisteredNurse = 'Registered Nurse (RN)',
  Rheumatologist = 'Rheumatologist',
  SleepSpecialist = 'Sleep Specialist',
  SpeechTherapist = 'Speech Therapist',
  SportsMedicineSpecialist = 'Sports Medicine Specialist',
  Surgeon = 'Surgeon',
  SurgicalAssistantRegisteredNurse = 'Surgical Assistant Registered Nurse',
  Therapist = 'Therapist (LCSW, LPC, LMFT)',
  Urologist = 'Urologist',
}

export enum TabTypes {
  CardType = 'CardType',
  Authentication = 'Authentication',
}

export enum EIdCardType {
  GOV_ID = 'Gov. ID',
  INSURANCE = 'Insurance',
  SCHOOL = 'School',
  WORK = 'Work',
}

export enum ETimePeriod {
  PRESENT = 'present',
  PAST = 'past',
}

export enum ETimeStatus {
  UPCOMING = 'upcoming',
  PAST = 'past',
}

export enum EVaccinationStatus {
  VALID = 'Valid',
  OUT_OF_DATE = 'OutOfDate',
}

export enum EMeasurement {
  MG = 'mg',
  MCG = 'mcg',
  G = 'g',
  ML = 'mL',
  PERCENT = '%',
}

export enum ECurrency {
  DOLLAR = '$',
}

export const cardTypeRequirePremium = [
  ECardType.Appointments,
  ECardType.Files,
  ECardType.Hospitalizations,
  ECardType.IdCard,
  ECardType.Procedures,
  ECardType.Questions,
  ECardType.Vaccinations,
  ECardType.ToDos,
];
