import { dispatchRouteChangeEvent } from "eventListeners/routeEvents";
import { useNavigate } from "react-router-dom";

export const useCustomNavigate = () => {
  const navigate = useNavigate();

  const customNavigate = (
    path: any,
    options?: { replace?: boolean; state?: any },
    callback?: () => void
  ) => {
    const cancelled = !dispatchRouteChangeEvent("start", { to: path });
    if (cancelled) {
      dispatchRouteChangeEvent("completed");
      return;
    }

    navigate(path, options);
    dispatchRouteChangeEvent("completed");
    callback?.();
  };

  const goBack = () => {
    const cancelled = !dispatchRouteChangeEvent("start");
    if (cancelled) {
      dispatchRouteChangeEvent("completed");
      return;
    }

    navigate(-1);
    dispatchRouteChangeEvent("completed");
  };

  return {
    navigate: customNavigate,
    goBack,
  };
};
