import { apiInstance } from "@apis";
import { getListParamsDefault } from "@services";
import {
  ECardType,
  ELogType,
  EOrder,
  EOrderBy,
  EOrderByParams,
  EQueryParams,
  EQuestionStatus,
  ETimePeriod,
  ETimeStatus,
  EToDoStatus,
  EVaccinationStatus,
  IBaseRes,
  ICardAllergyRes,
  ICardAppointmentRes,
  ICardConditionRes,
  ICardFileRes,
  ICardHospitalizationsRes,
  ICardIdCardRes,
  ICardPractitionerRes,
  ICardQuery,
  ICardTodoRes,
  ICreateLogPayload,
  INote,
  IPagable,
  IPrincipalInfo,
  IProfileBasic,
  IProfilesSelectRes,
  IQuestionItemList,
  IRelevantInfoRes,
  ISharingLog,
  ITopic,
  IUserAccessProfile,
  IVaccinationItemList,
} from "@types";

export const getSummaryTemporaryShare = async (temporaryShareId: string) => {
  const { data } = await apiInstance.get(`/v1/temporary-share/${temporaryShareId}/overview`);
  return data;
};

export const getListProcedure = async (profileId: string, timeStatus: ETimeStatus, topicIds: string[] = []) => {
  const res = await apiInstance.get(
    `/v1/temporary-share/${profileId}/card/procedures`,
    {
      params: {
        timeStatus, topicIds, size: 1000, orderBy: EOrderByParams.TITLE, order: EOrder.ASC,
      }
    }
  );
  return res?.data?.data;
};

export const getAllergiesCard = async (
  temporaryShareId: string,
  timePeriod: ETimePeriod
) => {
  const { data } = await apiInstance.get<IBaseRes<IPagable<ICardAllergyRes>>>(`/v1/temporary-share/${temporaryShareId}/card/allergies`, {
    params: {
      timePeriod,
      size: 1000,
      orderBy: EOrderByParams.TITLE,
      order: EOrder.ASC,
    }
  });
  return data.data.result;
};

export const getPrincipalOfUser = async (temporaryShareId: string): Promise<IPrincipalInfo> => {
  const res = await apiInstance.get(
    `/v1/temporary-share/${temporaryShareId}/principal`
  );
  return res?.data?.data;
};

export const requestOTP = async (
  temporaryShareId: string,
  termsAccepted: boolean,
  ageAccepted: boolean,
  policyAccepted: boolean
) => {
  const { data } = await apiInstance.get(
    `/v1/temporary-share/${temporaryShareId}/otp`,
    { params: { termsAccepted, ageAccepted, policyAccepted } }
  );
  return data;
};

export const verifyOTP = async (temporaryShareId: string, otp: string) => {
  const { data } = await apiInstance.post(`/v1/temporary-share/${temporaryShareId}/otp/${otp}`);
  return data;
};

export const verifyTokenForApp = async (temporaryShareId: string, principal: string, token: string) => {
  const queryToken = `?${EQueryParams.TOKEN}=${token}`;
  const queryPrincipal = `&${EQueryParams.PRINCIPAL}=${principal}`;
  const { data } = await apiInstance.post(`/v1/temporary-share/${temporaryShareId}/token${queryToken}${queryPrincipal}`);
  return data;
}

export const getProfilesTemporary = async () => {
  const { data } = await apiInstance.get<IBaseRes<IProfilesSelectRes[]>>(`/v1/temporary-share/profiles`);
  return data;
}

export const getProfileBasicTemporary = async (temporaryShareId: string) => {
  const { data } = await apiInstance.get<IBaseRes<IProfileBasic>>(`/v1/temporary-share/${temporaryShareId}/profile-basic`);
  return data?.data;
}

export const getListTemporaryService = async <T,>(
  temporaryShareId: string,
  cardType: ECardType,
  query?: ICardQuery,
) => {
  const { data } = await apiInstance.get<IBaseRes<IPagable<T>>>(
    `/v1/temporary-share/${temporaryShareId}/card/${cardType}`,
    { params: { size: 1000, ...query } }
  );
  return data?.data?.result;
};

export const getListMedication = async (profileId: string, timePeriod?: ETimePeriod) => {
  const res = await apiInstance.get(
    `/v1/temporary-share/${profileId}/card/medications`,
    {
      params: {
        timePeriod, size: 1000, orderBy: EOrderByParams.TITLE, order: EOrder.ASC,
      }
    }
  );
  return res?.data?.data;
};

export const getConditionsCard = async (temporaryShareId: string, timePeriod: ETimePeriod) => {
  const { data } = await apiInstance.get<IBaseRes<IPagable<ICardConditionRes>>>(`/v1/temporary-share/${temporaryShareId}/card/conditions`, {
    params: {
      timePeriod, size: 1000, orderBy: EOrderByParams.TITLE, order: EOrder.ASC,
    }
  });
  return data?.data?.result;
};

export const getAppointmentsCard = async (temporaryShareId: string, timeStatus: ETimeStatus) => {
  const { data } = await apiInstance.get<IBaseRes<IPagable<ICardAppointmentRes>>>(`/v1/temporary-share/${temporaryShareId}/card/appointments`, {
    params: {
      timeStatus, size: 1000, orderBy: EOrderByParams.TITLE, order: EOrder.ASC,
    }
  });
  return data?.data?.result;
}

export const getHospitalizationsCard = async (temporaryShareId: string, cardType: string) => {
  const { data } = await apiInstance.get<IBaseRes<IPagable<ICardHospitalizationsRes>>>(`/v1/temporary-share/${temporaryShareId}/card/${cardType}`, {
    params: {
      size: 1000, orderBy: EOrderByParams.TITLE, order: EOrder.ASC,
    }
  });
  return data?.data?.result;
}

export const getListVaccinations = async (temporaryShareId: string, vaccinationStatus: EVaccinationStatus) => {
  const { data } = await apiInstance.get<IBaseRes<IPagable<IVaccinationItemList>>>(
    `/v1/temporary-share/${temporaryShareId}/card/vaccinations`,
    {
      params: {
        ...getListParamsDefault,
        vaccinationStatus,
      }
    }
  );
  return data?.data;
};

export const getCardDetails = async <T>(temporaryShareId: string, cardType: ECardType, cardId: string) => {
  const { data } = await apiInstance.get<IBaseRes<T>>(`/v1/temporary-share/${temporaryShareId}/card/${cardType}/${cardId}`);
  return data?.data;
};

export const getUserAccessTemporaryShare = async (temporaryShareId: string) => {
  const { data } = await apiInstance.get<IBaseRes<IUserAccessProfile[]>>(`/v1/temporary-share/${temporaryShareId}/users`);
  return data;
};

export const getListQuestions = async (temporaryShareId: string, questionStatus: EQuestionStatus) => {
  const { data } = await apiInstance.get<IBaseRes<IPagable<IQuestionItemList>>>(
    `/v1/temporary-share/${temporaryShareId}/card/questions`,
    {
      params: {
        ...getListParamsDefault,
        questionStatus,
      }
    }
  );
  return data.data;
};

export const getListIdCards = async (temporaryShareId: string, timePeriod: ETimePeriod) => {
  const { data } = await apiInstance.get<IBaseRes<IPagable<ICardIdCardRes>>>(`/v1/temporary-share/${temporaryShareId}/card/id_card`, {
    params: {
      timePeriod, size: 1000, orderBy: EOrderByParams.TITLE, order: EOrder.ASC,
    }
  });
  return data?.data?.result;
};

export const getListPractitioners = async (temporaryShareId: string, timePeriod: ETimePeriod) => {
  const { data } = await apiInstance.get<IBaseRes<IPagable<ICardPractitionerRes>>>(`/v1/temporary-share/${temporaryShareId}/card/practitioners`, {
    params: {
      timePeriod, size: 1000, orderBy: EOrderByParams.TITLE, order: EOrder.ASC,
    }
  });
  return data?.data?.result;
};

export const getListNotes = async (temporaryShareId: string) => {
  const { data } = await apiInstance.get<IBaseRes<IPagable<INote>>>(
    `/v1/temporary-share/${temporaryShareId}/card/notes`,
    {
      params: {
        size: 1000,
        orderBy: EOrderByParams.UPDATED_TIME,
        order: EOrder.DESC
      }
    }
  );
  return data.data;
};

export const getCardsRelatedTopicTempShare = async (temporaryShareId: string, topicId: string, query?: ICardQuery) => {
  const { data } = await apiInstance.get<IBaseRes<IPagable<any>>>(
    `/v1/temporary-share/${temporaryShareId}/topic/${topicId}`,
    {
      params: { size: 1000, ...query }
    }
  );
  return data.data;
};

export const getTopicsTemporaryService = async (
  temporaryShareId: string
): Promise<ITopic[]> => {
  const paramsTopic: ICardQuery = {
    size: 1000,
    order: EOrder.ASC,
    orderBy: EOrderBy.TITLE,
  };
  const { data } = await apiInstance.get(
    `/v1/temporary-share/${temporaryShareId}`,
    { params: paramsTopic }
  );
  return data?.data?.result;
};

export const getListFiles = async (temporaryShareId: string) => {
  const { data } = await apiInstance.get<IBaseRes<IPagable<ICardFileRes>>>(
    `/v1/temporary-share/${temporaryShareId}/card/files`,
    {
      params: {
        size: 1000,
        orderBy: EOrderByParams.UPDATED_TIME,
        order: EOrder.DESC
      }
    }
  );
  return data?.data?.result;
}

export const getListToDos = async (temporaryShareId: string, status: EToDoStatus) => {
  const { data } = await apiInstance.get<IBaseRes<IPagable<ICardTodoRes>>>(
    `/v1/temporary-share/${temporaryShareId}/card/to_do`,
    {
      params: {
        size: 1000,
        orderBy: EOrderByParams.UPDATED_TIME,
        order: EOrder.DESC,
        toDoStatus: status
      }
    }
  );
  return data?.data?.result;
}

export const getRelevantPractitioners = async (temporaryShareId: string, cardType: string, practitionerId: string) => {
  const { data } = await apiInstance.get<IBaseRes<IRelevantInfoRes[]>>(
    `/v1/temporary-share/${temporaryShareId}/card/${cardType}/${practitionerId}/relevant-info`);
  return data;
}

export const getLinkedInfoToDos = async (temporaryShareId: string, cardId: string) => {
  const { data } = await apiInstance.get<IBaseRes<[]>>(
    `/v1/temporary-share/${temporaryShareId}/card/to-do/${cardId}/linked-info-cards`);
  return data;
}

export const createSharingLog = async (temporaryShareId: string, payload: ISharingLog) => {
  const { data } = await apiInstance.post<ICreateLogPayload<ISharingLog>>(`/v1/temporary-share/${temporaryShareId}/logging`, {
    type: ELogType.SHARING,
    list: [payload]
  });
  return data;
}