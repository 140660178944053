import { Flex } from "antd";
import styled from "styled-components";

import { KMdText700Secondary88, KTag } from "@components";
import { ITagCardType } from "@types";
import { cardTypeIcon } from "@utils/cardTypeIcon";

const TagCardType = styled(KTag)`
  height: 18px;
  svg {
    height: 16px;
    width: 16px;
  }
`;

export const LinkedInfoTags = ({
  linkedInfos,
}: {
  linkedInfos?: ITagCardType[];
}) => {
  return !!linkedInfos?.length ? (
    <Flex gap={4} align="center">
      <TagCardType
        icon={cardTypeIcon[linkedInfos[0].cardType].icon}
        style={{ maxWidth: "80%" }}
      >
        {linkedInfos[0].title}
      </TagCardType>
      {linkedInfos.length > 1 && (
        <KMdText700Secondary88>
          + {linkedInfos.length - 1}
        </KMdText700Secondary88>
      )}
    </Flex>
  ) : null;
};
