import { AppKinKeeperIcon, EMRSyncIcon, VectorRightIcon } from "@assets/icons";
import {
  CardItemStyled,
  KAnchor,
  KBadgeCount,
  KButton,
  KCard,
  KEmptyView,
  KLgText700,
  KMdText,
  KMdText700,
  KMdTextSecondary65,
  KSemiText700,
  KSmTextSecondary65,
  KXlText700,
  Loading,
} from "@components";
import { useCustomNavigate, useMedicalRecordPermission } from "@hooks";
import {
  getListMedicalRecordProfileService,
  requestExportMedicalRecordService,
} from "@services";
import { useEMRStore } from "@store";
import {
  Color,
  EEMRPage,
  EMRExportStatus,
  EUrlPath,
  IMedicalRecordImport,
  infoTypeTextMedRecord,
  IParams,
  MAX_NUMBER_OF_IMPORTS_PER_MONTH,
} from "@types";
import { formatDate } from "@utils/date";
import { Divider, Flex, message, Modal } from "antd";
import { modalProps } from "config";
import { MouseEvent, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { EMRSourceItem, EMRSyncButton } from "../components";

export const EMROverview = () => {
  const { navigate } = useCustomNavigate();
  const { profileId, connectionId } = useParams<IParams>();
  const {
    indexListMedRecord,
    forceUpdateListMedRecord,
    setImportedBrandIds,
    setPageEMR,
    forceUpdateDetailMedRecord,
  } = useEMRStore();
  const [openLearnMore, setOpenLearnMore] = useState(false);
  const [openConfirmSearch, setOpenConfirmSearch] = useState(false);
  const [prevMedicalRecords, setPrevMedicalRecords] = useState<
    IMedicalRecordImport[]
  >([]);
  const [availableImports, setAvailableImports] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingOrgConnectionId, setLoadingOrgConnectionId] = useState("");

  useEffect(() => {
    if (!profileId) return;
    (async () => {
      try {
        if (!indexListMedRecord) setLoading(true);
        const resMedRecord = await getListMedicalRecordProfileService(
          profileId
        );
        if (!resMedRecord) return;
        setPrevMedicalRecords(resMedRecord.result);
        setAvailableImports(resMedRecord.availableImports ?? 0);
        setImportedBrandIds(resMedRecord.result);
      } catch {
      } finally {
        setLoading(false);
      }
    })();
  }, [profileId, indexListMedRecord]);

  useEffect(() => {
    return () => useEMRStore.setState({ indexListMedRecord: 0 });
  }, []);

  useMedicalRecordPermission();

  const onSearchNewRecord = () => {
    navigate("search");
    setOpenConfirmSearch(false);
  };

  const onClickItemImported = (
    e: MouseEvent<HTMLElement, globalThis.MouseEvent>,
    source: IMedicalRecordImport
  ) => {
    if (
      (e.target as Element).closest(".btn-sync-again") ||
      source.status === EMRExportStatus.EXPORT_FAILED
    )
      return;
    navigate(
      `/user/${profileId}/${EUrlPath.MEDICAL_RECORDS}/${source.orgConnectionId}`
    );
  };

  const onClickSyncAgain = useCallback(
    async (med: IMedicalRecordImport) => {
      if (!profileId) return;
      try {
        const { brandId, orgConnectionId } = med;
        setLoadingOrgConnectionId(orgConnectionId);
        await requestExportMedicalRecordService(
          profileId,
          orgConnectionId,
          brandId,
          true
        );
        forceUpdateListMedRecord();
        forceUpdateDetailMedRecord();
      } catch (error: any) {
        const { code, message: errorMessage } = error?.response?.data || {};
        if (code === 403 || code === 503) {
          message.error(errorMessage);
        }
      } finally {
        setLoadingOrgConnectionId("");
      }
    },
    [
      forceUpdateDetailMedRecord,
      forceUpdateListMedRecord,
      profileId,
      setPageEMR,
    ]
  );

  return (
    <KCard $paddingSize="xl">
      <Flex vertical gap={4}>
        <KSemiText700>Medical Record Sources</KSemiText700>
        <Flex justify="space-between">
          <KLgText700>Available Imports</KLgText700>
          <KBadgeCount isNeedWidth={false}>
            {`${availableImports} of ${MAX_NUMBER_OF_IMPORTS_PER_MONTH}`}
          </KBadgeCount>
        </Flex>
        <KAnchor onClick={() => setOpenLearnMore(true)}>Learn more</KAnchor>
      </Flex>
      <KButton
        size="large"
        title="Import New Medical Record"
        disabled={!availableImports}
        onClick={() => setOpenConfirmSearch(true)}
      />
      <Divider />
      <Flex vertical flex={1} gap={12} style={{ overflow: "scroll" }}>
        <KLgText700>Previous imports</KLgText700>
        {loading ? (
          <Loading />
        ) : prevMedicalRecords.length > 0 ? (
          <Flex vertical gap={8} style={{ overflow: "scroll" }}>
            {prevMedicalRecords.map((med) => (
              <CardItemStyled
                $isActive={med.orgConnectionId === connectionId}
                key={med._id}
                $borderRadius={12}
                gap={8}
                onClick={(e) => onClickItemImported(e, med)}
              >
                <EMRSourceItem source={med} isMedicalRecord />
                <Divider />
                <Flex align="center" justify="space-between">
                  <KSmTextSecondary65>
                    {formatDate(
                      med.createdTime,
                      "MONTH_DAY_WITH_SHORT_YEAR_AND_TIME_AM_PM"
                    )}
                  </KSmTextSecondary65>
                  <EMRSyncButton
                    status={med.status}
                    onClick={() => onClickSyncAgain(med)}
                    loading={loadingOrgConnectionId === med.orgConnectionId}
                  />
                </Flex>
              </CardItemStyled>
            ))}
          </Flex>
        ) : (
          <KEmptyView
            icon={<EMRSyncIcon />}
            title="No previous imports."
            subTitle="Import a medical record to get started."
          />
        )}
      </Flex>
      <Modal
        open={openLearnMore}
        onCancel={() => setOpenLearnMore(false)}
        {...modalProps}
        styles={{ content: { padding: "16px 24px" } }}
        width={446}
      >
        <Flex flex={1} gap={8} vertical>
          <KSemiText700>Available Imports</KSemiText700>
          <KMdText>
            Your Premium Plan includes {""}
            <KMdText700>10 imports across all the profiles you own </KMdText700>
            each month. Each time you sync from a source, you'll use one of your
            imports.
          </KMdText>
          <KMdText>
            These Imports do not roll over, but you'll have a fresh set of 10
            starting on the 1st of each month.
          </KMdText>
          <KButton
            size="large"
            title="Return to KinKeeper"
            onClick={() => setOpenLearnMore(false)}
          />
        </Flex>
      </Modal>
      <Modal
        open={openConfirmSearch}
        onCancel={() => setOpenConfirmSearch(false)}
        {...modalProps}
        styles={{ content: { padding: "16px 24px" } }}
        width={446}
      >
        <Flex gap={24} vertical>
          <Flex gap={8} justify="center" align="center">
            <EMRSyncIcon width={72} fill={Color.PrimaryBase} />
            <VectorRightIcon width={32} color={Color.PrimaryBase} />
            <AppKinKeeperIcon />
          </Flex>
          <Flex vertical justify="center" align="start" gap={8}>
            <KXlText700 $align="center">
              Import select medical records directly into KinKeeper!
            </KXlText700>
            <KMdTextSecondary65 $align="justify">
              Pull in portions of your medical history directly from your
              providers by continuing below. Once imported, you’ll be able to
              organize and edit your KinKeeper copy of each record.
            </KMdTextSecondary65>
            <KMdTextSecondary65 $align="justify">
              We are working hard to import a complete set of information from
              your medical records. Currently, we support:
            </KMdTextSecondary65>
            <Flex vertical gap={4}>
              {Object.values(infoTypeTextMedRecord).map((item) => (
                <KMdTextSecondary65
                  key={item}
                >{`\u2022 ${item}`}</KMdTextSecondary65>
              ))}
            </Flex>
          </Flex>
          <Flex vertical gap={8}>
            <KButton
              size="large"
              title="Search for my records"
              onClick={onSearchNewRecord}
            />
            <KButton
              type="default"
              size="large"
              title="Cancel"
              onClick={() => setOpenConfirmSearch(false)}
            />
          </Flex>
        </Flex>
      </Modal>
    </KCard>
  );
};
