import { Color } from "../../types";
import { IconProps } from "./types";

export const VectorRightIcon: React.FC<IconProps> = ({
  style,
  width = 18,
  height = width * 0.7,
  color = Color.White,
}) => {
  return (
    <svg style={style} width={width} height={height} viewBox="0 0 20 14" fill={color}>
      <path
        d="M0 7.50003V6.50003C0 6.36742 0.0526772 6.24024 0.146446 6.14647C0.240215 6.0527 0.367392 6.00003 0.5 6.00003L16.67 6.00003L12.22 1.56003C12.1731 1.51354 12.1359 1.45824 12.1106 1.39731C12.0852 1.33638 12.0721 1.27103 12.0721 1.20503C12.0721 1.13902 12.0852 1.07367 12.1106 1.01274C12.1359 0.951809 12.1731 0.896507 12.22 0.850026L12.93 0.150025C12.9765 0.103161 13.0318 0.0659637 13.0927 0.0405798C13.1536 0.0151958 13.219 0.00212574 13.285 0.00212574C13.351 0.00212574 13.4164 0.0151958 13.4773 0.0405798C13.5382 0.0659637 13.5935 0.103161 13.64 0.150025L19.78 6.28003C19.9207 6.42054 19.9998 6.61118 20 6.81003V7.19003C19.9977 7.38844 19.9189 7.57831 19.78 7.72003L13.64 13.85C13.5935 13.8969 13.5382 13.9341 13.4773 13.9595C13.4164 13.9849 13.351 13.9979 13.285 13.9979C13.219 13.9979 13.1536 13.9849 13.0927 13.9595C13.0318 13.9341 12.9765 13.8969 12.93 13.85L12.22 13.14C12.1734 13.0944 12.1364 13.0399 12.1112 12.9798C12.0859 12.9197 12.0729 12.8552 12.0729 12.79C12.0729 12.7248 12.0859 12.6603 12.1112 12.6002C12.1364 12.5401 12.1734 12.4856 12.22 12.44L16.67 8.00003L0.5 8.00003C0.367392 8.00003 0.240215 7.94735 0.146446 7.85358C0.0526772 7.75981 0 7.63263 0 7.50003Z"
        fill={color}
      />
    </svg>
  );
};
