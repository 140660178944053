import { IconProps } from "./types";

export const OpenNewTabIcon = ({
  width = 24,
  height,
  color,
}: IconProps ) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height || width}
    viewBox="0 0 24 24"
    fill={color}
  >
    <path
      d="M21 13.5V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H10.5C10.6326 3 10.7598 3.05268 10.8536 3.14645C10.9473 3.24021 11 3.36739 11 3.5V4.5C11 4.63261 10.9473 4.75979 10.8536 4.85355C10.7598 4.94732 10.6326 5 10.5 5H5V19H19V13.5C19 13.3674 19.0527 13.2402 19.1464 13.1464C19.2402 13.0527 19.3674 13 19.5 13H20.5C20.6326 13 20.7598 13.0527 20.8536 13.1464C20.9473 13.2402 21 13.3674 21 13.5ZM20.78 3.5L20.51 3.23C20.376 3.09042 20.1933 3.00802 20 3H13.5C13.3674 3 13.2402 3.05268 13.1464 3.14645C13.0527 3.24021 13 3.36739 13 3.5V4.5C13 4.63261 13.0527 4.75979 13.1464 4.85355C13.2402 4.94732 13.3674 5 13.5 5H17.59L9.15 13.44C9.10314 13.4865 9.06594 13.5418 9.04055 13.6027C9.01517 13.6636 9.0021 13.729 9.0021 13.795C9.0021 13.861 9.01517 13.9264 9.04055 13.9873C9.06594 14.0482 9.10314 14.1035 9.15 14.15L9.85 14.85C9.89648 14.8969 9.95178 14.9341 10.0127 14.9594C10.0736 14.9848 10.139 14.9979 10.205 14.9979C10.271 14.9979 10.3364 14.9848 10.3973 14.9594C10.4582 14.9341 10.5135 14.8969 10.56 14.85L19 6.42V10.5C19 10.6326 19.0527 10.7598 19.1464 10.8536C19.2402 10.9473 19.3674 11 19.5 11H20.5C20.6326 11 20.7598 10.9473 20.8536 10.8536C20.9473 10.7598 21 10.6326 21 10.5V4C20.9946 3.80811 20.9159 3.62558 20.78 3.49V3.5Z"
      fill={color}
    />
  </svg>
);
