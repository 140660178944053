import {
  Loading
} from "@components";
import { useKContext } from "@context";
import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { EMROverview } from "./screens";

const MedicalRecordsPage = () => {
  const { isMobile } = useKContext();
  return !isMobile ? (
    <>
      <EMROverview/>
      <Suspense fallback={<Loading />}>
        <Outlet />
      </Suspense>
    </>
  ) : (
    <EMROverview/>
  )
};

export * from "./screens";
export default MedicalRecordsPage;
