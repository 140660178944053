export const ProfileSummaryIcon = ({
  width = 24,
  height,
}: {
  height?: number;
  width?: number;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height || width}
    viewBox="0 0 24 24"
    fill="#161925"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.75 6.04688C6.75 5.94375 6.83437 5.85938 6.9375 5.85938H15.9375C16.0406 5.85938 16.125 5.94375 16.125 6.04688V7.17188C16.125 7.275 16.0406 7.35938 15.9375 7.35938H6.9375C6.83437 7.35938 6.75 7.275 6.75 7.17188V6.04688ZM6.9375 9.23438H11.25C11.3531 9.23438 11.4375 9.31875 11.4375 9.42188V10.5469C11.4375 10.65 11.3531 10.7344 11.25 10.7344H6.9375C6.83437 10.7344 6.75 10.65 6.75 10.5469V9.42188C6.75 9.31875 6.83437 9.23438 6.9375 9.23438ZM4.875 19.9688H10.125C10.2281 19.9688 10.3125 20.0531 10.3125 20.1562V21.4688C10.3125 21.5719 10.2281 21.6562 10.125 21.6562H3.9375C3.52266 21.6562 3.1875 21.3211 3.1875 20.9062V2.53125C3.1875 2.11641 3.52266 1.78125 3.9375 1.78125H18.9375C19.3523 1.78125 19.6875 2.11641 19.6875 2.53125V10.9688C19.6875 11.0719 19.6031 11.1562 19.5 11.1562H18.1875C18.0844 11.1562 18 11.0719 18 10.9688V3.46875H4.875V19.9688ZM19.9826 15.3322C19.9846 16.1628 19.6758 16.964 19.1169 17.5784C20.3873 18.3639 21.256 19.7443 21.3393 21.3301C21.3409 21.3623 21.336 21.3946 21.3247 21.4248C21.3135 21.4551 21.2962 21.4828 21.2739 21.5061C21.2517 21.5295 21.2249 21.5481 21.1952 21.5608C21.1655 21.5735 21.1336 21.58 21.1013 21.58H19.7982C19.6732 21.58 19.5721 21.4818 19.5631 21.3569C19.4501 19.8544 18.1946 18.6643 16.6713 18.6643C15.148 18.6643 13.8925 19.8514 13.7794 21.3569C13.7705 21.4818 13.6694 21.58 13.5444 21.58H12.2383C12.206 21.58 12.1741 21.5735 12.1444 21.5608C12.1147 21.5481 12.0879 21.5295 12.0657 21.5061C12.0434 21.4828 12.0261 21.4551 12.0149 21.4248C12.0036 21.3946 11.9987 21.3623 12.0003 21.3301C12.0401 20.5721 12.2627 19.8349 12.6492 19.1816C13.0357 18.5283 13.5745 17.9783 14.2198 17.5784C13.6813 16.9834 13.354 16.1979 13.354 15.3322C13.354 13.4906 14.8386 12 16.6683 12C18.498 12 19.9826 13.4906 19.9826 15.3322ZM15.1302 15.3322C15.1302 16.186 15.8204 16.8793 16.6683 16.8793C17.5162 16.8793 18.2065 16.186 18.2065 15.3322C18.2065 14.4783 17.5162 13.7851 16.6683 13.7851C15.8204 13.7851 15.1302 14.4783 15.1302 15.3322Z"
    />
  </svg>
);
