export const AddPlusIcon = ({
  width = 18,
  height,
}: {
  height?: number;
  width?: number;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height || width}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M22 11.2857V12.7143C22 12.9037 21.9247 13.0854 21.7908 13.2194C21.6568 13.3533 21.4752 13.4286 21.2857 13.4286H13.4286V21.2857C13.4286 21.4752 13.3533 21.6568 13.2194 21.7908C13.0854 21.9247 12.9037 22 12.7143 22H11.2857C11.0963 22 10.9146 21.9247 10.7806 21.7908C10.6467 21.6568 10.5714 21.4752 10.5714 21.2857V13.4286H2.71429C2.52485 13.4286 2.34316 13.3533 2.20921 13.2194C2.07526 13.0854 2 12.9037 2 12.7143V11.2857C2 11.0963 2.07526 10.9146 2.20921 10.7806C2.34316 10.6467 2.52485 10.5714 2.71429 10.5714H10.5714V2.71429C10.5714 2.52485 10.6467 2.34316 10.7806 2.20921C10.9146 2.07526 11.0963 2 11.2857 2H12.7143C12.9037 2 13.0854 2.07526 13.2194 2.20921C13.3533 2.34316 13.4286 2.52485 13.4286 2.71429V10.5714H21.2857C21.4752 10.5714 21.6568 10.6467 21.7908 10.7806C21.9247 10.9146 22 11.0963 22 11.2857Z"
      fill="white"
    />
  </svg>
);
