import { Divider } from "antd";

import { KSemiText700, Loading } from "@components";
import { useFetchInfoTypesService } from "@hooks";
import { useListInfoType } from "@store";
import { EFileSort, ICardTodoRes } from "@types";
import { useMemo, useState } from "react";
import { SortItem, ToDoItem } from "../item";
import { CustomSpaceList, WrapperCardList } from "../share-component";
import { getQuery } from "@utils/card";

const renderCardItems = (filteredCards: ICardTodoRes[]) => {
  return filteredCards.map((card: ICardTodoRes) => (
    <ToDoItem key={card._id} card={card} />
  ));
};

export const ListToDos = () => {
  const {
    activeToDoList = [],
    completedToDoList = [],
    isLoadingList,
    isLoadingInactiveList,
  } = useListInfoType();
  const [sort, setSort] = useState<EFileSort>(EFileSort.New);

  const query = useMemo(() => getQuery(sort), [sort]);

  useFetchInfoTypesService("ToDos", query);

  return (
    <WrapperCardList space={16}>
      <CustomSpaceList>
        <KSemiText700>Active To-Dos</KSemiText700>
        <SortItem sort={sort} setSort={setSort} />
        {isLoadingList ? <Loading /> : renderCardItems(activeToDoList)}
      </CustomSpaceList>
      <Divider />
      <CustomSpaceList>
        <KSemiText700>Completed To-Dos</KSemiText700>
        {isLoadingInactiveList ? <Loading /> : renderCardItems(completedToDoList)}
      </CustomSpaceList>
    </WrapperCardList>
  );
};
