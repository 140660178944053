import { FC } from "react";
import { IconProps } from "./types";
import { Color } from "@types";

export const FileOutlineIcon: FC<IconProps> = ({
  style,
  width = 64,
  height,
  color = Color.SecondaryBase,
}) => {
  return (
    <svg
      style={style}
      width={width}
      height={height || width}
      fill={color}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.3828 11.2734L24.9766 2.86719C24.7422 2.63281 24.4258 2.5 24.0938 2.5H7.5C6.80859 2.5 6.25 3.05859 6.25 3.75V36.25C6.25 36.9414 6.80859 37.5 7.5 37.5H32.5C33.1914 37.5 33.75 36.9414 33.75 36.25V12.1602C33.75 11.8281 33.6172 11.5078 33.3828 11.2734ZM30.8672 12.7344H23.5156V5.38281L30.8672 12.7344ZM30.9375 34.6875H9.0625V5.3125H20.8594V13.75C20.8594 14.1851 21.0322 14.6024 21.3399 14.9101C21.6476 15.2178 22.0649 15.3906 22.5 15.3906H30.9375V34.6875Z"
        fill="#161925"
      />
    </svg>
  );
};
