import { FC, SVGProps } from "react";
import { IconProps } from "./types";

export const NoteInfoTypeIcon: FC<IconProps> = ({
  width = 24,
  height,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height || width}
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M15.7359 20.2797L20.7797 15.2359C20.9203 15.0953 21 14.9055 21 14.7062L21 3.75C21 3.33516 20.6648 3 20.25 3L3.75 3C3.33516 3 3 3.33516 3 3.75L3 19.75C3 20.1648 3.33516 20.5 3.75 20.5L15.2039 20.5C15.4031 20.5 15.5953 20.4203 15.7359 20.2797ZM14.8594 18.7703L14.8594 14.3594L19.2703 14.3594L14.8594 18.7703ZM4.6875 18.8125L4.6875 4.6875L19.3125 4.6875V12.7656L14.25 12.7656C13.9889 12.7656 13.7385 12.8693 13.5539 13.0539C13.3693 13.2385 13.2656 13.4889 13.2656 13.75L13.2656 18.8125L4.6875 18.8125Z"/>
  </svg>
);
