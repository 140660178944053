import { SearchOutlined } from "@ant-design/icons";
import {
  KButton,
  KCardItemInfo,
  KMdText,
  KMdText700PrimaryBase,
  KMdTextSecondary65,
  Loading,
} from "@components";
import { widthTabsAndCard } from "@constants/size";
import { useKContext } from "@context";
import { cardTypeToCardItem } from "@pages/card-list/components";
import { searchCardInfoTypeService } from "@services";
import { useProfile } from "@store";
import { ICombinedCard, IParams, ITopic } from "@types";
import { AutoComplete, Flex } from "antd";

import { FC, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { StyledDropDown, StyledInputSearch } from "./styles";

export const SearchInfoType: FC = () => {
  const { cardId } = useParams<IParams>();
  const { isMobile, navigateDetailCard } = useKContext();
  const { profile } = useProfile();
  const [cardOptions, setCardOptions] = useState<ICombinedCard[]>([]);
  const [topicOptions, setTopicOptions] = useState<ITopic[]>([]);
  const [loading, setLoading] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [search, setSearch] = useState("");
  const [recentSearches, setRecentSearches] = useState<string[]>([]);

  useEffect(() => {
    (openSearch && cardId) && setOpenSearch(false);
  }, [cardId])

  useEffect(() => {
    setRecentSearches(profile?.recentSearch?.[0]?.keyword || []);
  }, [profile]);

  const onSubmitSearch = (val?: string) => {
    if (!profile) return;
    (async () => {
      const keyword = val || search;
      try {
        setLoading(true);
        const res = await searchCardInfoTypeService(profile._id, keyword);
        res?.map(({ type, results }) => {
          type === 'cards' && setCardOptions(results);
          type === 'topics' && setTopicOptions(results);
        })
      } catch {
      } finally {
        if (keyword) {
          const uniqueRecentSearches = new Set([keyword, ...recentSearches]);
          setRecentSearches(Array.from(uniqueRecentSearches));
        }
        setLoading(false);
        setShowResult(true);
      }
    })();
  };

  const onClickRecentSearch = (val: string) => {
    setSearch(val);
    onSubmitSearch(val);
  };

  const onSearch = (e: any) => {
    const { value } = e.target;
    if (!value) setCardOptions([]);
    setSearch(value);
    setShowResult(false);
  };

  const onBlurSearch = () => {
    openSearch && setOpenSearch(false);
  };

  const customOptions = useMemo(() => {
    const countResult = cardOptions.length + topicOptions.length;
    return [
      {
        label: (
          <Flex vertical gap={16}>
            {loading ? (
              <Loading />
            ) : showResult ? (
              <>
                <KMdTextSecondary65>
                  {`${countResult} result${countResult > 1 ? "s" : ""}`}
                </KMdTextSecondary65>
                {cardOptions?.map((op) => {
                  const { CardItem } = cardTypeToCardItem[op.cardType];
                  return (
                    <CardItem
                      key={op._id}
                      card={op}
                      showIcon
                      isDetail
                      showMetadata
                      showCheckbox={false}
                    />
                  );
                })}
                {topicOptions.map(({ _id, title = "" }) => (
                  <KCardItemInfo
                    key={_id}
                    title={title}
                    onClick={() => navigateDetailCard(undefined, _id)}
                    addable={false}
                  />
                ))}
              </>
            ) : (
              <>
                <KMdText children="Recent searches" />
                {recentSearches?.map((val, idx) => (
                  <KMdText700PrimaryBase
                    key={idx}
                    children={val}
                    style={{ cursor: "pointer" }}
                    onClick={() => onClickRecentSearch(val)}
                  />
                ))}
              </>
            )}
          </Flex>
        ),
        options: [],
      },
    ];
  }, [cardOptions, showResult, loading, recentSearches, topicOptions]);

  return (!openSearch && isMobile)? (
    <SearchOutlined
      style={{ fontSize: 24, padding: 8 }}
      onClick={() => setOpenSearch(true)}
    />
  ) : (
    <AutoComplete
      value={search}
      autoFocus={isMobile}
      open={openSearch}
      options={customOptions}
      style={{ width: isMobile ? "100%" : widthTabsAndCard }}
      dropdownRender={(data) => <StyledDropDown children={data}/>}
    >
      <StyledInputSearch
        allowClear
        onChange={onSearch}
        onFocus={() => setOpenSearch(true)}
        onBlur={onBlurSearch}
        placeholder="Search"
        size={isMobile ? `middle` : `large`}
        onPressEnter={() => onSubmitSearch()}
        enterButton={
          <KButton
            size={isMobile ? `small` : `large`}
            icon={<SearchOutlined />}
            onClick={() => onSubmitSearch()}
            loading={loading}
          />
        }
      />
    </AutoComplete>
  );
};
