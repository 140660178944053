import {
  AvatarAccount,
  KButton,
  KFormUser,
  KMdText700PrimaryBase,
  KSmTextSecondary65,
  KSwitch,
  KTag,
  KText,
  KXlText700,
} from "@components";
import { zodResolver } from "@hookform/resolvers/zod";
import { useLoggingService, useUnsavedChangesWarning } from "@hooks";
import { CardUpgradePremium } from "@pages/components";
import {
  logoutWebAppService,
  resetPinService,
  updateUserInfoService,
  UserInfo,
} from "@services";
import { useAuthStore, useProfile, useUser } from "@store";
import {
  Color,
  EAuthPage,
  EContactType,
  EDateTimeFormat,
  ESpacing,
} from "@types";
import { formatPhoneNumber } from "@utils/formatPhoneNumber";
import { getToken, removeToken } from "@utils/storage";
import { accountSettingsSchema } from "Schema";
import { Divider, Flex, Form, message } from "antd";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { DeleteModal, UpdateContact } from "./components";
import { CustomEditIcon } from "./styled-components";
import { KContext } from "@context";

const AccountSettingsPage = () => {
  const { userInfo, setUserInfo, isFreemium } = useUser();
  const { pushInfoTypeLog } = useLoggingService();
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [updateContact, setUpdateContact] = useState<EContactType>();
  const [openUpdate, setOpenUpdate] = useState(false);
  const [isNotification, setIsNotification] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

  const onToggleNotifications = async () => {
    try {
      if (!userInfo) return;
      const res = await updateUserInfoService({
        ...userInfo,
        isReceiveNotifications: !isNotification,
      });
      pushInfoTypeLog("DELETED", res, "ACCOUNT_SETTINGS");
      setUserInfo(res);
      setIsNotification(!isNotification);
    } catch (error: any) {
      message.error(
        error?.response?.data?.message || "Update profile notifications failed"
      );
    }
  };
  const navigate = useNavigate();
  const { setCurrentPage } = useAuthStore();
  const { setTopics, setAmountOfCards } = useContext(KContext);
  const { setProfile } = useProfile();

  const method = useForm<UserInfo>({
    resolver: zodResolver(accountSettingsSchema),
    mode: "onTouched",
  });

  const {
    formState: { isValid, isDirty },
    getValues,
    reset,
  } = method;

  useUnsavedChangesWarning(isDirty);

  useEffect(() => {
    if (!userInfo) return;
    setIsNotification(userInfo.isReceiveNotifications);
    reset({
      ...userInfo,
      phoneNumber: formatPhoneNumber(userInfo.phoneNumber),
    });
  }, [userInfo]);

  const onSubmitUserInfo = async () => {
    try {
      setIsLoadingBtn(true);
      const res = await updateUserInfoService(getValues());
      pushInfoTypeLog("MODIFIED", res, "ACCOUNT_SETTINGS");
      setUserInfo(res);
      message.success("Update profile successfully!");
    } catch (err: any) {
      message.error(err?.response?.data?.message);
    } finally {
      setIsLoadingBtn(false);
    }
  };

  const onFocusContact = (e?: any) => {
    setUpdateContact(e.target.name);
    setOpenUpdate(true);
  };

  const handleDeleteAccount = () => {
    setIsOpenDeleteModal(true);
  };

  const onResetPin = async () => {
    try {
      await resetPinService();
      removeToken("WebApp");
      removeToken("FCM");
      removeToken("Pin");
      setUserInfo(null);
      setProfile(null);
      setTopics([]);
      setAmountOfCards([]);
      await logoutWebAppService(getToken("FCM"));
      navigate("/auth/sign-in");
      setCurrentPage(EAuthPage.WELCOME_USER);
    } catch (err: any) {
      console.log("err resetPinService --- ", err.message);
    }
  };

  return (
    <Flex
      vertical
      gap={32}
      style={{ backgroundColor: Color.White, padding: ESpacing.xl, flex: 1 }}
    >
      <Flex justify="center" align="center">
        <AvatarAccount />
      </Flex>
      <Flex style={{ overflowY: "auto" }}>
        <Flex flex={1} vertical gap={16} style={{ overflowY: "auto" }}>
          <FormProvider {...method}>
            <Form>
              <Flex vertical gap={24}>
                <Flex justify="space-between" gap={16}>
                  <KFormUser.Input
                    disabled={isLoadingBtn}
                    label="First Name"
                    name="firstName"
                  />
                  <KFormUser.Input
                    disabled={isLoadingBtn}
                    label="Last Name"
                    name="lastName"
                  />
                </Flex>
                <KFormUser.InputPhone
                  phone
                  readOnly
                  disabled={isLoadingBtn}
                  label="Phone"
                  name="phoneNumber"
                  suffix={<CustomEditIcon />}
                  onClick={onFocusContact}
                />
                <KFormUser.Input
                  readOnly
                  disabled={isLoadingBtn}
                  label="Email"
                  name="email"
                  suffix={<CustomEditIcon />}
                  onClick={onFocusContact}
                />
                <KButton
                  loading={isLoadingBtn}
                  onClick={onSubmitUserInfo}
                  htmlType="submit"
                  title="Save Account Info"
                  disabled={!isDirty || !isValid}
                  style={{ width: "50%" }}
                />
              </Flex>
            </Form>
          </FormProvider>
          <UpdateContact
            open={openUpdate}
            onOpen={setOpenUpdate}
            contact={updateContact}
          />
          <Divider />
          <Flex vertical gap={16}>
            <KXlText700>Notifications</KXlText700>
            <KText>
              Kith + Kin sends notifications for important profile activities
              like appointments, medication refill reminders, and sharing
              invites. You may pause or allow notifications for ALL profiles
              below:
            </KText>
            <Flex
              style={{
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <KText style={{ justifyContent: "center", alignItems: "center" }}>
                Push Notifications{" "}
                <KMdText700PrimaryBase>OK</KMdText700PrimaryBase>
              </KText>
              <KSwitch
                value={isNotification}
                onChange={onToggleNotifications}
              ></KSwitch>
            </Flex>
          </Flex>
          <Divider />
          <Flex vertical gap={16}>
            <KXlText700>Account Security</KXlText700>
            <KText>
              After 15 minutes of inactivity, we ask for a 4-digit PIN so that
              your information is both secure and easily accessible.
            </KText>
            <KButton
              onClick={onResetPin}
              title="Reset my PIN"
              style={{ width: "50%" }}
            />
          </Flex>
          <Divider />
          <Flex vertical gap={16}>
            <KXlText700>My Subscription</KXlText700>
            <Flex>
              <Flex vertical gap={4} flex={1}>
                <KText>My current plan:</KText>
                {isFreemium ? <KTag>Free</KTag> : <KTag $active>Premium</KTag>}
              </Flex>
              {userInfo?.subscription?.expiresDate && (
                <Flex vertical gap={4} flex={1}>
                  <KText>Good through:</KText>
                  <KSmTextSecondary65>
                    {moment(userInfo.subscription.expiresDate).format(
                      EDateTimeFormat.FULL_MONTH_YEAR
                    )}
                  </KSmTextSecondary65>
                </Flex>
              )}
            </Flex>
            <Divider />
            <Flex vertical gap={16}>
              <KXlText700>Delete Accounts</KXlText700>
              <KText>
                All of your profiles and data will be permanently deleted and
                unable to be restored. You will also be unsubscribed from all
                future marketing communications.
              </KText>
              <KButton
                title="Delete my account"
                onClick={handleDeleteAccount}
                style={{ width: "50%", backgroundColor: Color.ErrorBase }}
              />
            </Flex>
            {isFreemium && (
              <Flex justify="center">
                <CardUpgradePremium />
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
      <DeleteModal
        isOpen={isOpenDeleteModal}
        setIsOpen={setIsOpenDeleteModal}
      />
    </Flex>
  );
};

export default AccountSettingsPage;
