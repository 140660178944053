import { useCallback, useState } from "react";
import { Divider, Flex, Form, Input } from "antd";
import { FormItem } from "react-hook-form-antd";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { KAnchor, KButton, KErrorText, KFormGroup, KLgText700Secondary88, KMdTextSecondary88 } from "@components";
import validator from "@utils/validate";
import { useAuthStore } from "@store";
import { BackNavFormHeader } from "./BackButton";
import { EAuthErrorMsg, EAuthPage } from "@types";
import { checkEmailOrPhoneNumberExisted } from "services/auth";

enum EType {
  SMS = 1,
  EMAIL
}

const smsSchema = z.object({
  type: z.literal(1),
  phoneNumber: z.string()
    .min(1, { message: 'Phone number is required' })
    .refine(validator.validatePhoneNumber, 'Please provide valid phone number')
});

const emailSchema = z.object({
  type: z.literal(2),
  email: z
    .string()
    .min(1, { message: 'Email is required' })
    .email({ message: 'Email is invalid' }),
});

const schema = z.discriminatedUnion('type', [smsSchema, emailSchema]);

type FormData = z.infer<typeof schema>;

export const WelcomeGuest = () => {
  const { setCurrentPage, isCSLFlow, setIsRecognize } = useAuthStore();

  const [error, setError] = useState('');

  const {
    handleSubmit,
    formState: { isSubmitting },
    setValue,
    watch,
    control,
  } = useForm<FormData>({
    defaultValues: {
      email: '',
      phoneNumber: '',
    },
    resolver: zodResolver(schema),
    mode: 'all',
  });

  const onSelectType = (newType: EType) => {
    const type = watch('type');
    if (type === newType) return;
    if (type === EType.EMAIL) {
      setValue('email', '');
    }
    if (type === EType.SMS) {
      setValue('phoneNumber', '');
    }
    setError('');
    setValue('type', newType);
  };

  const buttonProps = useCallback((type: EType) => {
    const ghost = watch('type') !== type;
    return {
      type: "primary",
      size: "large",
      block: true,
      ghost,
      onClick: () => { onSelectType(type); }
    } as any;
  }, [watch('type')]);

  const onSubmit = async (values: FormData) => {
    try {
      const payload = { ...values } as any;
      const res = await checkEmailOrPhoneNumberExisted(payload?.email || payload?.phoneNumber);
      if (res?.data) {
        setCurrentPage(EAuthPage.CSL_WELCOME);
        setIsRecognize(true);
      } else {
        setError(values.type === EType.EMAIL
          ? EAuthErrorMsg.NOT_RECOGNIZE_EMAIL
          : EAuthErrorMsg.NOT_RECOGNIZE_PHONE
        );
      }
    } catch (error) {
    }
  };

  return (
    <Flex vertical gap={16}>
      <BackNavFormHeader screenKey={isCSLFlow ? EAuthPage.CSL_WELCOME : EAuthPage.AUTH_SCREEN} />
      <KLgText700Secondary88>
        How did you originally receive your link to the profile?
      </KLgText700Secondary88>
      <Flex gap={16}>
        <KButton
          title="SMS Message"
          {...buttonProps(EType.SMS)}
        />
        <KButton
          title="Email"
          {...buttonProps(EType.EMAIL)}
        />
      </Flex>
      {!!watch('type') && (
        <Form onFinish={handleSubmit(onSubmit)}>
          {watch('type') === EType.EMAIL ? (
            <KFormGroup label='What email address?'>
              <FormItem control={control} name='email'>
                <Input placeholder='Email address' size='large' />
              </FormItem>
            </KFormGroup>
          ) : (
            <KFormGroup label='What phone number?'>
              <FormItem control={control} name='phoneNumber'>
                <Input placeholder='Phone number' size='large' />
              </FormItem>
            </KFormGroup>
          )}
          <KButton
            title='Next'
            type='primary'
            size='large'
            block
            htmlType='submit'
            disabled={isSubmitting}
          />
        </Form>
      )}
      {error && (
        <KErrorText>{error}</KErrorText>
      )}
      <Divider />
      <Flex vertical>
        <KMdTextSecondary88>
          Already have a KinKeeper account?
        </KMdTextSecondary88>
        <KAnchor
          $isUnderlined
          size="md"
          color="PrimaryBase"
          onClick={() => { setCurrentPage(EAuthPage.WELCOME_USER); }}
        >
          Log in to your KinKeeper account instead.
        </KAnchor>
      </Flex>
    </Flex>
  );
};
