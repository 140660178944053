import { ECardType } from "./card";

export const privacyPoliciesSourceImport = [
  "KinKeeper is HIPAA compliant",
  "Your data is fully encrypted and we do not, and never will, sell or use your data for marketing",
  "You have complete control over who has access and can delete your information at any time",
];

export enum EEMRPage {
  IMPORT_SELECT = "import-select",
  IMPORT_SUCCESS = "import-success",
  FETCHING_RECORD = "fetching-record",
  SYNC_FAILED = "sync-failed",
}

export enum EMRExportStatus {
  PENDING = "pending",
  EXPORT_SUCCESS = "export-success",
  EXPORT_FAILED = "export-failed",
  SUCCESS = "success",
}

export const MAX_NUMBER_OF_IMPORTS_PER_MONTH = 10;

export const infoTypeTextMedRecord: Record<string, string> = {
  [ECardType.Allergies]: "Allergies",
  [ECardType.Appointments]: "Appointments",
  [ECardType.Conditions]: "Conditions",
  [ECardType.Medications]: "Medications",
  [ECardType.Practitioners]: "Practitioners",
  [ECardType.Procedures]: "Procedures",
  [ECardType.Vaccinations]: "Vaccinations",
};
