import { apiInstance } from "@apis";
import { VERIFY_PIN_ENDPOINT } from "@constants/url";
import { useKContext } from "@context";
import { useCustomNavigate } from "@hooks";
import { EStatusCode, EUrlPath, IParams } from "@types";
import {
  getToken,
  removeToken
} from "@utils/storage";
import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

export const AxiosInterceptor = () => {
  const {
    arrayPath,
    setShowErrorResult,
    isUserPath,
    handleUserUnauthorized,
  } = useKContext();
  const { navigate } = useCustomNavigate();
  const { tempShareId, emergencyAccessId } = useParams<IParams>();
  const [responseStatus, setResponseStatus] = useState<number>(0);

  useEffect(() => {
    if (!responseStatus) return;
    (async () => {
      switch (responseStatus) {
        case EStatusCode.NOT_FOUND:
          setShowErrorResult(EStatusCode.NOT_FOUND);
          break;
        case EStatusCode.UNAUTHORIZED:
          if (isUserPath) {
            if (!getToken("WebAppRefresh")) return handleUserUnauthorized();
            break;
          }

          if (arrayPath?.[1] === EUrlPath.EMERGENCY_ACCESS) {
            removeToken("EmergencyAccess");
            navigate(
              `/${EUrlPath.EMERGENCY_ACCESS}/${emergencyAccessId}/welcome`
            );
            break;
          }

          if (tempShareId) {
            removeToken("TempShare");
            navigate(tempShareId);
            break;
          }

          break;
        case EStatusCode.BAD_REQUEST:
          if (arrayPath?.[1] === EUrlPath.EMERGENCY_ACCESS) {
            setShowErrorResult(EStatusCode.NOT_FOUND);
          }
          break;
        default:
          break;
      }
    })();
  }, [responseStatus]);

  useMemo(() => {
    const requestInterceptor = apiInstance.interceptors.request.use(
      async (config) => {
        const temporaryToken = getToken("TempShare");
        const tokenWebApp = getToken("WebApp");
        const exportToken = getToken("Export");
        const emerAccessToken = getToken("EmergencyAccess");
        if (temporaryToken) {
          config.headers["x-temporary-share-token"] = temporaryToken;
        }

        if (isUserPath) {
          if (tokenWebApp) {
            config.headers["Authorization"] = `Bearer ${tokenWebApp}`;
          } else {
            navigate("/auth/sign-in");
            throw new axios.Cancel("No token available for user requests");
          }
        }

        if (exportToken) {
          config.headers["x-file-export-token"] = exportToken;
        }
        if (emerAccessToken && arrayPath?.[1] === EUrlPath.EMERGENCY_ACCESS) {
          config.headers["x-temporary-share-token"] = emerAccessToken;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    const responseInterceptor = apiInstance.interceptors.response.use(
      (response) => {
        setResponseStatus(+response?.status);
        return response;
      },
      async (error) => {
        const { url, method } = error.config;

        if (
          url === VERIFY_PIN_ENDPOINT &&
          method === "put" &&
          +error.response.status === 401
        ) {
          return Promise.reject(error);
        }

        setResponseStatus(+error.response.status);
        return Promise.reject(error);
      }
    );

    return () => {
      apiInstance.interceptors.request.eject(requestInterceptor);
      apiInstance.interceptors.response.eject(responseInterceptor);
    };
  }, [arrayPath, tempShareId, emergencyAccessId]);

  return <></>;
};
