import { EDateTimeFormat } from "@types";
import { DatePicker } from "antd";
import { KDatePickerProps } from "components/DatePicker/types";
import { styledInputIcon } from "components/Input/styles";
import moment from "moment";
import React, { useMemo } from "react";
import styled from "styled-components";
import { Moment } from "moment";
import momentGenerateConfig from "rc-picker/lib/generate/moment";

const TimePicker = styled(DatePicker.generatePicker<Moment>(momentGenerateConfig))`
  ${styledInputIcon}
`;

export const KTimePicker: React.FC<KDatePickerProps> = ({
  value = "",
  format = "TIME_AM_PM",
  ...props
}) => {
  const validValue = useMemo(() => {
    return moment(value).isValid() ? moment(value) : null;
  }, [value]);

  return (
    <TimePicker changeOnBlur value={validValue} picker="time" format={EDateTimeFormat[format]} {...props}/>
  );
};
