import { OpenInNewIcon } from "@assets/icons";
import { Color, urlRegex } from "@types";
import { Flex, Input, InputProps } from "antd";
import React from "react";
import { useFormContext } from "react-hook-form";
import styled from "styled-components";

const StyledTabInput = styled(Input)`
  .ant-input {
    color: ${Color.PrimaryBase};
  }
`;

export const KInputLink: React.FC<InputProps> = ({ ...props }) => {
  const { getValues } = useFormContext();
  const handleNavigate = () => {
    const checkUrlRegex = new RegExp(urlRegex);
    if (
      !getValues("patientPortal") ||
      !checkUrlRegex.test(getValues("patientPortal"))
    )
      return;
    window.open(getValues("patientPortal"), "_blank");
  };
  return (
    <StyledTabInput
      style={{ color: Color.PrimaryBase }}
      suffix={
        <Flex onClick={handleNavigate} style={{ cursor: "pointer" }}>
          <OpenInNewIcon />
        </Flex>
      }
      {...props}
    />
  );
};
