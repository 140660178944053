import React from "react";
import { CardWrapper } from "./styles";
import { ICardProps } from "./types";

export const KCard: React.FC<ICardProps> = (props) => (
  <CardWrapper {...props} vertical gap={16}/>
);

export * from './styles';
export * from './types';
