import { IndeterminateIcon } from "@assets/icons";
import {
  CardTitle,
  ICheckboxForm,
  KButton,
  KCheckBox,
  KLgText700,
  KMdTextSecondary65,
  KSmTextSecondary50,
  KText,
  KTextarea,
  StyledModal
} from "@components";
import { useKContext } from "@context";
import { useLoggingService, usePremiumPlan } from "@hooks";
import { toggleCompleteToDo } from "@services";
import { useDetailCard, useRoleUser, useUser } from "@store";
import {
  EToDoTriggerText,
  ICardTodoDetail,
  ICardTodoRes,
  IParams,
} from "@types";
import { getCriteriaCardType } from "@utils/index";
import { Flex, message } from "antd";
import { modalTodoProps } from "config";
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";

interface CompleteTaskModalContentProps {
  title?: string;
  comment: string;
  setComment: Dispatch<SetStateAction<string>>;
}

interface IMarkCompleteToDo extends Omit<ICheckboxForm, "name"> {
  card: ICardTodoDetail | ICardTodoRes;
  isDetail?: boolean;
}

const CompleteTaskModalContent: FC<CompleteTaskModalContentProps> = ({
  title,
  comment,
  setComment,
}) => (
  <Flex vertical gap={8}>
    {title && <KLgText700>{title}</KLgText700>}
    <Flex gap={2}>
      <KText>Any comments you’d like to add?</KText>
      <KMdTextSecondary65>(optional)</KMdTextSecondary65>
    </Flex>
    <KTextarea
      placeholder="Enter comments"
      disabled={false}
      value={comment}
      onChange={(e) => setComment(e.target.value)}
    />
  </Flex>
);

export const MarkCompleteToDo: FC<IMarkCompleteToDo> = ({
  title,
  icon,
  card,
  isDetail
}) => {
  const { profileId, cardId, tempShareId } = useParams<IParams>();
  const [loading, setLoading] = useState(false);
  const [openMarkComplete, setOpenMarkComplete] = useState(false);
  const [openCriteria, setOpenCriteria] = useState(false);
  const [openIncomplete, setOpenIncomplete] = useState(false);
  const [comment, setComment] = useState("");
  const [toDo, setToDo] = useState<ICardTodoDetail | ICardTodoRes>();
  const { forceUpdateList, onUpdateDetailCard } = useKContext();
  const { roleEditor, roleViewer } = useRoleUser();
  const { userInfo } = useUser();
  const isPremium = usePremiumPlan();
  const { setForceUpdateDetail, setOpenAddCard } = useDetailCard();
  const { pushInfoTypeLog } = useLoggingService();
  const {
    _id,
    title: titleModal,
    trigger = EToDoTriggerText.MARK_AS_COMPLETE,
    isCompleted,
    isMissed,
    createdBy,
  } = toDo || {};

  const isDisabled = useMemo(
    () =>
      !!tempShareId ||
      !isPremium ||
      (!(roleEditor || userInfo?._id === createdBy?._id) &&
        roleViewer &&
        isCompleted),
    [
      userInfo,
      roleEditor,
      roleViewer,
      isCompleted,
      createdBy,
      tempShareId,
      isPremium,
    ]
  );

  useEffect(() => {
    setToDo(card);
  }, [card]);

  const onSubmitComplete = useCallback(
    async (complete: boolean) => {
      if (!profileId || !_id || !toDo) return;
      try {
        setLoading(true);
        const payload = { comment, isCompleted: complete };
        const { data } = await toggleCompleteToDo(profileId, _id, payload);

        const updatedToDo = { ...data, isCompleted: complete, trigger };
        setToDo(updatedToDo);
        if (cardId === _id || isDetail) {
          if (isDetail) {
            const { attachments } = toDo;
            await onUpdateDetailCard({ ...updatedToDo, attachments });
          }
          setForceUpdateDetail();
        }

        pushInfoTypeLog("MODIFIED", toDo, "TO_DO");
        message.success(`${complete ? `Mark as complete` : "Incomplete"} successfully!`);
        openMarkComplete && setOpenMarkComplete(false);
        openCriteria && setOpenCriteria(false);
        openIncomplete && setOpenIncomplete(false);
        forceUpdateList();
        setComment("");
      } catch (err: any) {
        message.error(err?.response?.data?.message);
      } finally {
        setLoading(false);
      }
    },
    [
      profileId,
      _id,
      toDo,
      cardId,
      comment,
      openMarkComplete,
      openCriteria,
      isDetail,
      openIncomplete,
      trigger
    ]
  );

  const onComplete = useCallback(async (checked: boolean) => {
    if (isDisabled) return;
    if (!checked) return setOpenIncomplete(true);
    if (trigger === EToDoTriggerText.MARK_AS_COMPLETE) {
      return setOpenMarkComplete(true);
    }
    setOpenCriteria(true);
  }, [trigger, isDisabled]);

  const onCancelComplete = () => {
    toDo && setToDo({ ...toDo, isCompleted: false });
    openMarkComplete && setOpenMarkComplete(false);
    openCriteria && setOpenCriteria(false);
    openIncomplete && setOpenIncomplete(false);
  };

  const onAddCriteria = async () => {
    await onSubmitComplete(true);
    const cardType = getCriteriaCardType(trigger);
    if (!cardType) return;
    setOpenAddCard({ open: true, cardType });
  };

  return (
    <>
      <CardTitle
        icon={icon}
        children={title}
        suffix={
          isMissed ? (
            <IndeterminateIcon onClick={() => onComplete(true)} />
          ) : (
            <KCheckBox
              onChange={(e) => onComplete(e.target.checked)}
              checked={isCompleted}
              disabled={isDisabled}
            />
          )
        }
      />
      <StyledModal
        {...modalTodoProps}
        open={openMarkComplete}
        onOk={() => onSubmitComplete(true)}
        onCancel={onCancelComplete}
        confirmLoading={loading}
        title={<KSmTextSecondary50>Complete this task?</KSmTextSecondary50>}
        okButtonProps={{ style: { flex: 1 } }}
        okText="Yes, complete task"
        footer={(OkBtn, CancelBtn) => (
          <Flex flex={1}>
            <CancelBtn.CancelBtn />
            <CancelBtn.OkBtn />
          </Flex>
        )}
      >
        <CompleteTaskModalContent title={titleModal} comment={comment} setComment={setComment} />
      </StyledModal>
      <StyledModal
        {...modalTodoProps}
        open={openCriteria}
        onOk={() => onSubmitComplete(true)}
        onCancel={onCancelComplete}
        confirmLoading={loading}
        title={<KSmTextSecondary50>Mark this To-Do complete?</KSmTextSecondary50>}
        okText="Just mark complete"
        okButtonProps={{ type: "default", style: { flex: 1 } }}
        footer={(OkBtn, CancelBtn) => (
          <Flex vertical gap={8}>
            <Flex flex={1}>
              <CancelBtn.CancelBtn />
              <CancelBtn.OkBtn />
            </Flex>
            <KButton title={`Yes, and ${trigger}`} onClick={onAddCriteria} loading={loading} />
          </Flex>
        )}
      >
        <CompleteTaskModalContent title={titleModal} comment={comment} setComment={setComment} />
      </StyledModal>
      <StyledModal
        {...modalTodoProps}
        width={414}
        open={openIncomplete}
        onOk={() => onSubmitComplete(false)}
        onCancel={() => setOpenIncomplete(false)}
        confirmLoading={loading}
        title={
          <Flex vertical>
            <KSmTextSecondary50>Mark as incomplete?</KSmTextSecondary50>
            <KLgText700>{titleModal}</KLgText700>
          </Flex>
        }
        okButtonProps={{ style: { flex: 1 } }}
        okText="Yes, mark incomplete"
        footer={(OkBtn, CancelBtn) => (
          <Flex flex={1}>
            <CancelBtn.CancelBtn />
            <CancelBtn.OkBtn />
          </Flex>
        )}
      />
    </>
  );
};
