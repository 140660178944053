import { KButton, KModalAddCard } from "@components";
import { ECardType } from "@types";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { KButtonProps } from "./types";

interface IButtonAddCard extends Omit<KButtonProps, "title"> {
  cardType: ECardType;
  title?: string;
}

export const ButtonAddCard = ({
  cardType,
  title = "Add New",
  ...props
}: IButtonAddCard) => {
  const [openModal, setOpenModal] = useState(false);
  const method = useFormContext();

  return (
    <>
      <KButton
        ghost
        size="small"
        title={title}
        onClick={() => setOpenModal(true)}
        {...props}
      />
      <KModalAddCard
        updateMethods={method}
        cardType={cardType}
        onOpen={setOpenModal}
        open={openModal}
      />
    </>
  );
};
