import { FC } from "react";
import { IconProps } from "./types";

export const RefillEmptyIcon: FC<IconProps> = ({
  height = 28,
  width = 22,
  color = "white",
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.5 22H19.75V11.375C19.75 6.96562 16.4906 3.32187 12.25 2.71562V1.5C12.25 0.809375 11.6906 0.25 11 0.25C10.3094 0.25 9.75 0.809375 9.75 1.5V2.71562C5.50938 3.32187 2.25 6.96562 2.25 11.375V22H1.5C0.946875 22 0.5 22.4469 0.5 23V24C0.5 24.1375 0.6125 24.25 0.75 24.25H7.5C7.5 26.1813 9.06875 27.75 11 27.75C12.9313 27.75 14.5 26.1813 14.5 24.25H21.25C21.3875 24.25 21.5 24.1375 21.5 24V23C21.5 22.4469 21.0531 22 20.5 22ZM11 25.75C10.1719 25.75 9.5 25.0781 9.5 24.25H12.5C12.5 25.0781 11.8281 25.75 11 25.75ZM4.5 22V11.375C4.5 9.6375 5.175 8.00625 6.40312 6.77812C7.63125 5.55 9.2625 4.875 11 4.875C12.7375 4.875 14.3687 5.55 15.5969 6.77812C16.825 8.00625 17.5 9.6375 17.5 11.375V22H4.5Z"
      fill={color}
    />
  </svg>
);
