import { FC, SVGProps } from "react";
import { IconProps } from "./types";

export const HospitalizationInfoTypeIcon: FC<IconProps> = ({
  width = 24,
  height,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height || width}
    viewBox="0 0 24 24"
    {...props}
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M20.2188 0.5H4.28125C3.86641 0.5 3.53125 0.835156 3.53125 1.25V20.7188H2.22969C1.84297 20.7188 1.53125 21.0539 1.53125 21.4688V22.3125C1.53125 22.4156 1.60859 22.5 1.70469 22.5H22.793C22.8891 22.5 22.9664 22.4156 22.9664 22.3125V21.4688C22.9688 21.0539 22.657 20.7188 22.2703 20.7188H20.9688V1.25C20.9688 0.835156 20.6336 0.5 20.2188 0.5ZM14.9688 20.7188H19.4688V2H5.03125V20.7188H9.53125V15.25C9.53125 14.8352 9.86641 14.5 10.2812 14.5H14.2188C14.6336 14.5 14.9688 14.8352 14.9688 15.25V20.7188ZM13.4688 20.7188V16H11.0312V20.7188H13.4688ZM10.15 4.5C10.15 4.22386 10.3739 4 10.65 4H13.85C14.1261 4 14.35 4.22386 14.35 4.5V6.4H16.25C16.5261 6.4 16.75 6.62386 16.75 6.9V10.1C16.75 10.3761 16.5261 10.6 16.25 10.6H14.35V12.5C14.35 12.7761 14.1261 13 13.85 13H10.65C10.3739 13 10.15 12.7761 10.15 12.5V10.6H8.25C7.97386 10.6 7.75 10.3761 7.75 10.1V6.9C7.75 6.62386 7.97386 6.4 8.25 6.4H10.15V4.5ZM9.25 7.9V9.1H11.65V11.5H12.85V9.1H15.25V7.9H12.85V5.5H11.65V7.9H9.25Z"/>
  </svg>
);
