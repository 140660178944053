export const ShareEllipseIcon = ({
  width = 16,
  height,
}: {
  height?: number;
  width?: number;
}) => (
  <svg
    width={width}
    height={height || width}
    viewBox={`0 0 24 24`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="12" fill="url(#paint0_linear_122_37565)" />
    <path
      d="M15.6667 5C16.9553 5 18 6.04467 18 7.33333C18 8.622 16.9553 9.66667 15.6667 9.66667C14.9646 9.66667 14.335 9.35661 13.9072 8.86597L8.6154 11.5113C8.64899 11.6689 8.66667 11.8324 8.66667 12C8.66667 12.1678 8.64895 12.3315 8.61528 12.4893L13.9067 15.1346C14.3345 14.6436 14.9643 14.3333 15.6667 14.3333C16.9553 14.3333 18 15.378 18 16.6667C18 17.9553 16.9553 19 15.6667 19C14.378 19 13.3333 17.9553 13.3333 16.6667C13.3333 16.499 13.351 16.3355 13.3846 16.1779L8.09278 13.5326C7.66502 14.0233 7.03539 14.3333 6.33333 14.3333C5.04467 14.3333 4 13.2887 4 12C4 10.7113 5.04467 9.66667 6.33333 9.66667C7.03567 9.66667 7.66553 9.97697 8.0933 10.468L13.3847 7.82265C13.3511 7.66486 13.3333 7.50117 13.3333 7.33333C13.3333 6.04467 14.378 5 15.6667 5Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_122_37565"
        x1="12"
        y1="4.18006e-08"
        x2="12"
        y2="19.6364"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#83C4CE" />
        <stop offset="0.604167" stopColor="#2C7178" />
        <stop offset="1" stopColor="#81C2CC" />
      </linearGradient>
    </defs>
  </svg>
);
