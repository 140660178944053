import { getProfileByIdService } from "@services";
import { IProfileBasic, IProfilesSelectRes, IUserAccessProfile } from "@types";
import { getToken } from "@utils/index";
import { create } from "zustand";

type State = {
  profile: IProfileBasic | null;
  profileSelected: IProfilesSelectRes | null;
  setProfile: (data: IProfileBasic | null) => void;
  setProfileSelected: (data: IProfilesSelectRes | null) => void;
  loadingProfile: boolean;
  setLoadingProfile: (value: boolean) => void;
  fetchProfileInfo: (profileId: string) => void;
  usersAccessProfile: IUserAccessProfile[] | null;
  setUsersAccessProfile: (data: IUserAccessProfile[]) => void;
  isProfilePremium?: boolean;
};

type SwitchProfilesState = {
  openSwitch: boolean;
  setOpenSwitch: (value: boolean) => void;
  resetSwitch: string;
  setResetSwitch: (data: string) => void;
};

export const useProfile = create<State>((set) => ({
  profile: null,
  profileSelected: null,
  setProfile: (data) =>
    set({ profile: data, isProfilePremium: !!data?.isPremium }),
  setProfileSelected: (profileSelected) => set({ profileSelected }),
  loadingProfile: false,
  setLoadingProfile: (val) => set({ loadingProfile: val }),
  fetchProfileInfo: async (profileId) => {
    const token = getToken("WebApp");
    if (!token) return;
    try {
      set({ loadingProfile: true });
      const res = await getProfileByIdService(profileId);
      set({ profile: res });
    } catch (error) {
      console.error(error);
    } finally {
      set({ loadingProfile: false });
    }
  },
  usersAccessProfile: null,
  setUsersAccessProfile: (data) => set({ usersAccessProfile: data }),
}));

export const useSwitchProfiles = create<SwitchProfilesState>((set) => ({
  openSwitch: false,
  setOpenSwitch: (value) => set({ openSwitch: value }),
  profileSwitches: null,
  resetSwitch: "",
  setResetSwitch: (data) => set({ resetSwitch: data }),
}));
