import { Flex } from "antd";
import { useCallback, useContext, useMemo } from "react";
import { useParams } from "react-router-dom";

import { CardItemStyled, CardTitle, KMdTextSecondary65, KTag } from "@components";
import { KContext } from "@context";
import {
  ECardType,
  EDateTimeFormat,
  EMedicationStatus,
  IItemCardProps,
  IMedicationItem,
  IParams,
} from "@types";
import { getCardDateStr } from "@utils/date";
import { getInfoCard } from "@utils/cardTypeIcon";
import { CardItemContainer } from "..";
import { useCustomNavigate } from "@hooks";

export const MedicationItem = ({ card, showIcon, showMetadata, isDetail, showBorder }: IItemCardProps<IMedicationItem>) => {
  const { _id, title, startTime, endTime } = card;
  const { navigate } = useCustomNavigate();
  const { setCardTypeSelected, navigateDetailCard } = useContext(KContext);
  const { cardId } = useParams<IParams>();
  const iconCardType = showIcon && getInfoCard(ECardType.Medications).icon;

  const onClickItem = useCallback(() => {
    if (cardId === _id) return;
    if (!isDetail) {
      return navigate(
        _id,
        undefined,
        () => { setCardTypeSelected(ECardType.Medications); },
      );
    }
    navigateDetailCard(ECardType.Medications, _id);
  }, [_id, cardId]);

  const isActiveItem = useMemo(
    () => card?.status === EMedicationStatus.ACTIVELY_TAKING,
    [card?.status]
  );

  return (
    <CardItemStyled $isActive={cardId === _id} onClick={onClickItem} $showBorder={showBorder}>
      <CardItemContainer showMetadata={showMetadata} card={card}>
        <Flex justify="space-between" align="center" gap={4}>
          <CardTitle icon={iconCardType} flex={1}>
            {title}
          </CardTitle>
          {!!(card.takeAsNeeded && isActiveItem) && <KTag>As Needed</KTag>}
          {!!card.status && !isActiveItem && <KTag>{card.status}</KTag>}
        </Flex>
        {startTime && (
          <KMdTextSecondary65 $ellipsis numberOfLines={2}>
            {getCardDateStr(startTime, endTime, EDateTimeFormat.FULL_MONTH_YEAR)}
          </KMdTextSecondary65>
        )}
      </CardItemContainer>
    </CardItemStyled>
  );
};
