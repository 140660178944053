import { ContentWrapper, KCard } from "@components";
import { KContext, useKContext } from "@context";
import { ECardType, IParams } from "@types";
import { useContext, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  ListAllergies,
  ListAppointments,
  ListCardsRelatedTopic,
  ListConditions,
  ListFiles,
  ListHospitalizations,
  ListIdCards,
  ListMedication,
  ListNotes,
  ListPractitioners,
  ListProcedure,
  ListQuestions,
  ListToDos,
  ListVaccinations,
} from ".";

export const ListContainer = () => {
  const { cardType } = useParams<IParams>();
  const { topics, setCardTypeSelected } = useKContext();

  const isCardType = useMemo(() => (
    Object.values(ECardType).includes(cardType as ECardType)
  ), [cardType]);

  const renderSwitch = useMemo(() => {
    const isTopic = topics.findIndex(i => i._id === cardType) >= 0;
    if (!isCardType && isTopic) {
      return <ListCardsRelatedTopic />;
    }
    if (!isCardType && !isTopic && topics.length > 0) {
      return <NotFoundCard />;
    }
    switch (cardType) {
      case ECardType.Procedures:
        return <ListProcedure />;
      case ECardType.Allergies:
        return <ListAllergies />;
      case ECardType.Conditions:
        return <ListConditions />;
      case ECardType.Medications:
        return <ListMedication />;
      case ECardType.Appointments:
        return <ListAppointments />;
      case ECardType.Hospitalizations:
        return <ListHospitalizations />;
      case ECardType.Vaccinations:
        return <ListVaccinations />;
      case ECardType.Questions:
        return <ListQuestions />;
      case ECardType.IdCard:
        return <ListIdCards />;
      case ECardType.Practitioners:
        return <ListPractitioners />;
      case ECardType.Notes:
        return <ListNotes />;
      case ECardType.Files:
        return <ListFiles />;
      case ECardType.ToDos:
        return <ListToDos />;
      default:
        return null;
    }
  }, [cardType, topics, isCardType]);

  useEffect(() => {
    if (isCardType) {
      setCardTypeSelected(cardType as ECardType);
    }
  }, [cardType, isCardType]);

  return (
    <KCard>
      <ContentWrapper>
        {renderSwitch}
      </ContentWrapper>
    </KCard>
  );
};

const NotFoundCard = () => {
  const { setShowErrorResult } = useContext(KContext);
  useEffect(() => {
    setShowErrorResult(404);
  }, []);
  return <></>;
}
