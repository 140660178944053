import { KinKeeperCircleIcon } from "@assets/index";
import { KAvatar, KMdTextSecondary65 } from "@components";
import { ICardTodoDetail, EDateTimeFormat } from "@types";
import { Flex } from "antd";
import { EAvatarSize } from "components/enums";
import moment from "moment";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";

export const ActivitySection = () => {
  const { getValues, watch } = useFormContext<ICardTodoDetail>();
  const sortedActivity = useMemo(
    () =>
      getValues("activity")?.sort((a, b) =>
        moment(b.createdTime).diff(moment(a.createdTime))
      ),
    [watch("activity")]
  );

  return sortedActivity?.length > 0 ? (
    <Flex gap={16} vertical>
      {sortedActivity.map((act, idx) => (
        <Flex gap={8} vertical key={idx}>
          <Flex gap={4} align="center">
            {act.createdBy === "system" || !act.createdBy ? (
              <KinKeeperCircleIcon />
            ) : (
              <KAvatar
                imgUrl={act.avatar}
                firstName={act.firstName}
                lastName={act.lastName}
                size={EAvatarSize.small}
                shape="circle"
              />
            )}
            <KMdTextSecondary65>
              {act.firstName} • {""}
              {moment(act.createdTime).format(
                EDateTimeFormat.MONTH_DAY_WITH_SHORT_YEAR_AND_TIME_AM_PM
              )}
            </KMdTextSecondary65>
          </Flex>
          <KMdTextSecondary65>{act.comment}</KMdTextSecondary65>
        </Flex>
      ))}
    </Flex>
  ) : null;
};