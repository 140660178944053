import { CameraFilled, UserAddOutlined } from "@ant-design/icons";
import { EAvatarSize, KAvatar, KButton, Loading } from "@components";
import { KContext } from "@context";
import { zodResolver } from "@hookform/resolvers/zod";
import { useLoggingService, useUnsavedChangesWarning } from "@hooks";
import { updateProfileDetail, uploadAvatarProfile } from "@services";
import { useProfile, useRoleUser } from "@store";
import { Color, EErrorMessage, ESpacing, HealthDetail, IParams, IProfileBasic, SSN_REGEX, birthdayRegex, phoneNumberUSAuthRegex } from "@types";
import { isImageFile } from "@utils/files";
import { formatPhoneNumber } from "@utils/formatPhoneNumber";
import { Flex, Form, Upload, UploadFile, message } from "antd";
import { UploadChangeParam } from "antd/es/upload";
import { useContext, useEffect, useMemo, useState } from "react";
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useParams } from "react-router-dom";
import { z } from "zod";
import { EmergencyContact, HealthDetailInfo, PersonalInfo } from "./components";

const schema = z.object({
  basicInformation: z.object({
    firstName: z.string().min(1, EErrorMessage.REQUIRED),
    middleName: z.string().optional(),
    lastName: z.string().min(1, EErrorMessage.REQUIRED),
    birthDate: z.string().refine((value) => birthdayRegex.test(value), {
      message: EErrorMessage.REQUIRED,
    }),
    SSN: z
      .string()
      .nullable()
      .optional()
      .or(z.literal(''))
      .refine((value) => !value || SSN_REGEX.test(value), {
        message: EErrorMessage.SSN,
      }),
  }),
  emergencyContacts: z.array(
    z.object({
      firstName: z.string().min(1, EErrorMessage.REQUIRED),
      lastName: z.string().min(1, EErrorMessage.REQUIRED),
      phoneNumber: z.string().regex(phoneNumberUSAuthRegex, EErrorMessage.PHONE),
      email: z.string().email().optional().or(z.literal('')),
    }),
  ).optional(),
});

const initialEmergency = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
}

const ProfileBasicPage = () => {
  const { setIsViewingProfile } = useContext(KContext);
  const { profileId } = useParams<IParams>();
  const [isSubmittingAvt, setIsSubmittingAvt] = useState(false);
  const { roleEditor } = useRoleUser();
  const { setProfile, profile, loadingProfile } = useProfile();
  const { pushProfileInfoLog } = useLoggingService();

  const method = useForm<IProfileBasic>({
    resolver: zodResolver(schema),
    mode: 'onTouched',
  });

  const {
    formState: { isValid, isSubmitting, isDirty },
    getValues,
    reset,
    control
  } = method;

  useUnsavedChangesWarning(isDirty && roleEditor);

  const { append } = useFieldArray({ control, name: "emergencyContacts" });

  useEffect(() => {
    setIsViewingProfile(true);
    return () => {
      setIsViewingProfile(false);
    };
  }, []);

  useEffect(() => {
    const emergencyContacts = profile?.emergencyContacts?.map(
      (data) => ({
        ...data,
        phoneNumber: formatPhoneNumber(data.phoneNumber),
      })
    );
    const basicInformation = {
      ...profile?.basicInformation,
      SSN: profile?.basicInformation.SSN || null,
      middleName: profile?.basicInformation.middleName || "", 
    };
    profile && reset(
      {
        ...profile,
        emergencyContacts,
        basicInformation
      },
      {
        keepDirty: false,
        keepDefaultValues: false,
      }
    );
  }, [profile]);

  const basicInformation = useMemo(() => {
    return profile?.basicInformation;
  }, [profile?.basicInformation]);

  const isLoadingBtn = useMemo(() => {
    return isSubmitting || isSubmittingAvt
  }, [isSubmitting, isSubmittingAvt]);

  const handleUploadAvatar = async (file: UploadChangeParam<UploadFile>) => {
    if (!profileId || !file?.file?.originFileObj) return;
    const uploadedFile = file.file.originFileObj;
    if (!isImageFile(uploadedFile)) {
      message.error('Invalid file type. Please upload an image.');
      return;
    }
    try {
      setIsSubmittingAvt(true);
      const { url: avatar } = await uploadAvatarProfile(profileId, uploadedFile);
      setProfile({ ...getValues(), avatar });
      pushProfileInfoLog("MODIFIED", getValues());
      message.success('Update avatar successfully!');
    } catch (err: any) {
      message.error(err?.response?.data?.message);
    } finally {
      setIsSubmittingAvt(false);
    }
  }

  const onSubmitProfile = async () => {
    if (!profileId) return;

    const data = getValues();
    const { height = "", weight = "" } = data.healthDetail || {};
    const healthDetail: HealthDetail = { ...data.healthDetail, height, weight };
    try {
      const res = await updateProfileDetail(profileId, {
        ...data,
        healthDetail,
        basicInformation: {
          ...data.basicInformation,
          SSN: data.basicInformation.SSN || undefined,
        },
      });
      setProfile(res);
      pushProfileInfoLog("MODIFIED", getValues());
      message.success('Update profile successfully!');
    } catch (err: any) {
      message.error(err?.response?.data?.message);
    }
  };

  return loadingProfile ? (
    <Loading />
  ) : (
    <Flex vertical gap={8} style={{ backgroundColor: Color.White, padding: ESpacing.xl, flex: 1 }}>
      <Flex justify="space-between" gap={16} wrap="wrap">
        <Flex gap={16}>
          <KAvatar
            firstName={basicInformation?.firstName || ""}
            lastName={basicInformation?.lastName || ""}
            size={EAvatarSize.extra_large}
            imgUrl={profile?.avatar || ""}
          />
          <Upload
            onChange={handleUploadAvatar}
            customRequest={(op) => false}
            maxCount={1}
            multiple={false}
            showUploadList={false}
          >
            {roleEditor && (
              <KButton
                icon={<CameraFilled />}
                loading={isLoadingBtn}
                title="Edit Photo"
                size="large"
                ghost
              />
            )}
          </Upload>
        </Flex>
        {roleEditor && (
          <KButton
            loading={isLoadingBtn}
            onClick={onSubmitProfile}
            htmlType="submit"
            title="Save"
            size="large"
            disabled={!isDirty || !isValid}
          />
        )}
      </Flex>
      <FormProvider {...method}>
        <Form style={{ overflowY: 'auto' }} disabled={!roleEditor}>
          <Flex vertical gap={16} style={{ overflowY: 'auto', flex: 1 }}>
            <PersonalInfo />
            <EmergencyContact />
            {roleEditor && (
              <KButton
                ghost
                loading={isSubmitting}
                icon={<UserAddOutlined />}
                title="Add Contact"
                size="large"
                onClick={() => append(initialEmergency)}
              />
            )}
            <HealthDetailInfo />
          </Flex>
        </Form>
      </FormProvider>
    </Flex>
  );
};

export default ProfileBasicPage;
