import { ECardType } from ".";

export enum EToDoTriggerText {
  MARK_AS_COMPLETE = 'Just mark as complete',
  ADD_NOTE = 'Add a Note',
  UPLOAD_FILE = 'Upload a File',
  ADD_APPOINTMENT = 'Add Appointment Details',
  ADD_QUESTION = 'Add Question',
  ADD_TODO = 'Add another To-Do',
}

export const todoTriggers = [
  {
    text: EToDoTriggerText.MARK_AS_COMPLETE,
    cardType: null,
  },
  {
    text: EToDoTriggerText.ADD_NOTE,
    cardType: ECardType.Notes,
  },
  {
    text: EToDoTriggerText.UPLOAD_FILE,
    cardType: ECardType.Files,
  },
  {
    text: EToDoTriggerText.ADD_APPOINTMENT,
    cardType: ECardType.Appointments,
  },
  {
    text: EToDoTriggerText.ADD_QUESTION,
    cardType: ECardType.Questions,
  },
  {
    text: EToDoTriggerText.ADD_TODO,
    cardType: ECardType.ToDos,
  },
];

export enum EToDoRepeatStatus {
  NEVER = 'Never',
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
  EVERY_2_WEEKS = 'Every 2 weeks',
  EVERY_4_WEEKS = 'Every 4 weeks',
  MONTHLY = 'Monthly',
  YEARLY = 'Yearly',
}

export enum ETodoType {
  ASSIGN = 'assign',
  CLAIM = 'claim',
}

export const repeatOptions = Object.values(EToDoRepeatStatus).map((i) => ({ label: i, value: i }));
export const triggerOptions = Object.values(EToDoTriggerText).map((i) => ({ label: i, value: i }));