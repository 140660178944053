import {
  BackNavFormHeader,
  K2XlText700Secondary88,
  KButton,
  KErrorText,
  KMdText700PrimaryBase,
  KMdText700Secondary88,
  KPin,
} from '@components';
import { useCustomNavigate, useFetchSummaryService } from '@hooks';
import { useAuthStore } from '@store';
import { EStatusSuccess, IParams } from '@types';
import { createUUID, setToken, storeAppAccessTokens } from '@utils/storage';
import { Flex, message } from 'antd';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { authenticateWithEmailOrSms, verifyOtpCode } from 'services/auth';
import { EAuthPage } from 'types/auth.type';

export const VerifyOtpLogin = () => {
  const { navigate } = useCustomNavigate();
  const { getPrincipal } = useFetchSummaryService();
  const [code, setCode] = useState('');
  const [error, setError] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const { loginData, setLoginData, isCSLFlow, setShowModal, setCurrentPage } = useAuthStore();

  const { tempShareId } = useParams<IParams>();

  const resendOtp = async () => {
    if (!loginData?.type) return;
    try {
      await authenticateWithEmailOrSms({
        email: loginData.email,
        type: loginData.type,
        tempShareId,
      });
    } catch (error: any) {
      message.error(error?.response?.data?.message || 'Email not found!');
    }
  };

  const onVerifyOtp = async () => {
    setSubmitting(true);
    try {
      const deviceToken = createUUID();
      const res = await verifyOtpCode({
        code,
        email: loginData.email,
        deviceToken,
      });
      if (res.code === EStatusSuccess.CREATED) {
        storeAppAccessTokens(res.data);
        message.success('Login successful');
        setLoginData('');
        setShowModal(false);
        setCurrentPage(EAuthPage.AUTH_SCREEN);
        if (isCSLFlow && tempShareId) {
          await getPrincipal(tempShareId);
          navigate(`/${tempShareId}`, { replace: true });
        } else {
          setToken('Pin', new Date().getTime());
          navigate('/user');
        }
      }
    } catch (error) {
      setError(true);
    } finally {
      setSubmitting(false);
    }
  };

  const onChangeCode = (val: string) => {
    error && setError(false);
    setCode(val);
  };

  return (
    <Flex vertical gap={20}>
      <BackNavFormHeader screenKey={EAuthPage.WELCOME_USER} />

      <K2XlText700Secondary88>
        Enter the code we sent to login to your account:
      </K2XlText700Secondary88>

      <Flex style={{ alignSelf: 'center' }}>
        <KPin
          code={code}
          setCode={onChangeCode}
          isError={error}
          onFocus={() => setError(false)}
        />
      </Flex>

      {error && (
        <KErrorText style={{ textAlign: 'center', padding: '0 20px' }}>
          This code is invalid or has expired. Try again or go back to request another.
        </KErrorText>
      )}

      <KButton
        size='large'
        title='Log in'
        type='primary'
        disabled={code.length !== 6 || isSubmitting}
        onClick={onVerifyOtp}
      />

      <KMdText700Secondary88 style={{ textAlign: 'center' }}>
        {`Didn’t receive your code? `}
        <KMdText700PrimaryBase
          style={{ cursor: 'pointer' }}
          onClick={resendOtp}
          disabled={isSubmitting}
        >
          Try again.
        </KMdText700PrimaryBase>
      </KMdText700Secondary88>
    </Flex>
  );
};
