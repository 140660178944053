import { create } from "zustand";
import { AuthSchemaType, SignUpSchemaType } from "Schema";
import { AUTH_TYPE, EAuthPage } from "@types";

type State = {
  userInfo: AuthSchemaType & SignUpSchemaType;
  currentPage: EAuthPage;
  loginData: {
    email: string;
    type?: AUTH_TYPE;
  };
  isLoadingCSL: boolean;
  isCSLFlow: boolean;
  showModal: boolean;
  canAccess: boolean;
  isRecognize: boolean;
  isFromMobile: boolean;
  isAuthenticated: boolean;
  setLoginData: (email: string, type?: AUTH_TYPE) => void;
  setCurrentPage: (page: EAuthPage) => void;
  setUserInfo: (data: AuthSchemaType & SignUpSchemaType) => void;
  resetUserInfo: () => void;
  setIsLoadingCSL: (loading: boolean) => void;
  setIsCSLFlow: (isCSLFlow: boolean) => void;
  setShowModal: (showModal: boolean) => void;
  setCanAccess: (canAccess: boolean) => void;
  setIsRecognize: (isRecognize: boolean) => void;
  setIsFromMobile: (isFromMobile: boolean) => void;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
};

const defaultUserInfo: AuthSchemaType & SignUpSchemaType = {
  email: "",
  firstName: "",
  lastName: "",
  phoneNumber: "",
  ageAccepted: false,
  termsAccepted: false,
  policyAccepted: false,
};

export const useAuthStore = create<State>((set) => ({
  userInfo: defaultUserInfo,
  currentPage: EAuthPage.AUTH_SCREEN,
  loginData: {
    email: "",
  },
  isLoadingCSL: false,
  isCSLFlow: false,
  showModal: true,
  canAccess: false,
  isRecognize: false,
  isFromMobile: false,
  isAuthenticated: false,
  setLoginData: (email, type) => set({ loginData: { email, type } }),
  setCurrentPage: (page) => set({ currentPage: page }),
  setUserInfo: (data) => set({ userInfo: data }),
  resetUserInfo: () => set({ userInfo: defaultUserInfo }),
  setIsLoadingCSL: (loading) => set({ isLoadingCSL: loading }),
  setIsCSLFlow: (isCSLFlow) => set({ isCSLFlow }),
  setShowModal: (showModal) => set({ showModal }),
  setCanAccess: (canAccess) => set({ canAccess }),
  setIsRecognize: (isRecognize) => set({ isRecognize }),
  setIsFromMobile: (isFromMobile) => set({ isFromMobile }),
  setIsAuthenticated: (isAuthenticated) => set({ isAuthenticated }),
}));
