import { AllHTMLAttributes, FC, useState, ClipboardEvent } from "react";
import OtpInput from "react-otp-input";

import { CODE_LENGTH, EFontSize } from "@types";
import { containerStyle, inputStyle } from "./style";

export interface IKPinProps
  extends Omit<AllHTMLAttributes<HTMLInputElement>, "size"> {
  code: string;
  setCode: (val: string) => void;
  isError?: boolean;
  codeLength?: number;
  size?: keyof typeof EFontSize;
}

export const KPin: FC<IKPinProps> = ({
  code,
  setCode,
  isError = false,
  codeLength,
  size,
  ...rest
}) => {
  const [isFocus, setIsFocus] = useState(false);
  return (
    <OtpInput
      shouldAutoFocus={true}
      containerStyle={containerStyle(isError, isFocus, size)}
      onChange={setCode}
      value={code}
      inputStyle={inputStyle(isError, size)}
      numInputs={codeLength || CODE_LENGTH}
      inputType="tel"
      renderInput={(props) => (
        <input
          {...props}
          {...rest}
          type="tel"
          className="pin-input"
          autoComplete="new-password"
          onFocus={() => setIsFocus(true)}
          onBlur={() => setIsFocus(false)}
        />
      )}
      onPaste={(e: ClipboardEvent<HTMLDivElement>) =>
        setCode(
          e.clipboardData
            .getData("text")
            .replace(/\D/g, "")
            .slice(0, CODE_LENGTH)
        )
      }
    />
  );
};
