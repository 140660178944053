import { Flex } from "antd";
import { useCallback, useContext, useMemo } from "react";
import { useParams } from "react-router-dom";

import { AppointmentInfoTypeIcon, MedicationInfoTypeIcon } from "@assets/icons";
import { CardItemStyled, CardTitle, KMdTextSecondary65, KTag } from "@components";
import { KContext } from "@context";
import { ECardType, EDateTimeFormat, IItemCardProps, IParams, IQuestionItemList } from "@types";
import { formatTimeRangeCard, getInfoCard } from "@utils/index";
import { CardItemContainer } from "..";
import { useCustomNavigate } from "@hooks";

export const QuestionItem = ({ card, showIcon, showMetadata, isDetail }: IItemCardProps<IQuestionItemList>) => {
  const { _id, title, practitionerName, practitioner, appointment, appointmentName, answer, startTime, endTime } = card;
  const { cardId } = useParams<IParams>();
  const { navigate } = useCustomNavigate();
  const { setCardTypeSelected, navigateDetailCard } = useContext(KContext);
  const iconCardType = showIcon && getInfoCard(ECardType.Questions).icon;

  const onClickItem = useCallback(() => {
    if (cardId === _id) return;
    if (!isDetail) {
      return navigate(
        _id,
        undefined,
        () => { setCardTypeSelected(ECardType.Questions); },
      );
    }
    navigateDetailCard(ECardType.Questions, _id);
  }, [_id, cardId]);

  const appointmentTitle = useMemo(() => {
    if (typeof appointment === "object" && appointment?._id) {
      return appointment.title;
    }
    return appointmentName;
  }, [appointment]);

  return (
    <CardItemStyled $isActive={cardId === _id} onClick={onClickItem}>
      <CardItemContainer showMetadata={showMetadata} card={card}>
        <CardTitle icon={iconCardType}>
          {title}
        </CardTitle>
        {!!startTime && (
          <KMdTextSecondary65>
            {formatTimeRangeCard(startTime, endTime, EDateTimeFormat.FULL_MONTH_YEAR)}
          </KMdTextSecondary65>
        )}
        {(!!practitioner || !!appointment) && (
          <Flex gap={4} align="center">
            {!!practitioner && (
              <KTag icon={<MedicationInfoTypeIcon width={16} height={16} />}>
                {practitionerName}
              </KTag>
            )}
            {!!appointmentTitle && (
              <KTag icon={<AppointmentInfoTypeIcon width={16} height={16} />}>
                {appointmentTitle}
              </KTag>
            )}
          </Flex>
        )}
        {!!answer && (
          <KMdTextSecondary65>{answer}</KMdTextSecondary65>
        )}
      </CardItemContainer>
    </CardItemStyled>
  );
};
