import { Layout } from "antd";
import styled from "styled-components";

import { heightFooter } from "@constants/size";
import { Color } from "@types";

const { Footer } = Layout;

const RedemptionFooterContainerMobile = styled(Footer)`
  text-align: center;
  width: 100%;
  background-color: ${Color.White};
`;

const RedemptionFooterContainer = styled(Footer)`
  text-align: center;
  bottom: 0;
  height: ${heightFooter}px;
  width: 100%;
  background-color: ${Color.White};
  position: absolute;
`;

export { RedemptionFooterContainer, RedemptionFooterContainerMobile };
