import { Divider, Flex } from "antd";
import { useCallback, useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { FolderOpenOutlinedIcon, NoteInfoTypeIcon, PaperClipOutlinedIcon } from "@assets/icons";
import { CardItemStyled, CardTitle, KMdText700PrimaryBase, KMdTextSecondary65, KTag } from "@components";
import { KContext } from "@context";
import { ECardType, EDateTimeFormat, IItemCardProps, INote, IParams } from "@types";
import { MetadataDetail } from "..";
import { useCustomNavigate } from "@hooks";
import { formatDate, formatTimeRangeCard } from "@utils/date";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  .description {
    ol, ul {
      list-style-position: inside;
    }
    i, span {
      overflow-wrap: break-word;
    }
  }
  .ant-divider {
    margin: 8px 0 !important;
  }
`;

const NoteDescription = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

export const NoteItem = ({ card, isDetail, showMetadata }: IItemCardProps<INote>) => {
  const {
    _id,
    title,
    description,
    attachments,
    topics,
    startTime,
  } = card;
  const { cardId, cardType } = useParams<IParams>();
  const { navigate } = useCustomNavigate();
  const { setCardTypeSelected, navigateDetailCard } = useContext(KContext);

  const onClickItem = useCallback(() => {
    if (cardId === _id) return;
    if (!isDetail) {
      return navigate(
        _id,
        undefined,
        () => { setCardTypeSelected(ECardType.Notes); },
      );
    }
    navigateDetailCard(ECardType.Notes, _id);
  }, [_id, cardId]);

  const showTopics = useMemo(() => (
    !!Object.values(ECardType).includes(cardType as ECardType) &&
    !!topics.length
  ), [cardType]);

  return (
    <CardItemStyled $isActive={cardId === _id} onClick={onClickItem}>
      <Container>
        <CardTitle icon={<NoteInfoTypeIcon width={20} height={20} />}>
          {title}
        </CardTitle>
        {startTime && (
          <KMdTextSecondary65>
            {formatDate(startTime, "FULL_MONTH_YEAR_WITH_TIME_AM_PM")}
          </KMdTextSecondary65>
        )}
        {description && (
          <NoteDescription
            className="description"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
        {!!(attachments?.length || showTopics) && (
          <>
            <Divider />
            {!!attachments.length && (
              <Flex gap={4} wrap="wrap">
                {attachments.map(i => (
                  <KTag key={i._id} icon={<PaperClipOutlinedIcon width={16} height={16} />}>
                    {i.originalName}
                  </KTag>
                ))}
              </Flex>
            )}
            {showTopics && (
              <Flex gap={8} vertical>
                {topics.map(i => (
                  <Flex gap={4} key={i._id}>
                    <FolderOpenOutlinedIcon />
                    <KMdText700PrimaryBase>{i.title}</KMdText700PrimaryBase>
                  </Flex>
                ))}
              </Flex>
            )}
          </>
        )}
        {showMetadata && <MetadataDetail {...card} />}
      </Container>
    </CardItemStyled>
  );
};
