import { EFileSort } from "@types";
import { Select } from "antd";
import { useState } from "react";
import { IconFilter, SortFile } from "../list/styled-component";

export const SortItem = ({
  sort,
  setSort,
}: {
  sort: EFileSort;
  setSort: (val: EFileSort) => void;
}) => {
  const [openSortFile, setOpenSortFile] = useState(false);

  return (
    <SortFile
      onClick={() => setOpenSortFile(!openSortFile)}
      onBlur={() => setOpenSortFile(false)}
      onChange={(value) => setSort(value as EFileSort)}
      suffixIcon={<IconFilter />}
      defaultValue={EFileSort.New}
      value={sort}
      open={openSortFile}
    >
      <Select.Option value={EFileSort.New}>Newest First</Select.Option>
      <Select.Option value={EFileSort.Old}>Oldest First</Select.Option>
      <Select.Option value={EFileSort.A}>A to Z</Select.Option>
      <Select.Option value={EFileSort.Z}>Z to A</Select.Option>
      <Select.Option value={EFileSort.Recent}>Recent Activity</Select.Option>
    </SortFile>
  );
};
