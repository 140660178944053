import {
  CardItemStyled,
  CardTitle,
  KMdTextPrimaryBase,
  KMdTextSecondary65
} from "@components";
import { KContext } from "@context";
import { ECardType, EDateTimeFormat, ICardPractitionerRes, IItemCardProps, IParams } from "@types";
import { formatPhoneNumber, formatTimeRangeCard, getInfoCard } from "@utils/index";
import { useCallback, useContext } from "react";
import { useParams } from "react-router-dom";
import { CardItemContainer } from "..";
import { useCustomNavigate } from "@hooks";

export const PractitionerItem = ({
  card,
  showIcon,
  showMetadata,
  isDetail
}: IItemCardProps<ICardPractitionerRes>) => {
  const { navigate } = useCustomNavigate();
  const { _id, title, specialty, phone, cardType, startTime, endTime } = card;
  const { cardId } = useParams<IParams>();
  const { setCardTypeSelected, navigateDetailCard } = useContext(KContext);
  const iconCardType = showIcon && getInfoCard(cardType).icon;

  const onClickItem = useCallback(() => {
    if (cardId === _id) return;
    if (!isDetail) {
      return navigate(
        _id,
        undefined,
        () => { setCardTypeSelected(ECardType.Practitioners); },
      );
    }
    navigateDetailCard(ECardType.Practitioners, _id);
  }, [_id, cardId]);

  return (
    <CardItemStyled $isActive={cardId === _id} onClick={onClickItem}>
      <CardItemContainer showMetadata={showMetadata} card={card}>
        <CardTitle icon={iconCardType}>{title}</CardTitle>
        {specialty && <KMdTextSecondary65>{specialty}</KMdTextSecondary65>}
        {phone && <KMdTextPrimaryBase>{formatPhoneNumber(phone)}</KMdTextPrimaryBase>}
        {startTime && (
          <KMdTextSecondary65 $ellipsis numberOfLines={2}>
            {formatTimeRangeCard(
              startTime,
              endTime,
              EDateTimeFormat.FULL_MONTH_YEAR
            )}
          </KMdTextSecondary65>
        )}
      </CardItemContainer>
    </CardItemStyled>
  );
};
