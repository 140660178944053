export enum ELocalFileType {
  AVATAR = 'avatar',
}

export enum EDocumentFile {
  DOC = 'doc',
  DOCX = 'docx',
}

export enum EExcelFile {
  XLS = 'xls',
  XLSX = 'xlsx',
}

export enum EPowerPointFile {
  PPT = 'ppt',
  PPTX = 'pptx',
  PPS = 'pps',
  PPSX = 'ppsx',
}

export enum EPDFFile {
  PDF = 'pdf',
}

export enum ETextFile {
  TXT = 'txt',
  ODT = 'odt',
  RTF = 'rtf',
}

export enum EMediaFile {
  MP3 = 'mp3',
  MP4 = 'mp4',
}

export enum EMedicalFile {
  DCM = 'dcm',
  DICOM = 'dicom',
}

export enum EImageFile {
  JPG = 'jpg',
  JPEG = 'jpeg',
  PNG = 'png',
  TIFF = 'tiff',
  TIF = 'tif',
  BMP = 'bmp',
  HEIC = 'heic',
  WEBP = 'webp',
}

export const fileTypesPreview = [
  'doc',
  'docx',
  'xls',
  'xlsx',
  'ppt',
  'pptx',
  'pps',
  'ppsx',
  'pdf',
  'txt',
  'odt',
  'rtf',
];
