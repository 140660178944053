import { useKContext } from "@context";
import {
  getListCardsEmergencyAccessService,
  getListCardsService,
  getListTemporaryService,
} from "@services";
import { useListInfoType, InfoTypeProps } from "@store";
import { ECardType, ICardQuery, IParams } from "@types";
import { getQueryListInfoTypes } from "@utils/card";
import { getInfoCard } from "@utils/cardTypeIcon";
import { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";

type ISetListCardTypeMap = {
  [key in ECardType | string]: {
    active: keyof InfoTypeProps;
    inactive: keyof InfoTypeProps;
  };
};

export const useFetchInfoTypesService = (
  cardName: keyof typeof ECardType,
  query?: ICardQuery
) => {
  const { forceUpdateListIndex } = useKContext();
  const { setListInfoType, setLoadingList, setLoadingInactiveList } =
    useListInfoType();
  const { tempShareId, profileId, emergencyAccessId } = useParams<IParams>();
  const cardType = ECardType[cardName];

  const setListInfoTypeMap = useCallback(
    (cardType: ECardType, data: any, isActiveList = true) => {
      const listMap: ISetListCardTypeMap = {
        [ECardType.Procedures]: {
          active: "upcomingProcedureList",
          inactive: "pastProcedureList",
        },
        [ECardType.Allergies]: {
          active: "presentAllergyList",
          inactive: "pastAllergyList",
        },
        [ECardType.Conditions]: {
          active: "presentConditionsList",
          inactive: "pastConditionsList",
        },
        [ECardType.Medications]: {
          active: "presentMedicationList",
          inactive: "pastMedicationList",
        },
        [ECardType.Appointments]: {
          active: "upcomingAppointmentList",
          inactive: "pastAppointmentList",
        },
        [ECardType.Hospitalizations]: {
          active: "hospitalizationsList",
          inactive: "hospitalizationsList",
        },
        [ECardType.Vaccinations]: {
          active: "validVaccinationList",
          inactive: "outDateVaccinationList",
        },
        [ECardType.Questions]: {
          active: "unansweredQsList",
          inactive: "otherQsList",
        },
        [ECardType.IdCard]: {
          active: "presentCardIdList",
          inactive: "pastCardIdList",
        },
        [ECardType.Practitioners]: {
          active: "presentPractitionerList",
          inactive: "pastPractitionerList",
        },
        [ECardType.Notes]: { active: "cardNoteList", inactive: "cardNoteList" },
        [ECardType.Files]: { active: "cardFileList", inactive: "cardFileList" },
        [ECardType.ToDos]: {
          active: "activeToDoList",
          inactive: "completedToDoList",
        },
      };

      const listKey = isActiveList
        ? listMap[cardType].active
        : listMap[cardType].inactive;
      if (listKey) {
        setListInfoType({ [listKey]: data });
      }
    },
    [setListInfoType]
  );

  const fetchEmergencyList = useCallback(
    async (isActiveList = true) => {
      if (!emergencyAccessId) return;
      try {
        setLoadingList(true);
        const { activeQuery, inactiveQuery } = getQueryListInfoTypes(cardType);
        if (isActiveList) {
          setLoadingList(true);
          const data = await getListCardsEmergencyAccessService<any>(
            emergencyAccessId,
            cardType,
            {
              ...activeQuery,
              ...query,
            }
          );
          setListInfoTypeMap(cardType, data, isActiveList);
        } else {
          setLoadingInactiveList(true);
          const data = await getListCardsEmergencyAccessService<any>(
            emergencyAccessId,
            cardType,
            inactiveQuery
          );
          setListInfoTypeMap(cardType, data, false);
        }
      } catch (error) {
      } finally {
        if (isActiveList) return setLoadingList(false);
        setLoadingInactiveList(false);
      }
    },
    [
      emergencyAccessId,
      cardType,
      setListInfoTypeMap,
      query,
      setLoadingList,
      setLoadingInactiveList,
    ]
  );

  const fetchCardListUser = useCallback(
    async (isActiveList = true) => {
      const cardParam = getInfoCard(cardType).path;
      if (!profileId || !cardParam) return;
      try {
        const { activeQuery, inactiveQuery } = getQueryListInfoTypes(cardType);
        if (isActiveList) {
          setLoadingList(true);
          const data = await getListCardsService<any>(profileId, cardParam, {
            ...activeQuery,
            ...query,
          });
          setListInfoTypeMap(cardType, data, isActiveList);
        } else {
          setLoadingInactiveList(true);
          const data = await getListCardsService<any>(
            profileId,
            cardParam,
            inactiveQuery
          );
          setListInfoTypeMap(cardType, data, false);
        }
      } catch (error) {
      } finally {
        if (isActiveList) return setLoadingList(false);
        setLoadingInactiveList(false);
      }
    },
    [
      profileId,
      cardType,
      setListInfoTypeMap,
      query,
      setLoadingList,
      setLoadingInactiveList,
    ]
  );

  const fetchTemporaryShareList = useCallback(
    async (isActiveList = true) => {
      if (!tempShareId) return;
      try {
        setLoadingList(true);
        const { activeQuery, inactiveQuery } = getQueryListInfoTypes(cardType);
        if (isActiveList) {
          setLoadingList(true);
          const data = await getListTemporaryService<any>(
            tempShareId,
            cardType,
            {
              ...activeQuery,
              ...query,
            }
          );
          setListInfoTypeMap(cardType, data, isActiveList);
        } else {
          setLoadingInactiveList(true);
          const data = await getListTemporaryService<any>(
            tempShareId,
            cardType,
            inactiveQuery
          );
          setListInfoTypeMap(cardType, data, false);
        }
      } catch (error) {
      } finally {
        setLoadingList(false);
      }
    },
    [tempShareId, cardType, setListInfoTypeMap, query, setLoadingList]
  );

  useEffect(() => {
    fetchEmergencyList();
    fetchCardListUser();
    fetchTemporaryShareList();
    return () => {
      setListInfoTypeMap(cardType, []);
    };
  }, [profileId, emergencyAccessId, forceUpdateListIndex, query]);

  useEffect(() => {
    fetchEmergencyList(false);
    fetchCardListUser(false);
    fetchTemporaryShareList(false);
    return () => {
      setListInfoTypeMap(cardType, []);
    };
  }, [profileId, emergencyAccessId, forceUpdateListIndex]);
};
