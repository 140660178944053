import { Flex } from "antd";
import { useContext, useMemo } from "react";
import { useParams } from "react-router-dom";

import { CardItemStyled, KMdTextSecondary65 } from "@components";
import { KContext } from "@context";
import { ECardType, ICardTodoRes, IItemCardProps, IParams } from "@types";
import { getInfoCard } from "@utils/cardTypeIcon";
import { getTodoDateStr } from "@utils/date";

import { useCustomNavigate } from "@hooks";
import { CardItemContainer } from "../../share-component";
import { AssignedAndCompletion } from "./AssignedAndCompletion";
import { CardToDoTitle } from "./CardToDoTitle";
import { LinkedInfoTags } from "./LinkedInfoTags";

export const ToDoItem = ({
  card,
  showIcon,
  showMetadata,
  isDetail = false,
  showCheckbox = true,
}: IItemCardProps<ICardTodoRes>) => {
  const { cardId } = useParams<IParams>();
  const { navigate } = useCustomNavigate();
  const { setCardTypeSelected, navigateDetailCard } = useContext(KContext);
  const { _id, linkedInfos, isMissed, activity, cardType, startTime, isCompleted } = card;
  const iconCardType = showIcon ? getInfoCard(cardType).icon : null;

  const onClickItem = () => {
    if (!isDetail) {
      return navigate(
        _id,
        undefined,
        () => { setCardTypeSelected(ECardType.ToDos); },
      );
    }
    navigateDetailCard(ECardType.ToDos, _id);
  };

  const renderDateTime = useMemo(() => {
    const lastCompletedTime =
      activity?.length > 0 ? activity[activity.length - 1].createdTime : "";
    const dateTimeToDo = isCompleted ? lastCompletedTime : startTime;
    return getTodoDateStr(dateTimeToDo, isCompleted);
  }, [card]);

  return (
    <CardItemStyled
      $isActive={cardId === _id}
      onClick={onClickItem}
    >
      <CardItemContainer showMetadata={showMetadata} card={card}>
        <Flex justify="space-between" align="center" gap={4} flex={1}>
          <CardToDoTitle {...card} icon={iconCardType} />
          {showCheckbox && (
            <Flex align="center" gap={8}>
              <AssignedAndCompletion {...card} />
            </Flex>
          )}
        </Flex>
        {isMissed && !!activity && (
          <KMdTextSecondary65 $ellipsis numberOfLines={2}>
            {activity[activity?.length - 1]?.comment}
          </KMdTextSecondary65>
        )}
        <LinkedInfoTags linkedInfos={linkedInfos}/>
        {(!!renderDateTime && !isMissed) && (
          <KMdTextSecondary65 $ellipsis numberOfLines={2}>
            {renderDateTime}
          </KMdTextSecondary65>
        )}
      </CardItemContainer>
    </CardItemStyled>
  );
};

export * from "./LinkedInfoTags"
